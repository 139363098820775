import axios from 'axios';
import jwt_decode from "jwt-decode";
import { getCookie, setCookie, removeCookie } from './CookieUtil';

/* 헤더 인증키 가져오기 */
export function getHttpAuthHeader(){

    const authCheck = getCookie("AuthCheck");
    const authToken = getCookie("AuthToken");
    if(authCheck && authToken) {
        return `Bearer ${authToken}`;
    }

    return "";
};

/* Access Token 보관 */
export function AuthTokenSet(token){        

    if(token){        
        setCookie("AuthCheck", true, {path: "/"});
        setCookie("AuthToken", token, {path: "/"});             
    } else {
        delete axios.defaults.headers.common['Authorization'];
        removeCookie("AuthCheck");
        removeCookie("AuthToken");        
    }
};

/* Token 기반 사용자 기본 정보 */
export function getTokenInfo(token){

    let tokenInfo = null;

    try{
        if(!!token && token.indexOf(".") !== -1){
            tokenInfo = jwt_decode(token);
        } 
    } catch(e) {
        console.log(e);
    }

    return tokenInfo;
};

/* Token 유효성 판별 */
export function VarifyToken(location){  
    
    const menuList = JSON.parse(localStorage.getItem("accessMenuList"));
    
    let found = undefined;

    if (location != undefined){
        localStorage.setItem('lastUrl', location.pathname + location.search);                

        if (menuList != null && menuList != undefined){
            found = menuList.find(e => e.url === location.pathname);    
        }        
    }
    
    const authCheck = getCookie("AuthCheck");
    const authToken = getCookie("AuthToken");
    const userId = getCookie("usrId");

    if(authCheck && authToken) {
        const tokenInfo = getTokenInfo(authToken);

        //키 정보 유무
        if(!tokenInfo){
            return false;
        }
        
        //사용자 확인
        if(userId !== tokenInfo.sub){
            return false;
        }

        //키 유효 시간 확인
        if((tokenInfo.exp * 1000) < Date.now()){
            return false;
        }

        //메뉴 권한 확인        
        if(found === undefined){
            return false;
        }

        return true;

    }else{

        return false;
    }
};


/* 로그아웃 */
export function logout(){    

    removeCookie("usrId");
    removeCookie("AuthCheck");
    removeCookie("AuthToken");
    
    window.location.replace("/login");
}