import React from 'react';

export default function Sample(props){
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox="0 0 31.671 31.671"
      fill={props.color ? props.color : "currentColor"}      
    >      
      <path d="M13.188,2.723H2.158C0.955,2.723,0,3.658,0,4.863v2.362c0,0.997,0.645,1.835,1.52,2.098v14.869 c0,0.041,0.018,0.082,0.02,0.125c0.094,1.603,1.499,4.631,6.127,4.631c4.284,0,5.879-2.896,6.113-4.426 c0.016-0.108,0.043-0.222,0.043-0.33V9.323c0.874-0.263,1.52-1.101,1.52-2.098V4.863C15.342,3.658,14.392,2.723,13.188,2.723z M13.177,6.467c0,0.409-0.345,0.736-0.756,0.736h-0.034c-0.412,0-0.728,0.339-0.728,0.75v16.238c0,0-0.424,2.576-3.968,2.576 c-3.79,0-3.969-2.576-3.969-2.576V7.955c0-0.411-0.348-0.75-0.759-0.75H2.927c-0.41,0-0.724-0.327-0.724-0.736v-0.83 c0-0.411,0.313-0.751,0.724-0.751h9.494c0.411,0,0.756,0.34,0.756,0.751V6.467z"></path> <path d="M4.899,23.619c0,0,0.113,2.081,2.772,2.081c2.486,0,2.771-2.081,2.771-2.081v-6.884H4.899V23.619z"></path> <path d="M29.525,2.723H18.583c-1.195,0-2.142,0.936-2.142,2.141v2.362c0,0.997,0.646,1.835,1.521,2.098v14.869 c0,0.041,0.011,0.082,0.013,0.125c0.092,1.603,1.484,4.631,6.076,4.631c4.254,0,5.83-2.896,6.062-4.426 c0.016-0.108,0.037-0.222,0.037-0.33V9.323c0.875-0.263,1.521-1.101,1.521-2.098V4.863C31.671,3.658,30.725,2.723,29.525,2.723z M29.506,6.468c0,0.41-0.331,0.735-0.738,0.735H28.73c-0.404,0-0.742,0.34-0.742,0.75v16.238c0,0-0.411,2.576-3.932,2.576 c-3.761,0-3.931-2.576-3.931-2.576V7.955c0-0.41-0.341-0.75-0.747-0.75h-0.036c-0.405,0-0.733-0.325-0.733-0.735V5.64 c0-0.41,0.328-0.752,0.733-0.752h9.425c0.407,0,0.738,0.342,0.738,0.752V6.468z"></path> <path d="M21.305,23.619c0,0,0.115,2.081,2.75,2.081c2.471,0,2.756-2.081,2.756-2.081V7.468h-5.506V23.619z"></path>
    </svg>
  );
};