//Date형을 yyyymmdd 문자열로 반환
export const getYYYYMMDD = (date) => {
    const sYear = date.getFullYear();
    let sMonth = date.getMonth() + 1;
    let sDate = date.getDate();

    sMonth = sMonth > 9 ? sMonth : "0" + sMonth;
    sDate  = sDate > 9 ? sDate : "0" + sDate;
    
    return "" + sYear + sMonth + sDate;
}

export const addMonth = (date, month) => {    
    date.setMonth(date.getMonth() + month);
    return date;
}