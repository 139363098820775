import React, {useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomTextField from "../components/ui/CustomTextField";
import { Box, Modal, Divider, Button } from '@mui/material';
import axiosInstance from '../components/util/AxiosUtil';
import CustomDatatable from '../components/ui/CustomDatatable';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import Toast from '../components/ui/Toast';
import { viewModeType } from '../components/const/CommCode'
import Confirm from '../components/ui/Confirm';
import CustomAutocomplete from "../components/ui/CustomAutocomplete";

const defaultValues = {
    compSeq : '',
    auth : '',
    usrId : '',
    usrNm : '',
    hpno : '',
    eml : '',
    usrSts : '',
    pwd: ''
}

const defaultFindValues = {
    compSeq : '',
    usrId: '',
    usrNm: '',
    usrSts: ''
}

function User() {

    //조회 기본세팅
    const { 
        handleSubmit : find_handleSubmit, 
        control : find_control, 
        getValues: find_getValues, 
        formState: { errors: find_errors } 
    } = useForm( {defaultFindValues} );

    // [modal] 등록,수정 Form 기본세팅
    const { 
        handleSubmit, 
        getValues, 
        reset, 
        control, 
        formState: { errors } 
    } = useForm( {defaultValues} );

    //검색필터
    const [filterData, setFilterData] = useState( {defaultFindValues} );
    //모달 노출 상태 정보
    const [open, setOpen] = useState(false);
    //데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);
    //서버 요청 상태 정보
    const [viewMode, setViewMode] = useState(viewModeType.New);

    //confrim ref 객체
    const confirmRef = useRef();
    //toast ref 객체
    const toastRef = useRef();
    //[검색] 업체
    const [bsCodeList,setBsCodeList] = useState(([{compSeq: "", compNm: "전체"}]));
    //[modal] 업체
    const [mdBsCodeList, setMdBsCodeList] = useState(([{compSeq: "", compNm: "전체"}]));
    //[검색] 상태
    const [stsCodeList, setStsCodeList] = useState(([{dtlCd: "", dtlCdNm: "전체"}]));
    //[modal] 상태
    const [mdStsCodeList, setMdStsCodeList] = useState(([{dtlCd: "", dtlCdNm: "전체"}]));
    //[modal] 권한
    const [authCodeList, setAuthCodeList] = useState(([{auth: "", authDesc: "전체"}]));
    //id disabled 처리
    const [idDisabled,setIdDisabled] = useState(false);

    useEffect(() => {
        makeBsCodeList();
        makeStsCodeList();
        makeAuthCodeList();
    }, []);

    const makeBsCodeList = () => {
        //업체 검색 목록 생성
        axiosInstance.get('/businessCodeList').then((Response)=>{
            if (Response.request.status === 200) {  

                const list =  Response.data.list;
                const newList = [{compSeq: "", compNm: "전체"}, ...list];
                setBsCodeList(newList);

                setMdBsCodeList(list);
               
            }
            dataload(find_getValues());
        });
    }

    const makeStsCodeList = () => {
        //상태 검색 목록 생성
        axiosInstance.get('/codeList', {params: {grpCd: 'USR_STS_CD'}}).then((Response) => {
            if(Response.request.status === 200) {

                const list =  Response.data.list;
                const newList = [{dtlCd: "", dtlCdNm: "전체"}, ...list];
                setStsCodeList(newList);
                setMdStsCodeList(list);
            }
            dataload(find_getValues());
        });
    }

    const makeAuthCodeList = () => {
         //권한 목록 생성
         axiosInstance.get('/roleCodeList').then((Response)=>{
            if (Response.request.status === 200) {  

                const list =  Response.data.list;
                setAuthCodeList(list);
            }
        });
    }

    function dataReload(data){   
        setPageFixed(false);
        setFilterData(data);  
    }

    function dataload(data){
        setPageFixed(true);
        setFilterData(data);        
    }

    //등록버튼 클릭 이벤트
    const regClickEvent = () => {

        //모달 초기화
        reset(defaultValues);

        setIdDisabled(false);

        //등록으로 상태변경
        setViewMode(viewModeType.New);

        //모달 열기
        setOpen(true);
    }

    //테이블 컬럼정보
    const columns = [
        { id: "compNm", data: "compNm", title: '업체' ,width: '20%',className: "text-center", responsivePriority: 1 },
        { id: "usrId", data: "usrId", title: '아이디', width: '20%', className: "text-center",responsivePriority: 2 },
        { id: "usrNm", data: "usrNm", title: '사용자 명', width:'20%', className: "text-center", responsivePriority: 3 },
        { id: "hpno", data: "hpno", title: '연락처', width:'15%', className: "text-center", responsivePriority: 4 },
        { id: "usrStsNm", data: "usrStsNm", title: '상태', width:'15%',className: "text-center", responsivePriority: 5 },
        { id: "usrId", data: "usrId", title: '관리', width:'10%',className: "text-center", responsivePriority: 0, orderable:false }
    ]

    const columnDefs = [
        //수정, 삭제버튼
        {
            className: "text-center p-0", "targets":[5], render: function(data, type, row, meta){
                let customButton = "<button class='data-btn' data-id='" + row.usrId + "' data-type='update' style='margin:2px; padding:3px;'>수정</button>";

                customButton += "<button class='data-btn' data-id='" + row.usrId + "' data-type='delete' style='margin:2px; padding:3px;'>삭제</button>";                                    
                
                return customButton;
            }
        }
    ]

    //테이블 (수정, 삭제버튼) 클릭 콜백함수
    const buttonClick = (e) => {

        if(e.target.dataset.type === "update") {
            
            axiosInstance.get('/user', {params: {usrId: e.target.dataset.id}}).then((Response)=>{
            
                if(Response.request.status === 200){
   
                    setIdDisabled(true);
                    setPageFixed(false);
                    setViewMode(viewModeType.Edit);
                    reset(Response.data.info);
                    setOpen(true);
                }else{
                    toastRef.current.handleOpen("error", "오류입니다. 데이터를 조회 할 수 없습니다.");
                }
            });   

        }else if (e.target.dataset.type === "delete"){            
            confirmRef.current.handleOpen("사용자 정보 삭제", "사용자 정보를 삭제 합니다. 계속 진행 하시겠습니까?", e);
        } 

    }

    const del = (e) =>{
        axiosInstance.delete('/user', {params: {usrId: e.target.dataset.id}}).then((Response)=>{
            
            if(Response.request.status === 200){    
                if(Response.data.resultCode === "SUCCESS"){
                    toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
                    dataReload(find_getValues());
                }else if(Response.data.resultCode === "ERROR"){
                    toastRef.current.handleOpen("error", Response.data.resultMessage);                                        
                }   
              
            }else{                              
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }                  
        });
    }

    const onSubmit = (data) => {
        if (viewMode === viewModeType.New){
            axiosInstance.post('/user', {...data}).then((Response)=>{            
                if(Response.request.status === 200 ){  
                    
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                        setOpen(false);
                        dataReload(find_getValues());

                    //아이디 중복
                    }else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage );
                    }

                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            });        
        }else if (viewMode === viewModeType.Edit){

            axiosInstance.put('/user', {...data}).then((Response)=>{            
                if(Response.request.status === 200){ 
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                        setOpen(false);
                        dataReload(find_getValues());
                    } else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage);                                        
                    }      
                    
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }
            });
        }
    }


    //input css style
    const textFileStyled = { mt:2, width: '100%', 'textAlign': 'center' };

    return (
        <div style={{ 
            "padding": "16px 24px", 
            "color": "rgb(68, 89, 110)", 
            "height": "100%" 
        }}>
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>
                    <div style={{"marginBottom": "28px", "marginTop":"16px"}}>
                        <p fontWeight="600" className="css-1pr8naw">사용자 관리</p>
                        <p className="css-3vvtry">PoSE / 시스템 관리 /사용자 관리</p>
                    </div>         
                </Grid>
                {/* 검색창 */}
                <Grid container 
                  alignItems="center" 
                  xs={12} sm={12} md={12} lg={12} xl={12} 
                  className="page-filter" 
                  sx={{p:0}}
                  justifyContent={'flex-end'}
                >
                     {/* 업체 */}
                     <Grid xs={12} sm={6} md={6} lg={2.5} xl={3} sx={{ p: 2 }}>
                        <CustomAutocomplete 
                            name="compSeq"              
                            label="업체"           
                            options={bsCodeList}
                            value="compSeq"
                            text="compNm"             
                            control={find_control}
                            getValues={find_getValues}
                            errors={find_errors.compSeq}
                        />
                    </Grid>
                    {/* 아이디 */}
                    <Grid xs={12} sm={6} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField
                            fullWidth 
                            name="usrId"              
                            label="아이디"          
                            control={find_control}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){                                    
                                    dataReload(find_getValues());
                                }
                             }}
                        />
                    </Grid>
                    {/* 사용자 명 */}
                    <Grid xs={12} sm={6} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField
                            fullWidth 
                            name="usrNm"              
                            label="사용자 명"          
                            control={find_control}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){                                    
                                    dataReload(find_getValues());
                                }
                             }}
                        />
                    </Grid>
                     {/* 상태 */}
                     <Grid xs={12} sm={6} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomAutocomplete 
                            name="usrSts"              
                            label="상태"           
                            options={stsCodeList}
                            value="dtlCd"
                            text="dtlCdNm"             
                            control={find_control}
                            getValues={find_getValues}
                            errors={find_errors.usrSts}
                        />
                    </Grid>
                    {/* 조회, 등록버튼*/}
                    <Grid 
                        textAlign={'start'} 
                        alignItems="center" 
                        xs={12} sm={12} md={12} lg={3.5} xl={3}
                        sx={{p:0}}
                    >
                        <Button 
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ ml:2, height:'52px' }}  
                            startIcon={<SearchIcon />}
                            onClick={find_handleSubmit(dataload)}
                        >조회
                        </Button>
                        <Button
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ m:2, height:'52px' }} 
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={regClickEvent}
                        >등록
                        </Button>
                    </Grid>
                </Grid>

                {/* table */}
                <Grid container 
                        xs={12} sm={12} md={12} lg={12} xl={12} 
                        className="page-filter" 
                        sx={{ p: 2, mt: 3 }}
                >
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable id="table-list-0"               //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                        width="100%"                     //테이블 너비
                                        url="/usrList"                   //ajax url
                                        columns={columns}                //테이블 컬럼 정의
                                        columnDefs={columnDefs}          //Custom 컬럼 정의
                                        filter={filterData}              //ajax 검색 필터 정의
                                        paging={true}                    //페이징 여부
                                        pageLength="10"                  //페이징 수
                                        buttonClick ={buttonClick}       //버튼 클릭 콜백함수
                                        pageFixed={pageFixed}            //페이징 유지 여부
                                        order={[[0, 'asc']]}             //기본 정렬
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* 등록 / 수정 모달 */}
            <Modal open={open} 
                    onClose={e => setOpen(false)} 
                    aria-labelledby="parent-modal-title" 
                    aria-describedby="parent-modal-description" 
            >
                <Box className="smModal">
                    <h3 id="parent-modal-title" className="limiter">사용자 정보</h3>
                    <Divider sx={{ mt:2 }} />
                    
                    {/* 업체 */}
                    <Grid sx={textFileStyled}>
                        <CustomAutocomplete 
                            fullWidth
                            name="compSeq"              
                            label="*업체"
                            rules={{ required: "업체를 선택 해주세요." }}            
                            options={mdBsCodeList}          
                            value="compSeq"              
                            text="compNm"             
                            control={control}          
                            getValues={getValues}      
                            errors={errors.compSeq}
                        />
                    </Grid>
                    {/* 권한 */}
                    <Grid sx={textFileStyled}>
                        <CustomAutocomplete 
                            fullWidth
                            name="auth"              
                            label="*권한"
                            rules={{ required: "권한을 선택 해주세요." }}            
                            options={authCodeList}          
                            value="auth"              
                            text="authDesc"             
                            control={control}          
                            getValues={getValues}      
                            errors={errors.auth}
                        />
                    </Grid>
                     {/* 아이디 */}
                     <CustomTextField 
                        fullWidth 
                        name="usrId" 
                        label="*아이디" 
                        control={control}
                        disabled ={idDisabled}
                        rules={{ required: "아이디를 입력 해주세요." }} 
                        sx={textFileStyled} 
                        errors={errors.usrId}
                    />
                    {/* 사용자 명 */}
                    <CustomTextField 
                        fullWidth 
                        name="usrNm" 
                        label="*사용자 명" 
                        control={control}
                        rules={{ required: "사용자 명을 입력 해주세요." }} 
                        sx={textFileStyled} 
                        errors={errors.usrNm}
                    />

                    {viewMode === viewModeType.New
                    ?<CustomTextField 
                        fullWidth 
                        name="pwd" 
                        label="*비밀번호" 
                        type="password"
                        control={control}
                        sx={textFileStyled}
                        errors={errors.pwd}
                        rules={{required: '비밀번호를 입력 해주세요.',
                                pattern: {
                                    value: /^[a-zA-Z\\d`~!@#$%^&*()-_=+]{8,16}$/,
                                    message: '8~16자 영문, 숫자, 패스워드를 사용하세요.'
                                }
                        }}
                    /> 
                    :null}

                     {/* 연락처 */}
                     <CustomTextField 
                        fullWidth 
                        name="hpno" 
                        label="연락처" 
                        control={control}
                        sx={textFileStyled} 
                        errors={errors.hpno}
                    />
                    {/* 이메일 */}
                    <CustomTextField 
                        fullWidth 
                        name="eml" 
                        label="이메일" 
                        control={control}
                        sx={textFileStyled} 
                        errors={errors.eml}
                    />
                    {/* 상태 */}
                    <Grid sx={textFileStyled}>
                        <CustomAutocomplete 
                            fullWidth
                            name="usrSts"              
                            label="상태"
                            options={mdStsCodeList}          
                            value="dtlCd"              
                            text="dtlCdNm"             
                            control={control}          
                            getValues={getValues}      
                            errors={errors.usrSts}
                        />
                    </Grid>
                   
                    <Divider sx={{ mt:2, mb:3 }} />
                    
                    <Grid container direction="row" justifyContent="flex-end">
                        <Button variant="contained" size="large" color="primary" startIcon={<SaveAltIcon />} onClick={handleSubmit(onSubmit)}>저장</Button>
                        <Button variant="contained" size="large" color="primary" startIcon={<CloseIcon />} sx={{ ml: 2 }} onClick={e => setOpen(false)}>닫기</Button>
                    </Grid>
                </Box>
            </Modal>

            <Toast ref={toastRef} />
            <Confirm ref={confirmRef} ok={del} />
        </div>
    );
}

export default User;