import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import Router from "./Router";
import localeKo from 'dayjs/locale/ko';

function App() {
  return (    
    <RecoilRoot>
      <BrowserRouter>      
        <Router />
      </BrowserRouter>    
    </RecoilRoot>
  );
}

export default App;
