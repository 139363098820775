import React, { useState, useEffect, useRef, createRef }  from "react";
import axiosInstance from '../../../components/util/AxiosUtil';
import { useForm } from "react-hook-form";
import { Button, Box, Modal, Divider, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CustomTextField from '../../../components/ui/CustomTextField'
import CustomDatePicker from '../../../components/ui/CustomDatePicker'
import defaultProfile from "../../../assets/image/noimage.gif"
import { FileUploader } from "react-drag-drop-files";
import Toast from '../../../components/ui/Toast';
import LhContent from '../print/LhContent';
import ReactToPrint from "react-to-print";

const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

const defaultValues = {
    rtuSeq:"",
    submDay: "",
    cntPsnNm: "",
    rspofNm: "",
    tlno: "",
    ptblTel: "",
    eml: "",
    adr: ""
}

function LhDocument(props) {

    const rtuSeq = new URL(window.location.href).searchParams.get("rtuSeq");    

    const { handleSubmit, reset, setValue, control, formState: { errors } } = useForm({ defaultValues });

    //toast ref 객체
    const toastRef = useRef();

    // 프린터 ref 객체
    const printRef = createRef();

    //팝업 노출 상태 정보
    const [open, setOpen] = useState(false); 

    //연동시험신청서 정보
    const [data, setData] = useState({});

    //연동시험신청서 설비 목록
    const [devList, setDevList] = useState([]);

    //RTU 설치 사진 정보
    const [image001, setImage001] = useState({});

    //REMS 사진
    const [image004, setImage004] = useState({});    

    //이력 이미지 목록 정보
    const [imageHist, setImageHist] = useState([]);

    //버튼 비활성화 여부
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [focusPhotoTypeCd, setFocusPhotoTypeCd] = useState("");

    const handleImgError = (e) => {        
        e.target.src = defaultProfile;
    }   

    //파일 드래그 앤 드랍 컴포넌트 변경 이벤트
    const handleChange = (file, photoTypeCd) => {        
        imageSave(file, photoTypeCd);
    };

    //데이터 조회
    const getData = () => {
        //연동시험신청서 문서 정보 조회
        axiosInstance.get('/lhRtuCompleteDoc', {params: {rtuSeq: rtuSeq}}).then((Response)=>{
            if (Response.request.status === 200) {                 
                setData(Response.data.info);
                reset(Response.data.info);
            }
        });

        //연동시험신청서 문서 설비 목록 조회
        axiosInstance.get('/lhRtuDevList', {params: {rtuSeq: rtuSeq}}).then((Response)=>{
            if (Response.request.status === 200) {                 
                setDevList(Response.data.list);                
            }
        });

        //사진 정보 조회
        getImageList();

        setValue("rutSeq", rtuSeq);
    }

    const save = (data) => {
        axiosInstance.post('/connApyInf', {...data}).then((Response)=>{            
            if(Response.request.status === 200){     
                toastRef.current.handleOpen("success", "저장 되었습니다.");                                                        
            }else{                              
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
            }                  
        }); 
    }

    // 이미지 저장
    const imageSave = (file, photoTypeCd) => {
        //파일 서버 저장
        const formData = new FormData();
        formData.append("rtuSeq", rtuSeq);
        formData.append("photoTypeCd", photoTypeCd);
        formData.append("img", file);            
        
        axiosInstance.post('/photoDoc', formData, {headers: {'content-type': 'multipart/form-data'}}).then((Response)=>{            

            if (Response){
                if(Response.request.status === 200){     

                    toastRef.current.handleOpen("success", "저장 되었습니다.");                                        

                    //파일 저장 성공시 이미지 다시 조회
                    getImageList();

                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
            }
            
        });  
    };

    // 사진 이력 데이터 문서 사진 등록
    const photoHstToDoc = (histImage) => {

        if (histImage){
            axiosInstance.post('/photoHstToDoc', {rtuSeq: rtuSeq, photoTypeCd: histImage.photoTypeCd, photoSeq: histImage.photoSeq}).then((Response)=>{                    

                if (Response){
                    if(Response.request.status === 200){     
    
                        toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
    
                        //파일 저장 성공시 이미지 다시 조회
                        getImageList();
    
                        setOpen(false);
        
                    }else{                              
                        toastRef.current.handleOpen("error", "오류 입니다. 데이터를 삭제 할 수 없습니다.");                                        
                    }                  
                }else{
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 삭제 할 수 없습니다.");                                        
                }
                
            });  
        }       
    };

    // 이미지 삭제
    const del = (photoTypeCd, e) => {
        axiosInstance.delete('/photoDoc', {params: {rtuSeq: rtuSeq, photoTypeCd: photoTypeCd}}).then((Response)=>{                    

            if (Response){
                if(Response.request.status === 200){     

                    toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        

                    //파일 삭제 성공시 이미지 다시 조회
                    getImageList();
    
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 삭제 할 수 없습니다.");                                        
                }                  
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 삭제 할 수 없습니다.");                                        
            }
            
        });  
        
    }

    // 이미지 조회
    const getImageList = () => {          
        axiosInstance.get('/photoDocList', {params: {rtuSeq: rtuSeq}}).then((Response)=>{
            
            if(Response.request.status === 200){

                //초기화
                setImage001({});                
                setImage004({});                                

                Response.data.list.map(function(file){

                    //DB 결과값 이미지 파일 생성
                    const newBlob = new Blob([convertToBlob(file.blob)], {type:file.fileTypeNm});                           
                    const createFile = new File([newBlob], file.fileNm,{type:file.fileTypeNm});                                        

                    //이미지 랜더링 데이터 생성
                    const blod = 'data:' + file.fileTypeNm + ';base64,' + file.blob;

                    //State 추가
                    const item = {
                                    photoTypeCd: file.photoTypeCd, 
                                    file: createFile, 
                                    blod: blod
                                 };

                    if (item.photoTypeCd === "001"){
                        setImage001(item); 
                    }else if (item.photoTypeCd === "004"){
                        setImage004(item); 
                    }
                });   

                
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 조회 할 수 없습니다.");                   
            }
        });  
    }

    // 이미지 이력 목록 조회
    const getImageHistList = (photoTypeCd) => {          
        axiosInstance.get('/photoHstList', {params: {rtuSeq: rtuSeq, photoTypeCd: photoTypeCd}}).then((Response)=>{
            
            if(Response.request.status === 200){

                //초기화
                setImageHist([]);

                Response.data.list.map(function(file){

                    if (file.ord === 0 || file.ord === 1){
                        //DB 결과값 이미지 파일 생성
                        const newBlob = new Blob([convertToBlob(file.blob)], {type:file.fileTypeNm});                           
                        const createFile = new File([newBlob], file.fileNm,{type:file.fileTypeNm});                                        

                        //이미지 랜더링 데이터 생성
                        const blod = 'data:' + file.fileTypeNm + ';base64,' + file.blob;

                        //State 추가
                        const item = {
                                        photoSeq: file.photoSeq,
                                        photoTypeCd: file.photoTypeCd, 
                                        file: createFile, 
                                        blod: blod
                                    };

                        setImageHist(prev => [...prev, item]); 
                    }    
                });   

                
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 조회 할 수 없습니다.");   
            }
        });  
    }

    // Base64 문자열 Blob 타입 변경
    const convertToBlob = (base64) =>{
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        return byteArray;
    }

    // PDF 다운로드
    const PDFDownload = () => {
        axiosInstance.post('/lhRtuCompleteDocPdf', {rtuSeqList: rtuSeq}, {responseType: "blob"}).then((Response)=>{            
            if (Response){
                const blob = new Blob([Response.data]);
                const fileObjectUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = fileObjectUrl;
                link.style.display = "none";

                const disposition = Response.headers["content-disposition"];
                link.download = decodeURI(
                    disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1].replace(/['"]/g, "")
                );
                
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(fileObjectUrl);
                
            }else{
                toastRef.current.handleOpen("error", "시스템 오류 입니다. 관리자에게 문의 하세요.");
            }
        }); 
    }

    // 붙여넣기 이벤트
    document.onpaste = function(pasteEvent) {        
        if (focusPhotoTypeCd !== ""){
            const item = pasteEvent.clipboardData.items[0];
    
            if (item.type.indexOf("image") === 0)
            {
                const orgFile = item.getAsFile();
                const fileExtension = orgFile.name.split('.')[1];
                const fileName = Date.now() + '.' + fileExtension;                
                const newFile = new File([orgFile], fileName, {type: orgFile.type});

                imageSave(newFile, focusPhotoTypeCd);
            }
        }     
    }

    useEffect(() => {
        getData();
    }, []);

    return (

        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)", "height": "100%" }}>

            <Grid container spacing={2} sx={{ height:'100%'}}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>

                    <Grid justifyContent="center" alignItems="center" sx={{ display: 'flex' }}>

                        <Grid container justifyContent="center" alignItems="center" sx={{ display: 'flex', width:'100%', maxWidth:950 }}>
                            
                            <Grid container justifyContent="center" alignItems="center" sx={{ display: 'flex', width:'100%', maxWidth:950 }}>

                                <Grid className="title" xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center" sx={{ display: 'flex' }}>
                                    <p fontWeight="600" className="css-1pr8naw">데이터수집장치(RTU) 연동시험신청서</p>
                                </Grid>

                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" sx={{ display: 'flex', mb: 1 }}>
                                    <p>
                                        ※ LH 친환경•미래에너지 통합모니터링시스템에 접속하여 단지/설비정보 확인 후 기재하시기 바랍니다.<br/>
                                        ※ 설비정보는 개별설비별로 따로 기재하시기 바랍니다.<br/>
                                        ※ 제출/문의: rems@sqisoft.com
                                    </p>
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    제출일        
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    <CustomDatePicker 
                                        fullWidth
                                        name="submDay"          //name.                                        
                                        control={control}       //react hook form control.
                                    />
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sx={{ display: 'flex', pt:0, pb:0, minHeight:28 }}>
                                    기업정보        
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    담당자명        
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    <CustomTextField 
                                        fullWidth
                                        name="cntPsnNm"                                 //name.
                                        label=""                                        //label text.
                                        control={control}                               //react hook form control.                                          
                                    />  
                                </Grid>
                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    직책        
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    <CustomTextField 
                                        fullWidth
                                        name="rspofNm"                                  //name.
                                        label=""                                        //label text.
                                        control={control}                               //react hook form control.                                          
                                    />  
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    전화번호        
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    <CustomTextField 
                                        fullWidth
                                        name="tlno"                                     //name.
                                        label=""                                        //label text.
                                        control={control}                               //react hook form control.                                          
                                    /> 
                                </Grid>
                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    휴대전화        
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    <CustomTextField 
                                        fullWidth
                                        name="ptblTel"                                  //name.
                                        label=""                                        //label text.
                                        control={control}                               //react hook form control.                                          
                                    /> 
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    이메일        
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    <CustomTextField 
                                        fullWidth
                                        name="eml"                                      //name.
                                        label=""                                        //label text.
                                        control={control}                               //react hook form control.                                          
                                    /> 
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sx={{ display: 'flex', pt:0, pb:0, minHeight:28 }}>
                                    단지정보        
                                </Grid>
                                    
                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    단지 ID        
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    {data.siteSeq}
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    단지명       
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    {data.siteNm}
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    지역본부       
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    주소       
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:64}}>
                                    <CustomTextField 
                                        fullWidth
                                        name="addr"                                     //name.
                                        label=""                                        //label text.
                                        control={control}                               //react hook form control.                                          
                                    />  
                                </Grid>

                                {
                                    devList.map((dev, index) => ( 
                                        <Grid key={index} container>
                                            <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sx={{ display: 'flex', pt:0, pb:0, minHeight:28 }}>
                                                설비정보 (개별 설비로 따로 기재할 것)        
                                            </Grid>

                                            <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                설비 ID (설비번호)        
                                            </Grid>
                                            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                {dev.devSeq}
                                            </Grid>
                                            
                                            <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                설비명    
                                            </Grid>
                                            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                {dev.devNm}
                                            </Grid>

                                            <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                설비위치 
                                            </Grid>
                                            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                {dev.instlLoc}
                                            </Grid>

                                            <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                설치용량 
                                            </Grid>
                                            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                {dev.instlCpct}
                                            </Grid>

                                            <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                시공일시 
                                            </Grid>
                                            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                {dev.exworkDt}
                                            </Grid>

                                            <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                시리얼번호 
                                            </Grid>
                                            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                {dev.sn}
                                            </Grid>

                                            <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                검교정 유효기간 
                                            </Grid>
                                            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                                {dev.inspDate}
                                            </Grid>

                                            <Divider sx={{width:"100%"}} />
                                            <Divider sx={{width:"100%"}} />
                                        </Grid>
                                    ))
                                }
                                
                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sx={{ display: 'flex', pt:0, pb:0, minHeight:28 }}>
                                    RTU        
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    RTU 제조사/모델명 
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    {data.rtuModel}
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    직렬통신포트 2개 이상 여부 (RS232 또는 RS485)
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    O
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    Ethernet 포트 1개 이상 여부
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    O
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    USB 포트 2개 이상 여부
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    O  
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    액정표시장치 유무
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:0, pb:0, minHeight:28}}>
                                    O  
                                </Grid>
                                
                                <Grid className={focusPhotoTypeCd === "001" ? "focus border-1" : "border-1"} justifyContent="center" alignItems="center" onClick={()=>setFocusPhotoTypeCd("001")} xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', p:0, height:360}}>
                                    {
                                        image001.blod ?
                                        <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "001")}} name="file" types={fileTypes}> 
                                            <img src={image001.blod} alt="이미지" height="100%" width="100%" style={{maxHeight:350}} onError={handleImgError}/>  
                                        </FileUploader> :
                                        <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "001")}} name="file" types={fileTypes} >
                                            <div className="mouseOverCustom"
                                                style={{
                                                    "borderRadius":"10px",
                                                    "padding": "15px",
                                                    "border" : "3px dashed #1976d2",
                                                    "height" : "280px",
                                                    "width"  : "380px"
                                                }}
                                            >영역을 클릭 또는 이미지 파일을 끌어 놓아 주세요.</div>
                                        </FileUploader>
                                    }  
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sx={{ display: 'flex', pt:0, pb:0, minHeight:28 }}>
                                    RTU 설치 사진  
                                    <Button size="small" variant="contained" onClick={()=> { getImageHistList("001"); setOpen(true); }} sx={{m:1}}>변경</Button>
                                    <Button size="small" variant="contained" onClick={()=>del("001")} sx={{m:1}}>삭제</Button>
                                </Grid>
                                
                                <Grid className={focusPhotoTypeCd === "004" ? "focus border-1" : "border-1"} justifyContent="center" alignItems="center" onClick={()=>setFocusPhotoTypeCd("004")} xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', p:0, height:360}}>
                                    {
                                        image004.blod ?
                                        <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "004")}} name="file" types={fileTypes}> 
                                            <img src={image004.blod} alt="이미지" height="100%" width="100%" style={{maxHeight:350}} onError={handleImgError}/>  
                                        </FileUploader> :
                                        <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "004")}} name="file" types={fileTypes} >
                                            <div className="mouseOverCustom"
                                                style={{
                                                    "borderRadius":"10px",
                                                    "padding": "15px",
                                                    "border" : "3px dashed #1976d2",
                                                    "height" : "280px",
                                                    "width"  : "380px"
                                                }}
                                            >영역을 클릭 또는 이미지 파일을 끌어 놓아 주세요.</div>
                                        </FileUploader>
                                    }  
                                </Grid>                                
                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sx={{ display: 'flex', pt:0, pb:0, minHeight:28 }}>
                                    REMS 사진
                                    <Button size="small" variant="contained" onClick={()=> { getImageHistList("004"); setOpen(true); }} sx={{m:1}}>변경</Button>
                                    <Button size="small" variant="contained" onClick={()=>del("004")} sx={{m:1}}>삭제</Button>
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sx={{ display: 'flex', pt:0, pb:0, minHeight:28 }}>
                                    점검        
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:68}}>
                                    1
                                </Grid>
                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:0, pb:0, minHeight:68}}>
                                    단지 설비 담당자가 작업 내용을 충분히 숙지하도록 교육하였는가? (설비 담당자에게 작업한 내용을 설명하여 충분히 이해하였는지 확인합니다.)
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: 'flex', pt:0, pb:0, minHeight:68}}>
                                    <RadioGroup row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="Y"
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value="Y" control={<Radio />} label="적합" />
                                        <FormControlLabel value="N" control={<Radio />} label="부적합" />
                                        <FormControlLabel value="O" control={<Radio />} label="해당없음" />
                                    </RadioGroup>
                                </Grid>

                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:68}}>
                                    2
                                </Grid>
                                <Grid className="border-1 label-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:0, pb:0, minHeight:68}}>
                                    작업 후 환경정리는 깔끔하게 하였는가? (배선 절연처리, 선정리, 작업잔여물 청소, RTU 배치) (설비 담당자의 확인하에 환경정리가 잘 되었는지 점검합니다.)
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: 'flex', pt:0, pb:0, minHeight:68}}>
                                    <RadioGroup row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="Y"
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value="Y" control={<Radio />} label="적합" />
                                        <FormControlLabel value="N" control={<Radio />} label="부적합" />
                                        <FormControlLabel value="O" control={<Radio />} label="해당없음" />
                                    </RadioGroup>
                                </Grid>

                                <Grid xs={12} sm={12} md={0} lg={4} xl={4} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}></Grid>

                                <Grid xs={12} sm={12} md={3} lg={2} xl={2} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}>                                                                        
                                <Button fullWidth variant="contained" size="large" color="primary" sx={{ m: 1 }} startIcon={<SaveAltIcon />} onClick={handleSubmit(save)} >저장</Button>   
                                </Grid>
                                
                                <Grid xs={12} sm={12} md={3} lg={2} xl={2} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}>                                                                        
                                    <Button fullWidth variant="contained" size="large" color="primary" startIcon={<PrintIcon /> } onClick={PDFDownload} sx={{ m:1 }}>PDF</Button>
                                </Grid>

                                <Grid xs={12} sm={12} md={3} lg={2} xl={2} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}>                                                                        
                                    <ReactToPrint trigger={() => <Button fullWidth variant="contained" size="large" color="primary" startIcon={<PrintIcon />} sx={{ m: 1 }}>인쇄</Button>} content={() => printRef.current} /> 
                                </Grid>

                                <Grid xs={12} sm={12} md={3} lg={2} xl={2} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}>                                      
                                    <Button fullWidth variant="contained" size="large" color="primary" sx={{ m: 1 }} startIcon={<CloseIcon />} onClick={()=>window.close()}>닫기</Button>   
                                </Grid>

                            </Grid>
                            
                        </Grid>
                    </Grid>
                                   
                </Grid>
                
            </Grid> 

            <Modal open={open} onClose={e => setOpen(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                <Box className="Modal">
                    <h3 id="parent-modal-title">사진 선택 <h6 style={{marginTop:5}}>(사진 등록화면에서 업로드 한 사진 입니다.)</h6></h3>
                    <Divider sx={{ mt:2 }} />

                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1, mt: 1 }}>                        
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:1, height:300}}>
                            <Box sx={{border:"1px solid silver", width:"100%", height:"90%", textAlign:"center"}}>
                                <img src={imageHist.length > 0 && imageHist[0].blod ? imageHist[0].blod : ""} alt="이미지" height="100%" width="100%" onError={handleImgError}/>                                                    
                            </Box>
                            <Button fullWidth variant="contained" size="large" sx={{mt:1}} onClick={(e)=>{photoHstToDoc(imageHist.length > 0 && imageHist[0] ? imageHist[0] : null)}}>선택</Button>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:1, height:300}}>
                            <Box sx={{border:"1px solid silver", width:"100%", height:"90%", textAlign:"center"}}>
                                <img src={imageHist.length > 1 && imageHist[1].blod ? imageHist[1].blod : ""} alt="이미지" height="100%" width="100%" onError={handleImgError}/>                                                    
                            </Box>
                            <Button fullWidth variant="contained" size="large" sx={{mt:1}} onClick={(e)=>{photoHstToDoc(imageHist.length > 1 && imageHist[1] ? imageHist[1] : null)}}>선택</Button>
                        </Grid>
                    </Grid>   

                    <Divider sx={{ mt:1, mb:3 }} />
                    
                    <Grid container direction="row" justifyContent="flex-end">                        
                        <Button variant="contained" size="large" color="primary" sx={{ ml: 2 }} onClick={e => setOpen(false)}>닫기</Button>
                    </Grid>
                    
                </Box>
            </Modal>  

            <Toast ref={toastRef} />

            {/* 프린터 인쇄 영역 */}
            <LhContent ref={printRef} data={data} devList={devList} image001={image001} image004={image004} />

        </div>
    );
}

export default LhDocument;