import axios from "axios";

/* Helper Class
 * To make requesting countries and regions more flexible
 * */
class MapData {
  baseUrl = "https://code.highcharts.com/mapdata/";

  /* Helper Function
   * To get the countries of a specific region
   * */
  getWorld = async () => {
    return await axios.get(this.baseUrl + "countries/kr/kr-all.topo.json");
  };

  getName = (name) => {
    console.log(name);
    if (name === "Seoul"){
        return "서울";
    }else if (name === "Gangwon"){
        return "강원";
    }else if (name === "Incheon"){
        return "인천";
    }else if (name === "Gyeonggi"){
        return "경기";
    }else if (name === "North Chungcheong"){
        return "충청북도";
    }else if (name === "South Chungcheong"){
        return "충청남도";
    }else if (name === "Sejong"){
        return "세종";
    }else if (name === "Daejeon"){
        return "대전";
    }else if (name === "North Gyeongsang"){
        return "경상북도";
    }else if (name === "South Gyeongsang"){
        return "경상남도";
    }else if (name === "Daegu"){
        return "대구";
    }else if (name === "Ulsan"){
        return "울산";
    }else if (name === "Busan"){
        return "부산";
    }else if (name === "North Jeolla"){
        return "전라북도";
    }else if (name === "South Jeolla"){
        return "전라남도";
    }else if (name === "Gwangju"){
        return "광주";
    }else if (name === "Jeju"){
        return "제주";
    }else{
        return name;
    }
  }
}

export default MapData;
