import React, {useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import { Button, Box, Modal, Typography } from '@mui/material';
import { getYYYYMMDD, addMonth } from '../components/util/DateUtil';
import { useNavigate } from 'react-router-dom';
import { viewModeType } from '../components/const/CommCode';
import { useSetRecoilState } from 'recoil';
import { activeMenuPath } from '../components/atoms/MenuAtom';
import Grid from '@mui/material/Unstable_Grid2';
import CustomDatatable from '../components/ui/CustomDatatable';
import CustomAutocomplete from "../components/ui/CustomAutocomplete";
import CustomDatePicker from "../components/ui/CustomDatePicker";
import CustomTextField from "../components/ui/CustomTextField";
import axiosInstance from '../components/util/AxiosUtil';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Toast from '../components/ui/Toast';

const defaultValues = {     
    pjtSeq: '',    
    stCretDt:getYYYYMMDD(addMonth(new Date(), -9)),
    edCretDt:getYYYYMMDD(new Date())
}

const defaultAccValues = {
    workSeq: '',
    nrnGentCd: '',
    accId:''
}

const defaultKnrecValues = {
    workSeq: '',
    nrnGentCd: '',
    connTypeCd:''
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function EquipmentSupport() {

    const navigate = useNavigate();

    //toast ref 객체
    const toastRef = useRef();

    // 프로젝트 목록
    const [pjtList, setPjtList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // 프로젝트 계정 목록
    const [accList, setAccList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // 에너지원 코드 목록
    const [nrnGentDtlCdList, setNrnGentDtlCdList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // 통신방식 코드 목록
    const [connTypeCdList, setConnTypeCdList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // 선택된 요청서 번호
    const [selectWorkSeq, setSelectWorkSeq] = useState("");

    const { handleSubmit, control, getValues, formState: { errors } } = useForm({ defaultValues });

    const { handleSubmit : handleSubmit_acc, control : control_acc, getValues : getValues_acc, setValue: setValue_acc, reset : reset_acc, formState: { errors : errors_acc } } = useForm({ defaultAccValues });    

    const { handleSubmit : handleSubmit_knrec, control : control_knrec, getValues : getValues_knrec, setValue: setValue_knrec, reset : reset_knrec, formState: { errors : errors_knrec } } = useForm({ defaultKnrecValues });    

    // 활성화 메뉴 설정
    const setSelectMenuPath = useSetRecoilState(activeMenuPath);

    const [ filterData, setFilterData ] = useState( {defaultValues} );

    const [accModalOpen, setAccModalOpen] = useState(false);

    const [knrecModalOpen, setKnrecModalOpen] = useState(false);

    //데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);

    const accModalShow = (workSeq, isOpen) => {

        if (isOpen){

            reset_acc();
            
            setSelectWorkSeq(workSeq);

            //공단 계정 목록 조회
            axiosInstance.get('/workKeaAccList', {params: {workSeq: workSeq}}).then((Response)=>{            
                if (Response.request.status === 200) {                  
                    setAccList(Response.data.list);                       
                    setAccModalOpen(isOpen);
                }
            });
        }else{
            setAccModalOpen(isOpen);
        }        
    }

    const knrecModalShow = (workSeq, isOpen) => {

        if (isOpen){
            reset_knrec();            
            setSelectWorkSeq(workSeq);            
        }
        
        setKnrecModalOpen(isOpen);
    }

    //업무요청서 테이블 컬럼 정보
    const columns = [
        { id: "workSeq", data: "workSeq", title: '요청번호' ,width: '5%', className: "text-center", responsivePriority: 1 },
        { id: "pjtDisplayNm", data: "pjtDisplayNm", title: '프로젝트', width: '10%', className: "text-left",responsivePriority: 2 },
        { id: "workDisplayNm", data: "workDisplayNm", title: '현장명', width: '12%', className: "text-left",responsivePriority: 3 },        
        { id: "workSts", data: "workSts", title: '상태', width:'5%', className: "text-center", responsivePriority: 4 },
        { id: "cretrNm", data: "cretrNm", title: '요청자', width:'5%',className: "text-center", responsivePriority: 6 },
        { id: "cretDt", data: "cretDt", title: '요청일시', width:'10%',className: "text-center", responsivePriority: 6 },
        { id: "workSeq", data: "workSeq", title: '공단계정', width:'5%', className: "text-center", responsivePriority: 0, orderable:false },
        { id: "workSeq", data: "workSeq", title: '공단', width:'5%', className: "text-center", responsivePriority: 0, orderable:false },
        { id: "workSeq", data: "workSeq", title: 'LH', width:'5%', className: "text-center", responsivePriority: 0, orderable:false },
        { id: "workSeq", data: "workSeq", title: '관리', width:'23%', className: "text-center", responsivePriority: 0, orderable:false },
    ]

    //업무요청서 테이블 커스텀 컬럼 정보
    const columnDefs = [
        {
            className: "text-center p-0", "targets": [6], render: function (data, type, row, meta) {    
                return "<button class='data-btn excel-btn' " + (row.multiConnYn != 'Y' && row.bizInstnCd != "200" ? "disabled" : "") + " data-seq='" + row.workSeq + "' data-type='knrec-acc-excel' title='공단계정 Excel 다운로드' style='margin:2px; padding:3px;'>excel</button>";
            }
        },
        {
            className: "text-center p-0", "targets": [7], render: function (data, type, row, meta) {    
                return "<button class='data-btn excel-btn' " + (row.multiConnYn != 'Y' && row.bizInstnCd != "200" ? "disabled" : "") + " data-seq='" + row.workSeq + "' data-type='knrec-excel' title='공단 Excel 다운로드' style='margin:2px; padding:3px;'>excel</button>";
            }
        },
        {
            className: "text-center p-0", "targets": [8], render: function (data, type, row, meta) {    
                return "<button class='data-btn excel-btn' " + (row.multiConnYn != 'Y' && row.bizInstnCd != "100" ? "disabled" : "") + " data-seq='" + row.workSeq + "' data-type='lh-excel' title='LH Excel 다운로드' style='margin:2px; padding:3px;'>excel</button>";
            }
        },
        {
            className: "text-center p-0", "targets": [9], render: function (data, type, row, meta) {    

                let customButton = "<button class='data-btn' " + (row.virtlRegYn == 'Y' ? "disabled" : "") + " data-seq='" + row.workSeq + "' data-type='reg' style='margin:2px; padding:3px;'>설비등록</button>";

                customButton += "<button class='data-btn' data-seq='" + row.workSeq + "' data-type='dtl' style='margin:2px; padding:3px;'>업무요청서 보기</button>";                                    

                customButton += "<button class='data-btn' " + (row.virtlRegYn == 'Y' ? "disabled" : "") + " data-seq='" + row.workSeq + "' data-type='tmp-sync' style='margin:2px; padding:3px;'>임시현행화(" + row.curnWtr + ")</button>";                                    

                customButton += "<button class='data-btn' " + (row.curnWtr > 0 ? "disabled" : "") + " data-seq='" + row.workSeq + "' data-type='sync' style='margin:2px; padding:3px;'>현행화(" + row.curnWtr + ")</button>";                                    

                return customButton;
            }            
        }
    ]

    //테이블 버튼 클릭 콜백 함수
    const buttonClick = (e) =>{
        if (e.target.dataset.type === "reg"){            
            navigate("/rems/support/pv", { state: {workSeq:e.target.dataset.seq}});                        
        }else if (e.target.dataset.type === "knrec-acc-excel"){            
            accModalShow(e.target.dataset.seq, true);
        }else if (e.target.dataset.type === "knrec-excel"){
            knrecModalShow(e.target.dataset.seq, true);
        }else if (e.target.dataset.type === "lh-excel"){
            lhExcelDownload(e.target.dataset.seq);
        }else if (e.target.dataset.type === "sync"){
            workUpd(e.target.dataset.seq);
        }else if (e.target.dataset.type === 'tmp-sync'){
            tmpWorkUpd(e.target.dataset.seq);
        }else if (e.target.dataset.type === "dtl"){
            goToReq(viewModeType.Edit, e.target.dataset.seq);   
        }
    }

    function dataReload(data){   
        setPageFixed(false);
        setFilterData(data);        
    }

    // 공단 계정 엑셀 다운로드
    const knrecAccexcelDownload = (data) => {

        let url = `${process.env.REACT_APP_API_URL}` + "/rems/knrec/acc/excel/download";        
        url += "?workSeq=" + selectWorkSeq;
        url += "&nrnGentCd=" + data.nrnGentCd;
        url += "&accId=" + data.accId;
        
        window.location.href = url;

        setAccModalOpen(false);
    }

    // 공단 설비 엑셀 다운로드
    const knrecExcelDownload = (data) => {

        let url = `${process.env.REACT_APP_API_URL}` + "/rems/knrec/excel/download";        
        url += "?workSeq=" + selectWorkSeq;
        url += "&nrnGentCd=" + data.nrnGentCd;
        url += "&connTypeCd=" + data.connTypeCd;
        
        window.location.href = url;

        setKnrecModalOpen(false);
    }

    // LH 설비 엑셀 다운로드
    const lhExcelDownload = (workSeq) => {

        let url = `${process.env.REACT_APP_API_URL}` + "/rems/lh/excel/download";        
        url += "?workSeq=" + workSeq;
        url += "&nrnGentCd=" + "100";        
        
        window.location.href = url;
    }

    //업무요청서 등록 화면 이동    
    const goToReq = (viewModeType, workSeq) =>{                

        localStorage.setItem("lastUrl", "/sales/request");
        localStorage.setItem("lastParentMenuIndex", 10);        
        setSelectMenuPath("/sales/request");        
        navigate("/sales/request", {replace: true, state: {viewMode: viewModeType, workSeq: workSeq, listUrl: "/rems/support", listPageParentMenuIndex: "4"}});
    }

    //현행화
    const workUpd = (workSeq) => {
        axiosInstance.post('/work/upd', {workSeq: workSeq}).then((Response)=>{            
            if(Response.request.status === 200){     
                toastRef.current.handleOpen("success", "정상 처리 되었습니다.");                                                        
                dataReload(getValues());
            }else{                              
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 현행화 할 수 없습니다.");                                        
            }   
        }); 
    }

    //임시 현행화
    const tmpWorkUpd = (workSeq) => {
        axiosInstance.post('/tmp/work/upd', {workSeq: workSeq}).then((Response)=>{            
            if(Response.request.status === 200){     
                toastRef.current.handleOpen("success", "정상 처리 되었습니다.");                                                        
                dataReload(getValues());
            }else{                              
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 현행화 할 수 없습니다.");                                        
            }   
        }); 
    }

    useEffect(() => {

        //프로젝트 목록 조회
        axiosInstance.get('/pjtCodeList').then((Response)=>{
            if (Response.request.status === 200) {  
                Response.data.list.unshift({dtlCd: "", dtlCdNm: "전체"});
                setPjtList(Response.data.list);                       
            }
        });

        //에너지원 코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'NRN_GENT_DTL_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setNrnGentDtlCdList(Response.data.list);                     
            }
        });

        //통신방식 코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'CONN_TYPE_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setConnTypeCdList(Response.data.list);                     
            }
        });

        dataReload(getValues());


    }, []);

    return (
        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)"}}>

            <Grid container spacing={2} sx={{ height: '100%' }}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 0 }}>

                    <div style={{ "marginBottom": "16px" }}></div>
                        <div style={{ "marginBottom": "28px" }}>
                        <p fontWeight="600" className="css-1pr8naw">설비 등록지원</p>
                        <p className="css-3vvtry">PoSE / 신재생서비스 / 설비 등록지원</p>
                    </div>
                                   
                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 0 }}>

                    <Grid xs={12} sm={12} md={6} lg={6} xl={4} sx={{ p: 2 }}>

                        <CustomAutocomplete
                                name="pjtSeq"              
                                label="프로젝트"           
                                options={pjtList}          
                                value="dtlCd"              
                                text="dtlCdNm"             
                                control={control}          
                                getValues={getValues}                                      
                                errors={errors.pjtSeq}                                    
                        />

                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={6} xl={2} sx={{ p: 2 }}>
                        <CustomTextField fullWidth 
                                         name="workSeq" 
                                         label="요청번호" 
                                         control={control} 
                                         onKeyDown={(e) => {
                                            if (e.key === 'Enter'){                                    
                                                dataReload(getValues());
                                            }
                                         }}
                        />
                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={3} xl={2} sx={{ p: 2 }}>                        
                        <CustomDatePicker name="stCretDt" label="시작 등록일자" control={control} />
                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={3} xl={2} sx={{ p: 2 }}>
                        <CustomDatePicker name="edCretDt" label="종료 등록일자" control={control} sx={{ p: 2, width: '90%' }}/>                        
                    </Grid>

                    <Grid container alignItems="center"  xs={12} sm={12} md={6} lg={4} xl={2}>
                        <Button variant="contained" size="large" color="primary" sx={{ ml: 2, height:'52px' }} startIcon={<SearchIcon />} onClick={handleSubmit(dataReload)}>조회</Button>                                                
                    </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 2, mt: 3 }}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable id="table-list-0"          //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                   width="100%"                     //테이블 너비
                                   url="/workList"                  //ajax url
                                   columns={columns}                //테이블 컬럼 정의
                                   filter={filterData}              //ajax 검색 필터 정의                      
                                   columnDefs={columnDefs}          //Custom 컬럼 정의
                                   paging={true}                    //페이징 여부
                                   pageLength="10"                  //페이징 수
                                   buttonClick={buttonClick}        //버튼 클릭 콜백함수
                                   pageFixed={pageFixed}
                                   order={[[0, 'desc']]}            //기본 정렬
                        />
                    </Grid>
                </Grid>
                
            </Grid>

            <Modal
                open={accModalOpen}
                onClose={()=>accModalShow(null, false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" sx={{mb:3}}>
                        프로젝트 계정을 선택 해주세요 <span style={{color:'red'}}>(필수)</span>
                    </Typography>
                    <CustomAutocomplete
                                    name="accId"              
                                    label="*계정"           
                                    options={accList}          
                                    value="accId"              
                                    text="accId"             
                                    control={control_acc}          
                                    getValues={getValues_acc}     
                                    rules={{ required: "필수 입력 값 입니다." }} 
                                    errors={errors.accId}     
                    />

                    <Typography id="modal-modal-title" sx={{mt:3, mb:3}}>
                        에너지원을 선택 해주세요 <span style={{color:'red'}}>(필수)</span>
                    </Typography>
                    <CustomAutocomplete
                                    name="nrnGentCd"              
                                    label="*에너지원"           
                                    options={nrnGentDtlCdList}          
                                    value="dtlCd"              
                                    text="dtlCdNm"             
                                    control={control_acc}          
                                    getValues={getValues_acc}      
                                    rules={{ required: "필수 입력 값 입니다." }}
                                    errors={errors.nrnGentCd}     
                    />

                    <Button variant="contained" size="large" color="success" sx={{ width:'100%', mt:3 }} onClick={handleSubmit_acc(knrecAccexcelDownload)} startIcon={<FileDownloadIcon />}>엑셀 다운로드</Button>                                                
                </Box>
            </Modal>

            <Modal
                open={knrecModalOpen}
                onClose={()=>knrecModalShow(null, false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" sx={{mb:3}}>
                        통신방식을 선택해주세요. <span style={{color:'red'}}>(필수)</span>
                    </Typography>
                    <CustomAutocomplete
                                    name="connTypeCd"              
                                    label="*통신방식"           
                                    options={connTypeCdList}          
                                    value="dtlCd"              
                                    text="dtlCdNm"             
                                    control={control_knrec}          
                                    getValues={getValues_knrec}     
                                    rules={{ required: "필수 입력 값 입니다." }} 
                                    errors={errors.connTypeCd}     
                    />

                    <Typography id="modal-modal-title" sx={{mt:3, mb:3}}>
                        에너지원을 선택 해주세요 <span style={{color:'red'}}>(필수)</span>
                    </Typography>
                    <CustomAutocomplete
                                    name="nrnGentCd"              
                                    label="*에너지원"           
                                    options={nrnGentDtlCdList}          
                                    value="dtlCd"              
                                    text="dtlCdNm"             
                                    control={control_knrec}          
                                    getValues={getValues_knrec}      
                                    rules={{ required: "필수 입력 값 입니다." }}
                                    errors={errors.nrnGentCd}     
                    />

                    <Button variant="contained" size="large" color="success" sx={{ width:'100%', mt:3 }} onClick={handleSubmit_knrec(knrecExcelDownload)} startIcon={<FileDownloadIcon />}>엑셀 다운로드</Button>                                                
                </Box>
            </Modal>

            <Toast ref={toastRef} />
        </div>
    );
}

export default EquipmentSupport;