import React, {useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomTextField from "../components/ui/CustomTextField";
import { Box, Modal, Divider, Button } from '@mui/material';
import CustomAutocomplete from "../components/ui/CustomAutocomplete";
import axiosInstance from '../components/util/AxiosUtil';
import CustomDatatable from '../components/ui/CustomDatatable';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import Toast from '../components/ui/Toast';
import { viewModeType } from '../components/const/CommCode'
import Confirm from '../components/ui/Confirm';

//검색 요청 기본 데이터
const defaultFindValues = {     
    prdtDivCd : '',
    prdtNm : ''
}

//등록, 수정 기본 데이터
let defaultValues = {
    prdtSeq : '',
    prdtDivCd : '',   //제품구분
    prdtNm : '',      //제품명
    rmrk : '',        //비고
    useYn : '',       //사용여부
    cretrId : '',     //생성아이디
    cretDt : ''      //생성일시
    // saleUcost : '',   //판매단가
    // updtrId : '',     //수정자아이디
    // updtDt : '',      //수정일시
}

function Prdt() {

    //[검색]제품구분
    const [prdtDivCdList, setPrdtDivCdList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    //[modal] 제품구분
    const [modalPrdtDivCdList, setModalPrdtDivCdList] = useState ([{dtlCd: "", dtlCdNm: ""}]);

    //[검색]조회 기본세팅
    const { handleSubmit : find_handleSubmit, 
        control : find_control, 
        getValues: find_getValues, 
        formState: { errors: find_errors } 
    } = useForm( {defaultFindValues} );

    // [modal] 등록,수정 Form 기본세팅
    const { handleSubmit, getValues, reset, control, formState: { errors } } = useForm( {defaultValues} );

    //데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);

    //검색필터
    const [filterData, setFilterData] = useState( {defaultFindValues} );

    //모달 노출 상태 정보
    const [open, setOpen] = useState(false);

    //서버 요청 상태 정보
    const [viewMode, setViewMode] = useState(viewModeType.New);

     // [modal] 사용여부
     const [formUseYN, setFormUseYn] = useState([{dtlCd: "", dtlCdNm: ""}]);


    useEffect(() => {
        //[검색]제품 구분 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'PRDT_DIV_CD'}}).then((Response) => {
            if(Response.request.status === 200) {

                const list =  Response.data.list;
                const newList = [{dtlCd: "", dtlCdNm: "전체"}, ...list];

                //[검색] 제품 구분 목록 조회
                setPrdtDivCdList(newList);

                //[modal] 제품 구분 목록 조회
                setModalPrdtDivCdList(list);
            }
        })

        //(Modal)다중연동여부
        axiosInstance.get('/codeList', {params: {grpCd: 'USE_YN'}}).then((Response) => {
            if(Response.request.status === 200) {

                //(Modal) 사업구분 select박스
                setFormUseYn(Response.data.list);

            }
        })
    }, []);
    
    //테이블 컬럼정보
    const columns =  [
        { id: "prdtSeq", data: "prdtSeq", title: '제품 일련번호' ,width: '10%',className: "text-center", responsivePriority: 1 },
        { id: "prdtDivCdNm", data: "prdtDivCdNm", title: '제품구분', width: '15%', className: "text-center",responsivePriority: 2 },
        { id: "prdtNm", data: "prdtNm", title: '제품명', width:'15%', className: "text-center", responsivePriority: 3 },
        { id: "useYnNm", data: "useYnNm", title: '사용여부', width:'10%', className: "text-center", responsivePriority: 4 },
        { id: "cretDt", data: "cretDt", title: '등록일시', width:'20%',className: "text-center", responsivePriority: 5 },
        { id: "updtDt", data: "updtDt", title: '수정일시', width:'20%',className: "text-center", responsivePriority: 6 },
        { id: "prdtSeq", data: "prdtSeq", title: '관리', width:'10%',className: "text-center", responsivePriority: 0, orderable:false }
    ]

    //수정, 삭제
    const columnDefs = [
        {
            className: "text-center p-0", "targets":[6], render: function(data, type, row, meta){
                let customButton = "<button class='data-btn' data-seq='" + row.prdtSeq + "' data-type='update' style='margin:2px; padding:3px;'>수정</button>";

                customButton += "<button class='data-btn' data-seq='" + row.prdtSeq + "' data-type='delete' style='margin:2px; padding:3px;'>삭제</button>";                                    
                
                return customButton;
            }
        }
    ]
    function dataReload(data){   
        setPageFixed(false);
        setFilterData(data);  

    }
    const dataload = (data) =>{
        setPageFixed(true);
        setFilterData(data);        
    }

    //등록버튼 클릭 이벤트
    const regClickEvent = () => {

        //모달 초기화
        reset({defaultValues});

        //등록으로 상태변경
        setViewMode(viewModeType.New);

        //모달 열기
        setOpen(true);
    }

    //[modal] input css style
    const textFileStyled = { mt:2, width: '100%', 'textAlign': 'center' };

    const onSubmit = (data) => {
        //등록
        if (viewMode === viewModeType.New) {
            axiosInstance.post('/product', {...data}).then((Response)=>{            
                if(Response.request.status === 200 ){  
                    
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                        setOpen(false);
                        dataReload(find_getValues());
                    
                    //pjt_seq 중복이거나 insert가 안되었을 때
                    }else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage );
                    }

                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            });

        //수정
        }else if (viewMode === viewModeType.Edit) {

            axiosInstance.put('/product', {...data}).then((Response)=>{          
                
                if(Response.request.status === 200){     
                    toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                    setOpen(false);
                    dataReload(find_getValues());
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }
            });
        }
    }

    //테이블 버튼 클릭 콜백함수 (수정, 삭제버튼)
    const buttonClick = (e) => {

        if(e.target.dataset.type === "update") {

            axiosInstance.get('/product', {params: {prdtSeq: e.target.dataset.seq}}).then((Response)=>{
            
                if(Response.request.status === 200){
                    setPageFixed(false);
                    setViewMode(viewModeType.Edit);
                    reset(Response.data.info);
                    setOpen(true);
                }else{
                    toastRef.current.handleOpen("error", "오류입니다. 데이터를 조회 할 수 없습니다.");
                }
            });   

        }else if (e.target.dataset.type === "delete"){            
            confirmRef.current.handleOpen("제품 정보 삭제", "제품 정보를 삭제 합니다. 계속 진행 하시겠습니까?", e);
        }   

    }

    const del = (e) =>{
        axiosInstance.delete('/product', {params: {prdtSeq: e.target.dataset.seq}}).then((Response)=>{
            
            if(Response.request.status === 200){    
                toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
                dataReload(find_getValues());
            }else{                              
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }                  
        }); 
    }
     
    //toast ref 객체    
    const toastRef = useRef();

     //confrim ref 객체
    const confirmRef = useRef();

    return (
        <div style={{ 
            "padding": "16px 24px", 
            "color": "rgb(68, 89, 110)", 
            "height": "100%" 
        }}>
            <Grid container spacing={2} /* sx={{ height:'100%'}} */>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p:0 }}>
                    <div style={{ "marginBottom": "28px", "marginTop":"16px" }}>
                        <p fontWeight="600" className="css-1pr8naw">제품 관리</p>
                        <p className="css-3vvtry">PoSE / 제품 관리 / 제품 관리</p>
                    </div>               
                </Grid>

                {/* 검색창 */}
                <Grid 
                    container 
                    alignItems="center" 
                    xs={12} sm={12} md={12} lg={12} xl={12} 
                    className="page-filter" 
                    sx={{p:0}}
                    //justifyContent={'flex-end'}
                >
                    <Grid xs={12} sm={12} md={6} lg={3} xl={3} sx={{ p: 2 }}>
                        <CustomAutocomplete
                            name="prdtDivCd"              
                            label="제품구분"           
                            options={prdtDivCdList}
                            value="dtlCd"
                            text="dtlCdNm"             
                            control={find_control}
                            getValues={find_getValues}
                            errors={find_errors.prdtDivCd}
                        />
                    </Grid>

                    {/* 프로젝트 코드 */}
                    <Grid xs={12} sm={12} md={6} lg={3} xl={3} sx={{ p: 2 }}>
                        <CustomTextField 
                            fullWidth 
                            name="prdtNm" 
                            label="제품 명" 
                            control={find_control}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){                                    
                                    dataReload(find_getValues());
                                }
                            }}
                        />                          
                    </Grid>

                    {/* 조회, 등록버튼*/}
                    <Grid 
                        textAlign={'start'} 
                        alignItems="center" 
                        xs={12} sm={12} md={12} lg={6} xl={6} 
                        sx={{ p: 0 }}
                    >
                        <Button 
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ m:2, height:'52px' }}  
                            startIcon={<SearchIcon />}
                            onClick={find_handleSubmit(dataload)}
                        >조회
                        </Button>
                        <Button
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ mt:2, mr:2, mb:2, height:'52px' }} 
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={regClickEvent}
                        >등록
                        </Button>
                    </Grid>
                </Grid>

               {/* table */}
               <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 2, mt: 3 }}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable
                            id="table-list-0"                //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                            width="100%"                     //테이블 너비
                            url="/prdtList"                  //ajax url                                   
                            columns={columns}                //테이블 컬럼 정의                                   
                            columnDefs={columnDefs}          //Custom 컬럼 정의
                            filter={filterData}              //ajax 검색 필터 정의       
                            paging={true}                    //페이징 여부
                            pageLength="10"                  //페이징 수
                            buttonClick ={buttonClick}       //버튼 클릭 콜백함수
                            pageFixed={pageFixed}
                            order={[[0, 'asc']]}             //기본 정렬
                        />
                    </Grid>
                </Grid>

                {/* 등록 / 수정 모달 */}
                <Modal open={open} onClose={e => setOpen(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                    <Box className="smModal">
                        <h3 id="parent-modal-title">제품 정보</h3>
                        <Divider sx={{ mt:2 }} />

                        {/* 제품 구분 */}
                        <Grid sx={textFileStyled}>
                            <CustomAutocomplete
                                    fullWidth
                                    name="prdtDivCd"              
                                    label="*제품구분"
                                    rules={{ required: "제품구분을 입력 해주세요." }}            
                                    options={modalPrdtDivCdList}          
                                    value="dtlCd"              
                                    text="dtlCdNm"             
                                    control={control}          
                                    getValues={getValues}      
                                    errors={errors.prdtDivCd}             
                            />
                        </Grid>

                        {/* 제품명 */}
                        <CustomTextField 
                                fullWidth
                                name="prdtNm"                
                                label="*제품 명"
                                rules={{ required: "제품 명을 입력 해주세요." }}                           
                                control={control}                                                           
                                errors={errors.prdtNm}
                                sx={textFileStyled}                                 
                        /> 

                        {/* 사용여부여부 */}
                        <Grid sx={textFileStyled}>
                            <CustomAutocomplete
                                    fullWidth
                                    name="useYn"              
                                    label="*사용여부"           
                                    options={formUseYN}          
                                    value="dtlCd"              
                                    text="dtlCdNm" 
                                    rules={{ required: "사용여부를 선택 해주세요." }}             
                                    control={control}          
                                    getValues={getValues}      
                                    errors={errors.useYn}             
                            />
                        </Grid>

                        {/* 비고 */}
                        <CustomTextField 
                                fullWidth
                                name="rmrk"                
                                label="비고"        
                                control={control}                                                           
                                errors={errors.rmrk}
                                sx={textFileStyled} 
                        /> 

                        <Divider sx={{ mt:2, mb:3 }} />
                        
                        <Grid container direction="row" justifyContent="flex-end">
                            <Button variant="contained" size="large" color="primary" startIcon={<SaveAltIcon />} onClick={handleSubmit(onSubmit)}>저장</Button>
                            <Button variant="contained" size="large" color="primary" startIcon={<CloseIcon />} sx={{ ml: 2 }} onClick={e => setOpen(false)}>닫기</Button>
                        </Grid>
                        
                    </Box>
                </Modal>


            </Grid>
            <Toast ref={toastRef} />
            <Confirm ref={confirmRef} ok={del} />
        </div>
    );
}

export default Prdt;