import React, { useEffect } from 'react'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const colors = Highcharts.getOptions().colors.map((c, i) =>
    Highcharts.color(Highcharts.getOptions().colors[0]).brighten((i - 3) / 7).get()
);

const CustomPieChart = (props) => {

    const options = {

        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,            
            height: 60 + '%',
            type: 'pie',
            margin: [10, 10, 50, 10],
            backgroundColor: 'transparent'		
        },
        title: {
            text: props.title,
            align: 'center',            
            y: 1,
            style: {                            
                fontSize: '14px',                
                textTransform: 'none'
            }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        credits: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        xAxis: {
            type: 'category'
        },
        legend: {
            reversed: true,
            y: 20
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                colors: props.colors ? props.colors : colors,
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                    distance: -50,
                    filter: {
                        property: 'percentage',
                        operator: '>',
                        value: 4
                    }
                },
                showInLegend: true
            }
        },
        series: [{ 
                name: "data", 
                data: props.data
            }]
    }

    useEffect(() =>{

    }, [])

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />  
    )
  };
  
  export default CustomPieChart