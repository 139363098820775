import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { viewModeType } from '../components/const/CommCode'
import { Box, Modal, Divider, Button } from '@mui/material';
import CustomTextField from "../components/ui/CustomTextField";
import CustomDatatable from '../components/ui/CustomDatatable';
import CustomAutocomplete from "../components/ui/CustomAutocomplete";
import Toast from '../components/ui/Toast';
import Confirm from '../components/ui/Confirm';
import Grid from '@mui/material/Unstable_Grid2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../components/util/AxiosUtil';


//검색 요청 기본 데이터
const defaultFindValues = {
    makrTypeCd: '',
    makrCd:'',    
    makrNm:'',    
    useYn:''
}

//등록, 수정 기본 데이터
const defaultValues = {
    makrCd: '',
    makrTypeCd: '',
    modelCd: '',
    makrTypeNm: '',
    modelNm: '',
    lhModelCd: '',
    lhModelNm: '',
    keaModelCd: '',
    keaModelNm: '',
    useYn: 'Y'    
}

//css style
const modalFullStyle = { width: '100%', 'textAlign': 'center', display: 'flex', mt:2, pr:1, pl:1 };
const modalHalfStyle = { width: '50%', 'textAlign': 'center', mt:2, pr:1, pl:1};

function Model() {

    //팝업 노출 상태 정보
    const [open, setOpen] = useState(false); 
    
    //데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);

    //서버 요청 상태 정보
    const [viewMode, setViewMode] = useState(viewModeType.New);

    const [filterData, setFilterData] = useState( {defaultFindValues} );

    // 조회 Form
    const { handleSubmit : find_handleSubmit, control : find_control, getValues: find_getValues, formState: { errors: find_errors } } = useForm( {defaultFindValues} );

    // 등록,수정 Form
    const { handleSubmit, reset, control, getValues, formState: { errors } } = useForm( {defaultValues} );

    //input css style
    const textFileStyled = { mt:2, width: '100%', 'textAlign': 'center' };

    //제조사유형코드 목록
    const [makrTypeCdList, setMakrTypeCdList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    //검색필터에 사용되는 제조사 목록
    const [filterMakrCdList, setFilterMakrCdList] = useState([{makrCd: "", makrNm: ""}])

    //등록 및 수정에 사용되는 제조사 목록
    const [makrCdList, setMakrCdList] = useState([{makrCd: "", makrNm: ""}])

    //미맵핑 공단 모델 목록
    const [keaModelHstList, setKeaModelHstList] = useState();

    //미맵핑 LH 모델 목록
    const [lhModelHstList, setLhModelHstList] = useState();

    //사용여부코드 목록
    const [useYnList, setUseYnList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    //toast ref 객체
    const toastRef = useRef();

    //confrim ref 객체
    const confirmRef = useRef();

    const onSubmit = (data) => {        

        if (viewMode === viewModeType.New){
            axiosInstance.post('/model', {...data}).then((Response)=>{            
                if(Response.request.status === 200){     
                    toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                    setOpen(false);
                    dataReload(find_getValues());
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            });        
        }else if (viewMode === viewModeType.Edit){
            axiosInstance.put('/model', {...data}).then((Response)=>{            
                if(Response.request.status === 200){     
                    toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                    setOpen(false);
                    dataReload(find_getValues());
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }
            });
        }
    }

    //등록 버튼 클릭 이벤트
    const regClickEvent = () =>{
        setViewMode(viewModeType.New);
        reset({defaultValues}); 
        setOpen(true);
        createKeaModelHstCombo();
        createLhModelHstCombo();
    }

    //테이블 컬럼 정보
    const columns = [
        { id: "modelCd", data: "modelCd", title: '코드' ,width: '5%', className: "text-center", responsivePriority: 1 },
        { id: "makrTypeNm", data: "makrTypeNm", title: '제조사유형', width: '10%', className: "text-center",responsivePriority: 2 },
        { id: "makrNm", data: "makrNm", title: '제조사명', width:'12%', className: "text-left", responsivePriority: 3 },
        { id: "modelNm", data: "modelNm", title: '모델명', width:'12%', className: "text-left", responsivePriority: 3 },
        { id: "lhModelCd", data: "lhModelCd", title: 'LH모델코드', width:'8%', className: "text-center", responsivePriority: 4 },
        { id: "lhModelNm", data: "lhModelNm", title: 'LH모델명', width:'12.5%',className: "text-left", responsivePriority: 5 },
        { id: "keaModelCd", data: "keaModelCd", title: '공단모델코드', width:'8%',className: "text-center", responsivePriority: 6 },
        { id: "keaModelNm", data: "keaModelNm", title: '공단모델명', width:'12.5%',className: "text-left", responsivePriority: 7 },
        { id: "useYnNm", data: "useYnNm", title: '사용여부', width:'10%',className: "text-center", responsivePriority: 8 },
        { id: "modelCd", data: "modelCd", title: '관리', width:'17%', className: "text-center", responsivePriority: 0 },
    ]

    //테이블 커스텀 컬럼 정보
    const columnDefs = [
        {
            className: "text-center p-0", "targets": [9], render: function (data, type, row, meta) {                        
                return "<button class='data-btn' data-makrCd='" + row.makrCd + "' data-seq='" + row.modelCd + "' data-makrTypeCd='" + row.makrTypeCd + "' data-type='info' style='margin-right:5px'>상세</button>"
                     + "<button class='data-btn' data-makrCd='" + row.makrCd + "' data-seq='" + row.modelCd + "' data-makrTypeCd='" + row.makrTypeCd + "' data-type='delete' style='margin-right:5px'>삭제</button>"
            }            
        }
    ]

    //테이블 버튼 클릭 콜백 함수
    const buttonClick = (e) =>{
        if (e.target.dataset.type === "info"){
            
            axiosInstance.get('/model', {params: {makrCd: e.target.dataset.makrcd, modelCd: e.target.dataset.seq, makrTypeCd: e.target.dataset.makrtypecd}}).then((Response)=>{
            
                if(Response.request.status === 200){
                    setPageFixed(false);
                    setViewMode(viewModeType.Edit);
                    reset(Response.data.info);
                    setOpen(true);
                    createKeaModelHstCombo();
                    createLhModelHstCombo();
                }else{
                    toastRef.current.handleOpen("error", "오류입니다. 데이터를 조회 할 수 없습니다.");
                }
            });        
        }else if (e.target.dataset.type === "delete"){            
            confirmRef.current.handleOpen("모델 정보 삭제", "모델 정보를 삭제 합니다. 계속 진행 하시겠습니까?", e);
        }  
    }

    const del = (e) =>{
        axiosInstance.delete('/model', {params: {makrCd: e.target.dataset.makrcd, modelCd: e.target.dataset.seq, makrTypeCd: e.target.dataset.makrtypecd}}).then((Response)=>{
            
            if(Response.request.status === 200){
                toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
                dataReload(find_getValues());
            }else{
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }
        });
    }

    function dataReload(data){
        setPageFixed(false);
        setFilterData(data);        
    }

    function dataload(data){           
        setPageFixed(true);
        setFilterData(data);        
    }

    const makrTypeCdChanged = () => {
        createKeaModelHstCombo();
        createLhModelHstCombo();
        createMarkCdCombo();
    }

    const makrCdChanged = () => {
        createKeaModelHstCombo();
        createLhModelHstCombo();
    }

    const filterMakrTypeCdChanged = () => {
        createFilterMarkCdCombo();
    }

    const createFilterMarkCdCombo = () => {
        //검색필터 제조사 목록 조회
        axiosInstance.get('/makrList', {params: {start:0, length:9999, makrTypeCd:find_getValues().makrTypeCd, useYn:"Y"}}).then((Response)=>{                
            if (Response.request.status === 200) {   
                Response.data.list.unshift({makrCd: "", makrNm: "전체"});          
                setFilterMakrCdList(Response.data.list);                                                     
            }
        });
    }

    const createMarkCdCombo = () => {
        //등록/수정 제조사 목록 조회
        axiosInstance.get('/makrList', {params: {start:0, length:9999, makrTypeCd:getValues().makrTypeCd, useYn:"Y"}}).then((Response)=>{                
            if (Response.request.status === 200) {                   
                setMakrCdList(Response.data.list);                                                     
            }
        });
    }

    const createKeaModelHstCombo = () => {
        //미맵핑 공단 모델 목록 조회
        axiosInstance.get('/keaModelHstList', {params: {makrCd:getValues().makrCd, makrTypeCd:getValues().makrTypeCd, modelCd:getValues().modelCd}}).then((Response)=>{                
            if (Response.request.status === 200) {                   
                setKeaModelHstList(Response.data.list);                       
            }
        });
    }

    const createLhModelHstCombo = () => {
        //미맵핑 LH 모델 목록 조회
        axiosInstance.get('/lhModelHstList', {params: {makrCd:getValues().makrCd, makrTypeCd:getValues().makrTypeCd, modelCd:getValues().modelCd}}).then((Response)=>{                
            if (Response.request.status === 200) {             
                setLhModelHstList(Response.data.list);                       
            }
        });
    }

    useEffect(() => {
        //제조사유형코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'MAKR_TYPE_CD'}}).then((Response) => {
            if (Response.request.status === 200) {  
                Response.data.list.unshift({dtlCd: "", dtlCdNm: "전체"});
                setMakrTypeCdList(Response.data.list);                       
            }
        });

        //사용여부코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'USE_YN'}}).then((Response) => {
            if (Response.request.status === 200) {  
                Response.data.list.unshift({dtlCd: "", dtlCdNm: "전체"});
                setUseYnList(Response.data.list);                       
            }
        });

        createKeaModelHstCombo();

        createLhModelHstCombo();

        createFilterMarkCdCombo();

    }, []);

    return (
        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)" }}>

            <Grid container spacing={2} sx={{ height: '100%' }}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 0 }}>
                    <div style={{ "marginBottom": "16px" }}></div>
                    <div style={{ "marginBottom": "28px" }}>
                        <p fontWeight="600" className="css-1pr8naw">모델 관리</p>
                        <p className="css-3vvtry">PoSE / 신재생서비스 / 모델 관리</p>
                    </div>
                </Grid>

                <Grid container alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 0 }}>

                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomAutocomplete
                            name="makrTypeCd"              
                            label="제조사유형"           
                            options={makrTypeCdList}
                            value="dtlCd"
                            text="dtlCdNm"             
                            control={find_control}
                            getValues={find_getValues}
                            onChange={filterMakrTypeCdChanged}
                            errors={find_errors.makrTypeCd}
                        />
                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomAutocomplete
                            name="makrCd"              
                            label="제조사"           
                            options={filterMakrCdList}
                            value="makrCd"
                            text="makrNm"             
                            control={find_control}
                            getValues={find_getValues}
                            errors={find_errors.makrCd}
                        />
                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField fullWidth 
                                         name="modelNm" 
                                         label="모델명" 
                                         control={find_control} 
                                         onKeyDown={(e) => {
                                            if (e.key === 'Enter'){                                    
                                                dataReload(find_getValues());
                                            }
                                         }}
                        />
                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomAutocomplete
                            name="useYn"              
                            label="사용여부"           
                            options={useYnList}
                            value="dtlCd"
                            text="dtlCdNm"             
                            control={find_control}
                            getValues={find_getValues}
                            errors={find_errors.useYn}
                        />
                    </Grid>

                    <Grid alignItems="center" xs={12} sm={12} md={6} lg={4} xl={4} sx={{ p: 0 }}>
                        <Button variant="contained" size="large" color="primary" sx={{ ml: 2, height:'52px' }} startIcon={<SearchIcon />} onClick={find_handleSubmit(dataload)}>조회</Button>
                        <Button variant="contained" size="large" color="primary" sx={{ m: 2, height:'52px' }} startIcon={<AddCircleOutlineIcon />} onClick={regClickEvent} >등록</Button>
                    </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 2, mt: 3 }}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable id="table-list-0"          //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                   width="100%"                     //테이블 너비
                                   url="/modelList"                  //ajax url                                   
                                   columns={columns}                //테이블 컬럼 정의                                   
                                   columnDefs={columnDefs}          //Custom 컬럼 정의
                                   filter={filterData}              //ajax 검색 필터 정의       
                                   paging={true}                    //페이징 여부
                                   pageLength="10"                  //페이징 수
                                   buttonClick={buttonClick}        //버튼 클릭 콜백함수
                                   pageFixed={pageFixed}
                        />
                    </Grid>
                </Grid>

            </Grid>

            <Modal open={open} onClose={e => setOpen(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                <Box className="lgModal flex-w">
                    <h3 id="parent-modal-title">모델 정보</h3>
                    <Divider sx={{ mt:2, width: '100%'}} />

                    <Grid sx={modalHalfStyle}>
                        <CustomTextField fullWidth                                                   
                                         name="modelCd"                
                                         label="모델코드"                                         
                                         disabled                                        
                                         control={control}                                                     
                                         errors={errors.modelCd}
                        /> 
                    </Grid>

                    {
                        viewMode === viewModeType.New ?
                            <Grid sx={modalHalfStyle}>    
                                <CustomAutocomplete fullWidth
                                                    name="makrTypeCd"              
                                                    label="*제조사유형"                                                    
                                                    rules={{ required: "제조사유형을 선택 해주세요." }}            
                                                    options={makrTypeCdList}          
                                                    value="dtlCd"              
                                                    text="dtlCdNm"                                                         
                                                    control={control}          
                                                    getValues={getValues}      
                                                    errors={errors.makrTypeCd}
                                                    onChange={makrTypeCdChanged}
                                />
                            </Grid>
                        :
                            <Grid sx={modalHalfStyle}>    
                                <CustomTextField 
                                        fullWidth
                                        name="makrTypeCd"                                                        
                                        label="제조사유형코드"                                          
                                        control={control}                                                           
                                        errors={errors.makrTypeCd}                  
                                        hidden
                                /> 
                                <CustomTextField 
                                        fullWidth
                                        name="makrTypeNm"                
                                        label="제조사유형"                                                                      
                                        control={control}                                                           
                                        errors={errors.makrTypeNm}                  
                                        disabled
                                /> 
                            </Grid>
                    }    

                    {
                        viewMode === viewModeType.New ?
                            <Grid sx={modalHalfStyle}>    
                                <CustomAutocomplete fullWidth
                                            name="makrCd"              
                                            label="*제조사"                                                    
                                            rules={{ required: "제조사를 선택 해주세요." }}            
                                            options={makrCdList}          
                                            value="makrCd"              
                                            text="makrNm"                                                         
                                            control={control}          
                                            getValues={getValues}    
                                            onChange={makrCdChanged}  
                                            errors={errors.makrCd}
                                />
                            </Grid>
                        :
                            <Grid sx={modalHalfStyle}>    
                                <CustomTextField 
                                        fullWidth
                                        name="makrCd"                                                        
                                        label="제조사코드"                                          
                                        control={control}                                                           
                                        errors={errors.makrCd}                  
                                        hidden
                                /> 
                                <CustomTextField 
                                        fullWidth
                                        name="makrNm"                
                                        label="*제조사"                                                                      
                                        control={control}                                                           
                                        errors={errors.makrNm}                  
                                        disabled
                                /> 
                            </Grid>
                    }                                       

                    <Grid sx={modalHalfStyle}>
                        <CustomAutocomplete fullWidth
                                            name="useYn"              
                                            label="*사용여부"                                             
                                            rules={{ required: "사용여부를 선택 해주세요." }}                                                 
                                            options={useYnList}          
                                            value="dtlCd"              
                                            text="dtlCdNm"             
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.useYn}
                        />
                    </Grid>

                    <Grid sx={modalFullStyle}>
                        <CustomTextField fullWidth                                         
                                         name="modelNm"                
                                         label="*모델명"                                                                           
                                         rules={{ required: "모델명을 입력 해주세요." }}
                                         control={control}                                                     
                                         errors={errors.modelNm}
                        /> 
                    </Grid>

                    <Grid sx={modalHalfStyle}>
                        <CustomAutocomplete fullWidth
                                            name="keaModelCd"              
                                            label="공단모델"                                                                                  
                                            options={keaModelHstList}          
                                            value="modelNo"              
                                            text="modelNm"             
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.keaModelCd}
                        />
                    </Grid>

                    <Grid sx={modalHalfStyle}>
                        <CustomAutocomplete fullWidth
                                            name="lhModelCd"              
                                            label="LH모델"                                                                                    
                                            options={lhModelHstList}          
                                            value="modelNo"              
                                            text="modelNm"             
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.lhModelCd}
                        />                        
                    </Grid>

                    <Divider sx={{ mt:2, width: '100%'}} />
                    
                    <Grid sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', mt:2}}>
                        <Button variant="contained" size="large" color="primary" startIcon={<SaveAltIcon />} onClick={handleSubmit(onSubmit)}>저장</Button>
                        <Button variant="contained" size="large" color="primary" startIcon={<CloseIcon />} sx={{ ml: 2 }} onClick={e => setOpen(false)}>닫기</Button>
                    </Grid>
                    
                </Box>
            </Modal>
            
            <Toast ref={toastRef} />

            <Confirm ref={confirmRef} ok={del} />
        </div>
    );
}

export default Model;