import React from 'react'
import { FormControl, InputLabel, MenuItem, FormHelperText, Select } from '@mui/material';
import { Controller } from "react-hook-form";

const CustomSelect = (props) => {

    return (
        <FormControl sx={{ width: '100%' }} error={props.errors}>
            <InputLabel id={props.name + "_label"}>{props.label}</InputLabel>
            <Controller name={props.name} 
                        control={props.control} 
                        rules={props.rules} 
                        render={({ field: { onChange, value } }) => (                                
                            <Select onChange={onChange} 
                                    value={value} 
                                    label={props.label} 
                                    labelId={props.name + "_label"}>                                                        
                                
                                        {
                                            props.options.map((option) => {                               
                                                return (
                                                    <MenuItem key={option[props.value]} value={option[props.value]}>{option[props.text]}</MenuItem> 
                                                )   
                                            })
                                        }                                    

                            </Select>                            
            )} />                    

            {
                props.errors ? <FormHelperText>{props.errors.message}</FormHelperText> : null
            }

        </FormControl> 
    )
  };
  
  export default CustomSelect