import React, {useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomTextField from "../components/ui/CustomTextField";
import { Box, Modal, Divider, Button } from '@mui/material';
import CustomAutocomplete from "../components/ui/CustomAutocomplete";
import axiosInstance from '../components/util/AxiosUtil';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from "react-hook-form";
import { TextField } from '@mui/material';
import CustomDatatable from '../components/ui/CustomDatatable';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import Toast from '../components/ui/Toast';
import { viewModeType } from '../components/const/CommCode'
import Confirm from '../components/ui/Confirm';

//검색 요청 기본 데이터
const defaultFindValues = {     
    pjtNm : '',
    pjtCdNm : '',
    numRowYy: null
}

//등록, 수정 기본 데이터
let defaultValues = {
    pjtSeq : '',
    bizInstnCd : '',
    bizCd : '',
    pjtNm : '',
    numRowYy : null,
    pjtCdNm : '',
    multiConnYn : '',
    photoOtputTypeCd : ''
}


const Project = () => {
    
    
    //confrim ref 객체
    const confirmRef = useRef();

    //서버 요청 상태 정보
    const [viewMode, setViewMode] = useState(viewModeType.New);

    //조회 기본세팅
    const { handleSubmit : find_handleSubmit, 
            control : find_control, 
            getValues: find_getValues, 
            formState: { errors: find_errors } 
        } = useForm( {defaultFindValues} );

    //조회 폼 사업기관
    const [bizInstnList, setBizInstnList] =  useState([{dtlCd: "", dtlCdNm: ""}]);

    //검색필터
    const [filterData, setFilterData] = useState( {defaultFindValues} );

    //input css style
    const textFileStyled = { mt:2, width: '100%', 'textAlign': 'center' };

    //toast ref 객체
    const toastRef = useRef();

    //데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);

    //******************* Modal관련 상태 세팅 ****************************** */

    // 등록,수정 Form 기본세팅
    const { handleSubmit, getValues, reset, control, formState: { errors } } = useForm( {defaultValues} );

    //등록, 수정 폼 사업기관
    const [formBizInstnList, setFormBizInstnList] =  useState([{dtlCd: "", dtlCdNm: ""}]);

    //모달 노출 상태 정보
    const [open, setOpen] = useState(false);
    
    //등록, 수정 폼 사업구분
    const [formBizDivisionList, setFormBizDivisionList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    //등록, 수정 폼 다중연동 구분
    const [formUseYN, setFormUseYn] = useState([{dtlCd: "", dtlCdNm: ""}]);

    //등록, 수정 폼 사진출력유형 구분
    const [formPhotoOtputTypeCd, setFormPhotoOtputTypeCd] = useState([{dtlCd: "", dtlCdNm: ""}]);

    const [nextPjtSeq, setNextPjtSeq] = useState();
    //*************************** END ******************************** */
    
    useEffect(() => {
        //사업기관 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'BIZ_INSTN_CD'}}).then((Response) => {
            if(Response.request.status === 200) {

                const list =  Response.data.list;
                const newList = [{dtlCd: "", dtlCdNm: "전체"}, ...list];

                //사업기관 select박스
                setBizInstnList(newList);

                //(Modal)사업기관 select박스
                setFormBizInstnList(list);
            }
        })

        //(Modal)사업구분
        axiosInstance.get('/codeList', {params: {grpCd: 'BIZ_CD'}}).then((Response) => {
            if(Response.request.status === 200) {

                //(Modal) 사업구분 select박스
                setFormBizDivisionList(Response.data.list);
            }
        })

        //(Modal)다중연동여부
        axiosInstance.get('/codeList', {params: {grpCd: 'USE_YN'}}).then((Response) => {
            if(Response.request.status === 200) {

                //(Modal) 사업구분 select박스
                setFormUseYn(Response.data.list);

            }
        })

        //(Modal)사진출력유형
        axiosInstance.get('/codeList', {params: {grpCd: 'PHOTO_OTPUT_TYPE_CD'}}).then((Response) => {
            if(Response.request.status === 200) {

                //(Modal) 사진출력유형 select박스
                setFormPhotoOtputTypeCd(Response.data.list);

            }
        })

         //seq 조회
         axiosInstance.get('/getProjectSeq').then((Response) => {
            if(Response.request.status === 200) {
                setNextPjtSeq(Response.data.pjtSeq);
            }
        })

    },[]);

    //테이블 컬럼 정보
    const columns =  [
        { id: "pjtSeq", data: "pjtSeq", title: '프로젝트 일련번호' ,width: '10%',className: "text-center", responsivePriority: 1 },
        { id: "pjtNm", data: "pjtNm", title: '프로젝트 명', width: '15%', className: "text-left",responsivePriority: 2 },
        { id: "pjtCdNm", data: "pjtCdNm", title: '프로젝트 코드', width:'10%', className: "text-center", responsivePriority: 3 },
        { id: "bizInstn", data: "bizInstn", title: '사업기관', width:'15%', className: "text-center", responsivePriority: 4 },
        { id: "numRowYy", data: "numRowYy", title: '사업연도', width:'7%',className: "text-center", responsivePriority: 5 },
        { id: "bizType", data: "bizType", title: '사업구분', width:'25%',className: "text-left", responsivePriority: 6 },
        { id: "multiConnYnNm", data: "multiConnYnNm", title: '다중연동여부', width:'8%',className: "text-center", responsivePriority: 7 },
        { id: "pjtSeq", data: "pjtSeq", title: '관리', width:'10%', className: "text-center", responsivePriority: 0, orderable:false  }
    ]

    function dataReload(data){   
        setPageFixed(false);
        setFilterData(data);  

    }function dataload(data){
        setPageFixed(true);
        setFilterData(data);        
    }

    const columnDefs = [
        {
            className: "text-center p-0", "targets":[7], render: function(data, type, row, meta){
                let customButton = "<button class='data-btn' data-seq='" + row.pjtSeq + "' data-type='update' style='margin:2px; padding:3px;'>수정</button>";

                customButton += "<button class='data-btn' data-seq='" + row.pjtSeq + "' data-type='delete' style='margin:2px; padding:3px;'>삭제</button>";                                    
                
                return customButton;
            }
        }
    ]

    //등록버튼 클릭 이벤트
    const regClickEvent = () => {

        //등록 버튼 클릭시 기본 시퀀스 설정
        reset(defaultValues = {
            pjtSeq : nextPjtSeq,
            bizInstnCd : '',
            bizCd : '',
            pjtNm : '',
            numRowYy : null,
            pjtCdNm : '',
            multiConnYn : '',
            photoOtputTypeCd : ''
        });
        setViewMode(viewModeType.New);

        setOpen(true);
    }

    //테이블 버튼 클릭 콜백함수 (수정, 삭제버튼)
    const buttonClick = (e) => {

        if(e.target.dataset.type === "update") {

            axiosInstance.get('/project', {params: {pjtSeq: e.target.dataset.seq}}).then((Response)=>{
            
                if(Response.request.status === 200){
                    setPageFixed(false);
                    setViewMode(viewModeType.Edit);
                    reset(Response.data.info);
                    setOpen(true);
                }else{
                    toastRef.current.handleOpen("error", "오류입니다. 데이터를 조회 할 수 없습니다.");
                }
            });   

        }else if (e.target.dataset.type === "delete"){            
            confirmRef.current.handleOpen("프로젝트 정보 삭제", "프로젝트 정보를 삭제 합니다. 계속 진행 하시겠습니까?", e);
        }   

    }

    const del = (e) =>{
        axiosInstance.delete('/project', {params: {pjtSeq: e.target.dataset.seq}}).then((Response)=>{
            
            if(Response.request.status === 200){    
                toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
                dataReload(find_getValues());
            }else{                              
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }                  
        }); 
    }

    const onSubmit = (data) => {

        if (viewMode === viewModeType.New){
            axiosInstance.post('/project', {...data}).then((Response)=>{            
                if(Response.request.status === 200 ){  
                    
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                        setOpen(false);
                        dataReload(find_getValues());
                    
                    //pjt_seq 중복이거나 insert가 안되었을 때
                    }else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage );
                    }

                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            });        
        }else if (viewMode === viewModeType.Edit){
            axiosInstance.put('/project', {...data}).then((Response)=>{            
                if(Response.request.status === 200){     
                    toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                    setOpen(false);
                    dataReload(find_getValues());
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }
            });
        }

    }

    return (
        <div style={{ 
            "padding": "16px 24px", 
            "color": "rgb(68, 89, 110)", 
            "height": "100%" 
        }}>
            <Grid container spacing={2}> {/*sx={{ height:'100%'}} */}
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p:0 }}>
                    <div style={{ "marginBottom": "28px", "marginTop":"16px" }}>
                        <p fontWeight="600" className="css-1pr8naw">프로젝트 관리</p>
                        <p className="css-3vvtry">PoSE / 관리 / 프로젝트 관리</p>
                    </div>
                </Grid>

                {/* 프로젝트명, 프로젝트코드, 사업기관, 사업연도, 버튼 박스 */}
                <Grid 
                    container 
                    alignItems="center" 
                    xs={12} sm={12} md={12} lg={12} xl={12} 
                    className="page-filter" 
                    sx={{p:0}}
                >
                    {/* 프로젝트명 */}
                    <Grid xs={12} sm={12} md={6} lg={2} xl={3} sx={{ p: 2 }}>
                        <CustomTextField 
                            fullWidth
                            name="pjtNm"
                            label="프로젝트명"
                            control={find_control}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){                                    
                                    dataReload(find_getValues());
                                }
                             }}
                        />  
                    </Grid>

                    {/* 프로젝트 코드 */}
                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField 
                            fullWidth 
                            name="pjtCdNm" 
                            label="프로젝트 코드" 
                            control={find_control}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){                                    
                                    dataReload(find_getValues());
                                }
                             }}
                        />                          
                    </Grid>

                    {/* 사업기관 */}
                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomAutocomplete
                            name="bizInstnCd"              
                            label="사업기관"           
                            options={bizInstnList}          
                            value="dtlCd"              
                            text="dtlCdNm"             
                            control={find_control}          
                            getValues={find_getValues}      
                            errors={find_errors.bizInstnCd}                                    
                        />
                    </Grid>

                    {/* 사업연도 */}
                    <Grid xs={12} sm={12} md={6} lg={2.5} xl={2} sx={{ p: 2 }}>
                        <LocalizationProvider 
                            dateAdapter={AdapterDayjs} 
                            sx={{width:'100%'}} 
                            adapterLocale="ko"
                        >
                            <Controller 
                                name='numRowYy'
                                control={find_control}                                         
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                        label={['사업 연도']}
                                        inputFormat="YYYY"
                                        value={null}
                                        views={['year']}                                                  
                                        //onChange={(newValue) => onChange(newValue.format("YYYY"))} 
                                        onChange={(newValue) =>  {
                                            if (newValue){
                                                return onChange(newValue.format("YYYY"));
                                            }else{
                                                return onChange(newValue);
                                            }
                                        }} 
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        sx={{width:'100%'}}
                                    />
                                )} 
                            />    
                        </LocalizationProvider>
                    </Grid>

                    {/* 조회, 등록버튼*/}
                    <Grid 
                        textAlign={'start'} 
                        alignItems="center" 
                        xs={12} sm={12} md={12} lg={3.5} xl={3}
                        sx={{p:0}}
                    >
                        <Button 
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ ml:2, height:'52px' }}  
                            startIcon={<SearchIcon />}
                            onClick={find_handleSubmit(dataload)}
                        >조회
                        </Button>
                        <Button
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ m:2, height:'52px' }} 
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={regClickEvent}
                        >등록
                        </Button>
                    </Grid>
                </Grid>
                
                {/* table */}
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 2, mt: 3 }}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable
                            id="table-list-0"                //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                            width="100%"                     //테이블 너비
                            url="/pjtList"                   //ajax url                                   
                            columns={columns}                //테이블 컬럼 정의                                   
                            columnDefs={columnDefs}          //Custom 컬럼 정의
                            filter={filterData}              //ajax 검색 필터 정의       
                            paging={true}                    //페이징 여부
                            pageLength="10"                  //페이징 수
                            buttonClick={buttonClick}        //버튼 클릭 콜백함수
                            pageFixed={pageFixed}
                            order={[[0, 'desc']]}            //기본 정렬
                        />
                    </Grid>
                </Grid>
            
            </Grid>
            
            {/* 등록 / 수정 모달 */}
            <Modal open={open} onClose={e => setOpen(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                <Box className="smModal">
                    <h3 id="parent-modal-title">프로젝트 관리</h3>
                    <Divider sx={{ mt:2 }} />

                    {/* 일련번호 */}
                    <CustomTextField 
                            fullWidth
                            disabled
                            name="pjtSeq"                
                            label="*프로젝트 일련번호"
                            rules={{ required: "프로젝트 일련번호를 입력 해주세요." }}                           
                            control={control}                                                           
                            errors={errors.pjtSeq}
                            sx={textFileStyled} 
                    /> 

                    {/* 사업기관 */}
                    <Grid sx={textFileStyled}>
                        <CustomAutocomplete
                                fullWidth
                                name="bizInstnCd"              
                                label="*사업기관"
                                rules={{ required: "사업기관을 입력 해주세요." }}            
                                options={formBizInstnList}          
                                value="dtlCd"              
                                text="dtlCdNm"             
                                control={control}          
                                getValues={getValues}      
                                errors={errors.bizInstnCd}             
                        />
                    </Grid>

                    {/* 사업구분 */}
                    <Grid sx={textFileStyled}>
                        <CustomAutocomplete
                                fullWidth
                                name="bizCd"              
                                label="사업구분"           
                                options={formBizDivisionList}          
                                value="dtlCd"              
                                text="dtlCdNm"             
                                control={control}          
                                getValues={getValues}      
                                errors={errors.bizCd}             
                        />
                    </Grid>

                    {/* 수행년도 */}
                    <Grid sx={textFileStyled}>
                        <LocalizationProvider 
                                dateAdapter={AdapterDayjs} 
                                sx={{width:'100%'}} 
                                adapterLocale="ko"
                            >
                                <Controller 
                                    name='numRowYy'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePicker
                                            label={['수행년도']}
                                            inputFormat="YYYY"
                                            value={value}
                                            views={['year']}
                                            onChange={(newValue) =>  {
                                                if (newValue){
                                                    return onChange(newValue.format("YYYY"));
                                                }else{
                                                    return onChange(newValue);
                                                }
                                            }} 
                                            renderInput={(params) => <TextField fullWidth {...params}/>}
                                            sx={{width:'100%'}}
                                        />
                                    )} 
                                />    
                         </LocalizationProvider>
                    </Grid>

                    {/* 프로젝트명 */}
                    <CustomTextField 
                            fullWidth
                            name="pjtNm"                
                            label="프로젝트명"                              
                            control={control}                                                           
                            errors={errors.pjtNm}                  
                            sx={textFileStyled} 
                    /> 

                    {/* 프로젝트 코드 */}
                    <CustomTextField 
                            fullWidth
                            name="pjtCdNm"                
                            label="프로젝트 코드"                              
                            control={control}                                                           
                            errors={errors.pjtCdNm}                  
                            sx={textFileStyled} 
                    /> 

                    {/* 다중연동여부 */}
                    <Grid sx={textFileStyled}>
                        <CustomAutocomplete
                                fullWidth
                                name="multiConnYn"              
                                label="*다중연동여부"           
                                options={formUseYN}          
                                value="dtlCd"              
                                text="dtlCdNm" 
                                rules={{ required: "다중연동여부를 선택 해주세요." }}             
                                control={control}          
                                getValues={getValues}      
                                errors={errors.multiConnYn}             
                        />
                    </Grid>

                    {/* 사진출력유형 */}
                    <Grid sx={textFileStyled}>
                        <CustomAutocomplete
                                fullWidth
                                name="photoOtputTypeCd"              
                                label="*사진출력유형"           
                                options={formPhotoOtputTypeCd}          
                                value="dtlCd"              
                                text="dtlCdNm"             
                                control={control}          
                                getValues={getValues} 
                                rules={{ required: "사진출력유형을 입력 해주세요." }}      
                                errors={errors.photoOtputTypeCd}             
                        />
                    </Grid>

                    <Divider sx={{ mt:2, mb:3 }} />
                    
                    <Grid container direction="row" justifyContent="flex-end">
                        <Button variant="contained" size="large" color="primary" startIcon={<SaveAltIcon />} onClick={handleSubmit(onSubmit)}>저장</Button>
                        <Button variant="contained" size="large" color="primary" startIcon={<CloseIcon />} sx={{ ml: 2 }} onClick={e => setOpen(false)}>닫기</Button>
                    </Grid>
                    
                </Box>
            </Modal>
            


            <Toast ref={toastRef} />
            <Confirm ref={confirmRef} ok={del} />
        </div>
    );
}

export default Project;