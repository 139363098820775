import React, {useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { FormControl, RadioGroup, FormControlLabel, Button, Radio } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import axiosInstance from '../../../components/util/AxiosUtil';
import ListIcon from '@mui/icons-material/List';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PvContainer from './pv/PvContainer';
import PvContainerS from './pv/PvContainerS';
import GeoContainerS from './geo/GeoContainerS';
import StContainerS from './st/StContainerS';
import StnContainerS from './stn/StnContainerS';
import FuelContainerS from './fuel/FuelContainerS';
import Toast from '../../../components/ui/Toast';
import Confirm from '../../../components/ui/Confirm';
import { useLocation } from 'react-router-dom';

function EquipmentReg(props) {

    const navigate = useNavigate();

    const location = useLocation();

    //toast ref 객체
    const toastRef = useRef();

    //Confirm ref 객체
    const confirmRef = useRef();

    const [selectedValue, setSelectedValue] = useState('100');

    const [tmpValue, setTmpValue] = useState('100');

    // 에너지원 코드 목록
    const [nrnGentDtlCdList, setNrnGentDtlCdList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // 업무정보
    const [work, setWork] = useState({});
    
    const goListPage = () => {        
        navigate("/rems/support");
    }

    const handleChange = (event) => {
        setTmpValue(event.target.value);
        confirmRef.current.handleOpen("에너지원 변경", "저장하지 않은 정보는 삭제 됩니다. 에너지원을 변경 하시겠습니까?", event);
    };

    const radioChange = () => {
        setSelectedValue(tmpValue);
    }

    const createContainer = () => {
        switch(selectedValue){
            case "100":                
                return <PvContainerS workSeq={location.state.workSeq} nrnGentCd={selectedValue}/>
            case "200":
                return <GeoContainerS workSeq={location.state.workSeq} nrnGentCd={selectedValue}/>
            case "300":
                return <StContainerS workSeq={location.state.workSeq} nrnGentCd={selectedValue}/>
            case "310":
                return <StnContainerS workSeq={location.state.workSeq} nrnGentCd={selectedValue}/>
            default:
                return <FuelContainerS workSeq={location.state.workSeq} nrnGentCd={selectedValue}/>
        }
    }

    // 엑셀 샘플 다운로드
    const excelDownload = () => {

        let url = `${process.env.REACT_APP_API_URL}`;
        
        if(selectedValue === "100"){
            //태양광
            url += "/rems/pv/excel/download";
        }else if (selectedValue === "200"){
            //지열
            url += "/rems/heath/excel/download";
        }else if (selectedValue === "300"){
            //태양열
            url += "/rems/st/excel/download";
        }else if (selectedValue === "310"){
            //태양열(자연)
            url += "/rems/stn/excel/download";
        }else if (selectedValue === "400"){
            //연료전지
            url += "/rems/fuel/excel/download";
        }else{
            //기타
            url += "";
        }
        window.location.href = url;
    }

    useEffect(() => {

        //업무 정보 조회
        axiosInstance.get('/work', {params: {workSeq: location.state.workSeq}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setWork(Response.data.work);                     
            }
        });

        //에너지원 코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'NRN_GENT_DTL_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setNrnGentDtlCdList(Response.data.list);                     
            }
        });

    }, []);

    return (
        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)", height:"700px"}}>

            <Grid container spacing={2} sx={{ height:'100%', display: { xs: 'none', lg: 'block', xl: 'block' }}}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p:0 }}>

                    <div style={{ "marginBottom": "16px" }}></div>
                    <div style={{ "marginBottom": "28px" }}>
                        <p fontWeight="600" className="css-1pr8naw">신재생 에너지 관리</p>
                        <p className="css-3vvtry">PoSE / 관리 / 설비 등록지원 / 설비등록</p>
                        <p className="css-3vvtry" style={{marginTop:10}}>요청번호: {work.workSeq}, 프로젝트: {work.pjtDisplayNm}, 현장명: {work.workDisplayNm}</p>
                    </div>
                                   
                </Grid>
                
                <Grid container alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 0, marginBottom:2 }}>
                    <Grid xs={12} sm={12} md={12} lg={6} xl={6} sx={{ p: 2 }}>

                        <FormControl>                            
                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                {
                                    nrnGentDtlCdList.map((item, index) => (
                                        item.dtlCd != '500' ?
                                        <FormControlLabel key={index} value={item.dtlCd} control={<Radio checked={selectedValue === item.dtlCd} onChange={handleChange} />} label={item.dtlCdNm} />
                                        : null
                                    ))
                                }                                
                            </RadioGroup>
                        </FormControl>

                    </Grid>
                    <Grid container justifyContent="flex-end" xs={12} sm={12} md={12} lg={6} xl={6} sx={{pr:5, pb:2, pt:2}}>                        
                        <Button variant="contained" size="large" color="success" sx={{ ml: 2, width:186 }} onClick={excelDownload} startIcon={<FileDownloadIcon />}>엑셀 샘플 다운로드</Button>                                                                        
                        <Button variant="contained" size="large" color="primary" sx={{ ml: 2 }} startIcon={<ListIcon />} onClick={goListPage}>목록</Button>                                                
                    </Grid>
                </Grid>

                {                    
                    createContainer()
                }
                
            </Grid>
            
            <Grid container spacing={2} sx={{ height:'100%', p:4, display: { sm: 'block', md: 'block', lg: 'none', xl: 'none' }}}>
                <h3>지원하지 않는 해상도 입니다.</h3><br/><br/>(992px 미만 해상도에서는 해당 화면을 볼 수 없습니다.)
            </Grid>

            <Toast ref={toastRef} />

            <Confirm ref={confirmRef} ok={radioChange} />
        </div>
    );
}

export default EquipmentReg;