import React, { forwardRef } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const RtuInstContent = forwardRef((props, ref) => {

    return (
        <table className="print-ref" ref={ref}>
            <thead>
                <tr>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr style={{width:'210mm'}}>
                    <td className='title' colSpan="4">업무요청서</td>
                </tr>
                <tr style={{width:'210mm'}}>
                    <td colSpan="4" style={{paddingBottom:10, paddingLeft:0}}>요청번호: {props.data.workSeq}</td>
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className="cell" style={{width:'25mm', textAlign:'center'}}>프로젝트</td>
                    <td className="cell" style={{width:'80mm'}}>{props.data.pjtDisplayNm}</td>
                    <td className="cell" style={{width:'25mm', textAlign:'center'}}>사업명</td>
                    <td className="cell" style={{width:'80mm'}}>{props.data.rtuBizDiv}</td>                    
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className="cell" style={{width:'25mm', textAlign:'center'}}>현장명</td>
                    <td className="cell" style={{width:'80mm'}}>{props.data.spotNm}</td>
                    <td className="cell" style={{width:'25mm', textAlign:'center'}}>납기일</td>
                    <td className="cell" style={{width:'80mm'}}>{props.data.ddateDay}</td>
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className="cell" style={{width:'25mm', textAlign:'center'}}>업무내용</td>
                    <td className="cell" style={{width:'80mm'}}>{props.data.rtuWorkDiv}</td>
                    <td className="cell" style={{width:'25mm', textAlign:'center'}}>eTU 모델</td>
                    <td className="cell" style={{width:'80mm'}}>{props.data.prdtNm}</td>
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className='cell' colSpan="4" style={{textAlign:'center'}}>
                        <FormControlLabel control={<Checkbox checked={props.data.rtuSetYn} />} label="eTU 셋팅" />
                        <FormControlLabel control={<Checkbox checked={props.data.siteRegYn} />} label="수용가 등록" />
                        <FormControlLabel control={<Checkbox checked={props.data.loraRegYn} />} label="LoRa 등록" />
                        <FormControlLabel control={<Checkbox checked={props.data.facRegYn} />} label="설비등록" />
                    </td>                
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className='cell' colSpan="4" style={{textAlign:'center'}}>
                        <FormControlLabel control={<Checkbox checked={props.data.boxComposiYn} />} label="하이박스 구성" />
                        <FormControlLabel control={<Checkbox checked={props.data.npComposiYn} />} label="명판제작" />
                        <FormControlLabel control={<Checkbox checked={props.data.dlvrYn} />} label="배송"  />
                        <FormControlLabel control={<Checkbox checked={props.data.spotInstlYn} />} label="현장설치" />
                        <FormControlLabel control={<Checkbox checked={props.data.voicSendYn} />} label="음성송출" />                        
                    </td>                
                </tr>                
                <tr style={{width:'210mm'}}>
                    <td className='cell' colSpan="4" style={{textAlign:'center'}}>요청 사항</td>
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className='cell' colSpan="4" style={{height:240}}><pre>{props.data.regInfCntt}</pre></td>
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className='cell' colSpan="3" style={{textAlign:'center'}}>필요 사항</td>
                    <td className='cell' colSpan="3" style={{textAlign:'center'}}>{props.data.ncstMtrDate}</td>
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className='cell' colSpan="4" style={{height:120}}><pre>{props.data.ncstMtrCntt}</pre></td>
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className='cell' colSpan="3" style={{textAlign:'center'}}>처리 결과</td>
                    <td className='cell' colSpan="3" style={{textAlign:'center'}}>{props.data.procRsltDate}</td>
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className='cell' colSpan="4" style={{height:120}}><pre>{props.data.procRsltCntt}</pre></td>
                </tr>
            </tbody>
        </table> 
    )

});
  
export default RtuInstContent