import styled from '@emotion/styled';
import React from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import logoImage from '../../assets/image/logo_PoSE.png';

export default function SidebarHeader(props){

  const { rtl } = useProSidebar();

  const StyledLogo = styled.div`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background-color: #009fdb;
  background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
  ${({ rtl }) =>
    rtl
      ? `
      margin-left: 10px;
      margin-right: 4px;
      `
      : `
      margin-right: 10px;
      margin-left: 4px;
      `}
  `;

  return (
    <div className="css-1vmkajq">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={logoImage} width={140} height={44} alt=""/>
      </div>
    </div>
  );
};