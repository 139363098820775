import React, {  useState, useEffect, useRef} from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { Card } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import axiosInstance from '../../components/util/AxiosUtil';
import Paper from '@mui/material/Paper';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/photo.css';
import PhotoCard from '../photo/PhotoCard'

const PhotoDetail = () => {

    const navigate = useNavigate();
    const[photoKindList, setPhotoKindList] = useState([]);//사진종류 리스트
    const location = useLocation();

    useEffect(() => {
       
        //사진종류 목록 조회
        axiosInstance.get('/rtuPhotoTypeCodeList', {params: {rtuSeq: location.state.rtuSeq}}).then((Response)=>{
            if (Response.request.status === 200) {  
                setPhotoKindList(Response.data.list);
            }
        });
    }, []);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1.5),
        color: theme.palette.text.secondary,
      }));

    const buttonClick = () =>{
        navigate("/product/photo", {replace: false});
    };

    //뒤에 4자리 하이라이트 처리
    const cidCustom = (cids) => {

        var cidList = cids.split(',');

        return(
            <>
                {cidList.map((value,idx) => (
                    <div key={idx} className="cidsCustom">
                        <span>
                            {value.substring(0,value.length-4)}
                        </span>
                        <span className="colorHighlight">
                            {value.substring(value.length-4 ,value.length)}
                        </span>
                    </div>
                ))}
           </>
        );
    };
    
    return(
        <div style={{ "color": "rgb(68, 89, 110)", "height": "100%" }}>
             <Grid spacing={2} sx={{ height:'100%'}}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>
                    <div className="mobileHiddenCustom" style={{"padding": "16px 24px", "marginBottom":"10px"}}>
                        <div style={{ "marginBottom": "16px" }}></div>
                        <p fontWeight="600" className="css-1pr8naw">사진 등록</p>
                        <a className="css-3vvtry">PoSE / 영업 지원 / 사진 등록</a>
                    </div>

                    <Grid container direction="row" justifyContent="center" marginTop={'10px'} minHeight={'1500px'}>
                        <Grid xs={12} sm={12} md={10} lg={10} xl={8}>
                            <Card elevation={4}  style={{"background":"#f9fafb"}}>
                                <Grid bgcolor={'#f9fafb'} paddingBottom={'10px'}>
                                    <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="stretch"
                                        spacing={0.5}
                                    >
                                        <Item>
                                            <Typography component="div" variant="h5" style={{"fontWeight":"600"}}>
                                                {location.state ? location.state.siteNm : null}
                                            </Typography>
                                        </Item>
                                        <Item style={{"fontWeight":"600", "fontSize":"18px"}}>
                                            {location.state ? location.state.adr : null}
                                        </Item>
                                        <Item style={{"fontWeight":"600", "fontSize":"18px"}}> 
                                            설치계획서 번호 : {location.state ? location.state.instlPlndocNo : null}
                                        </Item>
                                        <Item style={{"fontWeight":"600", "fontSize":"18px", "display":"flex", "flexWrap":"wrap"}}> 
                                            CID : {location.state ? cidCustom(location.state.cids) : null}
                                        </Item>
                                    </Stack>
                                </Grid>

                                {photoKindList.map((value) => (
                                    <PhotoCard 
                                        key={value.dtlCd}
                                        dtlCd={value.dtlCd}
                                        dtlCdNm={value.dtlCdNm}
                                        rtuSeq={location.state.rtuSeq}
                                    />
                                ))}

                                <Grid textAlign={'center'} marginBottom={'10px'} bgcolor={'#f9fafb'} marginTop={'10px'}>
                                    <Button 
                                            variant="contained" 
                                            size="large"
                                            style={{
                                                "width": "75%", 
                                                "fontSize":"20px"
                                            }}
                                            onClick={() => buttonClick()}
                                    >
                                        완료
                                    </Button>
                                </Grid>

                               
                            </Card>

                        </Grid>
                    </Grid>
                </Grid>
             </Grid>
        </div>
    );
}

export default PhotoDetail;