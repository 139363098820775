import React, {useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomTextField from "../components/ui/CustomTextField";
import { Box, Modal, Divider, Button } from '@mui/material';
import axiosInstance from '../components/util/AxiosUtil';
import CustomDatatable from '../components/ui/CustomDatatable';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import Toast from '../components/ui/Toast';
import { viewModeType } from '../components/const/CommCode'
import Confirm from '../components/ui/Confirm';

//검색 요청 기본 테이터 세팅
const defaultFindValues = {
    //사이트명, 시도, 구군, 지역본부명
    siteNm : '',
    sido : '',
    sigungu : '',
    rgnHqNm : ''
}

//등록, 수정 기본 폼 데이터 세팅
let defaultValues = {
    //사이트명, 시도, 시군구, 지역본부명, LH 일련번호
    siteNm : '',
    sido : '',
    sigungu : '',
    rgnHqNm : '',
    lhSiteSeq : ''
}

function Site() {

    // [검색] 조회 기본세팅 - useForm
    const {
        handleSubmit : find_handleSubmit,
        getValues: find_getValues,
        control : find_control
    } = useForm( {defaultFindValues} );

    // [modal] 등록,수정 Form 기본세팅
    const { 
        handleSubmit, 
        getValues, 
        reset, 
        control, 
        formState: { errors } 
    } = useForm( {defaultValues} );

    //검색필터
    const [filterData, setFilterData] = useState( {defaultFindValues} );

    //데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);

    //서버 요청 상태 정보
    const [viewMode, setViewMode] = useState(viewModeType.New);

    //모달 노출 상태 정보
    const [open, setOpen] = useState(false);

    //테이블 컬럼정보
    const columns =  [
        { id: "siteSeq", data: "siteSeq", title: '사이트 일련번호' ,width: '10%',className: "text-center", responsivePriority: 1 },
        { id: "siteNm", data: "siteNm", title: '사이트 명', width: '30%', className: "text-center",responsivePriority: 2 },
        { id: "sido", data: "sido", title: '시도', width:'10%', className: "text-center", responsivePriority: 3 },
        { id: "sigungu", data: "sigungu", title: '구군', width:'5%', className: "text-center", responsivePriority: 4 },
        { id: "rgnHqNm", data: "rgnHqNm", title: '지역본부', width:'10%',className: "text-center", responsivePriority: 5 },
        { id: "lhSiteSeq", data: "lhSiteSeq", title: 'LH 일련번호', width:'10%',className: "text-center", responsivePriority: 6 },
        { id: "cretDt", data: "cretDt", title: '등록일시', width:'15%',className: "text-center", responsivePriority: 7 },        
        { id: "siteSeq", data: "siteSeq", title: '관리', width:'10%',className: "text-center", responsivePriority: 0, orderable:false }
    ]

    //수정, 삭제 버튼 html
    const columnDefs = [
        {
            className: "text-center p-0", "targets":[7], render: function(data, type, row, meta){
                let customButton = "<button class='data-btn' data-seq='" + row.siteSeq + "' data-type='update' style='margin:2px; padding:3px;'>수정</button>";

                customButton += "<button class='data-btn' data-seq='" + row.siteSeq + "' data-type='delete' style='margin:2px; padding:3px;'>삭제</button>";                                    
                
                return customButton;
            }
        }
    ]

    //테이블 버튼 클릭 콜백함수 (수정, 삭제버튼)
    const buttonClick = (e) => {

        if(e.target.dataset.type === "update") {

            axiosInstance.get('/site', {params: {siteSeq: e.target.dataset.seq}}).then((Response)=>{
            
                if(Response.request.status === 200){
                    setPageFixed(false);
                    setViewMode(viewModeType.Edit);
                    reset(Response.data.info);
                    setOpen(true);
                }else{
                    toastRef.current.handleOpen("error", "오류입니다. 데이터를 조회 할 수 없습니다.");
                }
            });   

        }else if (e.target.dataset.type === "delete"){            
            confirmRef.current.handleOpen("사이트 정보 삭제", "사이트 정보를 삭제 합니다. 계속 진행 하시겠습니까?", e);
        }   

    }

    const del = (e) =>{
        axiosInstance.delete('/site', {params: {siteSeq: e.target.dataset.seq}}).then((Response)=>{
            
            if(Response.request.status === 200){    
                toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
                dataReload(find_getValues());
            }else{                              
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }                  
        }); 
    }
    
    //등록버튼 클릭 이벤트
    const regClickEvent = () => {

        //모달 초기화
        reset({defaultValues});

        //등록으로 상태변경
        setViewMode(viewModeType.New);

        //모달 열기
        setOpen(true);
    }

    const onSubmit = (data) => {
        //등록
        if (viewMode === viewModeType.New) {
        
            axiosInstance.post('/site', {...data}).then((Response)=>{
                if(Response.request.status === 200 ){  
                    
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");
                        setOpen(false);
                        dataReload(find_getValues());
                    
                    //pjt_seq 중복이거나 insert가 안되었을 때
                    }else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage );
                    }

                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            });

        //수정
        }else if (viewMode === viewModeType.Edit) {

            axiosInstance.put('/site', {...data}).then((Response)=>{          
                
                if(Response.request.status === 200){     
                    toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                    setOpen(false);
                    dataReload(find_getValues());
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }
            });
        }
    }

    function dataReload(data){   
        setPageFixed(false);
        setFilterData(data);  

    }
    const dataload = (data) =>{
        setPageFixed(true);
        setFilterData(data);        
    }

    //toast ref 객체
    const toastRef = useRef();

    //confrim ref 객체
    const confirmRef = useRef();

    //style
    const style1={
        "padding": "16px 24px", 
        "color": "rgb(68, 89, 110)", 
        "height": "100%"
    }
    const style2={
        "marginBottom": "28px",
        "marginTop":"16px"
    }
    //[modal] input css style
    const textFileStyled = { mt:2, width: '100%', 'textAlign': 'center' };

    return (
        <div style={style1}>

            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} 
                      sx={{ height:'100%', p:0 }}
                >
                    <div style={style2}>
                        <p fontWeight="600" className="css-1pr8naw">사이트 관리</p>
                        <p className="css-3vvtry">PoSE / 신재생서비스 / 사이트 관리</p>
                    </div>         
                </Grid>
                
                {/* 검색창 */}
                <Grid container 
                      alignItems="center" 
                      xs={12} sm={12} md={12} lg={12} xl={12} 
                      className="page-filter" 
                      sx={{p:0}}
                >
                    {/* 사이트 명 */}
                    <Grid xs={12} sm={12} md={6} lg={2} xl={3} sx={{ p: 2 }}>
                        <CustomTextField 
                            fullWidth 
                            name="siteNm" 
                            label="사이트 명" 
                            control={find_control}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){                                    
                                    dataReload(find_getValues());
                                }
                            }}
                        />                          
                    </Grid>

                    {/* 시도 */}
                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField 
                            fullWidth 
                            name="sido" 
                            label="시도" 
                            control={find_control}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){                                    
                                    dataReload(find_getValues());
                                }
                            }}
                        />                          
                    </Grid>

                    {/* 구군 */}
                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField 
                            fullWidth 
                            name="sigungu" 
                            label="구군" 
                            control={find_control}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){                                    
                                    dataReload(find_getValues());
                                }
                            }}
                        />                          
                    </Grid>

                    {/* 지역본부 명 */}
                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField 
                            fullWidth 
                            name="rgnHqNm" 
                            label="지역본부 명" 
                            control={find_control}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){                                    
                                    dataReload(find_getValues());
                                }
                            }}
                        />                          
                    </Grid>

                    {/* 조회, 등록버튼*/}
                    <Grid textAlign={'start'} 
                          alignItems="center" 
                          container
                          xs={12} sm={12} md={12} lg={4} xl={3} 
                    >
                        <Button variant="contained" 
                                size="large" 
                                color="primary" 
                                sx={{ ml:2, height:'52px' }}  
                                startIcon={<SearchIcon />}
                                onClick={find_handleSubmit(dataload)}
                        >조회
                        </Button>
                        <Button variant="contained" 
                                size="large" 
                                color="primary" 
                                sx={{ m:2, height:'52px' }} 
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={regClickEvent}
                        >등록
                        </Button>
                    </Grid>
                </Grid>

                {/* table */}
               <Grid container 
                     xs={12} sm={12} md={12} lg={12} xl={12} 
                     className="page-filter" 
                     sx={{ p: 2, mt: 3 }}
                >
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable id="table-list-0"                //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                         width="100%"                     //테이블 너비
                                         url="/siteList"                  //ajax url
                                         columns={columns}                //테이블 컬럼 정의
                                         columnDefs={columnDefs}          //Custom 컬럼 정의
                                         filter={filterData}              //ajax 검색 필터 정의
                                         paging={true}                    //페이징 여부
                                         pageLength="10"                  //페이징 수
                                         buttonClick ={buttonClick}     //버튼 클릭 콜백함수
                                         pageFixed={pageFixed}            //페이징 유지 여부
                                         order={[[0, 'asc']]}             //기본 정렬
                        />
                    </Grid>
                </Grid>

                {/* 등록 / 수정 모달 */}
                <Modal open={open} 
                       onClose={e => setOpen(false)} 
                       aria-labelledby="parent-modal-title" 
                       aria-describedby="parent-modal-description" 
                >
                    <Box className="smModal">
                        <h3 id="parent-modal-title">사이트 정보</h3>
                        <Divider sx={{ mt:2 }} />

                        {/* 사이트 명 */}
                        <CustomTextField fullWidth
                                         name="siteNm"                
                                         label="*사이트 명"
                                         rules={{ required: "사이트 명을 입력 해주세요." }}                           
                                         control={control}                                                           
                                         errors={errors.siteNm}
                                         sx={textFileStyled} 
                        /> 
                        {/* 시도 */}
                        <CustomTextField fullWidth
                                         name="sido"                
                                         label="시도"                     
                                         control={control}                                                           
                                         errors={errors.sido}
                                         sx={textFileStyled} 
                        /> 
                        {/* 시군구 */}
                        <CustomTextField fullWidth
                                         name="sigungu"                
                                         label="시군구"                     
                                         control={control}                                                           
                                         errors={errors.sigungu}
                                         sx={textFileStyled} 
                        /> 
                        {/* 지역본부명 */}
                        <CustomTextField fullWidth
                                         name="rgnHqNm"                
                                         label="지역본부명"                        
                                         control={control}                                                           
                                         errors={errors.rgnHqNm}
                                         sx={textFileStyled} 
                        /> 
                        {/* LH 일련번호 */}
                        <CustomTextField fullWidth
                                         name="lhSiteSeq"                
                                         label="LH 일련번호"                  
                                         control={control}                                                           
                                         errors={errors.lhSiteSeq}
                                         sx={textFileStyled} 
                        /> 
                        

                        <Divider sx={{ mt:2, mb:3 }} />
                        
                        <Grid container 
                              direction="row" 
                              justifyContent="flex-end"
                        >
                            <Button variant="contained" 
                                    size="large" 
                                    color="primary" 
                                    startIcon={<SaveAltIcon />} 
                                    onClick={handleSubmit(onSubmit)}
                            >저장</Button>
                            <Button variant="contained" 
                                    size="large" 
                                    color="primary" 
                                    startIcon={<CloseIcon />} 
                                    sx={{ ml: 2 }} 
                                    onClick={e => setOpen(false)}
                            >닫기</Button>
                        </Grid>
                        
                    </Box>
                </Modal>

            </Grid>
            <Toast ref={toastRef} />
            <Confirm ref={confirmRef} ok={del} />
        </div>
    );
}

export default Site;