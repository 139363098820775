import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Toast from '../components/ui/Toast';
import KeyIcon from '@mui/icons-material/Key';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../components/util/AxiosUtil';

function MyInfo() {

    //input css style
    const textFileStyled = { mt:2, width: '100%', 'textAlign': 'center' };

    //toast ref 객체
    const toastRef = useRef();

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    
    const { register : register_password, handleSubmit : handleSubmit_password, reset : reset_password, formState: { errors : errors_password } } = useForm();

    //팝업 노출 상태 정보
    const [open, setOpen] = useState(false); 
    
    let [usrStsList, setUsrStsList] = useState([]);

    const [ usrSts, setUsrSts] = useState({}); 

    //비밀번호 변경 버튼 클릭 이벤트
    const pwdChangeClickEvent = () =>{    
        reset_password({});
        setOpen(true);
    }

    const onSubmit = (data, e) =>{
        axiosInstance.put('/auth/member', {...data}).then((Response)=>{            
            if(Response.request.status === 200 && Response.data.resultCode === "SUCCESS"){     
                toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
            }else{                              
                toastRef.current.handleOpen("error", Response.data.resultMessage);                                        
            }                  
        });  
    }

    const changePassword = (data, e) => {
        axiosInstance.put('/auth/member/pwd', {...data}).then((Response)=>{            
            if(Response.request.status === 200 && Response.data.resultCode === "SUCCESS"){     
                toastRef.current.handleOpen("success", "저장 되었습니다.");    
                setOpen(false);                                    
            }else{                              
                toastRef.current.handleOpen("error", Response.data.resultMessage);                                        
            }                  
        });  
    }

    useEffect(() => {

        axiosInstance.get('/codeList', {params: {grpCd: 'USR_STS_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {  
                      
                setUsrStsList(Response.data.list);

                axiosInstance.get('/auth/member').then((ResponseMember) => {
                    if (ResponseMember.request.status === 200) {                 
                        reset(ResponseMember.data.member);     
                        
                        const newUsrSts = usrStsList.filter((data) => {
                            return data.dtlCd === ResponseMember.data.member.usrSts;
                        });

                        setUsrSts(newUsrSts[0]);
                    }
                });
            }
        });

    }, []);

    return (
        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)", "height": "100%" }}>

            <Grid container spacing={2} sx={{ height:'100%'}}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>

                    <div style={{ "marginBottom": "16px" }}></div>
                        <div style={{ "marginBottom": "48px" }}>
                        <p fontWeight="600" className="css-1pr8naw">개인정보 관리</p>
                        <p className="css-3vvtry">PoSE / 개인정보 관리</p>
                    </div>
                        
                    <Grid justifyContent="center" alignItems="center" sx={{ display: 'flex' }}>

                        <Grid container justifyContent="center" alignItems="center" sx={{ display: 'flex', width:'100%', maxWidth:550, p:3, border:'1px solid silver' }}>

                            <Grid className="title" xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1 }}>
                                <TextField disabled fullWidth label="사용자 아이디" variant="outlined" defaultValue="default" sx={textFileStyled} {...register("usrId")} />
                            </Grid>

                            <Grid className="title" xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1 }}>
                                <TextField fullWidth label="핸드폰번호" variant="outlined" defaultValue="default" sx={textFileStyled} {...register("hpno")} />
                            </Grid>

                            <Grid className="title" xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1 }}>
                                <TextField fullWidth label="*이메일" variant="outlined" defaultValue="default" sx={textFileStyled} 
                                    error={errors.eml}
                                    helperText={errors.eml ? errors.eml.message : ""}        
                                    {...register("eml", { required: ' 이메일을 입력해주세요.' ,
                                                          pattern: { value: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i, message: '이메일 형식을 입력하세요.' }})}                                    
                                />
                            </Grid>

                            <Grid className="title" xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1 }}>
                                <Autocomplete value={usrSts}
                                              options={usrStsList}
                                              onChange={(event, newValue) => {                                            
                                                setUsrSts(newValue);
                                              }}                                                                                                                                    
                                              getOptionLabel={(option) => option.dtlCdNm}
                                              sx={textFileStyled}
                                              renderInput={(params) => <TextField {...params} label="상태" />}
                                />
                            </Grid>

                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}>                                      
                                <Button variant="contained" size="large" color="primary" startIcon={<KeyIcon />} onClick={pwdChangeClickEvent} sx={{ ml: 2 }}>비밀번호 변경</Button>                
                                <Button variant="contained" size="large" color="primary" startIcon={<SaveAltIcon />} sx={{ ml: 2 }} onClick={handleSubmit(onSubmit)}>저장</Button>                
                            </Grid>

                        </Grid>         
                    </Grid>
                </Grid>
                
            </Grid>

            <Modal open={open} onClose={e => setOpen(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                <Box className="Modal">
                    <h3 id="parent-modal-title">비밀번호 변경</h3>
                    <Divider sx={{ mt:2 }} />

                    <TextField fullWidth
                               label="현재 비밀번호"                                
                               type="password"
                               error={errors_password.currentPwd}
                               helperText={errors_password.currentPwd ? errors_password.currentPwd.message : ""}        
                               sx={{mt:2}}                                                                                     
                               {...register_password("currentPwd", { required: '현재 비밀번호를 입력 해주세요.'})}
                    />
                    <TextField fullWidth
                               label="신규 비밀번호" 
                               type="password"
                               error={errors_password.newPwd}
                               helperText={errors_password.newPwd ? errors_password.newPwd.message : ""}                                                                                             
                               sx={{mt:2}}                                                                       
                               {...register_password("newPwd", { required: '신규 비밀번호를 입력 해주세요.',
                                                                 pattern: { value: /^[a-zA-Z\\d`~!@#$%^&*()-_=+]{8,16}$/, message: '8~16자 영문, 숫자, 특수문자를 사용하세요.' }})}
                    />
                    <TextField fullWidth
                               label="신규 비밀번호 확인" 
                               type="password"
                               error={errors_password.confirmPwd}
                               helperText={errors_password.confirmPwd ? errors_password.confirmPwd.message : ""}                                 
                               sx={{mt:2}}                                                                                                                                   
                               {...register_password("confirmPwd", { required: '신규 비밀번호 확인을 입력 해주세요.',
                                                                     pattern: { value: /^[a-zA-Z\\d`~!@#$%^&*()-_=+]{8,16}$/, message: '8~16자 영문, 숫자, 특수문자를 사용하세요.' },
                                                                     validate: (value, formValues) => value === formValues.newPwd || '신규 비밀번호가 일치하지 않습니다.' })}
                    />                    

                    <Divider sx={{ mt:2, mb:2 }} />
                    
                    <Grid container direction="row" justifyContent="flex-end">
                        <Button variant="contained" size="large" color="primary" startIcon={<SaveAltIcon />} onClick={handleSubmit_password(changePassword)}>저장</Button>
                        <Button variant="contained" size="large" color="primary" startIcon={<CloseIcon />} sx={{ ml: 2 }} onClick={e => setOpen(false)}>닫기</Button>
                    </Grid>
                    
                </Box>
            </Modal>

            <Toast ref={toastRef} />

        </div>
    );
}

export default MyInfo;