import React, { useState, forwardRef, useImperativeHandle } from 'react'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Toast = forwardRef((props, ref) => {

    const [state, setState] = useState({ open: false, vertical: 'bottom', horizontal: 'right' });

    const [type, setType] = useState("success");

    const [message, setMessage] = useState("");

    const { vertical, horizontal, open } = state;

    const handleOpen = (type, message) => {
        setType(type);
        setMessage(message);
        setState({ ...state, open: true});
    }

    const handleClose = () => {
        setState({ open: false, vertical: 'bottom', horizontal: 'right'});
    }

    //부모 컴포넌트에서 호출 할 수 있는 함수 정의
    useImperativeHandle(ref, () => ({
        handleOpen, handleClose
    }))

    return (
        <Snackbar anchorOrigin={{ vertical, horizontal }}
                  autoHideDuration={6000}
                  open={open}
                  onClose={handleClose}>                             
                
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
  });
  
  export default Toast