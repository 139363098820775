import React, { useState, useEffect, useRef} from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import axiosInstance from '../../components/util/AxiosUtil';
import Paper from '@mui/material/Paper';
import '../../assets/css/photo.css';
import { FileUploader } from "react-drag-drop-files";
import Toast from '../../components/ui/Toast';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';

const PhotoCard = ({dtlCd, dtlCdNm, rtuSeq}) =>{

    //사진
    const [image000, setImage000] = useState({});
    const [image001, setImage001] = useState({});
    
    const [buttonActive000, setButtonActive000] = useState(true);
    const [buttonActive001, setButtonActive001] = useState(true);
   
    //toast ref 객체
    const toastRef = useRef();

    const fileTypes = ["JPG", "PNG", "GIF", 'JPEG'];

    const [focusPhotoTypeCd, setFocusPhotoTypeCd] = useState("");
    
    useEffect(()=>{
        getImageList();
    }, []);

    //사진 조회
    const getImageList = () => {

        axiosInstance.get('/photoHstList', {params: {rtuSeq: rtuSeq, photoTypeCd: dtlCd}})
            .then((Response)=>{

                if(Response.request.status === 200){

                    //사진 초기화
                    setImage000({});
                    setImage001({});

                    //버튼 초기화
                    setButtonActive000(true);
                    setButtonActive001(true);

                    Response.data.list.map(function(file){
                            //DB 결과값 이미지 파일 생성
                            const newBlob = new Blob([convertToBlob(file.blob)], {type:file.fileTypeNm});                           
                            const createFile = new File([newBlob], file.fileNm,{type:file.fileTypeNm});                                        

                            //이미지 랜더링 데이터 생성
                            const blod = 'data:' + file.fileTypeNm + ';base64,' + file.blob;

                            //State 추가
                            const item = {
                                photoTypeCd: file.photoTypeCd, 
                                file: createFile, 
                                blod: blod,
                                ord: file.ord
                            };

                            if(item.ord === 0){
                                setImage000(item);
                                setButtonActive000(false);
                            }else if(item.ord === 1){
                                setImage001(item);
                                setButtonActive001(false);
                            }
                        
                    });   
                    
                }else{
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 조회 할 수 없습니다.");                   
                }
        });
    };

    //Base64 문자열 Blob 타입 변경 함수
    const convertToBlob = (base64) => {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        return byteArray;
    }

    //파일 드래그 앤 드랍 컴포넌트 변경 이벤트
    const handleChange = (file, photoTypeCd, ord) => {        
        save(file, photoTypeCd, ord);
    };

    // 이미지 저장
    const save = (file, photoTypeCd, ord) => {

        //파일 서버 저장
        const formData = new FormData();
        formData.append("rtuSeq", rtuSeq);
        formData.append("photoTypeCd", photoTypeCd);
        formData.append("img", file);           
        formData.append("ord", ord);

        axiosInstance.post('/photoHst', formData, {headers: {'content-type': 'multipart/form-data'}}).then((Response)=>{            

            if (Response){
                if(Response.request.status === 200){     

                    toastRef.current.handleOpen("success", "저장 되었습니다.");                                        

                    //파일 저장 성공시 이미지 다시 조회
                    getImageList();

                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
            }
            
        });  

    };

    // 이미지 삭제
    const del = (photoTypeCd,ord, e) => {

        axiosInstance.delete('/photoHst', {params: {rtuSeq: rtuSeq, photoTypeCd: photoTypeCd, ord: ord}}).then((Response)=>{                    

            if (Response){
                if(Response.request.status === 200){     

                    toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        

                    //파일 삭제 성공시 이미지 다시 조회
                    getImageList();
    
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 삭제 할 수 없습니다.");                                        
                }                  
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 삭제 할 수 없습니다.");                                        
            }
            
        });  
    }

    //휴지통아이콘 비활성화시 테두리 제거
    const ButtonCustom = styled(IconButton)({
        "&.Mui-disabled": {
            border: 0
        }
    });

    //paper 스타일 변경
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1.5),
        color: theme.palette.text.secondary,
    }));

    // 붙여넣기 이벤트
    document.onpaste = function(pasteEvent) {        

        if (focusPhotoTypeCd !== ""){
            const item = pasteEvent.clipboardData.items[0];
    
            if (item.type.indexOf("image") === 0)
            {
                const orgFile = item.getAsFile();
                const fileExtension = orgFile.name.split('.')[1];
                const fileName = Date.now() + '.' + fileExtension;                
                const newFile = new File([orgFile], fileName, {type: orgFile.type});

                save(newFile,dtlCd, focusPhotoTypeCd);
            }
        }            
    }
    
    return(
        <Grid 
            className="mobileWidthCustom" 
            display={'flex'}
            sx={{height:'100%'}} 
            bgcolor={'#f9fafb'}
        >
            <Grid 
                xs={12} sm={6} md={6} lg={6} xl={6}
                textAlign={'center'} 
                sx={{padding: 1}}
            >
                <Card elevation={4}>
                    <Item 
                        style={{
                            "fontWeight":"600", 
                            "fontSize":"18px", 
                            "border":"3px solid #1976d2",
                            "padding":"5px 0 5px 0",
                            "display":"flex",
                            "alignItems":"center"
                        }}
                    >
                        <Grid  style={{"flex":"1 0 auto", "paddingLeft":"5px"}}>
                            {dtlCdNm} #1
                        </Grid>
                        <ButtonCustom 
                            color="error"  
                            disabled={buttonActive000}
                            onClick={()=>del(dtlCd, 0)}
                        >
                            <DeleteForeverIcon sx={{ height: 30, width: 30 }} />
                        </ButtonCustom>
                        
                    </Item>
                    <Grid 
                        justifyContent="center" 
                        alignItems="center" 
                        onClick={()=> setFocusPhotoTypeCd("000")} 
                        xs={12} sm={12} md={12} lg={12} xl={12} 
                        sx={{ display: 'flex', p:0, height:300}}
                    >
                        {image000.blod
                        
                        ? <FileUploader
                                handleChange={(file) => handleChange(file, dtlCd, "0")} 
                                name="file" 
                                types={fileTypes}
                        >
                                <img 
                                    src={image000.blod} 
                                    alt="이미지" 
                                    width="100%" 
                                    style={{"maxHeight":"300px"}}
                                />
                        </FileUploader>
                        :  <Grid
                                width={'100%'}
                                minHeight={'300px'}
                                display={'flex'}
                                flexDirection={'column'}
                                padding={'15px'}
                                justifyContent={'space-evenly'}
                            >
                                <FileUploader
                                    handleChange={(file) => handleChange(file, dtlCd, "0")} 
                                    name="file" 
                                    types={fileTypes}
                                    
                                >
                                    <Grid className="mouseOverCustom" >
                                        <AddPhotoAlternateOutlinedIcon color="primary"
                                                                        sx={{ fontSize: 130}}
                                        />
                                    </Grid>
                                </FileUploader>
                                <Grid className="clickMentCustom">클릭하거나 사진을 끌어 놓아 주세요</Grid>
                            </Grid>
                        }
                     </Grid>
                </Card>
            </Grid>

            <Grid 
                xs={12} sm={6} md={6} lg={6} xl={6}
                textAlign={'center'} 
                sx={{padding: 1}}
            >
                <Card elevation={4}>
                    <Item 
                        style={{
                            "fontWeight":"600", 
                            "fontSize":"18px", 
                            "border":"3px solid #1976d2",
                            "padding":"5px 0 5px 0",
                            "display":"flex",
                            "alignItems":"center"
                        }}
                    >
                        <Grid  style={{"flex":"1 0 auto", "paddingLeft":"5px"}}>
                            {dtlCdNm} #2
                        </Grid>
                        <ButtonCustom 
                            color="error"  
                            disabled={buttonActive001}
                            onClick={()=>del(dtlCd, 1)}
                        >
                            <DeleteForeverIcon sx={{ height: 30, width: 30 }} />
                        </ButtonCustom>
                        
                    </Item>
                    <Grid 
                        justifyContent="center" 
                        alignItems="center" 
                        onClick={()=> setFocusPhotoTypeCd("001")} 
                        xs={12} sm={12} md={12} lg={12} xl={12} 
                        sx={{ display: 'flex', p:0, height:300}}
                    >
                        {image001.blod
                        
                        ? <FileUploader
                                handleChange={(file) => handleChange(file, dtlCd, "1")} 
                                name="file" 
                                types={fileTypes}
                        >
                                <img 
                                    src={image001.blod} 
                                    alt="이미지" 
                                    width="100%" 
                                    style={{"maxHeight":"300px"}}
                                />
                        </FileUploader>
                        :  <Grid
                                width={'100%'}
                                minHeight={'300px'}
                                display={'flex'}
                                flexDirection={'column'}
                                padding={'15px'}
                                justifyContent={'space-evenly'}
                            >
                                <FileUploader
                                    handleChange={(file) => handleChange(file, dtlCd, "1")} 
                                    name="file" 
                                    types={fileTypes}
                                >
                                    <Grid className="mouseOverCustom">
                                        <AddPhotoAlternateOutlinedIcon color="primary"
                                                                        sx={{ fontSize: 130}}
                                        />
                                    </Grid>
                                </FileUploader>
                                <Grid className="clickMentCustom">클릭하거나 사진을 끌어 놓아 주세요</Grid>
                            </Grid>
                        }
                    </Grid>
                </Card>
            </Grid>
            <Toast ref={toastRef} />
        </Grid>
    );
}

export default PhotoCard;