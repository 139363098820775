import React, { useEffect } from 'react'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CustomColumnMuiltChart = (props) => {

    const options = {
        chart: {
            type: 'column',
            height: 30 + '%',
            margin: [35, 60, 65, 110],
            backgroundColor: 'transparent'	
        },
        title: {
            text: props.title,
            align: 'center',            
            y: 2,
            style: {                            
                fontSize: '14px',                
                textTransform: 'none'
            }
        },        
        credits: {
            enabled: false
        },
        xAxis: {
            categories: props.categories,
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: props.yTitle
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>' + props.seriesFormat + '</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        legend: {
            y: 20
        },   
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: props.data
    }
    
    useEffect(() =>{

    }, [])

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />  
    )
  };
  
  export default CustomColumnMuiltChart