
import React from 'react';

export default function Setting(props){
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox="0 0 461 461"
      fill={props.color ? props.color : "currentColor"}      
    >      
      <path d="M421,190.5h-26.018c-4.163-17.178-10.938-33.336-19.895-48.019l18.401-18.401c15.621-15.621,15.621-40.947,0-56.568 c-15.622-15.621-40.948-15.622-56.568,0l-18.401,18.401c-14.683-8.958-30.84-15.732-48.019-19.895V40c0-22.091-17.908-40-40-40 s-40,17.909-40,40v26.018c-17.178,4.163-33.336,10.938-48.019,19.895L124.08,67.512c-15.621-15.621-40.947-15.621-56.568,0 c-15.621,15.621-15.621,40.948,0,56.568l18.401,18.401c-8.958,14.683-15.732,30.84-19.895,48.019H40c-22.092,0-40,17.909-40,40 s17.908,40,40,40h26.018c4.163,17.178,10.938,33.335,19.895,48.018l-18.401,18.401c-15.621,15.621-15.621,40.947,0,56.568 c7.811,7.811,18.047,11.716,28.284,11.716s20.474-3.905,28.284-11.716l18.401-18.401c14.683,8.958,30.84,15.732,48.019,19.895V421 c0,22.091,17.908,40,40,40s40-17.909,40-40v-26.018c17.178-4.163,33.336-10.938,48.019-19.895l18.401,18.401 c7.811,7.811,18.047,11.716,28.284,11.716s20.474-3.905,28.284-11.716c15.621-15.621,15.621-40.947,0-56.568l-18.401-18.401 c8.958-14.683,15.732-30.84,19.895-48.018H421c22.092,0,40-17.909,40-40S443.092,190.5,421,190.5z M230.5,307.5 c-42.526,0-77-34.474-77-77s34.474-77,77-77s77,34.474,77,77S273.026,307.5,230.5,307.5z"></path>
    </svg>
  );
};