import React from 'react'
import { FormControlLabel, Checkbox } from '@mui/material';
import { Controller } from "react-hook-form";

const CustomCheckbox = (props) => {

    return (
        <Controller name={props.name}
                    control={props.control}                                         
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel control={<Checkbox onChange={onChange} checked={value} />} label={props.label} />
                    )} 
        />    
    )
  };
  
  export default CustomCheckbox