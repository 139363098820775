import React, { Component } from 'react'
import CustomProgress from './CustomProgress';

class CustomProgressContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            progress: 0,
            active: false
        }
    }

    componentDidMount(){        
        window.progressbar = this;
    }

    onChange = (value) => {
        
        if (value === 100){
            //응답 완료시 initProgress 콜백 호출
            this.setState({
                progress: value,
                active: true
            }, this.initProgress)
        } else{
            // progress 가 변할때마다 state 갱신
            this.setState({
                progress: value,
                active: true
            })
        }
    }

    initProgress = () => {
        this.setState({
            active: false,
            progress: 0
        });
    }

    render() {
        return (
            <CustomProgress active={this.state.active} progress={this.state.progress} />
        )
    }
}

export default CustomProgressContainer;