import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from '@mui/material';
import axiosInstance from '../components/util/AxiosUtil';
import Toast from '../components/ui/Toast';
import Alert from "../components/ui/Alert";
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import CustomAutocomplete from "../components/ui/CustomAutocomplete";
import CustomTextField from "../components/ui/CustomTextField";
import CustomDatatable from '../components/ui/CustomDatatable';

const defaultValues = { 
    pjtSeq: '',
    siteSeq:'',
    rtuSeq:'',
    keyWord:'',
    photoState:'',
}

function Document() {

    const datatableRef = useRef();

    //toast ref 객체
    const toastRef = useRef();
    
    // Alert ref 객체
    const alertRef= useRef();

    const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({defaultValues});

    const [ filterData, setFilterData ] = useState({});

    //프로젝트 검색 필터 목록 객체
    const [ pjtList, setPjtList ] = useState([{dtlCd: "", dtlCdNm: "전체"}]);

    //사이트 검색 필터 목록 객체
    const [ siteList, setSiteList ] = useState([{dtlCd: "", dtlCdNm: "전체"}]);

    //RTU 검색 필터 목록 객체
    const [ rtuList, setRtuList ] = useState([{dtlCd: "", dtlCdNm: "전체"}]);

    const [ photoStateList, setPhotoStateList ] = useState([{dtlCd: "", dtlCdNm: "전체"}, {dtlCd: "미완료", dtlCdNm: "미완료"}, {dtlCd: "완료", dtlCdNm: "완료"}]);

    //데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);

    let strRtuSeqList = "";
    
    let selectedPageNum = 0;

    //프로젝트 검색 목록 생성
    const makePjtCodeList = () => {        
        axiosInstance.get('/pjtCodeList').then((Response)=>{
            if (Response.request.status === 200) {  
                Response.data.list.unshift({dtlCd: "", dtlCdNm: "전체"});
                setPjtList(Response.data.list);   
                
                //사이트 검색 목록 초기화
                setSiteList([{dtlCd: "", dtlCdNm: "전체"}]);   
                setValue("siteSeq", "");  
            }

            dataLoad(getValues());
        });
    }

    //사이트 검색 목록 생성
    const makeSiteCodeList = () => {              
        axiosInstance.get('/siteCodeList', {params: {pjtSeq:getValues().pjtSeq}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                Response.data.list.unshift({dtlCd: "", dtlCdNm: "전체"});
                setSiteList(Response.data.list);   
                
                //Site 검색 선택 정보 초기화
                setValue("siteSeq", "");

                //RTU 검색 목록 초기화
                setRtuList([{dtlCd: "", dtlCdNm: "전체"}]);   
                setValue("rtuSeq", "");   
                
                if (Response.data.list.length === 2){                        
                    setValue("siteSeq", Response.data.list[1].dtlCd);
                    makeRtuCodeList();
                }   
                
                dataLoad(getValues());
            }
        });
    }

    //RTU 검색 목록 생성
    const makeRtuCodeList = () => {      
        if (getValues().siteSeq){
            axiosInstance.get('/rtuCodeList', {params: {siteSeq:getValues().siteSeq}}).then((Response)=>{
                if (Response.request.status === 200) {                                     
                    Response.data.list.unshift({dtlCd: "", dtlCdNm: "전체"});
                    setRtuList(Response.data.list);                    

                    if (Response.data.list.length === 2){                        
                        setValue("rtuSeq", Response.data.list[1].dtlCd);
                    }

                    dataLoad(getValues());
                }
            });
        }else{
            setValue("rtuSeq", "");
            setRtuList([{dtlCd: "", dtlCdNm: "전체"}]);
        }        
        
    }

    // 다운로드 파일 이름을 추출하는 함수
    const extractDownloadFilename = (response) => {
        const disposition = response.headers["content-disposition"];
        const fileName = decodeURI(
        disposition
            .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
            .replace(/['"]/g, "")
        );

        return fileName;
    };

    //에너지관리공단 사진대지 PDF 전체 다운로드
    const PDFKnrecDownload = () => {

        if (strRtuSeqList === ""){
            alertRef.current.handleOpen("확인", "사진대지(PDF) 파일을 다운로드 할 정보를 목록에서 선택 해주세요.");
        }else{
            axiosInstance.post('/knrecRtuCompleteDocPdf', {rtuSeqList: strRtuSeqList, pageNumber: selectedPageNum}, {responseType: "blob"}).then((Response)=>{            
                if (Response){
                    const blob = new Blob([Response.data]);
                    const fileObjectUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    
                    link.href = fileObjectUrl;
                    link.style.display = "none";

                    const disposition = Response.headers["content-disposition"];
                    link.download = decodeURI(
                        disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1].replace(/['"]/g, "")
                    );                    
    
                    document.body.appendChild(link);

                    link.click();
                    link.remove();

                    window.URL.revokeObjectURL(fileObjectUrl);
                    
                }else{
                    toastRef.current.handleOpen("error", "시스템 오류 입니다. 관리자에게 문의 하세요.");
                }
            }); 
        }
        
    }

    //LH 연동시험신청서 PDF 전체 다운로드
    const PDFLhDownload = () => {

        if (strRtuSeqList === ""){
            alertRef.current.handleOpen("확인", "연동시험신청서(PDF) 파일을 다운로드 할 정보를 목록에서 선택 해주세요.");
        }else{
            axiosInstance.post('/lhRtuCompleteDocPdf', {rtuSeqList: strRtuSeqList}, {responseType: "blob"}).then((Response)=>{            
                if (Response){
                    const blob = new Blob([Response.data]);
                    const fileObjectUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    
                    link.href = fileObjectUrl;
                    link.style.display = "none";

                    const disposition = Response.headers["content-disposition"];
                    link.download = decodeURI(
                        disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1].replace(/['"]/g, "")
                    );                    
    
                    document.body.appendChild(link);

                    link.click();
                    link.remove();

                    window.URL.revokeObjectURL(fileObjectUrl);
                    
                }else{
                    toastRef.current.handleOpen("error", "시스템 오류 입니다. 관리자에게 문의 하세요.");
                }
            }); 
        }
        
    }

    //프로젝트 검색필터 변경 이벤트
    const pjtSeqChanged = (option) =>{        
        makeSiteCodeList();
    }

    //사이트 검색필터 변경 이벤트
    const siteSeqChanged = (option) =>{
        makeRtuCodeList();
    }

    //datatable load
    function dataLoad(data){     
        setPageFixed(true);
        setFilterData(data);        
    }

    //테이블 컬럼 정보
    const columns = [
        { id: "rtuSeq", data: "rtuSeq", html: <input type='checkbox' id='checkall' />, width: '5%', className: "text-center", orderable: false, responsivePriority: 4 },
        { id: "pjtNm", data: "pjtNm", title: '프로젝트' ,width: '15%', className: "text-left", responsivePriority: 1 },
        { id: "siteNm", data: "siteNm", title: '사이트', width: '25%', className: "text-left", responsivePriority: 2 },
        { id: "instlPlndocNo", data: "instlPlndocNo", title: '설치계획서번호', width: '10%', className: "text-center", responsivePriority: 3 },
        { id: "rtuNm", data: "rtuNm", title: 'eTU', width:'10%', className: "text-center", responsivePriority: 4 },        
        { id: "photoState", data: "photoState", title: '사진등록상태', width:'10%', className: "text-center", responsivePriority: 5 },        
        { id: "rtuSeq", data: "rtuSeq", html: <div><button className='data-btn-highlight' data-type='engViewAll' style={{margin:'5px', width:'165px'}}>사진대지(PDF)</button><button className='data-btn-highlight' data-type='lhViewAll' style={{margin:'5px', width:'165px'}}>연동시험신청서(PDF)</button></div>, width:'25%', className: "text-center", orderable: false, responsivePriority: 0 },
    ]

    //테이블 커스텀 컬럼 정보
    const columnDefs = [
        {
            "targets": [0], render: function (data, type, row, meta) {                
                return "<label><input type='checkbox' data-pjtSeq='" + row.pjtSeq + "', data-siteSeq='" + row.siteSeq + "', data-rtuSeq='" + row.rtuSeq + "' ></label>";
            }
        },
        {
            className: "text-center p-0", "targets": [6], render: function (data, type, row, meta) { 
                
                return "<button class='data-btn' " + (row.multiConnYn != 'Y' && row.bizInstnCd != "200" ? "disabled" : "") + " data-pjtSeq='" + row.pjtSeq + "', data-siteSeq='" + row.siteSeq + "', data-rtuSeq='" + row.rtuSeq + "' data-type=" + (row.photoOtputTypeCd == '100' ? "'engView1'" : "'engView2'") + " style='margin:5px; width:165px'>사진대지 보기</button>"
                     + "<button class='data-btn' " + (row.multiConnYn != 'Y' && row.bizInstnCd != "100" ? "disabled" : "") + " data-pjtSeq='" + row.pjtSeq + "', data-siteSeq='" + row.siteSeq + "', data-rtuSeq='" + row.rtuSeq + "' data-type='lhView' style='margin:5px; width:165px'>연동시험신청서 보기</button>"
            }
        }
    ]

    //테이블 버튼 클릭 콜백 함수
    const buttonClick = (e) =>{
        if (e.target.dataset.type === "engView1"){                        
            //에너지관리공단 사진대지(사진3장) 문서 화면 이동
            window.open('/sales/document/knrecDocument_1?pjtSeq=' + e.target.dataset.pjtseq + '&siteSeq=' + e.target.dataset.siteseq + '&rtuSeq=' + e.target.dataset.rtuseq, '_blank');
        }else if (e.target.dataset.type === "engView2"){            
            //에너지관리공단 사진대지(사진5장) 문서 화면 이동
            window.open('/sales/document/knrecDocument_2?pjtSeq=' + e.target.dataset.pjtseq + '&siteSeq=' + e.target.dataset.siteseq + '&rtuSeq=' + e.target.dataset.rtuseq, '_blank');
        }else if (e.target.dataset.type === "lhView"){            
            //LH 연동시험신청서 문서 화면 이동
            window.open('/sales/document/lhDocument?pjtSeq=' + e.target.dataset.pjtseq + '&siteSeq=' + e.target.dataset.siteseq + '&rtuSeq=' + e.target.dataset.rtuseq, '_blank');
        }else if (e.target.dataset.type === "engViewAll"){
            PDFKnrecDownload();            
        }else if (e.target.dataset.type === "lhViewAll"){
            PDFLhDownload();
        }
    }

    //테이블 체크박스 변경 콜백 함수
    const changeEvent = (e, datasetList) => {

        strRtuSeqList = "";
        datasetList.map((item) => {
            strRtuSeqList += item.rtuseq + ",";
        })                
    }

    //테이블 페이지 변경 콜백 함수
    const pageChangeEvent = (e, page) => {        
        selectedPageNum = page;
    }

    useEffect(() => {

        makePjtCodeList();

        makeSiteCodeList();

    }, []);

    return (
        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)" }}>

            <Grid container spacing={2} sx={{ height: '100%' }}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 0 }}>
                    <div style={{ "marginBottom": "16px" }}></div>
                    <div style={{ "marginBottom": "28px" }}>
                        <p fontWeight="600" className="css-1pr8naw">문서 관리</p>
                        <p className="css-3vvtry">PoSE / 영업 지원 / 문서 관리</p>
                    </div>
                </Grid>

                <Grid container alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 0 }}>

                    <Grid xs={12} sm={12} md={12} lg={4} xl={2} sx={{ p: 2 }}>
                        <CustomAutocomplete
                                    name="pjtSeq"              
                                    label="프로젝트"           
                                    options={pjtList}          
                                    value="dtlCd"              
                                    text="dtlCdNm"             
                                    control={control}          
                                    getValues={getValues}      
                                    errors={errors.pjtSeq}     
                                    onChange={pjtSeqChanged}                               
                        />
                    </Grid>

                    <Grid xs={12} sm={12} md={12} lg={4} xl={3} sx={{ p: 2 }}>
                        <CustomAutocomplete
                                    name="siteSeq"              
                                    label="사이트"           
                                    options={siteList}          
                                    value="dtlCd"              
                                    text="dtlCdNm"             
                                    control={control}          
                                    getValues={getValues}      
                                    errors={errors.siteSeq}  
                                    onChange={siteSeqChanged}                                  
                        />                        
                    </Grid>

                    <Grid xs={12} sm={12} md={12} lg={4} xl={2} sx={{ p: 2 }}>
                        <CustomAutocomplete
                                    name="rtuSeq"              
                                    label="eTU"           
                                    options={rtuList}          
                                    value="dtlCd"              
                                    text="dtlCdNm"             
                                    control={control}          
                                    getValues={getValues}      
                                    errors={errors.rtuSeq}                                      
                        /> 
                    </Grid> 

                    <Grid xs={12} sm={12} md={12} lg={4} xl={2} sx={{ p: 2 }}>
                        <CustomAutocomplete
                                    name="photoState"              
                                    label="사진등록상태"           
                                    options={photoStateList}          
                                    value="dtlCd"              
                                    text="dtlCdNm"             
                                    control={control}          
                                    getValues={getValues}      
                                    errors={errors.photoState}                                      
                        /> 
                    </Grid>                  

                    <Grid alignItems="center" xs={12} sm={12} md={6} lg={4} xl={2} sx={{ p: 2 }}>
                        <CustomTextField 
                                    fullWidth
                                    name="keyWord"                                  //name.
                                    label="전체검색어"                               //label text.
                                    control={control}                               //react hook form control.                                          
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter'){                                    
                                            dataLoad(getValues());
                                        }
                                     }}
                        /> 
                    </Grid>

                    <Grid alignItems="center" xs={12} sm={12} md={6} lg={4} xl={1} sx={{ p: 2 }}>
                        <Button variant="contained" size="large" color="primary" startIcon={<SearchIcon />} onClick={handleSubmit(dataLoad)} sx={{height:'52px'}}>조회</Button>                        
                    </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 2, mt: 3 }}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable id="table-list-0"                  //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                   width="100%"                             //테이블 너비
                                   url="/rtuInstDocList"                    //ajax url                                   
                                   columns={columns}                        //테이블 컬럼 정의                                   
                                   columnDefs={columnDefs}                  //Custom 컬럼 정의
                                   filter={filterData}                      //ajax 검색 필터 정의       
                                   paging={true}                            //페이징 여부
                                   pageLength="200"                         //페이징 수
                                   buttonClick={buttonClick}                //버튼 클릭 콜백함수
                                   changeEvent={changeEvent}                //체크값 변경 콜백함수
                                   pageChangeEvent={pageChangeEvent}        //페이지 변경 콜백함수
                                   pageFixed={pageFixed}
                                   order={[[3, 'asc']]}
                                   ref={datatableRef}
                        />
                    </Grid>
                </Grid>

            </Grid>
            
            <Toast ref={toastRef} />

            <Alert ref={alertRef} />
            
        </div>
    );
}

export default Document;