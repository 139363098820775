
import React from 'react';

export default function Tree(props){
  return (    
    <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
    width={props.size}
    height={props.size} viewBox="0 0 64 64" enableBackground="new 0 0 64 64" fill={props.color ? props.color : "currentColor"}  >
    <g>
        <path d="M47.231,20.893C47.727,19.35,48,17.707,48,16c0-8.836-7.163-16-16-16S16,7.164,16,16
            c0,1.707,0.273,3.35,0.769,4.893C10.505,23.062,6,28.998,6,36c0,8.836,7.163,16,16,16c3.465,0,6.661-1.117,9.28-2.99L23.27,41H19
            c-0.553,0-1-0.447-1-1s0.447-1,1-1h4v-4c0-0.553,0.447-1,1-1s1,0.447,1,1v4.901l6,5.997V33.416l-3.7-3.7
            c-0.391-0.391-0.348-0.98,0.044-1.372c0.391-0.391,0.934-0.48,1.324-0.09L32,31.586l3.29-3.29c0.391-0.391,1.038-0.405,1.429-0.015
            s0.39,1.024-0.001,1.415L33,33.414v12.485l6-6.001V35c0-0.553,0.447-1,1-1s1,0.447,1,1v4h4c0.553,0,1,0.447,1,1s-0.447,1-1,1h-4.27
            l-8.01,8.01C35.34,50.883,38.535,52,42,52c8.837,0,16-7.164,16-16C58,28.998,53.495,23.062,47.231,20.893z"/>
        <path d="M28,52.961V63c0,0.553,0.447,1,1,1h6c0.553,0,1-0.447,1-1V52.961c-1.422-0.504-2.768-1.17-4-1.996
            C30.768,51.791,29.422,52.457,28,52.961z"/>
    </g>
    </svg>
  );
};