import React from 'react';
import { Fade, CircularProgress, Stack, Box, Typography } from '@mui/material/';

const CustomProgress = (props) => {
    
    return (
        <Fade in={props.active}>
            <Stack alignItems="center" justifyContent='center' style={{position:'fixed', zIndex:9999, top:0, left:0, width:'100%', height:'100%', backgroundColor:'#0c497729'}}>
                <CircularProgress variant="determinate" value={props.progress} />
                <Box
                    sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary" style={{fontWeight:700}}>
                        {`${Math.round(props.progress)}%`}
                    </Typography>
                </Box>
            </Stack>
        </Fade>
    )
}

export default CustomProgress;