import React, {useEffect, Fragment, useRef, useState} from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CustomDatatable from '../components/ui/CustomDatatable';
import highchartsMap from "highcharts/modules/map";
import Map from "../components/ui/Map";


const colors = Highcharts.getOptions().colors.map((c, i) =>
    // Start out with a darkened base color (negative brighten), and end
    // up with a much brighter color
    Highcharts.color(Highcharts.getOptions().colors[0])
        .brighten((i - 3) / 7)
        .get()
);

function Main() {

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,            
            height: 50 + '%',
            type: 'pie',
            margin: [10, 10, 50, 10]		
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        credits: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        xAxis: {
            type: 'category'
        },
        legend: {
            reversed: true
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                colors,
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                    distance: -50,
                    filter: {
                        property: 'percentage',
                        operator: '>',
                        value: 4
                    }
                },
                showInLegend: true
            }
        },
        series: [{ 
                name: "data", 
                data: [{
                    name: 'eTU-0316',
                    y: 74.77,
                    sliced: true,
                    selected: true
                },  {
                    name: 'eTU-0530',
                    y: 12.82
                },  {
                    name: 'eTU-0422',
                    y: 4.63
                }, {
                    name: 'RSV',
                    y: 2.44
                }]
            }]
    }

    const options_2 = {
        chart: {
            type: 'column',
            height: 18 + '%',
            margin: [15, 30, 65, 70]
        },
        title: {
            text: ''
        },        
        credits: {
            enabled: false
        },
        xAxis: {
            categories: [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월'
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: '대수 (개)'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: 'eTU-0422',
            data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4,
                194.1, 95.6, 54.4]
    
        }, {
            name: 'eTU-0530',
            data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5,
                106.6, 92.3]
    
        }, {
            name: 'eTU-0316',
            data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3,
                51.2]
    
        }, {
            name: 'RSV',
            data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8,
                51.1]
    
        }]
    }

    const options_3 = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,            
            height: 49 + '%',
            margin: [-5, -10, -5, -10],
            type: 'pie'	
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        credits: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },       
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                colors,                
                showInLegend: false
            }
        },
        series: [{ 
                name: "data", 
                data: [{
                    name: '태양광',
                    y: 74.77,
                    sliced: true,
                    selected: true
                },  {
                    name: '태양열',
                    y: 12.82
                },  {
                    name: '연료전지',
                    y: 4.63
                }, {
                    name: '소형풍력',
                    y: 2.44
                }]
            }]
    }

    const mapOptions = {
        title: {
            text: "Widget click by location",
            style: {
              color: "#fff"
            }
          },
          chart: {
            backgroundColor: "transparent",
            type: "map",
            map: null
          },
          mapNavigation: {
            enabled: true,
            enableButtons: false
          },
          credits: {
            enabled: false
          },
          colorAxis: {
            dataClasses: [
              {
                from: 1,
                color: "#C40401",
                name: "widget name one"
              },
              {
                from: 2,
                color: "#0200D0",
                name: "widget name two"
              }
            ]
          },
          tooltip: {
            pointFormatter: function() {
              return this.name;
            }
          },
          legend: {
            align: "right",
            verticalAlign: "top",
            x: -100,
            y: 70,
            floating: true,
            layout: "vertical",
            valueDecimals: 0,
            backgroundColor:
              // theme
              (Highcharts.defaultOptions &&
                Highcharts.defaultOptions.legend &&
                Highcharts.defaultOptions.legend.backgroundColor) ||
              "rgba(255, 255, 255, 0.85)"
          },
          series: [
            {
              name: "world map",
              dataLabels: {
                enabled: true,
                color: "#FFFFFF",
                format: "{point.postal-code}",
                style: {
                  textTransform: "uppercase"
                }
              },
              tooltip: {
                ySuffix: " %"
              },
              cursor: "pointer",
              joinBy: ["iso-a2", "code"],
              data: [],
              point: {
                events: {
                  click: function(r) {
                    console.log("click - to open popup as 2nd step");
                    console.log(r);
                  }
                }
              }
            }
          ]
      }

    const [ filterData, setFilterData ] = useState( {} );

    //테이블 컬럼 정보
    const columns = [
        { id: "A", data: "A", title: '업무유형' ,width: '20%', className: "text-center", responsivePriority: 1 },
        { id: "B", data: "B", title: '프로젝트', width: '40%', className: "text-center",responsivePriority: 2 },
        { id: "C", data: "C", title: '현장명', width:'40%', className: "text-center", responsivePriority: 3 }
    ]


    useEffect(() => {        
        highchartsMap(Highcharts);
    }, []);  

    return (
        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)", "height": "100%" }}>

            <Grid container sx={{ height:'100%' }}>
                <Grid container xs={12} sx={{ height:'45%'}}>
                    <Grid xs={7} sx={{ height:'100%'}}>
                        <Grid xs={12} sx={{ height:'50%', p:1}}>
                            <Box sx={{width:'100%', height:'100%', p:2, border:'1px solid #c6c4c480', borderRadius:2}}>
                                <Grid xs={12} sx={{height:'20%'}}>
                                    <Typography sx={{fontWeight:600, fontSize:16}}>업무 현황 (총 12건)</Typography>
                                </Grid>
                                <Grid container sx={{height:'80%'}} xs={12}>
                                    <Grid xs={3} sx={{height:'100%', pr:1}}>
                                        <div className="card bg-text-white h-100" style={{backgroundColor:"#00ac69", color:'white'}}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="me-3">
                                                        <div className="text-sm" style={{marginBottom:5}}>요청</div>
                                                        <div className="text-lg">3<span className="text-sm" style={{marginLeft:5}}>건</span></div>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 64 64" fill="#d4d4d5" stroke="currentColor"><polyline points="9 11 12 14 22 4"></polyline>
                                                    <path d="M48.5,58.5h-33a2,2,0,0,1-2-2V51a2,2,0,1,1,4,0V54.5h29V21.55l-9.4-12H17.5V40.32a2,2,0,0,1-4,0V7.5a2,2,0,0,1,2-2H38.07a2,2,0,0,1,1.58.77L50.08,19.63a2,2,0,0,1,.42,1.23V56.5A2,2,0,0,1,48.5,58.5Z"/><path d="M39.31,34H24.69a2,2,0,1,1,0-4H39.31a2,2,0,0,1,0,4Z"/><path d="M32,41.31a2,2,0,0,1-2-2V24.69a2,2,0,1,1,4,0V39.31A2,2,0,0,1,32,41.31Z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex align-items-center justify-content-between small">
                                                <a className="text-white text-sm" href="#!">보기</a>                                                
                                            </div>
                                        </div>                                      
                                    </Grid>                                
                                    <Grid xs={3} sx={{height:'100%', pr:1}}>
                                        <div className="card bg-text-white h-100" style={{backgroundColor:"#0061f2", color:'white'}}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="me-3">
                                                        <div className="text-sm" style={{marginBottom:5}}>진행</div>
                                                        <div className="text-lg">2<span className="text-sm" style={{marginLeft:5}}>건</span></div>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 20 20" fill="#d4d4d5" stroke="currentColor">
                                                    <path fill="#555" fill-rule="evenodd" d="M9.0971295,8.03755188 L9.0971295,2.84790039 C9.13471211,2.50546265 9.32401021,2.28468831 9.6650238,2.18557739 C10.0060374,2.08646647 10.3434812,2.17325872 10.6773552,2.44595413 L18.762796,9.28367933 C18.985685,9.48122766 19.0971295,9.72000122 19.0971295,10 C19.0971295,10.2799988 18.985685,10.5192994 18.762796,10.717902 L10.6237488,17.6020203 C10.2899882,17.8323466 9.96312968,17.9007416 9.64317322,17.8072052 C9.32321676,17.7136688 9.14120218,17.5091349 9.0971295,17.1936035 L9.0971295,11.9564819 L2.48309625,17.5543198 C2.15246682,17.8183996 1.80385844,17.9026947 1.43727112,17.8072052 C1.0706838,17.7117157 0.892550258,17.5071818 0.902870501,17.1936035 L0.902870501,2.93100117 C0.89243836,2.60200039 1.04287211,2.36458333 1.35417175,2.21875 C1.66547139,2.07291667 2.0218455,2.13023885 2.42329407,2.39071655 L9.0971295,8.03755188 Z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex align-items-center justify-content-between small">
                                                <a className="text-white text-sm" href="#!">보기</a>                                                
                                            </div>
                                        </div>  
                                    </Grid>
                                    <Grid xs={3} sx={{height:'100%', pr:1}}>
                                        <div className="card bg-text-white h-100" style={{backgroundColor:"#3b7c9b", color:'white'}}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="me-3">
                                                        <div className="text-sm" style={{marginBottom:5}}>완료</div>
                                                        <div className="text-lg">1<span className="text-sm" style={{marginLeft:5}}>건</span></div>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 1920 1920" fill="#d4d4d5" stroke="currentColor">
                                                    <path d="M960 1807.059c-467.125 0-847.059-379.934-847.059-847.059 0-467.125 379.934-847.059 847.059-847.059 467.125 0 847.059 379.934 847.059 847.059 0 467.125-379.934 847.059-847.059 847.059M960 0C430.645 0 0 430.645 0 960s430.645 960 960 960 960-430.645 960-960S1489.355 0 960 0M854.344 1157.975 583.059 886.69l-79.85 79.85 351.135 351.133L1454.4 717.617l-79.85-79.85-520.206 520.208Z" fill-rule="evenodd"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex align-items-center justify-content-between small">
                                                <a className="text-white text-sm" href="#!">보기</a>                                                
                                            </div>
                                        </div> 
                                    </Grid>                                
                                    <Grid xs={3} sx={{height:'100%'}}>
                                        <div className="card bg-text-white h-100" style={{backgroundColor:"#767676", color:'white'}}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="me-3">
                                                        <div className="text-sm" style={{marginBottom:5}}>취소</div>
                                                        <div className="text-lg">6<span className="text-sm" style={{marginLeft:5}}>건</span></div>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 32 32" enable-background="new 0 0 32 32" fill="#d4d4d5">
                                                        <circle fill="none" stroke="#d4d4d5" stroke-width="2" stroke-miterlimit="10" cx="16" cy="16" r="12"/>
                                                        <line fill="none" stroke="#d4d4d5" stroke-width="2" stroke-miterlimit="10" x1="7.8" y1="7.6" x2="24.2" y2="24.4"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex align-items-center justify-content-between small">
                                                <a className="text-white text-sm" href="#!">보기</a>                                                
                                            </div>
                                        </div> 
                                    </Grid>     
                                </Grid>                                                           
                            </Box>
                        </Grid>
                        <Grid xs={12} sx={{ height:'50%', p:1}}>
                            <Box sx={{width:'100%', height:'100%', p:2, border:'1px solid #c6c4c480', borderRadius:2}}>
                                <Grid xs={12} sx={{height:'20%'}}>
                                    <Typography sx={{fontWeight:600, fontSize:16}}>나의 업무 (총 3)</Typography>
                                </Grid>
                                <Grid container sx={{height:'80%'}} xs={12}>
                                    <Grid xs={3} sx={{height:'100%', pr:1}}>
                                        <div className="card bg-text-white h-100" style={{backgroundColor:"#00ac69", color:'white'}}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="me-3">
                                                        <div className="text-sm" style={{marginBottom:5}}>요청</div>
                                                        <div className="text-lg">0<span className="text-sm" style={{marginLeft:5}}>건</span></div>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 64 64" fill="#d4d4d5" stroke="currentColor"><polyline points="9 11 12 14 22 4"></polyline>
                                                    <path d="M48.5,58.5h-33a2,2,0,0,1-2-2V51a2,2,0,1,1,4,0V54.5h29V21.55l-9.4-12H17.5V40.32a2,2,0,0,1-4,0V7.5a2,2,0,0,1,2-2H38.07a2,2,0,0,1,1.58.77L50.08,19.63a2,2,0,0,1,.42,1.23V56.5A2,2,0,0,1,48.5,58.5Z"/><path d="M39.31,34H24.69a2,2,0,1,1,0-4H39.31a2,2,0,0,1,0,4Z"/><path d="M32,41.31a2,2,0,0,1-2-2V24.69a2,2,0,1,1,4,0V39.31A2,2,0,0,1,32,41.31Z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex align-items-center justify-content-between small">
                                                <a className="text-white text-sm" href="#!">보기</a>                                                
                                            </div>
                                        </div>                                      
                                    </Grid>                                
                                    <Grid xs={3} sx={{height:'100%', pr:1}}>
                                        <div className="card bg-text-white h-100" style={{backgroundColor:"#0061f2", color:'white'}}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="me-3">
                                                        <div className="text-sm" style={{marginBottom:5}}>진행</div>
                                                        <div className="text-lg">2<span className="text-sm" style={{marginLeft:5}}>건</span></div>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 20 20" fill="#d4d4d5" stroke="currentColor">
                                                    <path fill="#555" fill-rule="evenodd" d="M9.0971295,8.03755188 L9.0971295,2.84790039 C9.13471211,2.50546265 9.32401021,2.28468831 9.6650238,2.18557739 C10.0060374,2.08646647 10.3434812,2.17325872 10.6773552,2.44595413 L18.762796,9.28367933 C18.985685,9.48122766 19.0971295,9.72000122 19.0971295,10 C19.0971295,10.2799988 18.985685,10.5192994 18.762796,10.717902 L10.6237488,17.6020203 C10.2899882,17.8323466 9.96312968,17.9007416 9.64317322,17.8072052 C9.32321676,17.7136688 9.14120218,17.5091349 9.0971295,17.1936035 L9.0971295,11.9564819 L2.48309625,17.5543198 C2.15246682,17.8183996 1.80385844,17.9026947 1.43727112,17.8072052 C1.0706838,17.7117157 0.892550258,17.5071818 0.902870501,17.1936035 L0.902870501,2.93100117 C0.89243836,2.60200039 1.04287211,2.36458333 1.35417175,2.21875 C1.66547139,2.07291667 2.0218455,2.13023885 2.42329407,2.39071655 L9.0971295,8.03755188 Z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex align-items-center justify-content-between small">
                                                <a className="text-white text-sm" href="#!">보기</a>                                                
                                            </div>
                                        </div>  
                                    </Grid>
                                    <Grid xs={3} sx={{height:'100%', pr:1}}>
                                        <div className="card bg-text-white h-100" style={{backgroundColor:"#3b7c9b", color:'white'}}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="me-3">
                                                        <div className="text-sm" style={{marginBottom:5}}>완료</div>
                                                        <div className="text-lg">1<span className="text-sm" style={{marginLeft:5}}>건</span></div>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 1920 1920" fill="#d4d4d5" stroke="currentColor">
                                                    <path d="M960 1807.059c-467.125 0-847.059-379.934-847.059-847.059 0-467.125 379.934-847.059 847.059-847.059 467.125 0 847.059 379.934 847.059 847.059 0 467.125-379.934 847.059-847.059 847.059M960 0C430.645 0 0 430.645 0 960s430.645 960 960 960 960-430.645 960-960S1489.355 0 960 0M854.344 1157.975 583.059 886.69l-79.85 79.85 351.135 351.133L1454.4 717.617l-79.85-79.85-520.206 520.208Z" fill-rule="evenodd"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex align-items-center justify-content-between small">
                                                <a className="text-white text-sm" href="#!">보기</a>                                                
                                            </div>
                                        </div> 
                                    </Grid>                                
                                    <Grid xs={3} sx={{height:'100%'}}>
                                        <div className="card bg-text-white h-100" style={{backgroundColor:"#767676", color:'white'}}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="me-3">
                                                        <div className="text-sm" style={{marginBottom:5}}>취소</div>
                                                        <div className="text-lg">0<span className="text-sm" style={{marginLeft:5}}>건</span></div>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 32 32" enable-background="new 0 0 32 32" fill="#d4d4d5">
                                                        <circle fill="none" stroke="#d4d4d5" stroke-width="2" stroke-miterlimit="10" cx="16" cy="16" r="12"/>
                                                        <line fill="none" stroke="#d4d4d5" stroke-width="2" stroke-miterlimit="10" x1="7.8" y1="7.6" x2="24.2" y2="24.4"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex align-items-center justify-content-between small">
                                                <a className="text-white text-sm" href="#!">보기</a>                                                
                                            </div>
                                        </div> 
                                    </Grid>     
                                </Grid> 
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid xs={5} sx={{ height:'100%', p:1}}>
                        <Box sx={{width:'100%', height:'100%', p:2, border:'1px solid #c6c4c480', borderRadius:2}}>
                            <Grid xs={12} sx={{height:'20%'}}>
                                <Typography sx={{fontWeight:600, fontSize:16}}>제품 판매현황</Typography>
                                <Fragment>
                                    <HighchartsReact highcharts={Highcharts} options={options} />
                                </Fragment>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container xs={12} sx={{ height:'55%'}}>
                    <Grid xs={4} sx={{ height:'100%', p:1}}>
                        <Box sx={{width:'100%', height:'100%', p:2, border:'1px solid #c6c4c480', borderRadius:2}}>
                            <Typography sx={{fontWeight:600, fontSize:16}}>지역별 eTU 현황</Typography>                            
                            <Map />
                        </Box>
                    </Grid>
                    <Grid xs={8} sx={{ height:'100%'}}>
                        <Grid xs={12} sx={{ height:'50%', p:1}}>
                            <Box sx={{width:'100%', height:'100%', p:2, border:'1px solid #c6c4c480', borderRadius:2}}>
                                <Typography sx={{fontWeight:600, fontSize:16}}>월별 제품 납품현황</Typography>
                                <Fragment>
                                    <HighchartsReact highcharts={Highcharts} options={options_2} />
                                </Fragment>
                            </Box>
                        </Grid>
                        <Grid container xs={12} sx={{ height:'50%'}}>
                            <Grid xs={4} sx={{ height:'100%', p:1}}>
                                <Box sx={{width:'100%', height:'100%', p:2, border:'1px solid #c6c4c480', borderRadius:2}}>
                                <Grid xs={12} sx={{height:'20%'}}>
                                    <Typography sx={{fontWeight:600, fontSize:16}}>신재생서비스 에너지원</Typography>
                                    <Fragment>
                                        <HighchartsReact highcharts={Highcharts} options={options_3} />
                                    </Fragment>
                                </Grid>
                                </Box>
                            </Grid>
                            <Grid xs={8} sx={{ height:'100%', p:1}}>
                                <Box sx={{width:'100%', height:'100%', p:2, border:'1px solid #c6c4c480', borderRadius:2}}>
                                    <Typography sx={{fontWeight:600, fontSize:16}}>신규 업무요청 (3건)</Typography>
                                    
                                    <CustomDatatable id="table-list-0"          //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                                width="100%"                     //테이블 너비                                                
                                                columns={columns}                //테이블 컬럼 정의
                                                filter={filterData}              //ajax 검색 필터 정의 
                                                paging={true}                    //페이징 여부
                                                pageLength="3"                   //페이징 수                                                
                                                order={[[0, 'desc']]}            //기본 정렬                                                
                                    />
                                    
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
}

export default Main;