import React from "react";
import { Outlet } from "react-router-dom";
import Menu from './Menu'
import Header from './Header';
import CustomProgressContainer from '../ui/CustomProgressContainer';

function Layout() {

  return (

    <div style={{ display: 'flex', minHeight: '100%' }}>

      <CustomProgressContainer />

      <Menu />

      <div className="page-content-wrapper">

        <Header />

        <main className="page-content">          
            <Outlet/>
        </main>

      </div>

    </div>
  );
}

export default Layout;
