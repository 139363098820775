import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal, Box, Divider, Button } from '@mui/material';
import { getYYYYMMDD, addMonth } from '../components/util/DateUtil';
import { useSetRecoilState } from 'recoil';
import { viewModeType } from '../components/const/CommCode';
import { activeMenuPath } from '../components/atoms/MenuAtom';
import { useNavigate } from 'react-router-dom';
import CustomDatatable from '../components/ui/CustomDatatable';
import Toast from '../components/ui/Toast';
import Confirm from '../components/ui/Confirm';
import Grid from '@mui/material/Unstable_Grid2';
import CustomAutocomplete from "../components/ui/CustomAutocomplete";
import CustomDatePicker from "../components/ui/CustomDatePicker";
import CustomTextField from "../components/ui/CustomTextField";
import axiosInstance from '../components/util/AxiosUtil';
import Alert from "../components/ui/Alert"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';

const defaultValues = { 
    workSeq: '',
    pjtSeq: '',
    workTypeCd:'',
    workStsCd:'',
    spotNm:'',
    stCretDt:getYYYYMMDD(addMonth(new Date(), -3)),
    edCretDt:getYYYYMMDD(new Date())
}

const defaultUsrValues = {
    workSeq: '',
    usrId: '',
}

const defaultHstValues = {
    workSeq: '',
    pjtSeq: '',
}

function Reqment() {

    const workSeq = new URL(window.location.href).searchParams.get("workSeq"); 

    const navigate = useNavigate();

    const { handleSubmit, control, getValues, formState: { errors } } = useForm({ defaultValues });

    const { handleSubmit : handleSubmit_usr, control : control_usr, getValues : getValues_usr, setValue: setValue_usr, reset : reset_usr, formState: { errors : errors_usr } } = useForm({ defaultUsrValues });

    const [ filterData, setFilterData ] = useState( {defaultValues} );

    const [ filterUsrData, setFilterUsrData ] = useState( {defaultUsrValues} );

    const [ filterHstData, setFilterHstData ] = useState( {defaultHstValues} );

    //팝업 노출 상태 정보
    const [personOpen, setPersonOpen] = useState(false); 

    //팝업 노출 상태 정보
    const [hstOpen, setHstOpen] = useState(false); 

    // Alert ref 객체
    const alertRef= useRef();

    //toast ref 객체
    const toastRef = useRef();

    //confrim ref 객체
    const confirmRef = useRef();

    // 프로젝트 목록
    const [pjtList, setPjtList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // 업무유형 목록
    const [workTypeList, setWorkTypeList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // 업무상태 목록
    const [workStsList, setWorkStsList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // 추가 담당자 목록
    const [workTargetUsrList, setWorkTargetUsrList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // 활성화 메뉴 설정
    const setSelectMenuPath = useSetRecoilState(activeMenuPath);

    //업무요청서 테이블 컬럼 정보
    const columns = [
        { id: "workSeq", data: "workSeq", title: '요청번호' ,width: '5%', className: "text-center", responsivePriority: 1 },
        { id: "pjtDisplayNm", data: "pjtDisplayNm", title: '프로젝트', width: '20%', className: "text-left",responsivePriority: 2 },
        { id: "workDisplayNm", data: "workDisplayNm", title: '현장명', width: '15%', className: "text-left",responsivePriority: 3 },
        { id: "workType", data: "workType", title: '업무유형', width:'10%', className: "text-center", responsivePriority: 3 },
        { id: "workSts", data: "workSts", title: '상태', width:'5%', className: "text-center", responsivePriority: 4 },                
        { id: "cretrNm", data: "cretrNm", title: '요청자', width:'5%',className: "text-center", responsivePriority: 5 },
        { id: "cretDt", data: "cretDt", title: '요청일시', width:'10%',className: "text-center", responsivePriority: 6 },        
        { id: "workSeq", data: "workSeq", title: '관리', width:'25%', className: "text-center", responsivePriority: 0 },
    ]

    //담당자 테이블 컬럼 정보
    const columnsPerson = [
        { id: "compNm", data: "compNm", title: '회사명' ,width: '30%', className: "text-center", responsivePriority: 1 },
        { id: "usrNm", data: "usrNm", title: '성명', width: '15%', className: "text-center",responsivePriority: 2 },
        { id: "usrId", data: "usrId", title: '아이디' ,width: '20%', className: "text-center", responsivePriority: 1 },        
        { id: "hpno", data: "hpno", title: '휴대폰', width: '20%', className: "text-center",responsivePriority: 3 },        
        { id: "usrId", data: "usrId", title: '관리', width:'25%', className: "text-center", responsivePriority: 0 },
    ]

    //변경이력 테이블 컬럼 정보
    const columnsHst = [
        { id: "regDate", data: "regDate", title: '수정일자' ,width: '10%', className: "text-center", responsivePriority: 1 },
        { id: "updtrNm", data: "updtrNm", title: '수정자', width: '5%', className: "text-center",responsivePriority: 3 },
        { id: "chgAtrib", data: "chgAtrib", title: '수정정보' ,width: '10%', className: "text-center", responsivePriority: 2 },        
        { id: "bchng", data: "bchng", title: '변경전' ,width: '42.5%', className: "text-left", responsivePriority: 4 },        
        { id: "achng", data: "achng", title: '변경후', width: '42.5%', className: "text-left",responsivePriority: 5, render: function(data, type, row, meta){
            return highlightDifferences(row.bchng, row.achng);
        } },                
    ]

    //업무요청서 테이블 커스텀 컬럼 정보
    const columnDefs = [
        {
            className: "text-center p-0", "targets": [7], render: function (data, type, row, meta) {    

                let customButton = "<button class='data-btn' data-seq='" + row.workSeq + "' data-type='info' style='margin:2px; padding:3px;'>상세</button>";

                customButton += "<button class='data-btn' data-seq='" + row.workSeq + "' data-type='email' style='margin:2px; padding:3px;'>메일발송</button>";                    
                
                customButton += "<button class='" + (row.usrCnt > 0 ? 'data-btn' : 'data-btn-error') + "' data-seq='" + row.workSeq + "' data-type='person' style='margin:2px; padding:3px;'>담당자(" + row.usrCnt + "명)</button>";                    

                customButton += "<button class='data-btn' data-seq='" + row.workSeq + "' data-pjtSeq='" + row.pjtSeq + "' data-type='hst' style='margin:2px; padding:3px;'" + (row.chgHstCnt <= 0 ? 'disabled' : '') + ">변경이력</button>";                    

                if (row.workSts === "요청"){
                    customButton += "<button class='data-btn' data-seq='" + row.workSeq + "' data-type='delete' style='margin:2px; padding:3px;'>삭제</button>";                    
                }else{
                    customButton += "<button disabled class='data-btn' data-seq='" + row.workSeq + "' data-type='delete' style='margin:2px; padding:3px;'>삭제</button>";                    
                }

                return customButton;
            }            
        }
    ]

    //담당자 테이블 커스텀 컬럼 정보
    const columnPersonDefs = [
        {
            className: "text-center p-0", "targets": [4], render: function (data, type, row, meta) {    

                let customButton = "<button class='data-btn' data-seq='" + row.usrId + "' data-type='deleteUsr' style='margin-right:5px'>삭제</button>";

                return customButton;
            }            
        }
    ]

    //테이블 버튼 클릭 콜백 함수
    const buttonClick = (e) =>{
        if (e.target.dataset.type === "info"){            
            goToReq(viewModeType.Edit, e.target.dataset.seq);            
        }else if (e.target.dataset.type === "delete"){            
            confirmRef.current.handleOpen("업체 정보 삭제", "업체 정보를 삭제 합니다. 계속 진행 하시겠습니까?", e);
        }else if (e.target.dataset.type === "person"){
            openPersonModal(e.target.dataset.seq);
        }else if (e.target.dataset.type === "deleteUsr"){
            delReqUsr(e.target.dataset.seq);            
        }else if (e.target.dataset.type === "email"){
            sendEmail(e.target.dataset.seq);
        }else if (e.target.dataset.type === "hst"){
            openHstModal(e.target.dataset.seq, e.target.dataset.pjtSeq);
        }        
    }

    //업무요청서 삭제
    const delReq = (e) =>{
        axiosInstance.delete('/work', {params: {workSeq: e.target.dataset.seq}}).then((Response)=>{
            
            if(Response.request.status === 200){    
                toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
                dataReload(getValues());
            }else{                              
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }                  
        }); 
    }

    //업무요청서 등록 화면 이동    
    const goToReq = (viewModeType, workSeq) =>{                

        localStorage.setItem("lastUrl", "/sales/request");
        localStorage.setItem("lastParentMenuIndex", 7);        
        setSelectMenuPath("/sales/request");        
        navigate("/sales/request", {replace: true, state: {viewMode: viewModeType, workSeq: workSeq, listUrl: "/sales/work"}});
    }

    function dataReload(data){   
        setFilterData(data);        
    }

    function dataUsrReload(data){             
        setFilterUsrData(data);        
    }
    
    //업무요청서 담당자 설정 팝업 열기
    const openPersonModal = (workSeq) =>{     
        setValue_usr('workSeq', workSeq);

        //담당자 Autocomplte 조회
        getWorkTargetUsrList(workSeq);                

        //업무 담당자 조회
        dataUsrReload(getValues_usr());

        //팝업 열기
        setPersonOpen(true);
    }

    //업무요청서 변경이력 팝업 열기
    const openHstModal = (workSeq, pjtSeq) =>{             

        setFilterHstData({'workSeq': workSeq, 'pjtSeq': pjtSeq});

        //팝업 열기
        setHstOpen(true);
    }

    //업무요청서 추가 가능 담당자 목록 조회
    const getWorkTargetUsrList = (workSeq) => {             

        reset_usr({...getValues_usr(), usrId: ""});

        axiosInstance.get('/workTargetUsrList', {params: {workSeq: workSeq}}).then((Response)=>{
            if (Response.request.status === 200) {  
                Response.data.list.unshift({dtlCd: "", dtlCdNm: "선택"});
                setWorkTargetUsrList(Response.data.list);      
            }
        });
    }

    //업무담당자 추가
    const addReqUsr = (data) =>{

        if (data.usrId){

            axiosInstance.post('/work/usr', {workSeq: data.workSeq, usrId: data.usrId}).then((Response)=>{            
                if (Response){
                    if(Response.request.status === 200 && Response.data.resultCode === "SUCCESS"){     
                        alertRef.current.handleOpen("성공", Response.data.resultMessage);
                        reset_usr({...getValues_usr(), usrId: ""});
                        getWorkTargetUsrList(getValues_usr().workSeq);
                        dataUsrReload(getValues_usr());
                        
                    }else{                              
                        toastRef.current.handleOpen("error", Response.data.resultMessage);
                    }  
                }else{
                    toastRef.current.handleOpen("error", "시스템 오류 입니다. 관리자에게 문의 하세요.");
                }
            }); 
        }else{
            alertRef.current.handleOpen("확인", "담당자 정보를 선택 해주세요.");
        }        
    }

    //업무담당자 삭제
    const delReqUsr = (usrId) =>{

        if (usrId){

            axiosInstance.delete('/work/usr', {params: {workSeq: getValues_usr().workSeq, usrId: usrId}}).then((Response)=>{            
                if (Response){
                    if(Response.request.status === 200 && Response.data.resultCode === "SUCCESS"){     
                        alertRef.current.handleOpen("성공", Response.data.resultMessage);
                        getWorkTargetUsrList(getValues_usr().workSeq);
                        dataUsrReload(getValues_usr());
                    }else{                              
                        toastRef.current.handleOpen("error", Response.data.resultMessage);
                    }  
                }else{
                    toastRef.current.handleOpen("error", "시스템 오류 입니다. 관리자에게 문의 하세요.");
                }
            }); 
        }else{
            alertRef.current.handleOpen("확인", "삭제할 담당자 아이디 정보를 찾을 수 없습니다.");
        }        
    }

    const sendEmail = (workSeq) => {
        axiosInstance.post('/mail/work', {workSeq: workSeq}).then((Response)=>{            
            if (Response){
                if(Response.request.status === 200 && Response.data.resultCode === "SUCCESS"){     
                    alertRef.current.handleOpen("성공", Response.data.resultMessage);                    
                    
                }else{                              
                    toastRef.current.handleOpen("error", Response.data.resultMessage);
                }  
            }else{
                toastRef.current.handleOpen("error", "시스템 오류 입니다. 관리자에게 문의 하세요.");
            }
        }); 
    }

    const highlightDifferences = (txt1, txt2) => {

        const minLength = Math.min(txt1.length, txt2.length);
        let highlightedTxt = "";

        for (let i = 0; i < minLength; i++) {
            if (txt1.charAt(i) !== txt2.charAt(i)) {
                highlightedTxt += "<span class='text-danger font-weight-bold'>" + txt2.charAt(i) + "</span>";
            } else {
                highlightedTxt += txt2.charAt(i);
            }
        }
    
        if (txt2.length > minLength) {
            highlightedTxt += "<span class='text-danger font-weight-bold'>" + txt2.substring(minLength) + "</span>";
        }

        return highlightedTxt;
    }

    useEffect(() => {

        //프로젝트 목록 조회
        axiosInstance.get('/pjtCodeList').then((Response)=>{
            if (Response.request.status === 200) {  
                Response.data.list.unshift({dtlCd: "", dtlCdNm: "전체"});
                setPjtList(Response.data.list);                       
            }
        });

        //업무유형 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'WORK_TYPE_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {  
                Response.data.list.unshift({dtlCd: "", dtlCdNm: "전체"});
                setWorkTypeList(Response.data.list);                     
            }
        });

        //업무상태 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'WORK_STS_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {  
                Response.data.list.unshift({dtlCd: "", dtlCdNm: "전체"});
                setWorkStsList(Response.data.list);                     
            }
        });

        if (workSeq){
            setFilterData({...filterData, 'workSeq': workSeq});
        }

    }, []);

    return (
        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)" }}>

            <Grid container spacing={2} sx={{ height: '100%' }}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 0 }}>
                    <div style={{ "marginBottom": "16px" }}></div>
                    <div style={{ "marginBottom": "28px" }}>
                        <p fontWeight="600" className="css-1pr8naw">업무요청서 관리</p>
                        <p className="css-3vvtry">PoSE / 영업 지원 / 업무요청서 관리</p>
                    </div>
                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 0 }}>

                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>

                        <CustomAutocomplete
                                name="pjtSeq"              
                                label="프로젝트"           
                                options={pjtList}          
                                value="dtlCd"              
                                text="dtlCdNm"             
                                control={control}          
                                getValues={getValues}      
                                errors={errors.pjtSeq}                                    
                        />

                    </Grid>

                    {/* <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>

                        <CustomAutocomplete
                                    name="workTypeCd"              
                                    label="업무유형"           
                                    options={workTypeList}          
                                    value="dtlCd"              
                                    text="dtlCdNm"             
                                    control={control}          
                                    getValues={getValues}      
                                    errors={errors.workTypeCd}     
                        />

                    </Grid> */}

                    <Grid xs={12} sm={12} md={6} lg={6} xl={2} sx={{ p: 2 }}>
                        <CustomTextField fullWidth 
                                         name="spotNm" 
                                         label="현장명" 
                                         control={control} 
                                         onKeyDown={(e) => {
                                            if (e.key === 'Enter'){                                    
                                                dataReload(getValues());
                                            }
                                         }}
                        />
                    </Grid>


                    <Grid xs={12} sm={12} md={6} lg={2} xl={1} sx={{ p: 2 }}>

                        <CustomAutocomplete
                                        name="workStsCd"              
                                        label="업무상태"           
                                        options={workStsList}          
                                        value="dtlCd"              
                                        text="dtlCdNm"             
                                        control={control}          
                                        getValues={getValues}      
                                        errors={errors.workStsCd}     
                        />

                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={6} xl={2} sx={{ p: 2 }}>
                        <CustomTextField fullWidth 
                                         name="workSeq" 
                                         label="요청번호" 
                                         control={control} 
                                         onKeyDown={(e) => {
                                            if (e.key === 'Enter'){                                    
                                                dataReload(getValues());
                                            }
                                         }}
                        />
                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={2} xl={1} sx={{ p: 2 }}>                        
                        <CustomDatePicker name="stCretDt" label="시작 요청일자" control={control} />
                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={2} xl={1} sx={{ p: 2 }}>
                        <CustomDatePicker name="edCretDt" label="종료 요청일자" control={control} sx={{ p: 2, width: '90%' }}/>                        
                    </Grid>

                    <Grid container alignItems="center"  xs={12} sm={12} md={6} lg={4} xl={3} sx={{p:0}}>
                        <Button variant="contained" size="large" color="primary" sx={{ ml: 2, height:'52px' }} startIcon={<SearchIcon />} onClick={handleSubmit(dataReload)}>조회</Button>                        
                        <Button variant="contained" size="large" color="primary" sx={{ m: 2, height:'52px' }} startIcon={<AddCircleOutlineIcon />} onClick={()=>{goToReq(viewModeType.New)}} >업무요청서 등록</Button>
                    </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 2, mt: 3 }}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable id="table-list-0"          //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                   width="100%"                     //테이블 너비
                                   url="/workList"                  //ajax url
                                   columns={columns}                //테이블 컬럼 정의
                                   filter={filterData}              //ajax 검색 필터 정의                      
                                   columnDefs={columnDefs}          //Custom 컬럼 정의
                                   paging={true}                    //페이징 여부
                                   pageLength="10"                  //페이징 수
                                   buttonClick={buttonClick}        //버튼 클릭 콜백함수
                                   order={[[0, 'desc']]}            //기본 정렬
                        />
                    </Grid>
                </Grid>

            </Grid>


            <Modal open={personOpen} onClose={e => setPersonOpen(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                <Box className="Modal">
                    <h3 id="parent-modal-title">담당자 설정</h3>
                    <Divider sx={{ mt:2 }} />

                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 2, mt: 2 }}>                        
                        <Grid xs={12} sm={12} md={10} lg={10} xl={10} sx={{p:1}}>
                            <CustomAutocomplete
                                            name="usrId"              
                                            label="회사명 / 성명 / 아이디"           
                                            options={workTargetUsrList}          
                                            value="dtlCd"              
                                            text="dtlCdNm"             
                                            control={control_usr}          
                                            getValues={getValues_usr}      
                                            errors={errors.usrId}     
                            />
                        </Grid>
                        <Grid xs={12} sm={12} md={2} lg={2} xl={2} container direction="row" justifyContent="flex-end" alignItems="center" sx={{p:1}}>
                            <Button variant="contained" size="large" color="primary" onClick={handleSubmit_usr(addReqUsr)} sx={{width:"100%"}}>추가</Button>
                        </Grid>

                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{p:1, pt:2}}>
                            <CustomDatatable id="table-list-1"       //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                    width="100%"                     //테이블 너비
                                    url="/workUsrList"               //ajax url
                                    columns={columnsPerson}          //테이블 컬럼 정의
                                    filter={filterUsrData}           //ajax 검색 필터 정의                      
                                    columnDefs={columnPersonDefs}    //Custom 컬럼 정의
                                    paging={true}                    //페이징 여부
                                    pageLength="5"                   //페이징 수
                                    buttonClick={buttonClick}        //버튼 클릭 콜백함수
                                    order={[[0, 'desc']]}            //기본 정렬
                            />
                        </Grid>
                    </Grid>   

                    <Divider sx={{ mt:2, mb:3 }} />
                    
                    <Grid container direction="row" justifyContent="flex-end">                        
                        <Button variant="contained" size="large" color="primary" sx={{ ml: 2 }} onClick={e => setPersonOpen(false)}>닫기</Button>
                    </Grid>
                    
                </Box>
            </Modal>

            <Modal open={hstOpen} onClose={e => setHstOpen(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
                <Box className="Modal" style={{maxWidth:1500}}>
                    <h3 id="parent-modal-title">업무요청서 변경이력</h3>
                    <Divider sx={{ mt:2 }} />

                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 2, mt: 2 }}>                        
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{p:1, pt:2}}>
                            <CustomDatatable id="table-list-2"       //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                    width="100%"                     //테이블 너비
                                    url="/workInfChgHstList"         //ajax url
                                    columns={columnsHst}             //테이블 컬럼 정의                                                                        
                                    filter={filterHstData}           //ajax 검색 필터 정의                      
                                    paging={true}                    //페이징 여부
                                    pageLength="5"                  //페이징 수                                    
                                    order={[[0, 'desc']]}            //기본 정렬
                                    ordering={false}                 //컬럼 정렬 사용여부
                            />
                        </Grid>
                    </Grid>   

                    <Divider sx={{ mt:2, mb:3 }} />
                    
                    <Grid container direction="row" justifyContent="flex-end">                        
                        <Button variant="contained" size="large" color="primary" sx={{ ml: 2 }} onClick={e => setHstOpen(false)}>닫기</Button>
                    </Grid>
                    
                </Box>
            </Modal>

            <Toast ref={toastRef} />

            <Confirm ref={confirmRef} ok={delReq} />
            
            <Alert ref={alertRef} />

        </div>
    );
}

export default Reqment;