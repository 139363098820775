import React, { forwardRef } from 'react'
import defaultProfile from "../../../assets/image/noimage.gif"
import lhProfile from "../../../assets/image/lh.jpg"

const LhContent = forwardRef((props, ref) => {

    const handleImgError = (e) => {        
        e.target.src = defaultProfile;
    }   

    return (
        <div className="print-ref" ref={ref}>
            <table style={{pageBreakBefore:"avoid"}}>
                <thead>
                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{width:'210mm'}}>
                        <td className='title' colSpan={3} style={{height:"120mm", width:"210mm", textAlign:'left', verticalAlign:"top", paddingTop:"40px"}}>
                            <img src={lhProfile}></img>    
                        </td>
                    </tr>                
                    <tr style={{width:'210mm'}}>
                        <td colSpan={3} style={{height:"70mm", width:"210mm", textAlign:'center', verticalAlign:"middle", fontSize:"40px", borderTop:"solid 4px black", borderBottom:"solid 4px black"}}>친환경•미래에너지 통합모니터링시스템<br/><br/><br/>연동시험신청서</td>                    
                    </tr>             
                </tbody>
            </table> 

            <table style={{pageBreakBefore:"always"}}>
                <thead>
                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{width:'210mm'}}>                    
                        <td colSpan={3} style={{width:"210mm", textAlign:'center', verticalAlign:"middle", fontSize:"25px", textDecoration:"underline"}}>데이터수집장치(RTU) 연동시험신청서</td>                    
                    </tr>  
                    <tr style={{width:'210mm'}}>                   
                        <td colSpan={3} style={{width:"210mm", textAlign:'left', fontSize:"15px"}}>
                            ※ LH 친환경•미래에너지 통합모니터링시스템에 접속하여 단지/설비정보 확인 후 기재하시기 바랍니다.<br/>
                            ※ 설비정보는 개별설비별로 따로 기재하시기 바랍니다.<br/>
                            ※ 제출/문의: rems@sqisoft.com<br/>
                        </td>                    
                    </tr> 

                    <tr style={{width:'210mm'}}>
                        <td className="lh-label-cell" colSpan={2} style={{width:"60mm", textAlign:'center', verticalAlign:"middle"}}>제출일</td>
                        <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{props.data.submDay}</td>
                    </tr>   

                    <tr style={{width:'210mm'}}>
                        <td className="lh-label-cell" rowSpan={4} style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>기업정보</td>
                        <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>기업명</td>
                        <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>에스큐아이소프트</td>
                    </tr>   
                    <tr style={{width:'210mm'}}>
                        <td className="lh-label-cell" rowSpan={3} style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>담당자</td>                        
                        <td className="lh-cell" style={{width:"150mm", textAlign:'left', verticalAlign:"middle"}}><span style={{marginRight:"62mm"}}>(이름) {props.data.cntPsnNm}</span><span>(직책) {props.data.rspofNm}</span></td>
                    </tr>                 
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-cell" style={{width:"150mm", textAlign:'left', verticalAlign:"middle"}}><span style={{marginRight:"40mm"}}>(전화번호) {props.data.tlno}</span><span>(휴대전화) {props.data.ptblTel}</span></td>
                    </tr>                 
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-cell" style={{width:"150mm", textAlign:'left', verticalAlign:"middle"}}>(이메일) {props.data.eml}</td>
                    </tr>   

                    <tr style={{width:'210mm'}}>
                        <td className="lh-label-cell" rowSpan={4} style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>단지정보</td>
                        <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>단지 ID</td>
                        <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{props.data.siteSeq}</td>
                    </tr>
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>단지명</td>
                        <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{props.data.siteNm}</td>
                    </tr> 
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>지역본부</td>
                        <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{props.data.rgnHqNm}</td>
                    </tr> 
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>주소</td>
                        <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{props.data.addr}</td>
                    </tr> 

                    {
                        props.devList.map((dev, index) => ( 
                            index === 0 ?

                            <div key={index}>
                                <tr style={{width:'210mm'}}>
                                    <td className="lh-label-cell" rowSpan={7} style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>설비정보</td>
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>설비 ID<br/>(설비번호)</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.devSeq}</td>
                                </tr>
                                <tr style={{width:'210mm'}}>                        
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>설비명</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.devNm}</td>
                                </tr>
                                <tr style={{width:'210mm'}}>                        
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>설비위치</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.instlLoc}</td>
                                </tr>
                                <tr style={{width:'210mm'}}>                        
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>설치용량</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.instlCpct}</td>
                                </tr>
                                <tr style={{width:'210mm'}}>                        
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>시공일시</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.exworkDt}</td>
                                </tr>
                                <tr style={{width:'210mm'}}>                        
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>시리얼번호</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.sn}</td>
                                </tr>
                                <tr style={{width:'210mm'}}>                        
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>검교정<br/>유효기간</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.inspDate}</td>
                                </tr>
                            </div>

                            : null
                        ))
                    }
                    
                </tbody>
            </table> 

            <table style={{pageBreakBefore:"always"}}>
                <thead>
                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.devList.map((dev, index) => ( 
                            
                            index > 0 ?
                            <div key={index}>
                                <tr style={{width:'210mm'}}>
                                    <td className="lh-label-cell" rowSpan={7} style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>설비정보</td>
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>설비 ID<br/>(설비번호)</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.devSeq}</td>
                                </tr>
                                <tr style={{width:'210mm'}}>                        
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>설비명</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.devNm}</td>
                                </tr>
                                <tr style={{width:'210mm'}}>                        
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>설비위치</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.instlLoc}</td>
                                </tr>
                                <tr style={{width:'210mm'}}>                        
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>설치용량</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.instlCpct}</td>
                                </tr>
                                <tr style={{width:'210mm'}}>                        
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>시공일시</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.exworkDt}</td>
                                </tr>
                                <tr style={{width:'210mm'}}>                        
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>시리얼번호</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.sn}</td>
                                </tr>
                                <tr style={{width:'210mm'}}>                        
                                    <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>검교정<br/>유효기간</td>
                                    <td className="lh-cell" style={{width:"150mm", textAlign:'center', verticalAlign:"middle"}}>{dev.inspDate}</td>
                                </tr>
                            </div>

                            : null
                        ))
                    }
                    
                </tbody>
            </table> 

            <table style={{pageBreakBefore:"always"}}>
                <thead>
                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{width:'210mm'}}>
                        <td className="lh-label-cell" rowSpan={8} style={{width:"60mm", textAlign:'center', verticalAlign:"middle"}}>RTU</td>
                        <td className="lh-label-cell" style={{width:"65mm", textAlign:'center', verticalAlign:"middle"}}>점검항목</td>
                        <td className="lh-cell" colSpan={4} style={{width:"85mm", textAlign:'center', verticalAlign:"middle"}}>점검결과</td>
                    </tr>
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-label-cell" style={{width:"85mm", textAlign:'center', verticalAlign:"middle"}}>RTU 제조사/모델명</td>
                        <td className="lh-cell" colSpan={4} style={{width:"65mm", textAlign:'center', verticalAlign:"middle"}}>{props.data.rtuModel}</td>
                    </tr>
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-label-cell" style={{width:"85mm", textAlign:'center', verticalAlign:"middle"}}>직렬통신포트 2개 이상 (RS232 또는 RS485)</td>
                        <td className="lh-cell" colSpan={4} style={{width:"65mm", textAlign:'center', verticalAlign:"middle"}}>O</td>
                    </tr>
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-label-cell" style={{width:"85mm", textAlign:'center', verticalAlign:"middle"}}>Enternet 포트 1개 이상</td>
                        <td className="lh-cell" colSpan={4} style={{width:"65mm", textAlign:'center', verticalAlign:"middle"}}>O</td>
                    </tr>
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-label-cell" style={{width:"85mm", textAlign:'center', verticalAlign:"middle"}}>USB 포트 2개 이상</td>
                        <td className="lh-cell" colSpan={4} style={{width:"65mm", textAlign:'center', verticalAlign:"middle"}}>O</td>
                    </tr>
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-label-cell" style={{width:"85mm", textAlign:'center', verticalAlign:"middle"}}>액정표시장치</td>
                        <td className="lh-cell" colSpan={4} style={{width:"65mm", textAlign:'center', verticalAlign:"middle"}}>O</td>
                    </tr>
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>RTU설치사진</td>
                        <td className="lh-cell" colSpan={4} style={{width:"180mm", textAlign:'center', verticalAlign:"middle", height:'50mm'}} >
                            <img src={props.image001.blod ? props.image001.blod : ""} alt="이미지" height="100%" width="100%" onError={handleImgError}/>                                        
                        </td> 
                    </tr> 
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-label-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>RTU설치사진</td>
                        <td className="lh-cell" colSpan={4} style={{width:"180mm", textAlign:'center', verticalAlign:"middle", height:'50mm'}} >
                            <img src={props.image004.blod ? props.image004.blod : ""} alt="이미지" height="100%" width="100%" onError={handleImgError}/>                                        
                        </td> 
                    </tr> 

                    <tr>
                        <td colSpan={6} style={{height:"20mm"}}></td>
                    </tr>   

                    <tr style={{width:'210mm'}}>
                        <td className="lh-label-cell" colSpan={2} style={{width:"90mm", textAlign:'center', verticalAlign:"middle"}}>점검항목</td>
                        <td className="lh-label-cell" style={{width:"60mm", textAlign:'center', verticalAlign:"middle"}}>점검요령</td>
                        <td className="lh-label-cell" style={{width:"20mm", textAlign:'center', verticalAlign:"middle"}}>적합</td>
                        <td className="lh-label-cell" style={{width:"20mm", textAlign:'center', verticalAlign:"middle"}}>부적합</td>
                        <td className="lh-label-cell" style={{width:"20mm", textAlign:'center', verticalAlign:"middle"}}>해당<br/>없음</td>
                    </tr>
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>1</td>
                        <td className="lh-cell" style={{width:"60mm", textAlign:'left', verticalAlign:"middle"}}>단지 설비 담당자가 작업 내용을 충분히 숙지하도록 교육하였는가?</td>
                        <td className="lh-cell" style={{width:"60mm", textAlign:'left', verticalAlign:"middle"}}>설비 담당자에게 작업한 내용을 설명하여 충분히 이해하였는지 확인입니다.</td>
                        <td className="lh-cell" style={{width:"20mm", textAlign:'center', verticalAlign:"middle"}}>O</td>
                        <td className="lh-cell" style={{width:"20mm", textAlign:'center', verticalAlign:"middle"}}></td>
                        <td className="lh-cell" style={{width:"20mm", textAlign:'center', verticalAlign:"middle"}}></td>
                    </tr>
                    <tr style={{width:'210mm'}}>                        
                        <td className="lh-cell" style={{width:"30mm", textAlign:'center', verticalAlign:"middle"}}>2</td>
                        <td className="lh-cell" style={{width:"60mm", textAlign:'left', verticalAlign:"middle"}}>작업 후 환경정리는 깔끔하게 하였는가? (배선 절연처리, 선정리, 작업잔여물 청소, RTU 배치)</td>
                        <td className="lh-cell" style={{width:"60mm", textAlign:'left', verticalAlign:"middle"}}>설비 담당자의 확인하에 환경정리가 잘 되었는지 점검합니다.</td>
                        <td className="lh-cell" style={{width:"20mm", textAlign:'center', verticalAlign:"middle"}}>O</td>
                        <td className="lh-cell" style={{width:"20mm", textAlign:'center', verticalAlign:"middle"}}></td>
                        <td className="lh-cell" style={{width:"20mm", textAlign:'center', verticalAlign:"middle"}}></td>
                    </tr>         
                </tbody>
            </table> 
        </div>
    )

});
  
export default LhContent