import React, { forwardRef, useState, useImperativeHandle } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const Toast = forwardRef((props, ref) => {

    const [open, setOpen] = useState(false);

    const [title, setTitle] = useState('');

    const [message, setMessage] = useState('');

    const [event, setEvent] = useState({});

    const handleOpen = (title, message, e) => {
        setTitle(title);
        setMessage(message);        
        setEvent(e);
        setOpen(true);
    };

    const handleOk = () => {            
        props.ok(event);
        setOpen(false);
    }

    const handleClose = (e) => {            
        setOpen(false);
    };

    //부모 컴포넌트에서 호출 할 수 있는 함수 정의
    useImperativeHandle(ref, () => ({
        handleOpen, handleClose
    }))

    return (
        <Dialog open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title" >

            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" size="large" color="primary" autoFocus onClick={handleClose}>아니오</Button>
                <Button variant="contained" size="large" color="primary" onClick={handleOk}>예</Button>                
            </DialogActions>
        </Dialog>
    )
});

export default Toast