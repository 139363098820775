import React, { createRef, useRef, useState, useEffect }  from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { getYYYYMMDD } from '../../../components/util/DateUtil';
import { Button, Chip } from '@mui/material';
import { activeMenuPath } from '../../../components/atoms/MenuAtom';
import { viewModeType } from '../../../components/const/CommCode';
import Grid from '@mui/material/Unstable_Grid2';
import CustomTextField from "../../../components/ui/CustomTextField"
import CustomAutocomplete from "../../../components/ui/CustomAutocomplete"
import CustomCheckbox from "../../../components/ui/CustomCheckbox"
import CustomDatePicker from "../../../components/ui/CustomDatePicker"
import Toast from "../../../components/ui/Toast"
import Alert from "../../../components/ui/Alert"
import ReactToPrint from "react-to-print";
import RtuInstContent from '../print/RtuInstContent';
import axiosInstance from '../../../components/util/AxiosUtil';
import ListIcon from '@mui/icons-material/List';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import PrintIcon from '@mui/icons-material/Print';
import '../../../assets/css/RtuInstDocument.css';

// Form 기본 값
const defaultValues = {
    workSeq: "",
    workStsCd: "",
    rtuBizDivCd: "",
    rtuBizDiv: "",
    rtuWorkDivCd: "",
    rtuWorkDiv: "",
    pjtSeq: "",
    prdtSeq: "",
    prdtNm: "",
    pjtDisplayNm: "",
    ddateDay: getYYYYMMDD(new Date()),
    spotNm: "",            
    rtuSetYn: false,
    siteRegYn: false,
    loraRegYn: false,
    facRegYn: false,
    boxComposiYn: false,
    npComposiYn: false,
    dlvrYn: false,
    spotInstlYn: false,
    voicSendYn: false,
    regInfCntt: "",
    ncstMtrCntt: "",
    ncstMtrDate: getYYYYMMDD(new Date()),
    procRsltCntt: "",
    procRsltDate: getYYYYMMDD(new Date()),
    rmrk: ""
}

function RtuInstDocument(props) {
    
    const navigate = useNavigate();

    const { handleSubmit, watch, getValues, control, reset, formState: { errors } } = useForm( { defaultValues } );

    // 프린터 ref 객체
    const printRef = createRef();

    // Alert ref 객체
    const alertRef= useRef();

    // toast ref 객체
    const toastRef = useRef();

    // 첨부파일 ref 객체
    const fileRef = useRef();

    // 프로젝트 목록
    const [pjtList, setPjtList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // RTU 모델 목록
    const [rtuModelCdList, setRtuModelCdList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // RTU 설치 사업구분 목록
    const [rtuBizDivCdList, setRtuBizDivCdList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // RTU 설치 업무구분 목록
    const [rtuWorkDivCdList, setRtuWorkDivCdList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    // 업무상태 목록
    const [workStsCdList, setWorkStsCdList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    //버튼 비활성화 여부
    const [buttonDisabled, setButtonDisabled] = useState(false);

    // 활성화 메뉴 설정
    const setSelectMenuPath = useSetRecoilState(activeMenuPath);

    // 첨부파일 목록
    const [files, setFiles] = useState([]);

    // 첨부파일 선택시
    const loadFile = () => {

        let fileList = [];

        for (let i = 0; i < fileRef.current.files.length; i++){
            const file = fileRef.current.files[i];

            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onloadend = () => {
                fileList.push(file);
                setFiles([...files, ...fileList]);
            };
        }        
    };

    // 첨부파일 삭제
    const deleteFile = (name) => {

        const newFiles = files.filter((data) => {
            return data.name !== name
        });

        setFiles(newFiles);
    };

    // 첨부파일 클릭시 다운로드
    const clickFile = (name) => {

        const targetFile = files.filter((data) =>{
            return data.name === name
        });

        const fileUrl = window.URL.createObjectURL(targetFile[0]);

        const anchorElement = document.createElement('a');

        document.body.appendChild(anchorElement);
        anchorElement.download = name; 
        anchorElement.href = fileUrl;        
        anchorElement.click();
        
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectUrl(fileUrl);
    }

    //저장
    const onSubmit = (data, e) => {        

        setButtonDisabled(true);

        const formData = new FormData();

        Object.entries(data).map(([key, value]) => (
            formData.append(key, value)
        ));

        files.map(function(file){
            formData.append("files", file);
        });

        if (props.viewMode === viewModeType.New){
            axiosInstance.post('/work/rtuInst', formData, {headers: {'content-type': 'multipart/form-data'}}).then((Response)=>{            

                if (Response){
                    if(Response.request.status === 200 && Response.data.resultCode === "SUCCESS"){     
                        alertRef.current.handleOpen("성공", Response.data.resultMessage, e);                       
                    }else{                              
                        toastRef.current.handleOpen("error", Response.data.resultMessage);        
                        setButtonDisabled(false);
                    }  
                }else{
                    toastRef.current.handleOpen("error", "시스템 오류 입니다. 관리자에게 문의 하세요.");
                    setButtonDisabled(false);
                }
            });      
        }else if (props.viewMode === viewModeType.Edit){

            console.log(data);

            axiosInstance.put('/work/rtuInst', formData, {headers: {'content-type': 'multipart/form-data'}}).then((Response)=>{            

                if (Response){
                    if(Response.request.status === 200 && Response.data.resultCode === "SUCCESS"){     
                        alertRef.current.handleOpen("성공", Response.data.resultMessage, e);
                    }else{                              
                        toastRef.current.handleOpen("error", Response.data.resultMessage);
                    }  
                }else{
                    toastRef.current.handleOpen("error", "시스템 오류 입니다. 관리자에게 문의 하세요.");
                }

                setButtonDisabled(false);
            });      
        }
    }

    //목록 페이지 이동
    const goListPage = () => {
        localStorage.setItem("lastUrl", props.listUrl);
        localStorage.setItem("lastParentMenuIndex", props.listPageParentMenuIndex);        
        setSelectMenuPath(props.listUrl);     
        navigate(props.listUrl);
    }

    // Base64 문자열 Blob 타입 변경
    const convertToBlob = (base64) =>{
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        return byteArray;
    }

    useEffect(() => {

        //프로젝트 코드 목록 조회
        axiosInstance.get('/pjtCodeList').then((Response)=>{
            if (Response.request.status === 200) {                  
                setPjtList(Response.data.list);                       
            }
        });

        //RTU 모델 코드 목록 조회
        axiosInstance.get('/prdtCdList', {params: {prdtDivCd: '001'}}).then((Response)=>{
            if (Response.request.status === 200) { 
                setRtuModelCdList(Response.data.list);
            }
        });

        //RTU 설치 사업구분 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'RTU_BIZ_DIV_CD'}}).then((Response)=>{
            if (Response.request.status === 200) { 
                setRtuBizDivCdList(Response.data.list);
            }
        });

        //RTU 설치 업무구분 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'RTU_WORK_DIV_CD'}}).then((Response)=>{
            if (Response.request.status === 200) { 
                setRtuWorkDivCdList(Response.data.list);
            }
        });

        //업무상태 코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'WORK_STS_CD'}}).then((Response)=>{
            if (Response.request.status === 200) { 
                setWorkStsCdList(Response.data.list);
            }
        });

        if (props.viewMode === viewModeType.Edit){
            //업무 정보 조회
            axiosInstance.get('/work', {params: {workSeq: props.workSeq}}).then((Response)=>{
                if (Response.request.status === 200) { 
                    
                    Response.data.rtuInstlWork.rtuSetYn = Response.data.rtuInstlWork.rtuSetYn === "Y" ? true : false;
                    Response.data.rtuInstlWork.siteRegYn = Response.data.rtuInstlWork.siteRegYn === "Y" ? true : false;
                    Response.data.rtuInstlWork.loraRegYn = Response.data.rtuInstlWork.loraRegYn === "Y" ? true : false;
                    Response.data.rtuInstlWork.facRegYn = Response.data.rtuInstlWork.facRegYn === "Y" ? true : false;
                    Response.data.rtuInstlWork.boxComposiYn = Response.data.rtuInstlWork.boxComposiYn === "Y" ? true : false;
                    Response.data.rtuInstlWork.npComposiYn = Response.data.rtuInstlWork.npComposiYn === "Y" ? true : false;
                    Response.data.rtuInstlWork.dlvrYn = Response.data.rtuInstlWork.dlvrYn === "Y" ? true : false;
                    Response.data.rtuInstlWork.spotInstlYn = Response.data.rtuInstlWork.spotInstlYn === "Y" ? true : false;                    
                    Response.data.rtuInstlWork.voicSendYn = Response.data.rtuInstlWork.voicSendYn === "Y" ? true : false;                    
                    

                    reset({...getValues(), ...Response.data.work});                    
                    reset({...getValues(), ...Response.data.rtuInstlWork});          
                    
                    //첨부파일
                    setFiles([]);
                    Response.data.workAtchFileList.map(function(file){
                        //DB 결과값 이미지 파일 생성
                        const newBlob = new Blob([convertToBlob(file.blob)], {type:file.fileTypeNm});                           
                        let createFile = new File([newBlob], file.fileNm,{type:file.fileTypeNm});                    

                        //이미지 파일 데이터 추가
                        setFiles(prev => [...prev, createFile]);   
                    });
                }
            }); 
        }

    }, []);

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ display: 'flex', width:'100%', maxWidth:950 }}>

            <Grid className="title" xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center" sx={{ display: 'flex', mb: 4 }}>
                <p fontWeight="600" className="css-1pr8naw">업무요청서</p>
            </Grid>

            {
                props.viewMode === viewModeType.Edit ?

                <Grid className="title" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ p: 0, mb: 2 }}>
                    <CustomTextField disabled name="workSeq" label="요청번호" variant="standard" control={control} errors={errors.workSeq} />                                  
                </Grid>             

                : null
            }

            {
                props.viewMode === viewModeType.Edit ?

                <Grid className="title" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ p: 0, mb: 2 }}>
                    <CustomAutocomplete
                            name="workStsCd"                                      
                            label="업무상태"                                  
                            options={workStsCdList}                                  
                            value="dtlCd"                                     
                            text="dtlCdNm"                                   
                            control={control}                                  
                            getValues={getValues}                              
                            rules={{ required: "업무상태를 선택 해주세요." }}  
                            errors={errors.workStsCd}                             
                            variant="standard"
                     />                                   
                </Grid>             

                : null
            }

            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:2, pb:2, minHeight:108}}>

                <CustomAutocomplete
                            name="pjtSeq"                                      
                            label="*프로젝트"                                  
                            options={pjtList}                                  
                            value="dtlCd"                                     
                            text="dtlCdNm"                                   
                            control={control}                                  
                            getValues={getValues}                              
                            rules={{ required: "프로젝트를 선택 해주세요." }}  
                            errors={errors.pjtSeq}                             

                />           

            </Grid>
            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:2, pb:2, minHeight:108}}>
                <CustomAutocomplete
                            name="rtuBizDivCd"                                      
                            label="*사업명"                                  
                            options={rtuBizDivCdList}                                  
                            value="dtlCd"                                     
                            text="dtlCdNm"                                   
                            control={control}                                  
                            getValues={getValues}                              
                            rules={{ required: "사업명을 선택 해주세요." }}  
                            errors={errors.rtuBizDivCd}   
                />     
            </Grid>            
            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:2, pb:2, minHeight:108}}>
                <CustomTextField name="spotNm" label="*현장명" fullWidth control={control} rules={{ required: "현장명을 입력해주세요." }} errors={errors.spotNm} />  
            </Grid>
            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:2, pb:2, minHeight:108}}>
                <CustomDatePicker name="ddateDay" label="납기일자" control={control} />
            </Grid>
            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:2, pb:2, minHeight:108}}>

                <CustomAutocomplete
                            name="rtuWorkDivCd"                                      
                            label="*업무내용"                                  
                            options={rtuWorkDivCdList}                                  
                            value="dtlCd"                                     
                            text="dtlCdNm"                                   
                            control={control}                                  
                            getValues={getValues}                              
                            rules={{ required: "업무내용을 선택 해주세요." }}  
                            errors={errors.rtuWorkDivCd}   
                /> 
                
            </Grid>
            <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', pt:2, pb:2, minHeight:108}}>

                <CustomAutocomplete
                                name="prdtSeq"                                      
                                label="*eTU 모델"                                  
                                options={rtuModelCdList}                                  
                                value="dtlCd"                                     
                                text="dtlCdNm"                                   
                                control={control}                                  
                                getValues={getValues}                              
                                rules={{ required: "eTU 모델을 선택 해주세요." }}  
                                errors={errors.prdtSeq}                             

                />       

            </Grid>

            <Grid container className="border-1" justifyContent="center" alignItems="center" sx={{ width: "100%", m: 0 }}>
                <Grid xs={12} sm={6} md={6} lg={3} xl={3} sx={{ display: 'flex', pl: 5 }}>
                    <CustomCheckbox name="rtuSetYn" label="eTU 셋팅" control={control} />                    
                </Grid>

                <Grid xs={12} sm={6} md={6} lg={3} xl={3} justifyContent="flex-start" alignItems="center" sx={{ display: 'flex', pl: 5 }}>
                    <CustomCheckbox name="siteRegYn" label="수용가 등록" control={control} />                    
                </Grid>

                <Grid xs={12} sm={6} md={6} lg={3} xl={3} justifyContent="flex-start" alignItems="center" sx={{ display: 'flex', pl: 5 }}>
                    <CustomCheckbox name="loraRegYn" label="LoRa 등록" control={control} />                                        
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={3} xl={3} justifyContent="flex-start" alignItems="center" sx={{ display: 'flex', pl: 5 }}>
                    <CustomCheckbox name="facRegYn" label="설비등록" control={control} />                                                            
                </Grid>

                <Grid xs={12} sm={6} md={6} lg={3} xl={3} justifyContent="flex-start" alignItems="center" sx={{ display: 'flex', pl: 5 }}>
                    <CustomCheckbox name="boxComposiYn" label="하이박스 구성" control={control} />                    
                </Grid>

                <Grid xs={12} sm={6} md={6} lg={3} xl={3} justifyContent="flex-start" alignItems="center" sx={{ display: 'flex', pl: 5 }}>
                    <CustomCheckbox name="npComposiYn" label="명판제작" control={control} />                    
                </Grid>

                <Grid xs={12} sm={6} md={6} lg={3} xl={3} justifyContent="flex-start" alignItems="center" sx={{ display: 'flex', pl: 5 }}>
                    <CustomCheckbox name="dlvrYn" label="배송" control={control} />                    
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={3} xl={3} justifyContent="flex-start" alignItems="center" sx={{ display: 'flex', pl: 5 }}>
                    <CustomCheckbox name="spotInstlYn" label="현장설치" control={control} />                    
                </Grid>

                <Grid xs={12} sm={6} md={6} lg={3} xl={3} justifyContent="flex-start" alignItems="center" sx={{ display: 'flex', pl: 5 }}>
                    <CustomCheckbox name="voicSendYn" label="음성송출" control={control} />                    
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={3} xl={3} justifyContent="flex-start" alignItems="center" sx={{ display: 'flex', pl: 5 }}></Grid>
                <Grid xs={12} sm={6} md={6} lg={3} xl={3} justifyContent="flex-start" alignItems="center" sx={{ display: 'flex', pl: 5 }}></Grid>
                <Grid xs={12} sm={6} md={6} lg={3} xl={3} justifyContent="flex-start" alignItems="center" sx={{ display: 'flex', pl: 5 }}></Grid>
            </Grid>

            <Grid className="border-1" xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center" sx={{ display: 'flex', pt:2, pb:2}}>
                <CustomTextField name="regInfCntt" label="요청사항" multiline fullWidth rows={10} control={control} errors={errors.regInfCntt} />                  
            </Grid>            

            <Grid className="border-1" xs={12} sm={12} md={8} lg={9} xl={9} justifyContent="center" alignItems="center" sx={{ display: 'flex', minHeight:87.13}}>
                필요 사항
            </Grid>
            <Grid className="border-1" xs={12} sm={12} md={4} lg={3} xl={3} justifyContent="center" alignItems="center" sx={{ display: 'flex', pt:2, pb:2}}>
                <CustomDatePicker name="ncstMtrDate" label="일자" control={control} />                
            </Grid>
            <Grid className="border-1" xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center" sx={{ display: 'flex'}}>
                <CustomTextField name="ncstMtrCntt" multiline fullWidth rows={4} control={control} errors={errors.ncstMtrCntt} />                  
            </Grid>

            <Grid className="border-1" xs={12} sm={12} md={8} lg={9} xl={9} justifyContent="center" alignItems="center" sx={{ display: 'flex', minHeight:87.13}}>
                처리 결과
            </Grid>
            <Grid className="border-1" xs={12} sm={12} md={4} lg={3} xl={3} justifyContent="center" alignItems="center" sx={{ display: 'flex', pt:2, pb:2}}>
                <CustomDatePicker name="procRsltDate" label="일자" control={control} />                                
            </Grid>
            <Grid className="border-1" xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center" sx={{ display: 'flex'}}>
                <CustomTextField name="procRsltCntt" multiline fullWidth rows={4} control={control} errors={errors.procRsltCntt} />                                  
            </Grid>

            <Grid className="border-1" xs={12} sm={12} md={8} lg={9} xl={9} justifyContent="center" alignItems="center" sx={{ display: 'flex', minHeight:72.97}}>
                첨부 파일
            </Grid>
            <Grid className="border-1" xs={12} sm={12} md={4} lg={3} xl={3} justifyContent="center" alignItems="center" sx={{ display: 'flex', pt:2, pb:2}}>
                <Button variant="contained" size="large" component="label" startIcon={<FolderOpenIcon />}>  
                    첨부파일 추가                                          
                    <input type="file" multiple onChange={loadFile} ref={fileRef} hidden />                        
                </Button>  
            </Grid>
            <Grid className="border-1" xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" sx={{ p:2}}>                
                {
                    files.map((file, index) => (

                        <Chip key={index} label={file.name} data-name={file.name} variant="outlined" sx={{mr:2, mb:1, mt:1}}
                            onClick={clickFile.bind(this, file.name)}
                            onDelete={deleteFile.bind(this, file.name)}
                        />
                    ))
                }          
            </Grid>

            <Grid xs={12} sm={12} md={3} lg={6} xl={6} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}></Grid>
            
            {
                props.viewMode === viewModeType.Edit ? null : <Grid xs={12} sm={12} md={3} lg={2} xl={2} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}></Grid>                                    
            }

            <Grid xs={12} sm={12} md={3} lg={2} xl={2} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}>        
                <Button fullWidth variant="contained" size="large" color="primary" disabled={buttonDisabled} sx={{ ml: 0 }} startIcon={<SaveAltIcon />} onClick={handleSubmit(onSubmit)}>저장</Button>                                
            </Grid>
            
            {
                props.viewMode === viewModeType.Edit ? 
                    <Grid xs={12} sm={12} md={3} lg={2} xl={2} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}>                                      
                        <ReactToPrint trigger={() => <Button fullWidth variant="contained" size="large" color="primary" startIcon={<PrintIcon />} sx={{ ml: 0 }}>인쇄</Button>} content={() => printRef.current} /> 
                    </Grid>            
                    : null                    
            }

            <Grid xs={12} sm={12} md={3} lg={2} xl={2} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}>                                      
                <Button fullWidth variant="contained" size="large" color="primary" sx={{ ml:0 }} startIcon={<ListIcon />} onClick={goListPage}>목록</Button>   
            </Grid>

            {/* 프린터 인쇄 영역 */}
            <RtuInstContent ref={printRef} data={watch()} />

            {/* Toast 영역 */}
            <Toast ref={toastRef} />

            {/* Confirm 영역 */}
            <Alert ref={alertRef} ok={goListPage} />

        </Grid>
    );
}

export default RtuInstDocument;