import React, {useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import jspreadsheet from "jspreadsheet-ce";
import axiosInstance from '../../../../components/util/AxiosUtil';
import Grid from '@mui/material/Unstable_Grid2';
import excelImage from '../../../../assets/image/excelBackground.png';
import  { getHttpAuthHeader } from '../../../../components/util/AuthUtil'
import $ from 'jquery';
import "../../../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
import "../../../../assets/css/Jspreadsheet.css";
    
const PvInvrtrS = forwardRef((props, ref) => {

  //Model Dropdown Source Filter
  const modelFilter = (instance, cell, c, r, source, makrTypeCd) => {  

    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    
    let response = {data:{}};

    if (value){
      //모듈 모델 코드 목록 조회
      $.ajax({    
        type : 'get',
        url : `${process.env.REACT_APP_API_URL}` + '/modelCodeList',
        async : false,
        headers : {   
          'Accept':'application/json',
          'Content-Type':'application/x-www-form-urlencoded;charset=UTP-8',
          'Authorization' : getHttpAuthHeader()       
        },    
        dataType : 'json',
        data : {
          makrCd:value,
          makrTypeCd:makrTypeCd
        },
        success : function(result) { 
          // 결과
          response["data"] = result;                    
        },    
        error : function(request, status, error) { 
          console.log(error);
        }
      });
      
      const modulList = getModelCodeList(response);
      
      return modulList;

    }else{
      return source;
    }
  } 

  //시트 컬럼 정의
  let columns = [
    { name: 'eqprNm', type: 'text', title: '*설치자명(단지명)', width:180 },        
    { name: 'arnoAdr', type: 'text', title: '*주소', width: 350 },
    { name: 'keaBldgPurpCd', type: 'dropdown', title: '*건물용도', width: 220, autocomplete:true, source:[] },
    { name: 'keaBillTypeCd', type: 'dropdown', title: '*요금제', width: 150, autocomplete:true, source:[] },        
    { name: 'eqprHpno', type: 'text', title: '*설치자휴대폰번호', width: 150 },
    { name: 'instlPlanNo', type: 'numeric', title: '*설비설치계획서번호', width: 150 },
    { name: 'keaInstlTypeCd', type: 'dropdown', title: '*설치유형', width: 150, autocomplete:true, source:[] },                
    { name: 'devNo', type: 'numeric', title: '*인버터 시리얼 번호', width: 150 },
    { name: 'facNm', type: 'text', title: '*설비명', width: 150 },
    { name: 'instlPlacNm', type: 'text', title: '*설치장소', width: 150 },
    { name: 'modlMakrCd', type: 'dropdown', title: '*모듈 제조사', width: 200, autocomplete:true, source:[] },                               
    { name: 'modlModelCd', type: 'dropdown', title: '*모듈 모델', width: 200, autocomplete:true, source:[], filter:(instance, cell, c, r, source)=>modelFilter(instance, cell, c, r, source, '30001') },                                        
    { name: 'keaModlCrstlTypeCd', type: 'dropdown', title: '*모듈 결정분류', width: 120, autocomplete:true, source:[] },                        
    { name: 'modlPerCpct', type: 'numeric', title: '*모듈당 용량(W)', width: 150 },
    { name: 'modlTotCpct', type: 'numeric', title: '*모듈 총용량(kW)', width: 150 },
    { name: 'modlAzmth', type: 'numeric', title: '*모듈 방위각', width: 150 },
    { name: 'modlInclAng', type: 'numeric', title: '*모듈 경사각', width: 150 },
    { name: 'modlSerlCnt', type: 'numeric', title: '*모듈 직렬개수', width: 150 },
    { name: 'modlParalCnt', type: 'numeric', title: '*모듈 병렬개수', width: 150 },
    { name: 'invrtrMakrCd', type: 'dropdown', title: '*인버터 제조사', width: 200, autocomplete:true, source:[] },                                       
    { name: 'invrtrModelCd', type: 'dropdown', title: '*인버터 모델', width: 200, autocomplete:true, source:[], filter:(instance, cell, c, r, source)=>modelFilter(instance, cell, c, r, source, '30002') },                                                
    { name: 'keaPhsTypeCd', type: 'dropdown', title: '*위상종류', width: 120, autocomplete:true, source:[] },                                
    { name: 'keaTrckSysCd', type: 'dropdown', title: '*추적시스템', width: 120, autocomplete:true, source:[] },                        
    { name: 'invrtrCpct', type: 'numeric', title: '*인버터 용량(kW)', width: 150 },
    { name: 'pvPanlCpct', type: 'numeric', title: '*PV패널 기준 용량(kW)', width: 150 },
    { name: 'keaGrdConnCd', type: 'dropdown', title: '*계통접속', width: 120, autocomplete:true, source:[] },                                
    { name: 'cntrctrNm', type: 'text', title: '*시공업체', width: 150 },
    { name: 'cntrctrTlno', type: 'text', title: '*시공업체 연락처', width: 150 },
    { name: 'cntrctrHpno', type: 'text', title: '*시공업체 핸드폰 연락처', width: 180 },
    { name: 'exworkDay', type: 'calendar', title: '*시공일시', options: { format:'YYYY-MM-DD' },width: 150 },                
    { name: 'asExpDay', type: 'calendar', title: '*AS만료일', options: { format:'YYYY-MM-DD' },width: 150 },        
    { name: 'rmrk', type: 'text', title: '특이사항', width: 250 },
    { name: 'exworkCmpltDay', type: 'calendar', title: '*시공완료일', options: { format:'YYYY-MM-DD' },width: 150 },
    { name: 'loraId', type: 'text', title: 'Lora ID', width: 150 },
  ];

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    validate
  }))

  //시트 REF 객체
  const jRef = useRef();
  
  //데이터 유효성 검증
  const validate = () => {

    props.list.map((row, rowIndex) => {

      rowIndex++;

      $.each(row, function (key, value) {  

        const keyName = key.toString();
        const colIndex = getColIndex(keyName);

        if (colIndex >= 0){

          const errorMessage = row[keyName + "_v"].errorMessage;

          if (errorMessage != ""){
            jRef.current.jexcel.setComments(getAlphabetByIndex(colIndex+1) + rowIndex, errorMessage);
          }

        }        
      })
    });
  }

  //시트 생성
  const createSheet = (columns) => {
    
    if (jRef.current.jexcel) {
      //이미 생성된 시트가 존재시 삭제
      jspreadsheet.destroy(jRef.current);
    }

    //데이터 가공   
    let list = [];
    props.list.map((data) => {
      list.push([
                  data.eqprNm, 
                  data.arnoAdr, 
                  data.keaBldgPurpCd, 
                  data.keaBillTypeCd,
                  data.eqprHpno,
                  data.instlPlanNo,
                  data.keaInstlTypeCd,
                  data.devNo,
                  data.facNm,
                  data.instlPlacNm,
                  data.modlMakrCd,
                  data.modlModelCd,
                  data.keaModlCrstlTypeCd,
                  data.modlPerCpct,
                  data.modlTotCpct,
                  data.modlAzmth,
                  data.modlInclAng,
                  data.modlSerlCnt,
                  data.modlParalCnt,
                  data.invrtrMakrCd,
                  data.invrtrModelCd,
                  data.keaPhsTypeCd,
                  data.keaTrckSysCd,
                  data.invrtrCpct,
                  data.pvPanlCpct,
                  data.keaGrdConnCd,
                  data.cntrctrNm,
                  data.cntrctrTlno,
                  data.cntrctrHpno,
                  data.exworkDay,
                  data.asExpDay,
                  data.rmrk,
                  data.exworkCmpltDay,
                  data.loraId
                ]);      
    });

    if (props.list.length > 0){  

      //시트 초기화              
      jspreadsheet(jRef.current, {
        data:list,
        columns: columns,
        allowComments:true,
        defaultColWidth: 100,
        tableOverflow: true,
        allowDeleteColumn: false,
        allowDeleteColumn: false,
        allowInsertColumn: false,
        columnSorting: false,
        onafterchanges : function(worksheet, records){
          //셀 값 변경 처리
          records.map((item)=>{
            const keyName = getColName(item.col); 
            
            if (keyName === "exworkDay" || keyName === "asExpDay" || keyName === "exworkCmpltDay"){
              if (isDateString(item.newValue)){
                let originalDate = new Date(item.newValue);
                item.newValue = originalDate.toISOString().split('T')[0];
              }
            }

            props.list[item.row][keyName] = item.newValue == "" ? "" : item.newValue;              
          });          
          
        },
        oninsertrow : function(instance, index, cnt, obj, isBefor){
          //로우 추가 처리
          let rowIndex = !isBefor ? index + 1 : index;          
          
          let newObject = {};
          for (let key in props.list[0]){
            newObject[key] = "";
          }

          props.list.splice(rowIndex, 0, newObject);

        },
        ondeleterow : function(instance, index, cnt, obj, isBeforr){
          //로우 삭제 처리
          props.list.splice(index, cnt);          
        }
      });

      setSheetWidth();

    }else{
      //데이터 값이 없을 경우 시트 삭제
      jspreadsheet.destroy(jRef.current);
    }        
    
  }

  //시트 컬럼 설정
  const setColumns = async () => {

    //건물용도 코드 목록 조회
    const keaBldgPurpCdList = await axiosInstance.get('/codeList', {params: {grpCd: 'KEA_BLDG_PURP_CD'}});                
    //요금제 코드 목록 조회
    const kebBillTypeCdList = await axiosInstance.get('/codeList', {params: {grpCd: 'KEA_BILL_TYPE_CD'}});                
    //설치유형 코드 목록 조회
    const keaInstlTypeCdList = await axiosInstance.get('/codeList', {params: {grpCd: 'KEA_INSTL_TYPE_CD'}});                
    //모듈 결정분류 코드 목록 조회
    const keaModlCrstlTypeCdList = await axiosInstance.get('/codeList', {params: {grpCd: 'KEA_MODL_CRSTL_TYPE_CD'}});                
    //위상종류 코드 목록 조회
    const keaPhsTypeCdList = await axiosInstance.get('/codeList', {params: {grpCd: 'KEA_PHS_TYPE_CD'}});                
    //추적시스템 코드 목록 조회
    const keaTrckSysCdList = await axiosInstance.get('/codeList', {params: {grpCd: 'KEA_TRCK_SYS_CD'}});                
    //계통접속 코드 목록 조회
    const keaGrdConnCdList = await axiosInstance.get('/codeList', {params: {grpCd: 'KEA_GRD_CONN_CD'}});                
    //모듈 제조사 코드 목록 조회
    const modulMakrCodeList = await axiosInstance.get('/makrCodeList', {params: {makrTypeCd: '30001'}});                
    //모듈 모델 코드 목록 조회
    const modulModelCodeList = await axiosInstance.get('/modelCodeList', {params: {makrCd: '', makrTypeCd: '30001'}});                
    //인버터 제조사 코드 목록 조회
    const invMakrCodeList = await axiosInstance.get('/makrCodeList', {params: {makrTypeCd: '30002'}});                
    //인버터 모델 코드 목록 조회
    const invModelCodeList = await axiosInstance.get('/modelCodeList', {params: {makrCd: '', makrTypeCd: '30002'}});                

    columns[2].source = getCodeList(keaBldgPurpCdList);
    columns[3].source = getCodeList(kebBillTypeCdList);
    columns[6].source = getCodeList(keaInstlTypeCdList);
    columns[10].source = getCodeList(modulMakrCodeList);
    columns[11].source = getModelCodeList(modulModelCodeList);
    columns[12].source = getCodeList(keaModlCrstlTypeCdList);
    columns[19].source = getCodeList(invMakrCodeList);
    columns[20].source = getModelCodeList(invModelCodeList);
    columns[21].source = getCodeList(keaPhsTypeCdList);
    columns[22].source = getCodeList(keaTrckSysCdList);
    columns[25].source = getCodeList(keaGrdConnCdList);

    return columns;
  }

  //숫자 알파벳 변환
  function getAlphabetByIndex(index) {    

      var result = '';
      var aAscii = 'A'.charCodeAt(0);

      // 숫자가 26 이하인 경우
      if (index <= 26) {
          result += String.fromCharCode(aAscii + (index - 1));
      } else {
          // 숫자가 26을 넘어가면 두 자리 알파벳 계산
          var quotient = Math.floor((index - 1) / 26); // 몫
          var remainder = (index - 1) % 26; // 나머지

          result += String.fromCharCode(aAscii + (quotient - 1));
          result += String.fromCharCode(aAscii + (remainder));
          
      }

      return result;
  }

  //컬럼 인덱스 반환
  const getColIndex = (name) => {
    
    let colIndex = -1;

    columns.forEach(function(item, index){
      if (item.name === name){        
        colIndex = index;
        return;
      }        
    })

    return colIndex;
  }

  //인덱스 컬럼명 반환
  const getColName = (colIndex) => {

    let colName = columns[colIndex].name;

    return colName;
  }

  //공통코드 목록 변환
  const getCodeList = (response) => {
    let codeList = [];
    response.data.list.map ( (item) => codeList.push({"id": item.dtlCd, "name": item.dtlCdNm}));   
    return codeList;       
  }

  //모델코드 목록 변환
  const getModelCodeList = (response) => {
    let codeList = [];
    response.data.list.map ( (item) => codeList.push({"id": item.modelCd, "name": item.modelNm}));   
    return codeList;       
  }

  //시트 가로 사이즈 동적 변경
  const setSheetWidth = () => {
    var element = document.querySelector('.jexcel_content');
    if (element){                
      element.style.width = (window.innerWidth-320) + "px";
      element.style.height = (window.innerHeight-380) + "px";
      element.style.maxHeight = (window.innerHeight-380) + "px";
    }
  }

  //문자열 날짜 형식 확인
  function isDateString(str) {
    // Date 객체로 파싱을 시도
    const date = new Date(str);

    // 유효한 날짜인지 확인
    return !isNaN(date.getTime());
  }

  useEffect(() => {

    window.addEventListener("resize", setSheetWidth);

  }, []);
  
  useEffect(() => {
    
    setColumns().then(columns => {    
      createSheet(columns);         
      validate();
    });
    
  }, [props.list]);

  return (
    <div>
        <div ref={jRef} />
        {
          (props.list ? props.list.length : 0) > 0 ? <div></div> :
          
          <Grid container alignItems="center" justifyContent="center" xs={12} sm={12} md={12} lg={12} xl={12} sx={{height:'100%'}}>
              <img src={excelImage} width={200} height={200} alt=""/>
              데이터가 없습니다. 엑셀 파일 불러오기 후 데이터를 확인 해주세요.
          </Grid>
        }
    </div>
  )
});

export default PvInvrtrS;