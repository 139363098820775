import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import successImage from '../../assets/image/pin_success.png';
import errorImage from '../../assets/image/pin_error.png';
import noneImage from '../../assets/image/pin_none.png';

import successSelectImage from '../../assets/image/pin_success_select.png';
import errorSelectImage from '../../assets/image/pin_error_select.png';
import noneSelectImage from '../../assets/image/pin_none_select.png';

import axiosInstance from '../util/AxiosUtil';
import '../../assets/css/KakaoMap.css';

const { kakao } = window;

const KakaoMap = forwardRef((props, ref) => {

    const [map, setMap] = useState();
    const [clusterer, setClusterer] = useState();    

    const [isSuccess, setIsSuccess] = useState(true);    
    const [isError, setIsError] = useState(true);
    const [isNone, setIsNone] = useState(true);    

    //RTU 상태 갯수 정보
    const [rtuState, setRtuState] = useState({successCnt:0, errorCnt:0, noneCnt:0});

    //RTU 목록
    const [rtuList, setRtuList] = useState([{}]);

    //마커 배열
    const [markers, setMarkers] = useState([]);    
    const [errMarkers, setErrMarkers] = useState([]);

    //오버레이 배열
    let overlayArray = [];    
    const [overlays, setOverlays] = useState([]);       
    
    //선택된 마커
    let selectedMaker = null;    

    // 마커이미지의 주소와, 크기, 옵션으로 마커 이미지를 생성하여 리턴하는 함수입니다
    const createMarkerImage = (connStsCd) => {

        const src = ( connStsCd === "100" ? successImage : connStsCd === "200" ? errorImage : noneImage );
        const size = new kakao.maps.Size(19, 34);
        const markerImage = new kakao.maps.MarkerImage(src, size);

        return markerImage;            
    }

    // 선택된 마커이미지의 주소와, 크기, 옵션으로 마커 이미지를 생성하여 리턴하는 함수입니다
    const createSelectMarkerImage = (connStsCd) => {

        const src = ( connStsCd === "100" ? successSelectImage : connStsCd === "200" ? errorSelectImage : noneSelectImage );
        const size = new kakao.maps.Size(28, 38);
        const markerImage = new kakao.maps.MarkerImage(src, size);

        return markerImage;            
    }

    // 좌표와 마커이미지를 받아 마커를 생성하여 리턴하는 함수입니다
    const createMarker = (position, image)  => {
        const marker = new kakao.maps.Marker({
            position: position,
            image: image
        });

        return marker;  
    }   

    // 제품 마커를 생성하고 정상 마커 배열에 추가하는 함수입니다
    const createMarkers = () => {

        let makrerArray = [];
        overlayArray = [];

        for (let i = 0; i < rtuList.length; i++) {              
            
            const position = new kakao.maps.LatLng(rtuList[i].lat, rtuList[i].lng);

            if (!isSuccess && rtuList[i].connStsCd === "100"){
                continue;
            }
            if (!isError && rtuList[i].connStsCd === "200"){
                continue;
            }
            if (!isNone && rtuList[i].connStsCd === "300"){
                continue;
            }

            // 마커이미지와 마커를 생성합니다
            const markerImage = createMarkerImage(rtuList[i].connStsCd);  
            const selectMarkerImage = createSelectMarkerImage(rtuList[i].connStsCd);
            const marker = createMarker(position, markerImage);  

            // 생성된 정보를 마커 배열에 추가합니다
            makrerArray.push(marker);

            if (map != undefined){

                const overlay = createOverlay(marker, markerImage, rtuList[i], false);

                overlayArray.push(overlay);                

                // 마커를 클릭했을 때 커스텀 오버레이를 표시합니다
                kakao.maps.event.addListener(marker, 'click', function() {                    
                    
                    overlayDestroy();

                    for (let i = 0; i < overlayArray.length; i++) {  
                        overlayArray[i].setMap(null);
                    } 

                    const level = map.getLevel();

                    let offsetY = 0.1;
                    let offsetX = 0.08;
                    
                    switch(level) {
                        case 1: 
                            offsetY = 0.000390625; 
                            offsetX = 0.0003125;
                            break;
                        case 2: 
                            offsetY = 0.00078125; 
                            offsetX = 0.000625;
                            break;
                        case 3: 
                            offsetY = 0.0015625; 
                            offsetX = 0.00125;
                            break;
                        case 4: 
                            offsetY = 0.003125; 
                            offsetX = 0.0025;
                            break;
                        case 5: 
                            offsetY = 0.00625; 
                            offsetX = 0.005;
                            break;
                        case 6: 
                            offsetY = 0.0125; 
                            offsetX = 0.01;
                            break;
                        case 7: 
                            offsetY = 0.025; 
                            offsetX = 0.02;
                            break;
                        case 8: 
                            offsetY = 0.05; 
                            offsetX = 0.04;
                            break;
                        case 9: 
                            offsetY = 0.1; 
                            offsetX = 0.08;
                            break;
                        case 10: 
                            offsetY = 0.2; 
                            offsetX = 0.16;
                            break;
                        case 11: 
                            offsetY = 0.4; 
                            offsetX = 0.32;
                            break;
                        case 12: 
                            offsetY = 0.8; 
                            offsetX = 0.64; 
                            break;
                        case 13: 
                            offsetY = 1.6; 
                            offsetX = 1.28; 
                            break;
                        case 14: 
                            offsetY = 3.2; 
                            offsetX = 2.56; 
                            break;
                    }

                    const moveLatLon = new kakao.maps.LatLng(marker.getPosition().getLat() + offsetY, marker.getPosition().getLng() - offsetX);

                    map.panTo(moveLatLon);

                    overlay.setMap(map);

                    if (selectedMaker != null){
                        selectedMaker.setImage(markerImage);
                    }

                    marker.setImage(selectMarkerImage);

                    selectedMaker = marker;                    
                    
                });
            }
        }     
        
        setMarkers(makrerArray);    
        setOverlays(overlayArray);
    }

    // 마커 오버레이 컨텐츠 생성
    const createOverlay = (marker, markerImage, rtuInfo, isMakerRemove) => {
        let dialog = document.createElement('div');
        dialog.classList.add('map-dialog');
        dialog.classList.add(rtuInfo.connStsCd === "100" ? "success" : rtuInfo.connStsCd === "200" ? "error" : "none");

        let header = document.createElement('div');
        header.classList.add('map-dialog-header');                
        header.appendChild(document.createTextNode(rtuInfo.siteNm));
        header.title = rtuInfo.siteNm;

        let close = document.createElement('div');
        close.classList.add('map-dialog-close');

        // 닫기 이벤트 추가
        close.onclick = function() {
            overlay.setMap(null);            
            marker.setImage(markerImage);

            if (isMakerRemove){
                marker.setMap(null);
            }            
        };

        let content = document.createElement('div');
        content.classList.add('map-dialog-content');

        let content_title_1 = document.createElement('div');
        content_title_1.appendChild(document.createTextNode("에너지원"));
        content_title_1.classList.add('title');

        let content_node_1 = document.createElement('div');
        content_node_1.appendChild(document.createTextNode(rtuInfo.nrnGentNm));
        content_node_1.classList.add('node');

        let content_title_2 = document.createElement('div');
        content_title_2.appendChild(document.createTextNode("상태"));
        content_title_2.classList.add('title');

        let content_node_2 = document.createElement('div');
        content_node_2.appendChild(document.createTextNode(rtuInfo.connStsNm));
        content_node_2.classList.add('node');

        let content_title_3 = document.createElement('div');
        content_title_3.appendChild(document.createTextNode("시공사"));
        content_title_3.classList.add('title');

        let content_node_3 = document.createElement('div');
        content_node_3.appendChild(document.createTextNode(rtuInfo.cntrctrNm));
        content_node_3.title = rtuInfo.cntrctrNm;
        content_node_3.classList.add('node');

        let content_title_4 = document.createElement('div');
        content_title_4.appendChild(document.createTextNode("시공사연락처"));                
        content_title_4.classList.add('title');

        let content_node_4 = document.createElement('div');
        content_node_4.appendChild(document.createTextNode(rtuInfo.cntrctrTlno));
        content_node_4.title = rtuInfo.cntrctrTlno;
        content_node_4.classList.add('node');

        let content_title_5 = document.createElement('div');
        content_title_5.appendChild(document.createTextNode("주소"));                
        content_title_5.classList.add('title');

        let content_node_5 = document.createElement('p');
        content_node_5.appendChild(document.createTextNode(rtuInfo.adr));
        content_node_5.title = rtuInfo.adr;
        content_node_5.classList.add('adr');

        content.appendChild(content_title_1);
        content.appendChild(content_node_1);
        
        content.appendChild(content_title_2);
        content.appendChild(content_node_2);

        content.appendChild(content_title_3);
        content.appendChild(content_node_3);

        content.appendChild(content_title_4);
        content.appendChild(content_node_4);

        content.appendChild(content_title_5);
        content.appendChild(content_node_5);

        header.appendChild(close);
        dialog.appendChild(header);
        dialog.appendChild(content);

        const overlay = new kakao.maps.CustomOverlay({
            content: dialog,                    
            position: marker.getPosition()       
        });

        return overlay;
    }

    // 제품 마커들의 지도 표시 여부를 설정하는 함수입니다
    const setMarkerList = (map) => {        

        for (let i = 0; i < markers.length; i++) {  
            markers[i].setMap(map);
        }        
    }

    // 모든 마커 삭제
    const markerDestroy = () => {
        
        for (let i = 0; i < markers.length; i++) {  
            markers[i].setMap(null);
        }         
    }

    // 모든 오버레이 삭제
    const overlayDestroy = () => {
        for (let i = 0; i < overlays.length; i++) {  
            overlays[i].setMap(null);
        }         
    }

    const getData = () => {

        axiosInstance.get('/mapRtuList').then((Response)=>{
            
            if(Response.request.status === 200){
                
                setRtuState(Response.data.summ);
                
                setRtuList(Response.data.list);

            }else{
                console.log("오류입니다. 지도 데이터를 조회 할 수 없습니다.")                
            }
        });  
    }

    const handleSetMaker = (rtuInfo) => {

        if (rtuInfo !== undefined){
            for (let i = 0; i < errMarkers.length; i++) {  
                errMarkers[i].setMap(null);
            } 
            
            overlayDestroy();
    
            // 좌표 생성
            const position = new kakao.maps.LatLng(rtuInfo.lat, rtuInfo.lng);
    
            // 마커이미지와 마커를 생성합니다
            const markerImage = createMarkerImage(rtuInfo.connStsCd);  
            const selectMarkerImage = createSelectMarkerImage(rtuInfo.connStsCd);
            const marker = createMarker(position, markerImage);  
    
            setErrMarkers([...errMarkers, marker])
            marker.setMap(map);
    
            const overlay = createOverlay(marker, markerImage, rtuInfo, true);
    
            setOverlays([...overlays, overlay]);
    
            for (let i = 0; i < overlayArray.length; i++) {  
                overlayArray[i].setMap(null);
            } 
    
            const level = map.getLevel();
    
            let offsetY = 0.1;
            let offsetX = 0.08;
            
            switch(level) {
                case 1: 
                    offsetY = 0.000390625; 
                    offsetX = 0.0003125;
                    break;
                case 2: 
                    offsetY = 0.00078125; 
                    offsetX = 0.000625;
                    break;
                case 3: 
                    offsetY = 0.0015625; 
                    offsetX = 0.00125;
                    break;
                case 4: 
                    offsetY = 0.003125; 
                    offsetX = 0.0025;
                    break;
                case 5: 
                    offsetY = 0.00625; 
                    offsetX = 0.005;
                    break;
                case 6: 
                    offsetY = 0.0125; 
                    offsetX = 0.01;
                    break;
                case 7: 
                    offsetY = 0.025; 
                    offsetX = 0.02;
                    break;
                case 8: 
                    offsetY = 0.05; 
                    offsetX = 0.04;
                    break;
                case 9: 
                    offsetY = 0.1; 
                    offsetX = 0.08;
                    break;
                case 10: 
                    offsetY = 0.2; 
                    offsetX = 0.16;
                    break;
                case 11: 
                    offsetY = 0.4; 
                    offsetX = 0.32;
                    break;
                case 12: 
                    offsetY = 0.8; 
                    offsetX = 0.64; 
                    break;
                case 13: 
                    offsetY = 1.6; 
                    offsetX = 1.28; 
                    break;
                case 14: 
                    offsetY = 3.2; 
                    offsetX = 2.56; 
                    break;
            }
    
            const moveLatLon = new kakao.maps.LatLng(marker.getPosition().getLat() + offsetY, marker.getPosition().getLng() - offsetX);
    
            map.panTo(moveLatLon);
    
            overlay.setMap(map);
    
            marker.setImage(selectMarkerImage);
        }
        
    };

    //부모 컴포넌트에서 호출 할 수 있는 함수 정의
    useImperativeHandle(ref, () => ({
        handleSetMaker
    }))

    useEffect(() =>{

        getData();
        
    }, [])
    
    useEffect(() =>{
        
        if (map === undefined){
            const container = document.getElementById('kakoMap');

            const options = {
                center: new kakao.maps.LatLng(37.47840501810059, 126.86440503565339),
                level: 4
            };

            const mapObj = new kakao.maps.Map(container, options)
            
            setMap(mapObj);
        }

        //마커 삭제
        markerDestroy();

        //오버레이 삭제
        overlayDestroy();

        //마커 생성
        createMarkers();                

    }, [rtuList, isSuccess, isError, isNone])

    useEffect(() =>{

        //마커 지도표시
        setMarkerList(map);             

    }, [markers])

    return (        
        <div style={{position:'relative', width:'100%', height:props.height}}>
            <div id="kakoMap" style={{width:'100%', height:'100%', borderRadius:"15px"}}></div>
        
            <div class="category" style={{borderTopRightRadius:"15px"}}>
                <ul>                    
                    <li id="ico-success" className={isSuccess ? "menu_selected" : ""} onClick={() => setIsSuccess(!isSuccess)}>
                        <span className="ico_comm ico_success"></span>
                        정상<br/>({rtuState.successCnt.toLocaleString()})
                    </li>                    
                    <li id="ico-error" className={isError ? "menu_selected" : ""} onClick={() => setIsError(!isError)}>
                        <span className="ico_comm ico_error"></span>
                        장애<br/>({rtuState.errorCnt.toLocaleString()})
                    </li>
                    <li id="ico-none" className={isNone ? "menu_selected" : ""} style={{borderTopRightRadius:"15px"}} onClick={() => setIsNone(!isNone)}>
                        <span className="ico_comm ico_none"></span>
                        미작동<br/>({rtuState.noneCnt.toLocaleString()})
                    </li>                                        
                </ul>
            </div>
        </div>
    )

});

export default KakaoMap