import React, { useState, useRef, useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import axiosInstance from '../components/util/AxiosUtil';
import CustomTextField from '../components/ui/CustomTextField';

function Sample2() {

    const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({rtuSeq:'', photoTypeCd:''});

    const [ list, setList ] = useState([]);

    const imgRef = useRef();

    // 이미지 추가
    const loadImgFile = (data) => {

        const file = imgRef.current.files[0];

        //파일 서버 저장
        const formData = new FormData();
        formData.append("rtuSeq", data.rtuSeq);
        formData.append("photoTypeCd", data.photoTypeCd);
        formData.append("ord", 0);
        formData.append("img", file);            
        
        axiosInstance.post('/photoHst', formData, {headers: {'content-type': 'multipart/form-data'}}).then((Response)=>{            

            if (Response){
                if(Response.request.status === 200){     

                    //파일 저장 성공시 이미지 다시 조회
                    getImages();
    
                }else{                              
                    alert("실패");
                }                  
            }else{
                alert("시스템 오류");
            }
            
        });  

    };

    // 이미지 삭제
    const del = (photoSeq, e) => {

        axiosInstance.delete('/photoHst', {params: {photoSeq: photoSeq}}).then((Response)=>{                    

            if (Response){
                if(Response.request.status === 200){     

                    //파일 삭제 성공시 이미지 다시 조회
                    getImages();
    
                }else{                              
                    alert("실패");
                }                  
            }else{
                alert("시스템 오류");
            }
            
        });  
    }

    // 이미지 조회
    const getImages = () => {          
        
        axiosInstance.get('/photoHstList', {params: {rtuSeq: getValues("rtuSeq")}}).then((Response)=>{
            
            if(Response.request.status === 200){

                //데이터 초기화
                setList([]);                

                Response.data.list.map(function(file){

                    if (file.ord == 0){
                        //DB 결과값 이미지 파일 생성
                        const newBlob = new Blob([convertToBlob(file.blob)], {type:file.fileTypeNm});                           
                        const createFile = new File([newBlob], file.fileNm,{type:file.fileTypeNm});                                        

                        //이미지 랜더링 데이터 생성
                        const blod = 'data:' + file.fileTypeNm + ';base64,' + file.blob;

                        //List State 추가
                        const item = {
                                        photoSeq: file.photoSeq, 
                                        file: createFile, 
                                        blod: blod
                                    };

                        setList(prev => [...prev, item]);                    
                    }
                    
                });   

                
            }else{
                alert("조회 오류 입니다.");
            }
        });  
    }

    // Base64 문자열 Blob 타입 변경
    const convertToBlob = (base64) =>{
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        return byteArray;
    }

    useEffect(() => {

        getImages();

    }, []);

    return (

        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)" }}>

            <div style={{ "marginBottom": "16px" }}></div>
            <div style={{ "marginBottom": "48px" }}>
                <p fontWeight="600" className="css-1pr8naw">Photo Sample</p>
                <a href="https://v4.mui.com/getting-started/installation/" className="css-3vvtry">https://v4.mui.com</a>
            </div>
            
            <div style={{"marginTop":"5px", "marginBottom":"5px"}}>File Upload Sample.</div>

            <Box sx={{width:"100%", border: '1px dashed grey', padding:2}}>

                <Grid container spacing={4}>                    

                    <Grid xs={12}>

                        {
                            list.map((item, index) => (

                                <Box key={index} sx={{m:2, border:"1px solid silver", width:120, textAlign:"center"}}>
                                    <img src={item.blod} alt="이미지" height="100" width="120"/>
                                    <Button variant="contained" onClick={(e) => {del(item.photoSeq, e)}} sx={{m:2}} >삭제</Button>                        
                                </Box>
                            
                            ))
                        }
                        
                    </Grid>

                    <Grid xs={6}>  
                        <CustomTextField name="rtuSeq" label="RTU 일련번호" control={control} />                      
                    </Grid> 
                    <Grid xs={6}>  
                        <CustomTextField name="photoTypeCd" label="사진 구분 코드" control={control} />                      
                    </Grid> 

                    <Grid xs={12}>   
                          
                        <Button label="delete" variant="contained" component="label" onClick={getImages} >사진 추가
                            <input type="file" accept="image/*" onChange={handleSubmit(loadImgFile)} ref={imgRef} hidden /> 
                        </Button>                                          

                        <Button variant="contained" onClick={getImages} sx={{ml:2}}>사진 조회</Button>                        
                    </Grid>                       

                </Grid>                

            </Box>

        </div>
    );
}

export default Sample2;