import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Sidebar, Menu as ProMenu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import axiosInstance from '../util/AxiosUtil'
import SidebarHeader from "../ui/SideBarHeader";
import PlaceHolder from '../icon/PlaceHolder';
import Support from '../icon/Support';
import Setting from '../icon/Setting';
import MuiltSetting from '../icon/MuiltSetting';
import Tree from '../icon/Tree';
import Product from '../icon/Product';
import Sample from '../icon/Sample';
import "../../assets/css/Menu.css"

export default function Menu() {

    const navigate = useNavigate();
    const { toggleSidebar, broken } = useProSidebar();
    const [lastParentMenuIndex, setLastParentMenuIndex] = useState("");    
    const [lastUrl, setLastUrl] = useState("");    
    const [menuList, setMenuList] = useState([]);

    useEffect(() => {

        axiosInstance.get('/auth/usrMenuList').then((Response) => {

            if (Response.request.status === 200) {        
                
                axiosInstance.get('/auth/member').then((ResponseMember) => {

                    const lastUrl = localStorage.getItem('lastUrl');
                    const lastParentMenuIndex = localStorage.getItem('lastParentMenuIndex');

                    setLastUrl((lastUrl && lastUrl !== "/" && lastUrl !== "") ? lastUrl : ResponseMember.data.member.stUrl);
                    setLastParentMenuIndex(lastParentMenuIndex ? lastParentMenuIndex : "");

                    setMenuList([...Response.data.list]);                                        
                });
            }
        });

    }, []);

    const getIcon = (tag) => {
        
        if (tag === "PlaceHolder"){
            return (
                <PlaceHolder size="18" color="#e66937" />
            )
        }else if (tag === "Support"){
            return (
                <Support size="18" color="#e66937" />
            )            
        }else if (tag === "Setting"){
            return (
                <Setting size="18" color="#e66937" />
            )
        }else if (tag === "MuiltSetting"){
            return (
                <MuiltSetting size="18" color="#e66937" />
            )
        }else if (tag === "Sample"){
            return (
                <Sample size="18" color="#e66937" />
            )
        }else if (tag === "Tree"){
            return (
                <Tree size="18" color="#e66937" />
            )
        }else if (tag === "Product"){
            return (
                <Product size="18" color="#e66937" />
            )
        }
        
    }

    //하위 메뉴 생성
    const createSubMenu = (menu, i) => (
        <SubMenu defaultOpen={lastParentMenuIndex == i} key={i} label={menu.menuName} icon={getIcon(menu.tag)}>
            {
                menuList.map((m, index) => (
                    menu.menuId === m.upperMenuId ? 
                    <MenuItem key={index} className={lastUrl === m.url ? "active" : ""} onClick={(e) => goToPage(m.url, i, e)}>{m.menuName}</MenuItem> : null
                ))
            }            
        </SubMenu>
    )

    //메뉴 화면 이동
    const goToPage = (path, parentMenuIndex) => {      
        
        //메뉴 닫기
        toggleSidebar();

        setTimeout(
            () => {
                //100ms 후 실행 (메뉴바 숨김 처리 시간)                
                localStorage.setItem('lastUrl', path);
                localStorage.setItem('lastParentMenuIndex', parentMenuIndex);        

                setLastParentMenuIndex(parentMenuIndex);
                setLastUrl(path);

                navigate(path);                
            }, 100
        )        
    };

    return (
        <Sidebar defaultCollapsed={false} rtl={false} customBreakPoint="800px" image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg">
            <SidebarHeader style={{ marginBottom: '24px', marginTop: '16px' }} />
            <ProMenu
                menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                        if (level === 0)
                            return {
                                color: disabled ? '#f5d9ff' : '#d359ff',
                                backgroundColor: active ? '#eecef9' : undefined,
                            };
                    },
                }}
            >

                {
                    menuList.map((menu, index) => (

                        menu.childCnt === 0 && menu.menuDpth === 0 ?
                            <MenuItem key={index}
                                icon={getIcon(menu.tag)}
                                className={lastUrl === menu.url ? "active" : ""}
                                onClick={(e) => goToPage(menu.url, menu.menuName, menu.menuDesc, e)}>{menu.menuName}
                            </MenuItem> 
                        : menu.childCnt > 0 && menu.menuDpth === 0 ?
                            createSubMenu(menu, index)
                        : 
                            null
                    ))
                }

            </ProMenu>
        </Sidebar>
    );
}