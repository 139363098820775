import React, {useState, useEffect, forwardRef } from "react";
import {Unstable_Grid2 as Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import axiosInstance from '../../../../components/util/AxiosUtil';
import excelImage from '../../../../assets/image/excelBackground.png';
import '../../../../assets/css/Table.css'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    //'&:nth-of-type(odd)': {
      //backgroundColor: '#F6F9FD',
    //},
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const PvInvrtr = forwardRef((props, ref) => {

    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    // 인버터 목록
    const [list, setList] = useState([]);

    //건물용도 코드 목록
    const [keaBldgPurpCdList, setKeaBldgPurpCdList] = useState([]);
    //요금제 코드 목록
    const [keaBillTypeCdList, setKeaBillTypeCd] = useState([]);
    //설치유형 코드 목록
    const [keaInstlTypeCdList, setKeaInstlTypeCdList] = useState([]);
    //모듈결정분류 코드 목록
    const [keaModlCrstlTypeCdList, setKeaModlCrstlTypeCdList] = useState([]);
    //위상종류 코드 목록
    const [keaPhsTypeCdList, setKeaPhsTypeCdList] = useState([]);
    //추적시스템 코드 목록
    const [keaTrckSysCdList, setKeaTrckSysCdList] = useState([]);
    //계통접속 코드 목록
    const [keaGrdConnCdList, setKeaGrdConnCdList] = useState([]);
    //모듈 제조사 코드 목록
    const [modlMakrCdList, setModlMakrCdList] = useState([]);
    //모듈 모델 코드 목록
    const [modlModelCdList, setModlModelCdList] = useState([]);
    //인버터 제조사 코드 목록
    const [invrtrMakrCdList, setInvrtrMakrCdList] = useState([]);
    //인버터 모델 코드 목록
    const [invrtrModelCdList, setInvrtrModelCdList] = useState([]);

    const filterModelList = (modelList, makrCd) => {
        return modelList.filter((el) => 
            el.makrCd == makrCd
        );
    }    

    const handleResize = () => {
        setScreenHeight(window.innerHeight - 415);
    };

    const handleChange = (e, index, key) => {
        let newArray = [...list];
        newArray[index][key] = e.target.value;
        setList(newArray);
    }

    const handleChangeOnlyNumber = (e, index, key) => {
        let newArray = [...list];
        newArray[index][key] = e.target.value.replace(/[^0-9]/g, '');
        setList(newArray);
    }

    const handleChangeOnlyDecimal = (e, index, key) => {
        let newArray = [...list];
        newArray[index][key] = e.target.value.replace(/[^0-9.]/g, '');
        setList(newArray);
    }

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        setScreenHeight(window.innerHeight - 415);

        //건물용도 코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'KEA_BLDG_PURP_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setKeaBldgPurpCdList(Response.data.list);                     
            }
        });

        //요금제 코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'KEA_BILL_TYPE_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setKeaBillTypeCd(Response.data.list);                     
            }
        });

        //설치유형 코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'KEA_INSTL_TYPE_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setKeaInstlTypeCdList(Response.data.list);                     
            }
        });

        //모듈 결정분류 코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'KEA_MODL_CRSTL_TYPE_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setKeaModlCrstlTypeCdList(Response.data.list);                     
            }
        });

        //위상종류 코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'KEA_PHS_TYPE_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setKeaPhsTypeCdList(Response.data.list);                     
            }
        });
        
        //추적시스템 코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'KEA_TRCK_SYS_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setKeaTrckSysCdList(Response.data.list);                     
            }
        });
        
        //계통접속 코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'KEA_GRD_CONN_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setKeaGrdConnCdList(Response.data.list);                     
            }
        });     
        
        //모듈 제조사 코드 목록 조회
        axiosInstance.get('/makrCodeList', {params: {makrTypeCd: '30001'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setModlMakrCdList(Response.data.list);                     
            }
        });    
        //모듈 모델 코드 목록 조회
        axiosInstance.get('/modelCodeList', {params: {makrCd: '', makrTypeCd: '30001'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setModlModelCdList(Response.data.list);                     
            }
        });   

        //인버터 제조사 코드 목록 조회
        axiosInstance.get('/makrCodeList', {params: {makrTypeCd: '30002'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setInvrtrMakrCdList(Response.data.list);                     
            }
        });    

        //인버터 모델 코드 목록 조회
        axiosInstance.get('/modelCodeList', {params: {makrCd: '', makrTypeCd: '30002'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setInvrtrModelCdList(Response.data.list);                     
            }
        });   

        return () => {
            // cleanup            
            window.removeEventListener("resize", handleResize);
        };

    }, []);
    
    useEffect(() => {

        setList(props.list); 

    }, [props.list]);

    return (
        <TableContainer sx={{ height:screenHeight, maxHeight: screenHeight }}>

            {
                list.length > 0 ?
                <Table className="pose-table" stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell align='center' style={{ minWidth: 50, fontSize:14 }}>NO</TableCell>
                        <TableCell align='center' style={{ minWidth: 250, fontSize:14 }}><span style={{color:'red'}}>*</span>설치자명(단지명)</TableCell>
                        <TableCell align='center' style={{ minWidth: 250, fontSize:14 }}><span style={{color:'red'}}>*</span>주소</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>건물용도</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>요금제</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>설치자휴대폰번호</TableCell>
                        <TableCell align='center' style={{ minWidth: 160, fontSize:14 }}><span style={{color:'red'}}>*</span>설비설치계획서번호</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>설치유형</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>인버터 시리얼 번호</TableCell>                        
                        <TableCell align='center' style={{ minWidth: 250, fontSize:14 }}><span style={{color:'red'}}>*</span>설비명</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>설치장소</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>모듈 제조사</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>모듈 모델</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>모듈 결정분류</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>모듈당 용량(W)</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>모듈 총용량(kW)</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>모듈 방위각</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>모듈 경사각</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>모듈 직렬개수</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>모듈 병렬개수</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>인버터 제조사</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>인버터 모델</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>위상종류</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>추적시스템</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>인버터 용량(kW)</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>PV패널 기준 용량(kW)</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>계통접속</TableCell>
                        <TableCell align='center' style={{ minWidth: 250, fontSize:14 }}><span style={{color:'red'}}>*</span>시공업체</TableCell>
                        <TableCell align='center' style={{ minWidth: 250, fontSize:14 }}><span style={{color:'red'}}>*</span>시공업체 연락처</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>시공업체 핸드폰 연락처</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>시공일시</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>AS만료일</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}>특이사항</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}><span style={{color:'red'}}>*</span>시공완료일</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}>Lora ID</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                        {
                            
                            list.map((item, index) => (
                                //태양광 인버터 정보
                                <StyledTableRow key={index} hover role="checkbox" tabIndex={-1}>
                                    <TableCell align='center'>{index+1}</TableCell>   
                                    {/* 설치자명(단지명) */}
                                    <TableCell align='center'> 
                                        <input className={item.eqprNm_v.result} 
                                               title={item.eqprNm_v.errorMessage} 
                                               value={item.eqprNm || ''} 
                                               onChange={(e)=>handleChange(e, index, "eqprNm")}                                                
                                        />                                         
                                    </TableCell>    
                                    {/* 주소 */}
                                    <TableCell align='center'> 
                                        <input className={item.arnoAdr_v.result} 
                                               title={item.arnoAdr_v.errorMessage} 
                                               value={item.arnoAdr || ''} 
                                               onChange={(e)=>handleChange(e, index, "arnoAdr")} 
                                        /> 
                                    </TableCell>    
                                    {/* 건물용도 */}
                                    <TableCell align='center'>
                                        <select className={item.keaBldgPurpCd_v.result} 
                                                title={item.keaBldgPurpCd_v.errorMessage} 
                                                value={item.keaBldgPurpCd} 
                                                onChange={(e)=>handleChange(e, index, "keaBldgPurpCd")}>
                                            <option value=""></option>
                                            {
                                                keaBldgPurpCdList.map((item, i) => (
                                                    <option key={i} value={item.dtlCd}>{item.dtlCdNm}</option>
                                                ))                                                        
                                            }  
                                        </select>                                       
                                    </TableCell> 
                                    {/* 요금제 */}
                                    <TableCell align='center'>
                                        <select className={item.keaBillTypeCd_v.result} 
                                                title={item.keaBillTypeCd_v.errorMessage} 
                                                value={item.keaBillTypeCd} 
                                                onChange={(e)=>handleChange(e, index, "keaBillTypeCd")}>
                                            <option value=""></option>
                                            {
                                                keaBillTypeCdList.map((item, i) => (
                                                    <option key={i} value={item.dtlCd}>{item.dtlCdNm}</option>
                                                ))                                                        
                                            }  
                                        </select> 
                                    </TableCell> 
                                    {/* 설치자휴대폰번호 */}
                                    <TableCell align='center'> 
                                        <input className={item.eqprHpno_v.result} 
                                               title={item.eqprHpno_v.errorMessage} 
                                               value={item.eqprHpno || ''} 
                                               onChange={(e)=>handleChange(e, index, "eqprHpno")}                                               
                                        /> 
                                    </TableCell>
                                    {/* 설치계획번호 */}
                                    <TableCell align='center'> 
                                        <input className={item.instlPlanNo_v.result} 
                                               title={item.instlPlanNo_v.errorMessage} 
                                               value={item.instlPlanNo || ''} 
                                               onChange={(e)=>handleChangeOnlyNumber(e, index, "instlPlanNo")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 설치유형 */}
                                    <TableCell align='center'>
                                        <select className={item.keaInstlTypeCd_v.result} 
                                                title={item.keaInstlTypeCd_v.errorMessage} 
                                                value={item.keaInstlTypeCd} 
                                                onChange={(e)=>handleChange(e, index, "keaInstlTypeCd")}>                                                
                                            <option value=""></option>
                                            {
                                                keaInstlTypeCdList.map((item, i) => (
                                                    <option key={i} value={item.dtlCd}>{item.dtlCdNm}</option>
                                                ))                                                        
                                            }  
                                        </select> 
                                    </TableCell> 
                                    {/* 통신 장치 번호 */}
                                    <TableCell align='center'> 
                                        <input className={item.devNo_v.result} 
                                               title={item.devNo_v.errorMessage} 
                                               value={item.devNo || ''} 
                                               onChange={(e)=>handleChangeOnlyNumber(e, index, "devNo")}                                               
                                        />                                     
                                    </TableCell>                                     
                                    {/* 설비명 */}
                                    <TableCell align='center'> 
                                        <input className={item.facNm_v.result} 
                                               title={item.facNm_v.errorMessage} 
                                               value={item.facNm || ''} 
                                               onChange={(e)=>handleChange(e, index, "facNm")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 설치장소 */}
                                    <TableCell align='center'> 
                                        <input className={item.instlPlacNm_v.result} 
                                               title={item.instlPlacNm_v.errorMessage} 
                                               value={item.instlPlacNm || ''} 
                                               onChange={(e)=>handleChange(e, index, "instlPlacNm")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 모듈제조사 */}
                                    <TableCell align='center'>
                                        <select className={item.modlMakrCd_v.result} 
                                                title={item.modlMakrCd_v.errorMessage} 
                                                value={item.modlMakrCd} 
                                                onChange={(e)=>handleChange(e, index, "modlMakrCd")}>
                                            <option value=""></option>
                                            {
                                                modlMakrCdList.map((item, i) => (
                                                    <option key={i} value={item.dtlCd}>{item.dtlCdNm}</option>
                                                ))                                                        
                                            }  
                                        </select> 
                                    </TableCell>
                                    {/* 모듈모델 */}
                                    <TableCell align='center'>                                        
                                        <select className={item.modlModelCd_v.result} 
                                                title={item.modlModelCd_v.errorMessage} 
                                                value={item.modlModelCd}
                                                onChange={(e)=>handleChange(e, index, "modlModelCd")}>                                        
                                            <option value=""></option>
                                            {   
                                                filterModelList(modlModelCdList, item.modlMakrCd).map((item, i) => (
                                                    <option key={i} value={item.modelCd}>{item.modelNm}</option>
                                                ))                                                        
                                            }
                                        </select> 
                                    </TableCell>
                                    {/* 모듈결정분류 */}
                                    <TableCell align='center'>
                                        <select className={item.keaModlCrstlTypeCd_v.result} 
                                                title={item.keaModlCrstlTypeCd_v.errorMessage} 
                                                value={item.keaModlCrstlTypeCd} 
                                                onChange={(e)=>handleChange(e, index, "keaModlCrstlTypeCd")}>
                                            <option value=""></option>
                                            {
                                                keaModlCrstlTypeCdList.map((item, i) => (
                                                    <option key={i} value={item.dtlCd}>{item.dtlCdNm}</option>
                                                ))                                                        
                                            }  
                                        </select>                                         
                                    </TableCell> 
                                    {/* 모듈당용량 */}
                                    <TableCell align='center'> 
                                        <input className={item.modlPerCpct_v.result} 
                                               title={item.modlPerCpct_v.errorMessage} 
                                               value={item.modlPerCpct || ''} 
                                               onChange={(e)=>handleChangeOnlyDecimal(e, index, "modlPerCpct")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 모듈총용량 */}
                                    <TableCell align='center'> 
                                        <input className={item.modlTotCpct_v.result} 
                                               title={item.modlTotCpct_v.errorMessage} 
                                               value={item.modlTotCpct || ''} 
                                               onChange={(e)=>handleChangeOnlyDecimal(e, index, "modlTotCpct")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 모듈방위각 */}
                                    <TableCell align='center'> 
                                        <input className={item.modlAzmth_v.result} 
                                               title={item.modlAzmth_v.errorMessage} 
                                               value={item.modlAzmth || ''} 
                                               onChange={(e)=>handleChangeOnlyDecimal(e, index, "modlAzmth")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 모듈정사각 */}
                                    <TableCell align='center'> 
                                        <input className={item.modlInclAng_v.result} 
                                               title={item.modlInclAng_v.errorMessage} 
                                               value={item.modlInclAng || ''} 
                                               onChange={(e)=>handleChangeOnlyDecimal(e, index, "modlInclAng")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 모듈직렬갯수 */}
                                    <TableCell align='center'> 
                                        <input className={item.modlSerlCnt_v.result} 
                                               title={item.modlSerlCnt_v.errorMessage} 
                                               value={item.modlSerlCnt || ''} 
                                               onChange={(e)=>handleChangeOnlyNumber(e, index, "modlSerlCnt")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 모듈병렬갯수 */}
                                    <TableCell align='center'> 
                                        <input className={item.modlParalCnt_v.result} 
                                               title={item.modlParalCnt_v.errorMessage} 
                                               value={item.modlParalCnt || ''} 
                                               onChange={(e)=>handleChangeOnlyNumber(e, index, "modlParalCnt")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 인버터 제조사 */}
                                    <TableCell align='center'>

                                        <select className={item.invrtrMakrCd_v.result} 
                                                title={item.invrtrMakrCd_v.errorMessage} 
                                                value={item.invrtrMakrCd} 
                                                onChange={(e)=>handleChange(e, index, "invrtrMakrCd")}>
                                            <option value=""></option>
                                            {
                                                invrtrMakrCdList.map((item, i) => (
                                                    <option key={i} value={item.dtlCd}>{item.dtlCdNm}</option>
                                                ))                                                        
                                            }  
                                        </select> 

                                    </TableCell>
                                    {/* 인버터 모델 */}
                                    <TableCell align='center'>
                                        <select className={item.invrtrModelCd_v.result} 
                                                title={item.invrtrModelCd_v.errorMessage} 
                                                value={item.invrtrModelCd}
                                                onChange={(e)=>handleChange(e, index, "invrtrModelCd")}>                                        
                                            <option value=""></option>
                                            {   
                                                filterModelList(invrtrModelCdList, item.invrtrMakrCd).map((item, i) => (
                                                    <option key={i} value={item.modelCd}>{item.modelNm}</option>
                                                ))                                                        
                                            }
                                        </select> 
                                    </TableCell>
                                    {/* 위상종류 */}
                                    <TableCell align='center'>
                                        <select className={item.keaPhsTypeCd_v.result} 
                                                title={item.keaPhsTypeCd_v.errorMessage} 
                                                value={item.keaPhsTypeCd} 
                                                onChange={(e)=>handleChange(e, index, "keaPhsTypeCd")}>
                                            <option value=""></option>
                                            {
                                                keaPhsTypeCdList.map((item, i) => (
                                                    <option key={i} value={item.dtlCd}>{item.dtlCdNm}</option>
                                                ))                                                        
                                            }  
                                        </select> 
                                    </TableCell> 
                                    {/* 추적시스템 */}
                                    <TableCell align='center'>
                                        <select className={item.keaTrckSysCd_v.result} 
                                                title={item.keaTrckSysCd_v.errorMessage} 
                                                value={item.keaTrckSysCd} 
                                                onChange={(e)=>handleChange(e, index, "keaTrckSysCd")}>
                                            <option value=""></option>
                                            {
                                                keaTrckSysCdList.map((item, i) => (
                                                    <option key={i} value={item.dtlCd}>{item.dtlCdNm}</option>
                                                ))                                                        
                                            }  
                                        </select> 
                                    </TableCell> 
                                    {/* 인버터용량 */}
                                    <TableCell align='center'> 
                                        <input className={item.invrtrCpct_v.result} 
                                               title={item.invrtrCpct_v.errorMessage} 
                                               value={item.invrtrCpct || ''} 
                                               onChange={(e)=>handleChangeOnlyDecimal(e, index, "invrtrCpct")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 패널용량 */}
                                    <TableCell align='center'> 
                                        <input className={item.pvPanlCpct_v.result} 
                                               title={item.pvPanlCpct_v.errorMessage} 
                                               value={item.pvPanlCpct || ''} 
                                               onChange={(e)=>handleChangeOnlyDecimal(e, index, "pvPanlCpct")}                                               
                                        /> 
                                    </TableCell>                                                     
                                    {/* 계통접속 */}
                                    <TableCell align='center'>
                                        <select className={item.keaGrdConnCd_v.result} 
                                                title={item.keaGrdConnCd_v.errorMessage} 
                                                value={item.keaGrdConnCd} 
                                                onChange={(e)=>handleChange(e, index, "keaGrdConnCd")}>                                                
                                            <option value=""></option>
                                            {
                                                keaGrdConnCdList.map((item, i) => (
                                                    <option key={i} value={item.dtlCd}>{item.dtlCdNm}</option>
                                                ))                                                        
                                            }  
                                        </select> 
                                    </TableCell>
                                    {/* 시공업체 */}
                                    <TableCell align='center'> 
                                        <input className={item.cntrctrNm_v.result} 
                                               title={item.cntrctrNm_v.errorMessage} 
                                               value={item.cntrctrNm || ''} 
                                               onChange={(e)=>handleChange(e, index, "cntrctrNm")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 시공업체 연락처 */}
                                    <TableCell align='center'> 
                                        <input className={item.cntrctrTlno_v.result} 
                                               title={item.cntrctrTlno_v.errorMessage} 
                                               value={item.cntrctrTlno || ''} 
                                               onChange={(e)=>handleChange(e, index, "cntrctrTlno")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 시공업체 핸드폰 */}
                                    <TableCell align='center'>
                                        <input className={item.cntrctrHpno_v.result} 
                                               title={item.cntrctrHpno_v.errorMessage} 
                                               value={item.cntrctrHpno || ''} 
                                               onChange={(e)=>handleChange(e, index, "cntrctrHpno")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 시공일시 */}
                                    <TableCell align='center'> 
                                        <input className={item.exworkDay_v.result} 
                                               title={item.exworkDay_v.errorMessage} 
                                               value={item.exworkDay || ''} 
                                               onChange={(e)=>handleChange(e, index, "exworkDay")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* AS만료일시 */}
                                    <TableCell align='center'> 
                                        <input className={item.asExpDay_v.result} 
                                               title={item.asExpDay_v.errorMessage} 
                                               value={item.asExpDay || ''} 
                                               onChange={(e)=>handleChange(e, index, "asExpDay")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 특이사항 */}
                                    <TableCell align='center'> 
                                        <input className={item.rmrk_v.result} 
                                               title={item.rmrk_v.errorMessage} 
                                               value={item.rmrk || ''} 
                                               onChange={(e)=>handleChange(e, index, "rmrk")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 시공완료일 */}
                                    <TableCell align='center'> 
                                        <input className={item.exworkCmpltDay_v.result} 
                                               title={item.exworkCmpltDay_v.errorMessage} 
                                               value={item.exworkCmpltDay || ''} 
                                               onChange={(e)=>handleChange(e, index, "exworkCmpltDay")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* Lora ID */}
                                    <TableCell align='center'> 
                                        <input className={item.loraId_v.result} 
                                               title={item.loraId_v.errorMessage} 
                                               value={item.loraId || ''} 
                                               onChange={(e)=>handleChange(e, index, "loraId")}                                               
                                        /> 
                                    </TableCell>                                     
                                                                                                                        
                                </StyledTableRow>
                            ))                            
                            
                        }                                    
                    
                    </TableBody>
                </Table>
                :
                <Grid container alignItems="center" justifyContent="center" xs={12} sm={12} md={12} lg={12} xl={12} sx={{height:'100%'}}>
                    <img src={excelImage} width={200} height={200} alt=""/>
                    데이터가 없습니다.
                </Grid>
            }
            
            </TableContainer>
    )
});

export default PvInvrtr;