import React, {useRef, useState, useEffect} from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { Tabs, Tab, Typography, Box, Button } from '@mui/material';
import axiosInstance from '../../../../components/util/AxiosUtil';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ListIcon from '@mui/icons-material/List';
import Alert from '../../../../components/ui/Alert';
import Toast from "../../../../components/ui/Toast"
import PvInvrtrS from './PvInvrtrS';
import PvCombS from './PvCombS';
import PvArdyS from './PvArdyS';
import $ from 'jquery';
  
//태양광 인버터 정보 유효성 검사 규칙
const pvRules = {
    eqprNm: { required: '필수 입력 값 입니다.', maxLength: 50 },
    arnoAdr: { required: '필수 입력 값 입니다.', maxLength: 100 },
    keaBldgPurpCd: { required: '필수 입력 값 입니다.', maxLength: 50 },
    keaBillTypeCd: { required: '필수 입력 값 입니다.', maxLength: 50 },
    eqprHpno: { required: '필수 입력 값 입니다.', maxLength: 20 },
    instlPlanNo: { required: '필수 입력 값 입니다.', maxLength: 20, format: 'number' },
    keaInstlTypeCd: { required: '필수 입력 값 입니다.', maxLength: 50 },
    devNo: { required: '필수 입력 값 입니다.', maxLength: 20, format: 'number' },    
    facNm: { required: '필수 입력 값 입니다.', maxLength: 50 },    
    modlMakrCd: { required: '필수 입력 값 입니다.', maxLength: 10 },
    modlModelCd: { required: '필수 입력 값 입니다.', maxLength: 10 },
    keaModlCrstlTypeCd: { required: '필수 입력 값 입니다.', maxLength: 50 },
    modlPerCpct: { required: '필수 입력 값 입니다.', integerLen: 13, floatLen: 3, format: 'number' },
    modlTotCpct: { required: '필수 입력 값 입니다.', integerLen: 13, floatLen: 3, format: 'number' },
    modlAzmth: { required: '필수 입력 값 입니다.', maxLength: 3, format: 'number' },
    modlInclAng: { required: '필수 입력 값 입니다.', maxLength: 3, format: 'number' },
    modlSerlCnt: { required: '필수 입력 값 입니다.', maxLength: 10, format: 'number' },
    modlParalCnt: { required: '필수 입력 값 입니다.', maxLength: 10, format: 'number' },
    invrtrMakrCd: { required: '필수 입력 값 입니다.', maxLength: 10},
    invrtrModelCd: { required: '필수 입력 값 입니다.', maxLength: 10 },
    keaPhsTypeCd: { required: '필수 입력 값 입니다.', maxLength: 50 },
    keaTrckSysCd: { required: '필수 입력 값 입니다.', maxLength: 50 },
    invrtrCpct: { required: '필수 입력 값 입니다.', integerLen: 8, floatLen: 3, format: 'number' },
    pvPanlCpct: { required: '필수 입력 값 입니다.', integerLen: 13, floatLen: 3, format: 'number' },
    keaGrdConnCd: { required: '필수 입력 값 입니다.', maxLength: 50 },
    cntrctrNm: { required: '필수 입력 값 입니다.', maxLength: 50 },
    cntrctrTlno: { required: '필수 입력 값 입니다.', maxLength: 20 },
    cntrctrHpno: { required: '필수 입력 값 입니다.', maxLength: 20 },
    exworkDay: { required: '필수 입력 값 입니다.', format: 'date' },
    asExpDay: { required: '필수 입력 값 입니다.', format: 'date' },
    rmrk: { maxLength: 1024 },
    exworkCmpltDay: { required: '필수 입력 값 입니다.', format: 'date' },
    loraId: { maxLength: 20 },
    instlPlacNm: { required: '필수 입력 값 입니다.', maxLength: 50 },
}

//태양광 접속반 유효성 검사 규칙
const combRules = {
    eqprNm: { required: '필수 입력 값 입니다.', maxLength: 50 },
    arnoAdr: { required: '필수 입력 값 입니다.', maxLength: 100 },
    devNo: { required: '필수 입력 값 입니다.', maxLength: 20, format: 'number' },
    facNm: { required: '필수 입력 값 입니다.', maxLength: 50 },
    instlPlacNm: { required: '필수 입력 값 입니다.', maxLength: 50 },    
    chCnt: { required: '필수 입력 값 입니다.', maxLength: 10, format: 'number' },
    cntrctrNm: { required: '필수 입력 값 입니다.', maxLength: 50 },
    exworkDay: { required: '필수 입력 값 입니다.', format: 'date' },
    rmrk: { maxLength: 500 },
    instlConfDay: { required: '필수 입력 값 입니다.', format: 'date' },
    combBoxMakrCd: { required: '필수 입력 값 입니다.', maxLength: 10 },
    combBoxModelCd: { required: '필수 입력 값 입니다.', maxLength: 10 },
}

//태양광 기상반 유효성 검사 규칙
const prnmtrRules = {
    eqprNm: { required: '필수 입력 값 입니다.', maxLength: 50 },
    arnoAdr: { required: '필수 입력 값 입니다.', maxLength: 100 },
    devNo: { required: '필수 입력 값 입니다.', maxLength: 20, format: 'number' },
    facNm: { required: '필수 입력 값 입니다.', maxLength: 50 },
    instlPlacNm: { required: '필수 입력 값 입니다.', maxLength: 50 },
    prnmtrMakrCd: { required: '필수 입력 값 입니다.', maxLength: 10 },
    prnmtrModelCd: { required: '필수 입력 값 입니다.', maxLength: 10 },    
    cntrctrNm: { required: '필수 입력 값 입니다.', maxLength: 50 },
    exworkDay: { required: '필수 입력 값 입니다.', format: 'date' },
    rmrk: { maxLength: 500 },
    instlConfDay: { required: '필수 입력 값 입니다.', format: 'date' },
}

function TabPanel(props) {

    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index){
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function PvContainerS(props) {

    // toast ref 객체
    const toastRef = useRef();

    // Alert ref 객체
    const alertRef= useRef();

    const fileRef = useRef();

    const [tabIndex, setTabIndex] = useState(0);

    // 인버터 목록
    const [inverterList, setInverterList] = useState([]);
    // 접속반 목록
    const [connectorList, setConnectorList] = useState([]);
    // 기상반 목록
    const [weatherList, setWeatherList] = useState([]);

    // 인버터 유효성 검증 오류 갯수
    const [inverterErrorCnt, setInverterErrorCnt] = useState(0);
    // 접속반 유효성 검증 오류 갯수
    const [connectorErrorCnt, setConnectorErrorCnt] = useState(0);
    // 기상반 유효성 검증 오류 갯수
    const [weatherErrorCnt, setWeatherErrorCnt] = useState(0);

    //엑셀 파일 읽기
    const readExcel = (e) => {
        const file = fileRef.current.files[0];

        if (file !== undefined){
            
            const formData = new FormData();
            formData.append("file", file);
                    
            axiosInstance.post('/rems/pv/excel/read', formData, {headers: {'content-type': 'multipart/form-data'}}).then((Response)=>{            

                if (Response){
                    if(Response.request.status === 200){                             
                        createValidateList("001", Response.data.info.InvrtrFacHstList);                        
                        createValidateList("002", Response.data.info.combBoxFacHstList);                        
                        createValidateList("003", Response.data.info.prnmtrFacHstList);                        
                    }else{                              
                        alert("실패");
                    }                  
                }else{
                    alert("시스템 오류");
                }
                
            });  

            e.target.value = '';
        }
        
    }

    const handleTabChange = (e, newIndex) => {
        setTabIndex(newIndex);        
    };

    // 서버에 전송할 목록 정보 필터후 재생성
    const getFormData = (list) => {
        let data = [];

        list.map((item, index) => {
            let row = {};
            $.each(item, function (key, value) {  
                if (key.toString().indexOf('_v') === -1){
                    row[key] = value;
                }
            })
            data.push(row);
        });

        return data;
    }

    //저장
    const save = () => {    

        let errorCnt = 0;

        //인버터 유효성 검증
        errorCnt += createValidateList("001", inverterList);            
        //접속반 유효성 검증
        errorCnt += createValidateList("002", connectorList);            
        //기상반 유효성 검증
        errorCnt += createValidateList("003", weatherList);            

        if (errorCnt > 0){

            if (pvRef.current){
                pvRef.current.validate();
            }

            if (combRef.current){
                combRef.current.validate();
            }

            if (ardyRef.current){
                ardyRef.current.validate();
            }            
            
            toastRef.current.handleOpen("error", "입력된 양식에 잘못된 정보가 있습니다. 내용을 확인 해주세요. (* 오류가 발생한 영역 위에 마우스 커서 오버시 오류 내용을 확인 할 수 있습니다.)");                                                    

            return;
        }else{
            //서버 저장 요청
            replaceEmpty(inverterList);
            replaceEmpty(connectorList);
            replaceEmpty(weatherList);
            
            let inverterData = getFormData(inverterList);
            let combBoxData = getFormData(connectorList);
            let weatherData = getFormData(weatherList);            
            
            var data = {
                            invrtrFacListJsonString:JSON.stringify(inverterData), 
                            combBoxFacListJsonString:JSON.stringify(combBoxData), 
                            prnmtrFacListJsonString:JSON.stringify(weatherData),  
                            workSeq:props.workSeq, 
                            nrnGentCd:props.nrnGentCd
                        };

            setTimeout(() => {
                axiosInstance.post('/rems/pv/fac/hst', {...data}).then((Response)=>{            
                    if(Response.request.status === 200){     
                        alertRef.current.handleOpen("성공", "저장 되었습니다.");                    
                    }else{                              
                        alertRef.current.handleOpen("오류", "오류 입니다. 데이터를 저장 할 수 없습니다.)");                                        
                    }                  
                });          
            }, 1000);
            
        }        
    }

    //NULL 유니코드값 빈값으로 치환
    const replaceEmpty = (list) => {
        list.map((item) => {
            $.each(item, function (key, value) {  
                if (typeof value === "string"){
                    item[key] = value.replace(/\u0000/g, "");
                }                    
            })
        })
    }

    //유효성 검증 결과 데이터 생성 [markType:(001:인버터, 002:접속반, 003:일사량)]
    const createValidateList = (markType, list) => {

        let errCnt = 0;

        let newList = [];

        list.map((item, index) => {

            let validateRes = {};
            
            $.each(item, function (key, value) {  
                const res = validate(markType, key, value);                                    
                validateRes[key + "_v"] = res;  
                if (res.result === "error"){
                    errCnt++;
                }
            })

            Object.assign(item, validateRes);

            newList.push(item);
        });

        if (markType === "001"){
            setInverterList(newList);
            setInverterErrorCnt(errCnt);
        }else if (markType === "002"){
            setConnectorList(newList);
            setConnectorErrorCnt(errCnt);
        }else if (markType === "003"){
            setWeatherList(newList);
            setWeatherErrorCnt(errCnt);
        }
        
        return errCnt;
    }

    //유효성 검증 [markType:(001:인버터, 002:접속반, 003:일사량)]
    const validate = (makrType, key, value) => {

        let result = {isError: "success", errorMessage: ""};

        let rule = null;

        if (makrType === "001"){
            rule = pvRules[key];
        }else if (makrType === "002"){
            rule = combRules[key];
        }else if (makrType === "003"){
            rule = prnmtrRules[key];
        }

        if (value == undefined || value == null){
            value = "";
        }

        if (rule != undefined){
            $.each(rule, function (ruleKey, ruleVal) {
                if (ruleKey === "required"){
                    //필수값 체크
                    if (value == undefined || value == null || value == ""){
                        result["result"] = "error";
                        result["errorMessage"] = result["errorMessage"] !== "" ? result["errorMessage"] + "\r\n" + ruleVal : ruleVal;                        
                    }
                }else if (ruleKey.indexOf('maxLength') > -1){
                    if (value.length > ruleVal){
                        result["result"] = "error";
                        result["errorMessage"] = result["errorMessage"] !== "" ? result["errorMessage"] + "\r\n" + "최대 " + ruleVal + "자까지 입력이 가능 합니다." : "최대 " + ruleVal + "자까지 입력이 가능 합니다.";                        
                    }
                }else if (ruleKey === "format"){
                    if (ruleVal === "date"){
                        let regex =  RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
                        if (!regex.test(value)){
                            result["result"] = "error";
                            result["errorMessage"] = result["errorMessage"] !== "" ? result["errorMessage"] + "\r\n" + "날짜형식('YYYY-MM-DD')을 입력 해주세요." : "날짜형식('YYYY-MM-DD')을 입력 해주세요.";                                                    
                        }
                    }else if (ruleVal === "number"){
                        let regex = /^[0-9.]+$/;
                        if (!regex.test(value)){
                            result["result"] = "error";
                            result["errorMessage"] = result["errorMessage"] !== "" ? result["errorMessage"] + "\r\n" + "숫자만 입력 해주세요." : "숫자만 입력 해주세요.";                                                    
                        }
                    }
                }else if (ruleKey === "integerLen"){
                    let regex = RegExp('^[0-9]{1,' + Number(ruleVal) + '}$');    
                    
                    if (!regex.test(parseInt(value))){                        
                        result["result"] = "error";
                        result["errorMessage"] = result["errorMessage"] !== "" ? result["errorMessage"] + "\r\n" + "정수형 자릿수는 최대 " + ruleVal + "자까지 입력이 가능 합니다." : "정수형 자릿수는 최대 " + ruleVal + "자까지 입력이 가능 합니다.";                                                    
                    }
                }else if (ruleKey === "floatLen"){
                    
                    let index = value.toString().indexOf(".");

                    if (index > -1){
                        let val = value.toString().substr(index+1, value.length);                        

                        let regex = RegExp('^[0-9]{1,' + Number(ruleVal) + '}$');                                        

                        if (!regex.test(val)){                        
                            result["result"] = "error";
                            result["errorMessage"] = result["errorMessage"] !== "" ? result["errorMessage"] + "\r\n" + "소수점 자릿수는 최대 " + ruleVal + "자까지 입력이 가능 합니다." : "소수점 자릿수는 최대 " + ruleVal + "자까지 입력이 가능 합니다.";                                                    
                        }
                    }
                    
                }
            })
        }

        return result;
    }

    useEffect(() => {

        //태양광 설비 이력 목록 조회
        axiosInstance.get('/rems/pv/fac/hst', {params: {workSeq: props.workSeq}}).then((Response)=>{
            if (Response.request.status === 200) {        
                
                // 인버터 목록
                createValidateList("001", Response.data.info.invrtrFacHstList);                        
                // 접속반 목록
                createValidateList("002", Response.data.info.combBoxFacHstList);                        
                // 기상반 목록
                createValidateList("003", Response.data.info.prnmtrFacHstList);  
            }
        });

    }, []);


    const pvRef = useRef();    

    const combRef = useRef();

    const ardyRef = useRef();

    return (
        <div>
            <Grid container alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 0, overflow: 'hidden', borderRadius:0 }}>
                        
                <Grid xs={6}>        
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab className={inverterErrorCnt > 0 ? "error" : ""} label={inverterErrorCnt > 0 ? "인버터(" + inverterErrorCnt + ")" : "인버터"} 
                             style={{fontSize:14, color: (inverterErrorCnt > 0 ? "red" : "")}} 
                             {...a11yProps(0)}
                        />
                        <Tab className={connectorErrorCnt > 0 ? "error" : ""} label={connectorErrorCnt > 0 ? "접속반(" + connectorErrorCnt + ")" : "접속반"} 
                             style={{fontSize:14, color: (connectorErrorCnt > 0 ? "red" : "")}} 
                             {...a11yProps(1)}
                        />
                        <Tab className={weatherErrorCnt > 0 ? "error" : ""} label={weatherErrorCnt > 0 ? "기상반(" + weatherErrorCnt + ")" : "기상반"} 
                             style={{fontSize:14, color: (weatherErrorCnt > 0 ? "red" : "")}} 
                             {...a11yProps(2)}
                        />
                    </Tabs>                            
                </Grid>  
                <Grid container justifyContent="flex-end" xs={6} sx={{pr:5}}>         
                    
                    <Button label="delete" variant="contained" component="label" size="large" color="success" sx={{ ml: 2, width:186 }} startIcon={<FileUploadIcon />} >엑셀 파일 불러오기
                        <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={readExcel} ref={fileRef} hidden /> 
                    </Button>  
                    <Button variant="contained" size="large" color="primary" sx={{ ml: 2 }} startIcon={<ListIcon />} onClick={save}>저장</Button>                                                
                </Grid>                  
                    
            </Grid>
            <Grid container alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p:0, borderRadius:0  }}>
                        
                <TabPanel value={tabIndex} index={0} style={{width:'100%', overflow: 'hidden', padding:0}}>
                    {/* 인버터 */}
                    {/* <PvInvrtr list={inverterList}/> */}
                    <PvInvrtrS ref={pvRef} list={inverterList}/>
                </TabPanel>
                <TabPanel value={tabIndex} index={1} style={{width:'100%', overflow: 'hidden', padding:0}}>
                    {/* 접속반 */}
                    <PvCombS ref={combRef} list={connectorList}/>
                </TabPanel>
                <TabPanel value={tabIndex} index={2} style={{width:'100%', overflow: 'hidden', padding:0}}>
                    {/* 기상반 */}
                    <PvArdyS ref={ardyRef} list={weatherList}/>
                </TabPanel>
            </Grid>

            <Alert ref={alertRef} />

            <Toast ref={toastRef} />
        </div>
    );
}

export default PvContainerS;