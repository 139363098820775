import React, { useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';

export default function FileDownload() {

    // 엑셀 샘플 다운로드
    const excelDownload = (type) => {

        let url = `${process.env.REACT_APP_API_URL}`;
        
        if(type === "100"){
            //태양광
            url += "/rems/pv/excel/download";
        }else if (type === "200"){
            //지열
            url += "/rems/heath/excel/download";
        }else if (type === "300"){
            //태양열
            url += "/rems/st/excel/download";
        }else if (type === "310"){
            //태양열(자연)
            url += "/rems/stn/excel/download";
        }else if (type === "400"){
            //연료전지
            url += "/rems/fuel/excel/download";
        }else{
            //기타
            url += "";
        }

        window.location.href = url;
    }

    return (
        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)" }}>

            <Grid container spacing={2} sx={{ height: '100%' }}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 0 }}>
                    <div style={{ "marginBottom": "16px" }}></div>
                    <div style={{ "marginBottom": "28px" }}>
                        <p fontWeight="600" className="css-1pr8naw">파일 다운로드</p>
                        <p className="css-3vvtry">신재생 설비 등록 양식</p>
                    </div>
                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1 }}>
                    <Grid xs={2} sm={2} md={2} lg={2} xl={2} sx={{border:"1px solid #d3dbe9", backgroundColor:"#474866", color:"#ffffff", textAlign:"center"}}>
                        No
                    </Grid>
                    <Grid xs={10} sm={10} md={10} lg={10} xl={10} sx={{border:"1px solid #d3dbe9", backgroundColor:"#474866", color:"#ffffff"}}>
                        파일
                    </Grid>

                    <Grid xs={2} sm={2} md={2} lg={2} xl={2} sx={{border:"1px solid #d3dbe9", backgroundColor:"#fdfdfd", color:"#000000", textAlign:"center"}}>
                        1
                    </Grid>
                    <Grid xs={10} sm={10} md={10} lg={10} xl={10} sx={{border:"1px solid #d3dbe9", backgroundColor:"#fdfdfd", color:"#000000"}}>
                        <a href="#" onClick={()=>excelDownload('100')}>태양광 설비 등록 양식 다운로드 (태양광_설비등록자료.xlsx)</a>
                    </Grid>

                    <Grid xs={2} sm={2} md={2} lg={2} xl={2} sx={{border:"1px solid #d3dbe9", backgroundColor:"#fdfdfd", color:"#000000", textAlign:"center"}}>
                        2
                    </Grid>
                    <Grid xs={10} sm={10} md={10} lg={10} xl={10} sx={{border:"1px solid #d3dbe9", backgroundColor:"#fdfdfd", color:"#000000"}}>
                        <a href="#" onClick={()=>excelDownload('300')}>태양열 설비 등록 양식 다운로드 (태양열_설비등록자료.xlsx)</a>
                    </Grid>

                    <Grid xs={2} sm={2} md={2} lg={2} xl={2} sx={{border:"1px solid #d3dbe9", backgroundColor:"#fdfdfd", color:"#000000", textAlign:"center"}}>
                        3
                    </Grid>
                    <Grid xs={10} sm={10} md={10} lg={10} xl={10} sx={{border:"1px solid #d3dbe9", backgroundColor:"#fdfdfd", color:"#000000"}}>
                        <a href="#" onClick={()=>excelDownload('310')}>태양열 자연 설비 등록 양식 다운로드 (태양열(자연)_설비등록자료.xlsx)</a>
                    </Grid>

                    <Grid xs={2} sm={2} md={2} lg={2} xl={2} sx={{border:"1px solid #d3dbe9", backgroundColor:"#fdfdfd", color:"#000000", textAlign:"center"}}>
                        4
                    </Grid>
                    <Grid xs={10} sm={10} md={10} lg={10} xl={10} sx={{border:"1px solid #d3dbe9", backgroundColor:"#fdfdfd", color:"#000000"}}>
                        <a href="#" onClick={()=>excelDownload('200')}>지열 설비 등록 양식 다운로드 (지열_설비등록자료.xlsx)</a>
                    </Grid>

                    <Grid xs={2} sm={2} md={2} lg={2} xl={2} sx={{border:"1px solid #d3dbe9", backgroundColor:"#fdfdfd", color:"#000000", textAlign:"center"}}>
                        5
                    </Grid>
                    <Grid xs={10} sm={10} md={10} lg={10} xl={10} sx={{border:"1px solid #d3dbe9", backgroundColor:"#fdfdfd", color:"#000000"}}>
                        <a href="#" onClick={()=>excelDownload('400')}>연료전지 설비 등록 양식 다운로드 (연료전지_설비등록자료.xlsx)</a>
                    </Grid>
                </Grid>

            </Grid>

        </div>
    );
}