import React, {useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomTextField from "../components/ui/CustomTextField";
import { Box, Modal, Divider, Button } from '@mui/material';
import CustomAutocomplete from "../components/ui/CustomAutocomplete";
import axiosInstance from '../components/util/AxiosUtil';
import CustomDatatable from '../components/ui/CustomDatatable';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import Toast from '../components/ui/Toast';
import { viewModeType } from '../components/const/CommCode'
import Confirm from '../components/ui/Confirm';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//검색 요청 기본 데이터
const defaultFindValues = {
    //프로젝트, 사이트, 에너지원, 연동유형, 설치계획서번호
    pjtSeq : '',
    siteSeq : '',
    nrnGentCd : '',
    connTypeCd : '',
    instlPlndocNm : ''
}

//모달 기본데이터
let defaultValues = {
   
	rtuSeq : '',        /* RTU 일련번호 */
	pjtSeq : '',        /* 프로젝트 일련번호 */
	siteSeq : '',       /* 사이트 일련번호 */ 
	nrnGentCd : '',     /* 에너지원(신재생 발전코드) */
	connTypeCd : '',    /* 연동유형코드 */
    suplTypeCd : '',    /* 공급유형코드 */
	connStsCd : '',     /* 연동상태코드 */
    rtuRemotInfo : '',  /* RTU 원경정보 */
    accId : '',         /* 계정 ID */
    keaId : '',         /* 공단 ID */
    adr : '',           /* 주소 */
    lat : 0,            /* 위도 */
    lng : 0,            /* 경도 */
    connDt : null,        /* 연동일시 */
    loraId : '',        /* lora ID */
    rmark : '',          /* 비고 */
    instlPlndocNo : ''  /* 설치계획서 번호 */
}

function Rtu() {

    //[검색]조회 기본세팅
    const { 
        handleSubmit : find_handleSubmit, 
        control : find_control, 
        getValues: find_getValues, 
        setValues: find_setValues,
        formState: { errors: find_errors } 
    } = useForm( {defaultFindValues} );

    // [modal] 등록,수정 Form 기본세팅
    const { 
        handleSubmit, 
        getValues, 
        reset, 
        control, 
        formState: { errors } 
    } = useForm( {defaultValues} );

    // 데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);

    //******************* 검색 상태 세팅 ****************************** */
    // 검색필터
    const [filterData, setFilterData] = useState( {defaultFindValues} );
    // [검색] 프로젝트
    const [pjtList, setPjtList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    // [검색] 사이트
    const [siteList, setSiteList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    // [검색] 에너지원
    const [nrnGentCdList, setNrnGentCdList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    // [검색] 연동유형
    const [connTypeCdList, setConnTypeCdList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);

    //******************* Modal관련 상태 세팅 ****************************** */
    //[modal] RTU 일련번호
    const [modalRtuList, setModalRtuList] = useState('');
    // [modal] 프로젝트
    const [modalPjtList, setModalPjtList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    // [modal] 사이트
    const [modalSiteList, setModalSiteList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    // [modal] 에너지원
    const [modalNrnList , setModalNrnList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    // [modal] 연동유형
    const [modalConnTypeList , setModalConnTypeList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    // [modal] 공급유형
    const [modalSupList , setModalSupList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    // [modal] 연동상태
    const [modalConnStsList, setModalConnStsList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);

    //등록버튼 시퀀스값 생성
    const [nextRtuSeq, setNextRtuSeq] = useState();
    //confrim ref 객체
    const confirmRef = useRef();
    //서버 요청 상태 정보
    const [viewMode, setViewMode] = useState(viewModeType.New);
    //모달 노출 상태 정보
    const [open, setOpen] = useState(false);
    //toast ref 객체
    const toastRef = useRef();

    useEffect(() => {
        //검색목록
        makeSearchList();

        //생성 될 시퀀스값 가져오기
        nextSeqSearch();
    }, []);

    //다음 시퀀스값 가져오기
    const nextSeqSearch = () => {
        //seq 조회
        axiosInstance.get('/getNextRtuSeq').then((Response) => {
            if(Response.request.status === 200) {
                setNextRtuSeq(Response.data.rtuSeq);
            }
        })
    }

    //검색창 목록 생성
    const makeSearchList = () => {

        //프로젝트 검색 목록 생성
        axiosInstance.get('/pjtCodeList').then((Response)=>{
            if (Response.request.status === 200) {  

                const list =  Response.data.list;
                const newList = [{dtlCd: "", dtlCdNm: "전체"}, ...list];

                setPjtList(newList);   
                setModalPjtList(list);
            }
        });

        //사이트 검색 목록 생성
        axiosInstance.get('/siteCodeList', {params: {pjtSeq:''}}).then((Response)=>{
            if (Response.request.status === 200) {                  

                const list =  Response.data.list;
                const newList = [{dtlCd: "", dtlCdNm: "전체"}, ...list];

                setSiteList(newList);  
                setModalSiteList(list); 
            }
        });

        //에너지원 검색 목록 생성
        axiosInstance.get('/codeList', {params: {grpCd: 'NRN_GENT_CD'}}).then((Response) => {
            if(Response.request.status === 200) {

                const list =  Response.data.list;
                const newList = [{dtlCd: "", dtlCdNm: "전체"}, ...list];

                setNrnGentCdList(newList);
                setModalNrnList(list);
            }
        });

        // 연동유형 검색 목록 생성
        axiosInstance.get('/codeList', {params: {grpCd: 'CONN_TYPE_CD'}}).then((Response) => {
            if(Response.request.status === 200) {

                const list =  Response.data.list;
                const newList = [{dtlCd: "", dtlCdNm: "전체"}, ...list];

                setConnTypeCdList(newList);
                setModalConnTypeList(newList);
            }
        });

        // 공급유형 목록 생성
        axiosInstance.get('/codeList', {params: {grpCd: 'SUPL_TYPE_CD'}}).then((Response) => {
            if(Response.request.status === 200) {

                const list =  Response.data.list;
                const newList = [{dtlCd: "", dtlCdNm: "전체"}, ...list];
                setModalSupList(newList);
            }
        });

        // 연동상태 목록 생성
        axiosInstance.get('/codeList', {params: {grpCd: 'CONN_STS_CD'}}).then((Response) => {
            if(Response.request.status === 200) {

                const list =  Response.data.list;
                setModalConnStsList(list);
            }
        });
    }


    //등록버튼 클릭 이벤트
    const regClickEvent = () => {

        //모달 초기화
        reset(defaultValues = {
            rtuSeq : nextRtuSeq, 
            pjtSeq : '',        
            siteSeq : '',      
            nrnGentCd : '',     
            connTypeCd : '',  
            suplTypeCd : '',
            connStsCd : '',   
            rtuRemotInfo : '',  
            accId : '',     
            keaId : '',      
            adr : '',          
            lat : 0,           
            lng : 0,           
            connDt : null,       
            loraId : '',        
            rmark : ''
        });

        //등록으로 상태변경
        setViewMode(viewModeType.New);

        //모달 열기
        setOpen(true);
    }

    function dataReload(data){   
        setPageFixed(false);
        setFilterData(data);  

    }
    const dataload = (data) =>{
        setPageFixed(true);
        setFilterData(data);        
    }

    //테이블 컬럼정보
    const columns = [
        { id: "rtuSeq", data: "rtuSeq", title: 'RTU 일련번호' ,width: '3%',className: "text-center", responsivePriority: 1 },
        { id: "pjtNm", data: "pjtNm", title: '프로젝트', width: '12%', className: "text-center",responsivePriority: 2 },
        { id: "siteNm", data: "siteNm", title: '사이트', width:'15%', className: "text-center", responsivePriority: 3 },
        { id: "nrnGentNm", data: "nrnGentNm", title: '에너지원', width:'8%', className: "text-center", responsivePriority: 4 },
        { id: "connTypeNm", data: "connTypeNm", title: '연동유형', width:'10%',className: "text-center", responsivePriority: 5 },
        { id: "rtuRemotInfo", data: "rtuRemotInfo", title: 'RTU원격정보', width:'12%',className: "text-center", responsivePriority: 6 },
        { id: "instlPlndocNo", data: "instlPlndocNo", title: '설치계획서번호', width:'10%',className: "text-center", responsivePriority: 7 },
        { id: "loraId", data: "loraId", title: 'lora ID', width:'10%',className: "text-center", responsivePriority: 8 },
        { id: "accId", data: "accId", title: '계정 ID', width:'10%',className: "text-center", responsivePriority: 9 },
        { id: "rtuSeq", data: "rtuSeq", title: '관리', width:'10%',className: "text-center", responsivePriority: 0, orderable:false }
    ]

    const columnDefs = [
        //rtu 원격정보 줄바꿈 처리
        {
            className: "text-center p-0", "targets":[5], render: function(data, type, row, meta){
                let str = row.rtuRemotInfo;
                let substrIdx =  str.indexOf('any');
                
                let teamInfo = str.substring(0,substrIdx);
                let anyInfo = str.substring(substrIdx,str.length);

                let result = "<div>" + teamInfo + "</div>";
                result += "<div>" + anyInfo + "</div>";

                return result;
            }
        },
        //수정, 삭제버튼
        {
            className: "text-center p-0", "targets":[9], render: function(data, type, row, meta){
                let customButton = "<button class='data-btn' data-seq='" + row.rtuSeq + "' data-type='update' style='margin:2px; padding:3px;'>수정</button>";

                customButton += "<button class='data-btn' data-seq='" + row.rtuSeq + "' data-type='delete' style='margin:2px; padding:3px;'>삭제</button>";                                    
                
                return customButton;
            }
        }
    ]

    //테이블 (수정, 삭제버튼) 클릭 콜백함수 
    const buttonClick = (e) => {

        if(e.target.dataset.type === "update") {

            axiosInstance.get('/rtu', {params: {rtuSeq: e.target.dataset.seq}}).then((Response)=>{
            
                if(Response.request.status === 200){
                    setPageFixed(false);
                    setViewMode(viewModeType.Edit);
                    reset(Response.data.info);
                    setOpen(true);
                }else{
                    toastRef.current.handleOpen("error", "오류입니다. 데이터를 조회 할 수 없습니다.");
                }
            });   

        }else if (e.target.dataset.type === "delete"){            
            confirmRef.current.handleOpen("RTU 정보 삭제", "RTU 정보를 삭제 합니다. 계속 진행 하시겠습니까?", e);
        }   

    }
    const del = (e) =>{
        axiosInstance.delete('/rtu', {params: {rtuSeq: e.target.dataset.seq}}).then((Response)=>{
            
            if(Response.request.status === 200){    
                toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
                dataReload(find_getValues());
            }else{                              
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }                  
        }); 
    }
    const onSubmit = (data) => {

        if (viewMode === viewModeType.New){
            axiosInstance.post('/rtu', {...data}).then((Response)=>{            
                if(Response.request.status === 200 ){  
                    
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                        setOpen(false);
                        dataReload(find_getValues());
                    
                    }else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage );
                    }
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            });        
        }else if (viewMode === viewModeType.Edit){
            axiosInstance.put('/rtu', {...data}).then((Response)=>{            
                if(Response.request.status === 200){     
                    toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                    setOpen(false);
                    dataReload(find_getValues());
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }
            });
        }

    }

    //style
    const style1={
        "padding": "16px 24px", 
        "color": "rgb(68, 89, 110)", 
        "height": "100%"
    }
    const style2={
        "marginBottom": "28px",
        "marginTop":"16px"
    }
    //input css style
    const modalFullStyle = { width: '100%', 'textAlign': 'center', display: 'flex', mt:2, pr:1, pl:1 };
    const modalHalfStyle = { width: '50%', 'textAlign': 'center', mt:2, pr:1, pl:1};

    return (
        <div style={style1}>

            <Grid container spacing={2}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>
                    <div style={style2}>
                        <p fontWeight="600" className="css-1pr8naw">RTU 관리</p>
                        <p className="css-3vvtry">PoSE / 신재생서비스 / RTU 관리</p>
                    </div>
                </Grid>
                
            </Grid>
            {/* 검색창 */}
            <Grid container 
                  alignItems="center" 
                  xs={12} sm={12} md={12} lg={12} xl={12} 
                  className="page-filter" 
                  sx={{p:0}}
            >
                {/* 프로젝트 */}
                <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                    <CustomAutocomplete name="pjtSeq"              
                                        label="프로젝트"           
                                        options={pjtList}
                                        value="dtlCd"
                                        text="dtlCdNm"             
                                        control={find_control}
                                        getValues={find_getValues}
                                        errors={find_errors.pjtSeq}
                    />
                </Grid>

                {/* 사이트 */}
                <Grid xs={12} sm={12} md={6} lg={3} xl={3} sx={{ p: 2 }}>
                    <CustomAutocomplete name="siteSeq"              
                                        label="사이트"           
                                        options={siteList}
                                        value="dtlCd"
                                        text="dtlCdNm"             
                                        control={find_control}
                                        getValues={find_getValues}
                                        errors={find_errors.siteSeq}
                    />
                </Grid>

                {/* 에너지원 */}
                <Grid xs={12} sm={12} md={6} lg={2} xl={1.5} sx={{ p: 2 }}>
                    <CustomAutocomplete name="nrnGentCd"              
                                        label="에너지원"           
                                        options={nrnGentCdList}
                                        value="dtlCd"
                                        text="dtlCdNm"             
                                        control={find_control}
                                        getValues={find_getValues}
                                        errors={find_errors.nrnGentCd}
                    />
                </Grid>
                
                {/* 연동유형 */}
                <Grid xs={12} sm={12} md={6} lg={2} xl={1.5} sx={{ p: 2 }}>
                    <CustomAutocomplete name="connTypeCd"              
                                        label="연동유형"           
                                        options={connTypeCdList}
                                        value="dtlCd"
                                        text="dtlCdNm"             
                                        control={find_control}
                                        getValues={find_getValues}
                                        errors={find_errors.connTypeCd}
                    />
                </Grid>

                {/* 설치 계획서 번호 */}
                <Grid xs={12} sm={12} md={6} lg={2} xl={1.5} sx={{ p: 2 }}>
                    <CustomTextField fullWidth 
                                     name="instlPlndocNo" 
                                     label="설치계획서 번호" 
                                     control={find_control}
                                     onKeyDown={(e) => {
                                        if (e.key === 'Enter'){                                    
                                            dataReload(find_getValues());
                                        }
                                     }}
                    />                          
                </Grid>

                {/* 조회, 등록버튼*/}
                <Grid container 
                      textAlign={'start'} 
                      alignItems="center" 
                      display={'flex'}
                      xs={12} sm={12} md={6} lg={4} xl={2.5} 
                >
                    <Button variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ ml:2, height:'52px' }}  
                            startIcon={<SearchIcon />}
                            onClick={find_handleSubmit(dataload)}
                    >조회
                    </Button>
                    <Button variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ m:2, height:'52px' }} 
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={regClickEvent}
                    >등록
                    </Button>
                </Grid>
            </Grid>

            {/* table */}
            <Grid container 
                    xs={12} sm={12} md={12} lg={12} xl={12} 
                    className="page-filter" 
                    sx={{ p: 2, mt: 3 }}
            >
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CustomDatatable id="table-list-0"                //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                     width="100%"                     //테이블 너비
                                     url="/rtuList"                  //ajax url
                                     columns={columns}                //테이블 컬럼 정의
                                     columnDefs={columnDefs}          //Custom 컬럼 정의
                                     filter={filterData}              //ajax 검색 필터 정의
                                     paging={true}                    //페이징 여부
                                     pageLength="10"                  //페이징 수
                                     buttonClick ={buttonClick}       //버튼 클릭 콜백함수
                                     pageFixed={pageFixed}            //페이징 유지 여부
                                     order={[[0, 'asc']]}             //기본 정렬
                    />
                </Grid>
            </Grid>

            {/* 등록 / 수정 모달 */}
            <Modal open={open} 
                    onClose={e => setOpen(false)} 
                    aria-labelledby="parent-modal-title" 
                    aria-describedby="parent-modal-description" 
            >
                <Box className="lgModal flex-w">
                    <h3 id="parent-modal-title" className="limiter">RTU 정보</h3>
                    <Divider sx={{ mt:2, width: '100%'}} />
                    {/* RTU 일련번호 */}
                    <Grid sx={modalFullStyle}>
                        <CustomTextField 
                                        fullWidth
                                        sx={{width:'50%', pr:1}}
                                        name="rtuSeq"                
                                        label="*RTU 일련번호"
                                        size="small"
                                        disabled
                                        rules={{ required: "RTU일련번호가 필요합니다." }}
                                        control={control}                                                     
                                        errors={errors.rtuSeq}
                        /> 
                    </Grid>
                    <Grid sx={modalHalfStyle}>
                        {/* 프로젝트 */}
                        <CustomAutocomplete fullWidth
                                            name="pjtSeq"              
                                            label="*프로젝트"
                                            size="small"
                                            rules={{ required: "프로젝트를 선택 해주세요." }}            
                                            options={modalPjtList}          
                                            value="dtlCd"              
                                            text="dtlCdNm"             
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.pjtSeq}
                        />
                    </Grid>
                    <Grid sx={modalHalfStyle}>
                        {/* 사이트 */}
                        <CustomAutocomplete fullWidth
                                            name="siteSeq"              
                                            label="*사이트"
                                            rules={{ required: "사이트를 선택 해주세요." }}            
                                            options={modalSiteList}          
                                            value="dtlCd"              
                                            text="dtlCdNm"    
                                            size="small"         
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.siteSeq}
                        />
                    </Grid>
                    <Grid sx={modalHalfStyle}>
                        {/* 에너지원 */}
                        <CustomAutocomplete fullWidth
                                            name="nrnGentCd"              
                                            label="*에너지원"
                                            rules={{ required: "에너지원을 선택 해주세요." }}            
                                            options={modalNrnList}          
                                            value="dtlCd"              
                                            text="dtlCdNm"   
                                            size="small"          
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.nrnGentCd}
                        />
                    </Grid>
                    <Grid sx={modalHalfStyle}>
                        {/* 연동유형 */}
                        <CustomAutocomplete fullWidth
                                            name="connTypeCd"              
                                            label="연동유형"
                                            options={modalConnTypeList}          
                                            value="dtlCd"              
                                            text="dtlCdNm"  
                                            size="small"           
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.connTypeCd}
                        />
                    </Grid>
                    
                    <Grid sx={modalHalfStyle}>
                        {/* 공급유형 */}
                        <CustomAutocomplete fullWidth
                                            name="suplTypeCd"              
                                            label="공급유형"        
                                            options={modalSupList}          
                                            value="dtlCd"              
                                            text="dtlCdNm"    
                                            size="small"         
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.suplTypeCd}
                        />
                    </Grid>
                    <Grid sx={modalHalfStyle}>
                        {/* 연동상태 */}
                        <CustomAutocomplete fullWidth
                                            name="connStsCd"              
                                            label="연동상태"
                                            options={modalConnStsList}          
                                            value="dtlCd"              
                                            text="dtlCdNm"             
                                            control={control}         
                                            size="small" 
                                            getValues={getValues}      
                                            errors={errors.connStsCd}
                        />
                    </Grid>
                    {/* RTU 원격정보 */}
                    <Grid sx={modalFullStyle}>
                        <CustomTextField fullWidth
                                        name="rtuRemotInfo"                
                                        label="RTU 원격정보"
                                        multiline
                                        rows={'2'}
                                        control={control}                                                     
                                        errors={errors.rtuRemotInfo}
                        /> 
                    </Grid>
                    {/* 계정아이디 */}
                    <Grid sx={modalHalfStyle}>
                        <CustomTextField fullWidth
                                    name="accId"                
                                    label="계정아이디"
                                    control={control}         
                                    size="small"                                            
                                    errors={errors.accId}
                        /> 
                    </Grid>
                    {/* 공단아이디 */}
                    <Grid sx={modalHalfStyle}>
                        <CustomTextField fullWidth
                                    name="keaId"                
                                    label="공단아이디"
                                    size="small"
                                    control={control}                                                     
                                    errors={errors.keaId}
                        /> 
                    </Grid>
                    
                    {/* 주소 */}
                    <Grid sx={modalHalfStyle}>
                        <CustomTextField fullWidth
                                name="adr"                
                                label="주소"
                                size="small"
                                control={control}
                                errors={errors.adr}
                        /> 
                    </Grid>
                    <Grid sx={modalHalfStyle}>
                        <CustomTextField fullWidth
                                name="instlPlndocNo"                
                                label="설치계획서번호"
                                control={control}   
                                size="small"                                                 
                                errors={errors.instlPlndocNo}
                        /> 
                    </Grid>
                    <Grid sx={modalHalfStyle}>
                        <CustomTextField fullWidth
                                name="lat"                
                                label="위도"
                                control={control}   
                                size="small"                                                 
                                errors={errors.lat}
                        /> 
                    </Grid>
                    <Grid sx={modalHalfStyle}>
                        <CustomTextField fullWidth
                            name="lng"                
                            label="경도"
                            control={control}                  
                            size="small"                                   
                            errors={errors.lng}
                        /> 
                    </Grid>
                    {/* 연동일시 */}
                    <Grid sx={modalHalfStyle}>
                        <LocalizationProvider 
                            dateAdapter={AdapterDayjs} 
                            adapterLocale="ko"
                        >
                            <Controller 
                                name='connDt'
                                control={control}                                         
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                        label={['연동일시']}
                                        inputFormat="YYYY-MM-DD"
                                        value={value}
                                        openTo="year"
                                        views={['year', 'month', 'day']}
                                        onChange={(newValue) =>  {
                                            if (newValue){
                                                return onChange(newValue.format("YYYYMMDD"));
                                            }else{
                                                return onChange(newValue);
                                            }
                                        }} 
                                        renderInput={(params) =>  <TextField fullWidth {...params} size="small"/>}
                                        sx={{width:'100%'}}
                                    />
                                )} 
                            />    
                        </LocalizationProvider>
                                              
                        {/* <CustomDatePicker name="connDt" label="연동일시" control={control} /> */}
                        
                    </Grid>
                    <Grid sx={modalHalfStyle}>
                        <CustomTextField fullWidth
                                        name="loraId"                
                                        label="LORA ID"
                                        control={control}     
                                        size="small"                                                
                                        errors={errors.loraId}
                        />  
                    </Grid>
                    {/* 비고 */}
                    <Grid sx={modalFullStyle}>
                        <CustomTextField fullWidth
                                name="rmark"                
                                label="비고"
                                multiline
                                rows={'2'}
                                control={control}                                                     
                                errors={errors.rmark}
                        /> 
                    </Grid>
                    <Divider sx={{ mt:2, width: '100%'}} />
                    
                    <Grid sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', mt:2}}>
                            <Button variant="contained" 
                                    size="large" 
                                    color="primary" 
                                    startIcon={<SaveAltIcon />} 
                                    onClick={handleSubmit(onSubmit)}
                            >저장</Button>
                            <Button variant="contained" 
                                    size="large" 
                                    color="primary" 
                                    startIcon={<CloseIcon />} 
                                    sx={{ ml: 2 }} 
                                    onClick={e => setOpen(false)}
                            >닫기</Button>
                    </Grid>
                </Box>
            </Modal>
            <Toast ref={toastRef} />
            <Confirm ref={confirmRef} ok={del} />
        </div>
    );
}

export default Rtu;