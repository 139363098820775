import React, { forwardRef } from 'react'
import defaultProfile from "../../../assets/image/noimage.gif"

const KnrecContent_2 = forwardRef((props, ref) => {

    const handleImgError = (e) => {        
        e.target.src = defaultProfile;
    }   

    return (
        <table className="print-ref" ref={ref}>
            <thead>
                <tr>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr style={{width:'210mm'}}>
                    <td className='title' colSpan="4">모니터링 RTU 설치 사진대지</td>
                </tr>                
                <tr style={{width:'210mm'}}>
                    <td className="cell" style={{width:'35mm', textAlign:'center', verticalAlign:"middle"}}>설치자명</td>
                    <td className="cell" style={{width:'120mm', verticalAlign:"middle"}}>{props.data.siteNm}</td>
                    <td className="cell" style={{width:'25mm', textAlign:'center', verticalAlign:"middle"}}>설치계획번호</td>
                    <td className="cell" style={{width:'30mm',verticalAlign:"middle"}}>{props.data.instlPlndocNo}</td>                    
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className="cell" style={{width:'35mm', height:'20mm', textAlign:'center', verticalAlign:"middle"}}>CID</td>
                    <td className="cell" colSpan="3" style={{width:'70mm', verticalAlign:"middle"}}>{props.data.cids}</td>                    
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className="cell" style={{width:'35mm', height:'20mm', textAlign:'center',verticalAlign:"middle"}}>주소</td>
                    <td className="cell" colSpan="3" style={{width:'70mm', verticalAlign:"middle"}}>{props.data.sido + " " + props.data.sigungu}</td>                    
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className="cell" colSpan="2" style={{width:'50%', height:'90mm', textAlign:'center'}}>
                        <img src={props.image001.blod ? props.image001.blod : ""} alt="이미지" height="100%" width="100%" onError={handleImgError}/>                                    
                    </td>
                    <td className="cell" colSpan="2" style={{width:'50%', height:'90mm', textAlign:'center'}}>
                        <img src={props.image002.blod ? props.image002.blod : ""} alt="이미지" height="100%" width="100%" onError={handleImgError}/>                                    
                    </td>
                </tr>
                <tr style={{width:'210mm'}}>
                    <td className="cell" colSpan="2" style={{width:'50%', textAlign:'center', verticalAlign:"middle"}}>
                        RTU 설치 사진
                    </td>
                    <td className="cell" colSpan="2" style={{width:'50%', textAlign:'center', verticalAlign:"middle"}}>
                        외함 전경 사진
                    </td>
                </tr>

                <tr style={{width:'210mm'}}>
                    <td className="cell" colSpan="2" rowSpan="2" style={{width:'50%', textAlign:'center', verticalAlign:'middle'}}>
                        <img src={props.image003.blod ? props.image003.blod : ""} alt="이미지" height="180mm" width="100%" onError={handleImgError}/>                                    
                    </td>
                    <td className="cell" colSpan="2" style={{width:'50%', height:'45mm', textAlign:'center'}}>
                        <img src={props.image004.blod ? props.image004.blod : ""} alt="이미지" height="100%" width="100%" onError={handleImgError}/>                                    
                    </td>                    
                </tr>

                <tr style={{width:'210mm'}}>
                    <td className="cell" colSpan="2" style={{width:'50%', height:'45mm', textAlign:'center'}}>
                        <img src={props.image005.blod ? props.image005.blod : ""} alt="이미지" height="100%" width="100%" onError={handleImgError}/>                                    
                    </td>
                </tr>
                
                <tr style={{width:'210mm'}}>
                    <td className="cell" colSpan="2" style={{width:'50%', textAlign:'center', verticalAlign:"middle"}}>
                        RTU 명판 사진
                    </td>
                    <td className="cell" colSpan="2" style={{width:'50%', textAlign:'center', verticalAlign:"middle"}}>
                        REMS 상태
                    </td>
                </tr>              
            </tbody>
        </table> 
    )

});
  
export default KnrecContent_2