import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import CustomAutocomplete from "../components/ui/CustomAutocomplete";
import axiosInstance from '../components/util/AxiosUtil';
import CustomDatatable from '../components/ui/CustomDatatable';

//검색 요청 기본 데이터
const defaultValues = {
    pjtSeq : '',
    siteSeq : '',
    rtuSeq : ''
}

function Equipment() {

    //[검색]조회 기본세팅
    const { handleSubmit, 
            control, 
            getValues, 
            setValue, 
            formState: { errors } 
    } = useForm({defaultValues});

    // 데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);

    /************************ 검색 상태 세팅 ************************/
    // 검색필터
    const [filterData, setFilterData] = useState( {defaultValues} );
    // [검색] 프로젝트
    const [pjtList, setPjtList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    // [검색] 사이트
    const [siteList, setSiteList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    // [검색] RTU
    const [rtuList, setRtuList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
 
    useEffect(() => {
        makePjtCodeList();
        makeSiteCodeList();
    }, []);
    
    const makePjtCodeList = () => {
        //프로젝트 검색 목록 생성
        axiosInstance.get('/pjtCodeList').then((Response)=>{
            if (Response.request.status === 200) {  

                const list =  Response.data.list;
                const newList = [{dtlCd: "", dtlCdNm: "전체"}, ...list];

                setPjtList(newList);  
                //사이트 검색목록 초기화
                setSiteList([{dtlCd: "", dtlCdNm: "전체"}]);   
                setValue("siteSeq", "");  
            }
            dataLoad(getValues());
        });
    }
    const makeSiteCodeList = () => {
        //사이트 검색 목록 생성
        axiosInstance.get('/siteCodeList', {params: {pjtSeq:getValues().pjtSeq}}).then((Response)=>{
            if (Response.request.status === 200) {                  

                const list =  Response.data.list;
                const newList = [{dtlCd: "", dtlCdNm: "전체"}, ...list];
                setSiteList(newList);

                //site 검색 선택 정보 초기화
                setValue("siteSeq", ""); 
            }
        });
    }

    const makeRtuCodeList = () => {
        //사이트 검색 목록 생성
        axiosInstance.get('/rtuCodeList', {params: {siteSeq:getValues().siteSeq}}).then((Response)=>{
            if (Response.request.status === 200) {                  

                const list =  Response.data.list;
                const newList = [{dtlCd: "", dtlCdNm: "전체"}, ...list];
                setRtuList(newList);
            }
        });
    }

    //프로젝트 검색필터 변경 이벤트
    const pjtSeqChanged = (option) =>{        
        makeSiteCodeList();
    }
    //사이트 검색필터 변경 이벤트
    const siteSeqChanged = (option) =>{
        makeRtuCodeList();
    }
    //datatable load
    function dataLoad(data){     
        setPageFixed(true);
        setFilterData(data);        
    }
    //테이블 컬럼정보
    const columns =  [
        { id: "devSeq", data: "devSeq", title: '설비일련번호' ,width: '15%',className: "text-center", responsivePriority: 1 },
        { id: "pjtNm", data: "pjtNm", title: '프로젝트', width: '10%', className: "text-center",responsivePriority: 2 },
        { id: "siteNm", data: "siteNm", title: '사이트', width:'15%', className: "text-center", responsivePriority: 3 },
        { id: "rtuSeq", data: "rtuSeq", title: 'RTU', width:'10%', className: "text-center", responsivePriority: 4 },
        { id: "devNm", data: "devNm", title: '설비명', width:'15%',className: "text-center", responsivePriority: 5 },
        { id: "makrNm", data: "makrNm", title: '제조사', width:'10%',className: "text-center", responsivePriority: 6 },
        { id: "modelNm", data: "modelNm", title: '모델', width:'10%',className: "text-center", responsivePriority: 7 },
        { id: "cretDt", data: "cretDt", title: '등록일시', width:'15%',className: "text-center", responsivePriority: 8 }
    ]

    return (
        <div style={{ "padding": "16px 24px", 
                        "color": "rgb(68, 89, 110)", 
                        "height": "100%" 
        }}>
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>
                    <div style={{"marginBottom": "28px", "marginTop":"16px"}}>
                        <p fontWeight="600" className="css-1pr8naw">설비 조회</p>
                        <p className="css-3vvtry">PoSE / 관리 / 설비 조회</p>
                    </div>          
                </Grid>
                {/* 검색창 */}
                <Grid container 
                  alignItems="center" 
                  xs={12} sm={12} md={12} lg={12} xl={12} 
                  className="page-filter" 
                  sx={{p:0}}
                >
                    {/* 프로젝트 */}
                    <Grid xs={12} sm={12} md={6} lg={3} xl={3} sx={{ p: 2 }}>
                        <CustomAutocomplete name="pjtSeq"              
                                            label="프로젝트"           
                                            options={pjtList}
                                            value="dtlCd"
                                            text="dtlCdNm"             
                                            control={control}
                                            getValues={getValues}
                                            errors={errors.pjtSeq}
                                            onChange={pjtSeqChanged}
                        />
                    </Grid>
                    {/* 사이트 */}
                    <Grid xs={12} sm={12} md={6} lg={4} xl={4} sx={{ p: 2 }}>
                        <CustomAutocomplete name="siteSeq"              
                                            label="사이트"           
                                            options={siteList}
                                            value="dtlCd"
                                            text="dtlCdNm"             
                                            control={control}
                                            getValues={getValues}
                                            errors={errors.siteSeq}
                                            key={siteList.dtlCd}
                                            onChange={siteSeqChanged}
                        />
                    </Grid>

                    {/* RTU */}
                    <Grid xs={12} sm={12} md={6} lg={3} xl={3} sx={{ p: 2 }}>
                        <CustomAutocomplete name="rtuSeq"
                                            label="RTU"
                                            options={rtuList}
                                            value="dtlCd"
                                            text="dtlCdNm"             
                                            control={control}
                                            getValues={getValues}
                                            errors={errors.rtuSeq}
                                            key={siteList.dtlCd}
                        />
                    </Grid>
                    {/* 조회, 등록버튼*/}
                    <Grid textAlign={'start'} 
                          alignItems="center" 
                          container
                          xs={12} sm={12} md={6} lg={2} xl={2} 
                    >
                        <Button variant="contained" 
                                size="large" 
                                color="primary" 
                                sx={{ m:2, height:'52px' }}  
                                startIcon={<SearchIcon />}
                                onClick={handleSubmit(dataLoad)}
                        >조회
                        </Button>
                    </Grid>
                </Grid>
                {/* table */}
                <Grid 
                    container 
                    xs={12} sm={12} md={12} lg={12} xl={12} 
                    className="page-filter" 
                    sx={{ p: 2, mt: 3 }}
                >
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable id="table-list-0"                //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                         width="100%"                     //테이블 너비
                                         url="/devList"                   //ajax url
                                         columns={columns}                //테이블 컬럼 정의
                                         filter={filterData}              //ajax 검색 필터 정의
                                         paging={true}                    //페이징 여부
                                         pageLength="10"                  //페이징 수
                                         pageFixed={pageFixed}            //페이징 유지 여부
                                         order={[[0, 'asc']]}             //기본 정렬
                        />
                    </Grid>
                </Grid>

            </Grid>

        </div>
    );
}

export default Equipment;