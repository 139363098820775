import React, {useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import jspreadsheet from "jspreadsheet-ce";
import axiosInstance from '../../../../components/util/AxiosUtil';
import Grid from '@mui/material/Unstable_Grid2';
import excelImage from '../../../../assets/image/excelBackground.png';
import  { getHttpAuthHeader } from '../../../../components/util/AuthUtil'
import $ from 'jquery';

import "../../../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
import "../../../../assets/css/Jspreadsheet.css";
    
const GeoS = forwardRef((props, ref) => {

  //Model Dropdown Source Filter
  const modelFilter = (instance, cell, c, r, source, makrTypeCd) => {  

    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    
    let response = {data:{}};

    if (value){
      //모듈 모델 코드 목록 조회
      $.ajax({    
        type : 'get',
        url : `${process.env.REACT_APP_API_URL}` + '/modelCodeList',
        async : false,
        headers : {   
          'Accept':'application/json',
          'Content-Type':'application/x-www-form-urlencoded;charset=UTP-8',
          'Authorization' : getHttpAuthHeader()       
        },    
        dataType : 'json',
        data : {
          makrCd:value,
          makrTypeCd:makrTypeCd
        },
        success : function(result) { 
          // 결과
          response["data"] = result;                    
        },    
        error : function(request, status, error) { 
          console.log(error);
        }
      });
      
      const modulList = getModelCodeList(response);
      
      return modulList;

    }else{
      return source;
    }
  }

  //시트 컬럼 정의
  let columns = [
    { name: 'eqprNm', type: 'text', title: '*설치자명(단지명)', width:180 },        
    { name: 'arnoAdr', type: 'text', title: '*주소', width: 350 },
    { name: 'keaSysFormCd', type: 'dropdown', title: '*시스템방식', width: 150, autocomplete:true, source:[] },
    { name: 'keaSysDivCd', type: 'dropdown', title: '*시스템구분', width: 150, autocomplete:true, source:[] },
    { name: 'keaBldgPurpCd', type: 'dropdown', title: '*건물용도', width: 220, autocomplete:true, source:[] },
    { name: 'keaBillTypeCd', type: 'dropdown', title: '*요금제', width: 150, autocomplete:true, source:[] },
    { name: 'eqprHpno', type: 'text', title: '*설치자휴대폰번호', width: 350 },
    { name: 'instlPlanNo', type: 'text', title: '*설비설치계획서번호', width: 350 },
    { name: 'facNm', type: 'text', title: '*설비명', width: 150 },
    { name: 'instlPlacNm', type: 'text', title: '*설치장소', width: 150 },
    { name: 'hpMakrCd', type: 'dropdown', title: '*히트펌프 제조사', width: 220, autocomplete:true, source:[] },
    { name: 'hpModelCd', type: 'dropdown', title: '*히트펌프 모델', width: 220, autocomplete:true, source:[], filter:(instance, cell, c, r, source)=>modelFilter(instance, cell, c, r, source, '30005') },
    { name: 'hpCpct', type: 'numeric', title: '*히트펌프 용량(kW)', width: 150 },
    { name: 'htWtrCaloMakrCd', type: 'dropdown', title: '*급탕측 열량계 제조사', width: 350, autocomplete:true, source:[] },
    { name: 'htWtrCaloModelCd', type: 'dropdown', title: '*급탕측 열량계 모델', width: 350, autocomplete:true, source:[], filter:(instance, cell, c, r, source)=>modelFilter(instance, cell, c, r, source, '30014') },
    { name: 'cnhCaloMakrCd', type: 'dropdown', title: '*냉난방측 열량계 제조사', width: 350, autocomplete:true, source:[] },
    { name: 'cnhCaloModelCd', type: 'dropdown', title: '*냉난방측 열량계 모델', width: 350, autocomplete:true, source:[], filter:(instance, cell, c, r, source)=>modelFilter(instance, cell, c, r, source, '30014') },
    { name: 'pwrMakrCd', type: 'dropdown', title: '*전력량계 제조사', width: 150, autocomplete:true, source:[] },
    { name: 'pwrModelCd', type: 'dropdown', title: '*전력량계 모델', width: 220, autocomplete:true, source:[], filter:(instance, cell, c, r, source)=>modelFilter(instance, cell, c, r, source, '30015') },
    { name: 'cahFlux', type: 'numeric', title: '*냉온수유량(LPM)', width: 150 },
    { name: 'geotmWtrFlux', type: 'numeric', title: '*지열수유량(LPM)', width: 150 },
    { name: 'brngDpth', type: 'numeric', title: '*천공깊이(m)', width: 150 },
    { name: 'brngCnt', type: 'numeric', title: '*천공개수', width: 150 },
    { name: 'bntntMakrCd', type: 'dropdown', title: '*벤토나이트 제조사', width: 220, autocomplete:true, source:[] },
    { name: 'bntntModelCd', type: 'dropdown', title: '*벤토나이트 모델', width: 220, autocomplete:true, source:[], filter:(instance, cell, c, r, source)=>modelFilter(instance, cell, c, r, source, '30006') },
    { name: 'cntrctrNm', type: 'text', title: '*시공업체', width: 150 },
    { name: 'cntrctrTlno', type: 'text', title: '*시공업체 연락처', width: 150 },
    { name: 'cntrctrHpno', type: 'text', title: '*시공업체 핸드폰 연락처', width: 350 },
    { name: 'exworkDay', type: 'calendar', title: '*시공일시', options: { format:'YYYY-MM-DD' }, width: 150 },
    { name: 'asExpDay', type: 'calendar', title: '*AS만료일', options: { format:'YYYY-MM-DD' }, width: 150 },
    { name: 'rmrk', type: 'text', title: '특이사항', width: 350 },
    { name: 'instlConfDay', type: 'calendar', title: '*시공완료일', options: { format:'YYYY-MM-DD' }, width: 150 },
    { name: 'loraId', type: 'text', title: 'Lora ID', width: 150 }
  ]    

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    validate
  }))

  //시트 REF 객체
  const jRef = useRef();
  
  //데이터 유효성 검증
  const validate = () => {

    props.list.map((row, rowIndex) => {

      rowIndex++;

      $.each(row, function (key, value) {  

        const keyName = key.toString();
        const colIndex = getColIndex(keyName);

        if (colIndex >= 0){

          const errorMessage = row[keyName + "_v"].errorMessage;

          if (errorMessage != ""){
            jRef.current.jexcel.setComments(getAlphabetByIndex(colIndex+1) + rowIndex, errorMessage);
          }

        }        
      })
    });
  }

  //시트 생성
  const createSheet = (columns) => {
    
    if (jRef.current.jexcel) {
      //이미 생성된 시트가 존재시 삭제
      jspreadsheet.destroy(jRef.current);
    }

    //데이터 가공   
    let list = [];
    props.list.map((data) => {
      list.push([
                  data.eqprNm, 
                  data.arnoAdr, 
                  data.keaSysFormCd, 
                  data.keaSysDivCd, 
                  data.keaBldgPurpCd, 
                  data.keaBillTypeCd, 
                  data.eqprHpno, 
                  data.instlPlanNo, 
                  data.facNm, 
                  data.instlPlacNm, 
                  data.hpMakrCd, 
                  data.hpModelCd, 
                  data.hpCpct, 
                  data.htWtrCaloMakrCd, 
                  data.htWtrCaloModelCd, 
                  data.cnhCaloMakrCd, 
                  data.cnhCaloModelCd, 
                  data.pwrMakrCd, 
                  data.pwrModelCd, 
                  data.cahFlux, 
                  data.geotmWtrFlux, 
                  data.brngDpth, 
                  data.brngCnt, 
                  data.bntntMakrCd, 
                  data.bntntModelCd, 
                  data.cntrctrNm, 
                  data.cntrctrTlno, 
                  data.cntrctrHpno, 
                  data.exworkDay, 
                  data.asExpDay, 
                  data.rmrk, 
                  data.instlConfDay, 
                  data.loraId                  
                ]);      
    });

    if (props.list.length > 0){  

      //시트 초기화              
      jspreadsheet(jRef.current, {
        data:list,
        columns: columns,
        allowComments:true,
        defaultColWidth: 100,
        tableOverflow: true,
        allowDeleteColumn: false,
        allowDeleteColumn: false,
        allowInsertColumn: false,
        columnSorting: false,
        onafterchanges : function(worksheet, records){
          //셀 값 변경 처리
          records.map((item)=>{
            const keyName = getColName(item.col); 
            
            if (keyName === "exworkDay" || keyName === "asExpDay" || keyName === "instlConfDay"){
              if (isDateString(item.newValue)){
                let originalDate = new Date(item.newValue);
                item.newValue = originalDate.toISOString().split('T')[0];
              }
            }

            props.list[item.row][keyName] = item.newValue == "" ? "" : item.newValue;              
          });          
          
        },
        oninsertrow : function(instance, index, cnt, obj, isBefor){
          //로우 추가 처리
          let rowIndex = !isBefor ? index + 1 : index;          
          
          let newObject = {};
          for (let key in props.list[0]){
            newObject[key] = "";
          }

          props.list.splice(rowIndex, 0, newObject);

        },
        ondeleterow : function(instance, index, cnt, obj, isBeforr){
          //로우 삭제 처리
          props.list.splice(index, cnt);          
        }
      });

      setSheetWidth();

    }else{
      //데이터 값이 없을 경우 시트 삭제
      jspreadsheet.destroy(jRef.current);
    }        
    
  }

  //시트 컬럼 설정
  const setColumns = async () => {
    
    //시스템방식 코드 목록 조회
    const keaSysFormCdList = await axiosInstance.get('/codeList', {params: {grpCd: 'KEA_SYS_FORM_CD'}});   

    //시스템구분 코드 목록 조회
    const keaSysDivCdList = await axiosInstance.get('/codeList', {params: {grpCd: 'KEA_SYS_DIV_CD'}});   

    //건물용도 코드 목록 조회
    const keaBldgPurpCdList = await axiosInstance.get('/codeList', {params: {grpCd: 'KEA_BLDG_PURP_CD'}});   

    //요금제 코드 목록 조회
    const keaBillTypeCdList = await axiosInstance.get('/codeList', {params: {grpCd: 'KEA_BILL_TYPE_CD'}});   

    //히트펌프 제조사 코드 목록 조회
    const hpMakrCdList = await axiosInstance.get('/makrCodeList', {params: {makrTypeCd: '30005'}});   

    //히트펌프 모델 코드 목록 조회
    const hpModelCdList = await axiosInstance.get('/modelCodeList', {params: {makrCd: '', makrTypeCd: '30005'}});   

    //열량계 제조사 코드 목록 조회
    const caloMakrCdList = await axiosInstance.get('/makrCodeList', {params: {makrTypeCd: '30014'}});   

    //열량계 모델 코드 목록 조회
    const caloModelCdList = await axiosInstance.get('/modelCodeList', {params: {makrCd: '', makrTypeCd: '30014'}});   

    //전력량계 제조사 코드 목록 조회
    const pwrMakrCdList = await axiosInstance.get('/makrCodeList', {params: {makrTypeCd: '30015'}});   

    //전력량계 모델 코드 목록 조회
    const pwrModelCdList = await axiosInstance.get('/modelCodeList', {params: {makrCd: '', makrTypeCd: '30015'}});   

    //벤토나이트 제조사 코드 목록 조회
    const bntntMakrCdList = await axiosInstance.get('/makrCodeList', {params: {makrTypeCd: '30006'}});   

    //벤토나이트 모델 코드 목록 조회
    const bntntModelCdList = await axiosInstance.get('/modelCodeList', {params: {makrCd: '', makrTypeCd: '30006'}});   

    columns[2].source = getCodeList(keaSysFormCdList);
    columns[3].source = getCodeList(keaSysDivCdList);    
    columns[4].source = getCodeList(keaBldgPurpCdList);    
    columns[5].source = getCodeList(keaBillTypeCdList);   
    columns[10].source = getCodeList(hpMakrCdList);    
    columns[11].source = getModelCodeList(hpModelCdList);   
    columns[13].source = getCodeList(caloMakrCdList);    
    columns[14].source = getModelCodeList(caloModelCdList); 
    columns[15].source = getCodeList(caloMakrCdList);    
    columns[16].source = getModelCodeList(caloModelCdList);  
    columns[17].source = getCodeList(pwrMakrCdList);    
    columns[18].source = getModelCodeList(pwrModelCdList);   
    columns[23].source = getCodeList(bntntMakrCdList);    
    columns[24].source = getModelCodeList(bntntModelCdList);    

    return columns;
  }

  //숫자 알파벳 변환
  function getAlphabetByIndex(index) {    

      var result = '';
      var aAscii = 'A'.charCodeAt(0);

      // 숫자가 26 이하인 경우
      if (index <= 26) {
          result += String.fromCharCode(aAscii + (index - 1));
      } else {
          // 숫자가 26을 넘어가면 두 자리 알파벳 계산
          var quotient = Math.floor((index - 1) / 26); // 몫
          var remainder = (index - 1) % 26; // 나머지

          result += String.fromCharCode(aAscii + (quotient - 1));
          result += String.fromCharCode(aAscii + (remainder));
          
      }

      return result;
  }

  //컬럼 인덱스 반환
  const getColIndex = (name) => {
    
    let colIndex = -1;

    columns.forEach(function(item, index){
      if (item.name === name){        
        colIndex = index;
        return;
      }        
    })

    return colIndex;
  }

  //인덱스 컬럼명 반환
  const getColName = (colIndex) => {

    let colName = columns[colIndex].name;

    return colName;
  }

  //공통코드 목록 변환
  const getCodeList = (response) => {
    let codeList = [];
    response.data.list.map ( (item) => codeList.push({"id": item.dtlCd, "name": item.dtlCdNm}));   
    return codeList;       
  }

  //모델코드 목록 변환
  const getModelCodeList = (response) => {
    let codeList = [];
    response.data.list.map ( (item) => codeList.push({"id": item.modelCd, "name": item.modelNm}));   
    return codeList;       
  }

  //시트 가로 사이즈 동적 변경
  const setSheetWidth = () => {
    var element = document.querySelector('.jexcel_content');
    if (element){                
      element.style.width = (window.innerWidth-320) + "px";
      element.style.height = (window.innerHeight-380) + "px";
      element.style.maxHeight = (window.innerHeight-380) + "px";
    }
  }

  //문자열 날짜 형식 확인
  function isDateString(str) {
    // Date 객체로 파싱을 시도
    const date = new Date(str);

    // 유효한 날짜인지 확인
    return !isNaN(date.getTime());
  }

  useEffect(() => {

    window.addEventListener("resize", setSheetWidth);

  }, []);
  
  useEffect(() => {
    
    setColumns().then(columns => {    
      createSheet(columns);         
      validate();
    });
    
  }, [props.list]);

  return (
    <div>
        <div ref={jRef} />
        {
          (props.list ? props.list.length : 0) > 0 ? <div></div> :
          
          <Grid container alignItems="center" justifyContent="center" xs={12} sm={12} md={12} lg={12} xl={12} sx={{height:'100%'}}>
              <img src={excelImage} width={200} height={200} alt=""/>
              데이터가 없습니다. 엑셀 파일 불러오기 후 데이터를 확인 해주세요.
          </Grid>
        }
    </div>
  )
});

export default GeoS;