import React, {useState, useEffect, forwardRef} from "react";
import {Unstable_Grid2 as Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import axiosInstance from '../../../../components/util/AxiosUtil';
import excelImage from '../../../../assets/image/excelBackground.png';
import '../../../../assets/css/Table.css'

const StyledTableRow = styled(TableRow)(({ theme }) => ({    
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const PvArdy = forwardRef((props, ref) => {

    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    // 기상반 목록
    const [list, setList] = useState([]);

    //제조사 코드 목록
    const [prnmtrMakrCdList, setPrnmtrMakrCdList] = useState([]);
    //모델 코드 목록
    const [prnmtrModelCdList, setPrnmtrModelCdList] = useState([]);

    const filterModelList = (modelList, makrCd) => {
        return modelList.filter((el) => 
            el.makrCd == makrCd
        );
    }    

    const handleResize = () => {
        setScreenHeight(window.innerHeight - 415);
    };

    const handleChange = (e, index, key) => {
        let newArray = [...list];
        newArray[index][key] = e.target.value;
        setList(newArray);
    }

    const handleChangeOnlyNumber = (e, index, key) => {
        let newArray = [...list];
        newArray[index][key] = e.target.value.replace(/[^0-9]/g, '');
        setList(newArray);
    }

    const handleChangeOnlyDecimal = (e, index, key) => {
        let newArray = [...list];
        newArray[index][key] = e.target.value.replace(/[^0-9.]/g, '');
        setList(newArray);
    }

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        setScreenHeight(window.innerHeight - 415);

        //제조사 코드 목록 조회
        axiosInstance.get('/makrCodeList', {params: {makrTypeCd: '40001'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setPrnmtrMakrCdList(Response.data.list);                     
            }
        });    

        //모델 코드 목록 조회
        axiosInstance.get('/modelCodeList', {params: {makrCd: '', makrTypeCd: '40001'}}).then((Response)=>{
            if (Response.request.status === 200) {                  
                setPrnmtrModelCdList(Response.data.list);                     
            }
        });  

        return () => {
            // cleanup
            window.removeEventListener("resize", handleResize);
        };

    }, []);

    useEffect(() => {

        setList(props.list);        

    }, [props.list]);

    return (
        <TableContainer sx={{ height:screenHeight, maxHeight: screenHeight }}>

            {
                list.length > 0 ?
                <Table className="ardy-table pose-table" stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell align='center' style={{ minWidth: 50, fontSize:14 }}>NO</TableCell>
                        <TableCell align='center' style={{ minWidth: 250, fontSize:14 }}>설치자명(단지명)</TableCell>
                        <TableCell align='center' style={{ minWidth: 250, fontSize:14 }}>주소</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}>통신 장치 번호<br/>(국번 / 유닛ID)</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}>설비명</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}>설치장소</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}>제조사</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}>모델</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}>시공업체</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}>시공일시</TableCell>                        
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}>특이사항</TableCell>
                        <TableCell align='center' style={{ minWidth: 150, fontSize:14 }}>시공완료일</TableCell>                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                        {
                            list.map((item, index) => (
                                //태양광 기상반 정보
                                <StyledTableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell align='center'>{index+1}</TableCell>   
                                    {/* 설치자명(단지명) */}
                                    <TableCell align='center'> 
                                        <input className={item.eqprNm_v.result} 
                                               title={item.eqprNm_v.errorMessage} 
                                               value={item.eqprNm || ''} 
                                               onChange={(e)=>handleChange(e, index, "eqprNm")}                                                
                                        />                                         
                                    </TableCell>    
                                    {/* 주소 */}
                                    <TableCell align='center'> 
                                        <input className={item.arnoAdr_v.result} 
                                               title={item.arnoAdr_v.errorMessage} 
                                               value={item.arnoAdr || ''} 
                                               onChange={(e)=>handleChange(e, index, "arnoAdr")} 
                                        /> 
                                    </TableCell>   
                                    {/* 통신 장치 번호 */}
                                    <TableCell align='center'> 
                                        <input className={item.devNo_v.result} 
                                               title={item.devNo_v.errorMessage} 
                                               value={item.devNo || ''} 
                                               onChange={(e)=>handleChangeOnlyNumber(e, index, "devNo")}                                               
                                        />                                     
                                    </TableCell> 
                                    
                                    {/* 설비명 */}
                                    <TableCell align='center'> 
                                        <input className={item.facNm_v.result} 
                                               title={item.facNm_v.errorMessage} 
                                               value={item.facNm || ''} 
                                               onChange={(e)=>handleChange(e, index, "facNm")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 설치장소 */}
                                    <TableCell align='center'> 
                                        <input className={item.instlPlacNm_v.result} 
                                               title={item.instlPlacNm_v.errorMessage} 
                                               value={item.instlPlacNm || ''} 
                                               onChange={(e)=>handleChange(e, index, "instlPlacNm")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 제조사 */}
                                    <TableCell align='center'>
                                        <select className={item.prnmtrMakrCd_v.result} 
                                                title={item.prnmtrMakrCd_v.errorMessage} 
                                                value={item.prnmtrMakrCd} 
                                                onChange={(e)=>handleChange(e, index, "prnmtrMakrCd")}>
                                            <option value=""></option>
                                            {
                                                prnmtrMakrCdList.map((item, i) => (
                                                    <option key={i} value={item.dtlCd}>{item.dtlCdNm}</option>
                                                ))                                                        
                                            }  
                                        </select> 
                                    </TableCell>
                                    {/* 모델 */}
                                    <TableCell align='center'>
                                        <select className={item.prnmtrModelCd_v.result} 
                                                title={item.prnmtrModelCd_v.errorMessage} 
                                                value={item.prnmtrModelCd}
                                                onChange={(e)=>handleChange(e, index, "prnmtrModelCd")}>                                        
                                            <option value=""></option>                                           
                                            {   
                                                filterModelList(prnmtrModelCdList, item.prnmtrMakrCd).map((item, i) => (
                                                    <option key={i} value={item.modelCd}>{item.modelNm}</option>
                                                ))                                                        
                                            }
                                        </select> 
                                    </TableCell>                                    
                                    {/* 시공업체 */}
                                    <TableCell align='center'> 
                                        <input className={item.cntrctrNm_v.result} 
                                               title={item.cntrctrNm_v.errorMessage} 
                                               value={item.cntrctrNm || ''} 
                                               onChange={(e)=>handleChange(e, index, "cntrctrNm")}                                               
                                        /> 
                                    </TableCell>                                     
                                    {/* 시공일시 */}
                                    <TableCell align='center'> 
                                        <input className={item.exworkDay_v.result} 
                                               title={item.exworkDay_v.errorMessage} 
                                               value={item.exworkDay || ''} 
                                               onChange={(e)=>handleChange(e, index, "exworkDay")}                                               
                                        /> 
                                    </TableCell>                                     
                                    {/* 특이사항 */}
                                    <TableCell align='center'> 
                                        <input className={item.rmrk_v.result} 
                                               title={item.rmrk_v.errorMessage} 
                                               value={item.rmrk || ''} 
                                               onChange={(e)=>handleChange(e, index, "rmrk")}                                               
                                        /> 
                                    </TableCell> 
                                    {/* 시공완료일 */}
                                    <TableCell align='center'> 
                                        <input className={item.instlConfDay_v.result} 
                                               title={item.instlConfDay_v.errorMessage} 
                                               value={item.instlConfDay || ''} 
                                               onChange={(e)=>handleChange(e, index, "instlConfDay")}                                               
                                        /> 
                                    </TableCell>                                        
                                </StyledTableRow>
                            ))                                        
                        }                                    
                    
                    </TableBody>
                </Table>
                :
                <Grid container alignItems="center" justifyContent="center" xs={12} sm={12} md={12} lg={12} xl={12} sx={{height:'100%'}}>
                    <img src={excelImage} width={200} height={200} alt=""/>
                    데이터가 없습니다.
                </Grid>
            }
            
            </TableContainer>
    );
});

export default PvArdy;