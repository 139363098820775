import React, {useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomTextField from "../components/ui/CustomTextField";
import { Box, Modal, Divider, Button } from '@mui/material';
import axiosInstance from '../components/util/AxiosUtil';
import CustomDatatable from '../components/ui/CustomDatatable';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import Toast from '../components/ui/Toast';
import { viewModeType } from '../components/const/CommCode'
import Confirm from '../components/ui/Confirm';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
//검색 요청 기본 데이터 세팅
const defaultFindValues = {
    authNm : ''
}

//모달 데이터 기본 세팅
let defaultValues = {
    auth : '',
    authNm : '',
    authDesc : '',
    menuId : ['']
}


function Role() {

    // [검색] 조회 기본세팅 - useForm
    const {
        handleSubmit : find_handleSubmit,
        getValues: find_getValues,
        control : find_control
    } = useForm( {defaultFindValues} );

    // [modal] 등록,수정 Form 기본세팅
    const { 
        handleSubmit, 
        getValues, 
        reset, 
        control, 
        formState: { errors } 
    } = useForm( {defaultValues} );

    //검색필터
    const [filterData, setFilterData] = useState( {defaultFindValues} );
    //모달 노출 상태 정보
    const [open, setOpen] = useState(false);
    //데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);
    //서버 요청 상태 정보
    const [viewMode, setViewMode] = useState(viewModeType.New);
    //모달 메뉴리스트
    const [menuList, setMenuList] = useState([{menuId: "", menuName: "", menuDesc: "", menuDpth: ""}]);
    //모달 체크
    const [menuChecked, setMenuChecked] = useState(['']);
    //권한 disabled 처리
    const [authDisabled, setAuthDisabled] = useState(false);
   
    //confrim ref 객체
    const confirmRef = useRef();
    //toast ref 객체
    const toastRef = useRef();

    useEffect(() => {
        makeMenuList();
    }, []);

    const makeMenuList = () => {
         //프로젝트 검색 목록 생성
         axiosInstance.get('/menuList').then((Response)=>{
            if (Response.request.status === 200) {  

                const list =  Response.data.list;
                setMenuList(list);
            }
        });
    }

    function dataReload(data){   
        setPageFixed(false);
        setFilterData(data);  

    }function dataload(data){
        setPageFixed(true);
        setFilterData(data);        
    }

    //테이블 컬럼정보
    const columns = [
        { id: "auth", data: "auth", title: '권한 아이디' ,width: '20%',className: "text-center", responsivePriority: 1 },
        { id: "authNm", data: "authNm", title: '권한 명', width: '20%', className: "text-center",responsivePriority: 2 },
        { id: "authDesc", data: "authDesc", title: '권한 설명', width:'20%', className: "text-center", responsivePriority: 3 },
        { id: "cretDt", data: "cretDt", title: '등록일시', width:'15%', className: "text-center", responsivePriority: 4 },
        { id: "updtDt", data: "updtDt", title: '수정일시', width:'15%',className: "text-center", responsivePriority: 5 },
        { id: "auth", data: "auth", title: '관리', width:'10%',className: "text-center", responsivePriority: 0, orderable:false }
    ]

    const columnDefs = [
        //수정, 삭제버튼
        {
            className: "text-center p-0", "targets":[5], render: function(data, type, row, meta){
                let customButton = "<button class='data-btn' data-auth='" + row.auth + "' data-authnm='" + row.authNm + "' data-authdesc='" + row.authDesc + "' data-type='update' style='margin:2px; padding:3px;'>수정</button>";

                customButton += "<button class='data-btn' data-auth='" + row.auth + "' data-type='delete' style='margin:2px; padding:3px;'>삭제</button>";                                    
                
                return customButton;
            }
        }
    ]

    //테이블 (수정, 삭제버튼) 클릭 콜백함수
    const buttonClick = (e) => {
        if(e.target.dataset.type === "update") {
            
            axiosInstance.get('/roleMenuInfo', {params: {auth: e.target.dataset.auth}}).then((Response)=>{
            
                if(Response.request.status === 200){

                    let dataAuth = e.target.dataset.auth;
                    let dataAuthNm = e.target.dataset.authnm;
                    let dataDesc = e.target.dataset.authdesc;

                    let authMenuList = [''];
                    Response.data.list.map((value) => {
                       authMenuList.push(value.menuId);
                    })

                    reset({
                        auth : dataAuth,
                        authNm : dataAuthNm,
                        authDesc : dataDesc,
                        menuId : authMenuList
                    });
                    setAuthDisabled(true);
                    setMenuChecked(authMenuList);
                    setPageFixed(false);
                    setViewMode(viewModeType.Edit);
                    //reset(Response.data.list);
                    
                    setOpen(true);
                }else{
                    toastRef.current.handleOpen("error", "오류입니다. 데이터를 조회 할 수 없습니다.");
                }
            });   

        }else if (e.target.dataset.type === "delete"){            
            confirmRef.current.handleOpen("권한 정보 삭제", "권한 정보를 삭제 합니다. 계속 진행 하시겠습니까?", e);
        } 

    }

    const del = (e) =>{
        axiosInstance.delete('/role', {params: {auth: e.target.dataset.auth}}).then((Response)=>{
            
            if(Response.request.status === 200){    
                if(Response.data.resultCode === "SUCCESS"){
                    toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
                    dataReload(find_getValues());
                }else if(Response.data.resultCode === "ERROR"){
                    toastRef.current.handleOpen("error", Response.data.resultMessage);                                        
                }   
              
            }else{                              
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }                  
        });
    }

     //등록버튼 클릭 이벤트
     const regClickEvent = () => {

        //모달 초기화
        reset(defaultValues);

        //권한 disabled처리
        setAuthDisabled(false);

        //메뉴 체크 초기화
        setMenuChecked(['']);
        
        //등록으로 상태변경
        setViewMode(viewModeType.New);

        //모달 열기
        setOpen(true);
    }

    const handleToggle = (value) => () => {
        const currentIndex = menuChecked.indexOf(value);
        const newChecked = [...menuChecked];

        if (currentIndex === -1) {
        newChecked.push(value);
        } else {
        newChecked.splice(currentIndex, 1);
        }

        setMenuChecked(newChecked);
    };

    const onSubmit = (data) => {

        let menuIds = "";
        menuIds = menuChecked.join();

        if (viewMode === viewModeType.New){

            axiosInstance.post('/role', {auth : data.auth,
                                        authNm : data.authNm,
                                        authDesc : data.authDesc,
                                        menuId : menuIds}).then((Response) => {
                /* 데이터 전송 체크 */
                if(Response.request.status === 200){
                    /* 권한 중복체크 */
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                        setOpen(false);
                        dataReload(find_getValues());
                    }else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage );
                    }
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                } 
            })

        }else if (viewMode === viewModeType.Edit){

            axiosInstance.put('/role', {auth : data.auth,
                                        authNm : data.authNm,
                                        authDesc : data.authDesc,
                                        menuId : menuIds}).then((Response)=>{            
                if(Response.request.status === 200){ 
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                        setOpen(false);
                        dataReload(find_getValues());
                    } else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage);                                        
                    }      
                    
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }
            });
        }
    }

    //input css style
    const textFileStyled = { mt:2, width: '100%', 'textAlign': 'center' };

    return (
        <div style={{ 
            "padding": "16px 24px", 
            "color": "rgb(68, 89, 110)", 
            "height": "100%" 
        }}>
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>
                    <div style={{"marginBottom": "28px", "marginTop":"16px"}}>
                        <p fontWeight="600" className="css-1pr8naw">권한 관리</p>
                        <p className="css-3vvtry">PoSE / 시스템 관리 /권한 관리</p>
                    </div>         
                </Grid>
                {/* 검색창 */}
                <Grid container 
                  alignItems="center" 
                  xs={12} sm={12} md={12} lg={12} xl={12} 
                  className="page-filter" 
                  sx={{p:0}}
                  justifyContent={'flex-end'}
                >
                    {/* 권한명 */}
                    <Grid xs={12} sm={6} md={6} lg={4} xl={4} sx={{ p: 2 }}>
                        <CustomTextField
                            fullWidth 
                            name="authNm"              
                            label="권한 명"          
                            control={find_control}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){                                    
                                    dataReload(find_getValues());
                                }
                             }}
                        />
                    </Grid>
                    {/* 조회, 등록버튼*/}
                    <Grid 
                        textAlign={'start'} 
                        alignItems="center" 
                        xs={12} sm={6} md={6} lg={4} xl={3}
                        sx={{p:0}}
                    >
                        <Button 
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ ml:2, height:'52px' }}  
                            startIcon={<SearchIcon />}
                            onClick={find_handleSubmit(dataload)}
                        >조회
                        </Button>
                        <Button
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ m:2, height:'52px' }} 
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={regClickEvent}
                        >등록
                        </Button>
                    </Grid>
                </Grid>

                {/* table */}
                <Grid container 
                        xs={12} sm={12} md={12} lg={12} xl={12} 
                        className="page-filter" 
                        sx={{ p: 2, mt: 3 }}
                >
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable id="table-list-0"               //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                        width="100%"                     //테이블 너비
                                        url="/roleList"                   //ajax url
                                        columns={columns}                //테이블 컬럼 정의
                                        columnDefs={columnDefs}          //Custom 컬럼 정의
                                        filter={filterData}              //ajax 검색 필터 정의
                                        paging={true}                    //페이징 여부
                                        pageLength="10"                  //페이징 수
                                        buttonClick ={buttonClick}       //버튼 클릭 콜백함수
                                        pageFixed={pageFixed}            //페이징 유지 여부
                                        order={[[0, 'asc']]}             //기본 정렬
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* 등록 / 수정 모달 */}
            <Modal open={open} 
                    onClose={e => setOpen(false)}
                    aria-labelledby="parent-modal-title" 
                    aria-describedby="parent-modal-description" 
            >
                <Box className="mdModal flex-w" flexDirection={'column'}>
                    <h3 id="parent-modal-title" className="limiter">권한 정보</h3>
                    <Divider sx={{ mt:2, width: '100%'}} />

                    {/* 권한 */}
                    <CustomTextField 
                        fullWidth 
                        name="auth" 
                        label="*권한" 
                        control={control}
                        rules={{ required: "권한을 입력 해주세요." }} 
                        disabled ={authDisabled}
                        sx={textFileStyled} 
                        errors={errors.auth}
                    />
                    {/* 권한 명 */}
                    <CustomTextField 
                        fullWidth 
                        name="authNm" 
                        label="*권한 명" 
                        control={control}
                        rules={{ required: "권한 명을 입력 해주세요." }} 
                        sx={textFileStyled} 
                        errors={errors.authNm}
                    />
                    {/* 권한 설명 */}
                    <CustomTextField 
                        fullWidth 
                        name="authDesc" 
                        label="권한 설명" 
                        control={control}
                        sx={textFileStyled} 
                        errors={errors.authDesc}
                        style={{"marginBottom":"15px"}}
                    />

                    <List sx={{ width: '100%', 
                                borderRadius:'5px', 
                                border: '2px solid #1976d2', 
                                padding: '0', 
                                overflow: 'auto',
                                maxHeight: 300}}>
                        <ListItem
                           disablePadding
                           sx={{ textAlign: 'center', background: '#dce9f945', borderBottom: '2px solid #1976d2'}}
                        >
                            <ListItemText primary={`메뉴`} sx={{width: '35%', borderRight: '1px solid gray'}} />
                            <ListItemText primary={`메뉴 설명`} sx={{width: '50%', borderRight: '1px solid gray'}}/>
                            <ListItemText primary={`권한`} sx={{width: '15%'}}/>
                        </ListItem>
                        {menuList.map((value) => {
                            let bgCustom = {};
                            if(value.menuDpth === 0 ){
                                bgCustom = {borderTop: '1px solid rgba(0, 0, 0, 0.3)',
                                            background: '#dce9f945'}
                            }else {
                                bgCustom = {borderTop: '1px solid rgba(0, 0, 0, 0.3)'}
                            }

                            return(
                            <ListItem
                                key={value.menuId}
                                disablePadding
                                sx={bgCustom}
                            >
                                <ListItemButton role={undefined} onClick={handleToggle(value.menuId)} sx={{textAlign: 'center', padding: '0'}} dense>
                                    <ListItemText primary={value.menuName}  sx={{width: '35%', borderRight: '1px solid rgba(0, 0, 0, 0.3)'}} />
                                    <ListItemText primary={value.menuDesc}   sx={{width: '50%', borderRight: '1px solid rgba(0, 0, 0, 0.3)'}}/>
                                    <ListItemIcon sx={{width: '15%', justifyContent:'flex-end'}}>
                                        <Switch
                                           // edge="end"
                                            checked={menuChecked.indexOf(value.menuId) !== -1}
                                            tabIndex={-1}
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                           );
                        })}
                    </List>
                    <Divider sx={{ mt:2, mb:3 }} />
                    
                    <Grid container direction="row" justifyContent="flex-end">
                        <Button variant="contained" size="large" color="primary" startIcon={<SaveAltIcon />} onClick={handleSubmit(onSubmit)}>저장</Button>
                        <Button variant="contained" size="large" color="primary" startIcon={<CloseIcon />} sx={{ ml: 2 }} onClick={e => setOpen(false)}>닫기</Button>
                    </Grid>
                </Box>

            </Modal>

            <Toast ref={toastRef} />
            <Confirm ref={confirmRef} ok={del} />
        </div>
    );
}

export default Role;