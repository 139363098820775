import React from 'react'
import { TextField } from '@mui/material';
import { Controller } from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const CustomDatePicker = (props) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} sx={{width:'100%'}} adapterLocale="ko">

            <Controller name={props.name}
                        control={props.control}                                         
                        render={({ field: { onChange, value } }) => (
                            <MobileDatePicker
                                label={props.label}
                                inputFormat="YYYY-MM-DD"
                                value={value}                                                    
                                onChange={(newValue) => onChange(newValue.format("YYYYMMDD"))}  
                                renderInput={(params) => <TextField fullWidth {...params} />}
                                sx={{width:'100%'}}
                            />
                        )} 
            />    
                                
                                
        </LocalizationProvider>
    )
  };
  
  export default CustomDatePicker