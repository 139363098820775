import React from "react";
import { logout } from '../util/AuthUtil'
import { useProSidebar } from 'react-pro-sidebar';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AddAPhoto from '@mui/icons-material/AddAPhoto';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LockOpen from '@mui/icons-material/LockOpen';
import "../../assets/css/Header.css"

export default function Header() {

    const { toggleSidebar, broken } = useProSidebar();

    const goMyInfo = () =>{        
        localStorage.setItem('lastParentMenuIndex', 17);
        localStorage.setItem('lastUrl', '/system/myInfo');
        window.location.href = "/system/myInfo";        
    }

    const goPhoto = () =>{        
        localStorage.setItem('lastParentMenuIndex', 1);
        localStorage.setItem('lastUrl', '/product/photo');
        window.location.href = "/product/photo";        
    }

    const goFileDownload = () =>{        
        window.open('/fileDownload', '_blank', "width=620,height=500");
    }

    return (
        <header className="page-header">

            <AppBar position="static">
                <Toolbar>
                    {broken && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => {toggleSidebar()}}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 700 }}>
                        Product of SQISoft Energy
                    </Typography>

                    <div>
                    <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={goPhoto}
                        >
                            <AddAPhoto />
                        </IconButton>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={goFileDownload}
                        >
                            <FileDownloadIcon />
                        </IconButton>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={goMyInfo}
                        >
                            <AccountCircle />
                        </IconButton>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={logout}
                        >
                            <LockOpen />
                        </IconButton>
                    </div>

                </Toolbar>
            </AppBar>

        </header>
    );
}