import axios from 'axios';
import  { getHttpAuthHeader, logout } from './AuthUtil';
import { useRecoilState } from 'recoil';

let progress = 0;
let timerId = null;

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    withCredentials: true,
    headers: {
        'Accept':'application/json',
        'Content-Type':'application/x-www-form-urlencoded;charset=UTP-8',
        'Authorization' : getHttpAuthHeader()
    },
});

axiosInstance.interceptors.request.use(

    function (config) {            
        config.headers.Authorization = getHttpAuthHeader();  
        return config;
    }
);

const setProgress = (value) => {
    progress = value;

    if (window.progressbar){
        window.progressbar.onChange(progress);
    }    
}

const timer = () => {

    if (progress < 98){
        const diff = 100 - progress;
        const inc = diff / (10 + progress * (1 + progress / 100));
        setProgress(progress + inc);
    }

    // 50 ms 단위로 timer 재귀호출
    timerId = setTimeout(timer, 50); 
}

axiosInstance.interceptors.request.use(
    function (config) {        
        setProgress(0);
        timer();
        return config;
    },
    function (error){
        return Promise.reject(error);
    }
)

axiosInstance.interceptors.response.use(

    response => {      

        
        if (timerId){            
            clearTimeout(timerId);
            timerId = null;
        }
        
        setProgress(100);

        return response;

    }, error => {        

        setProgress(100);
        
        if (error.response){
            // 요청이 됬으나 서버가 2xx의 범위를 벗어나는 상태 코드를 응답
            if(error.response.status === 401){        
                logout();
            }else{
                alert("요청에 실패 했습니다. (오류코드:" + error.response.status + ")");
            }    
        }else if (error.request){
            // 요청이 됬으나 응답을 받지 못함
            alert('서버 오류입니다. 관리자에게 문의 하세요.')
        }else{
            alert('알수 없는 오류 입니다. 관리자에게 문의 하세요.');
        }                
    }
);



export default axiosInstance;