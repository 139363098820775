import React, {useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomTextField from "../components/ui/CustomTextField";
import { Box, Modal, Divider, Button } from '@mui/material';
import CustomAutocomplete from "../components/ui/CustomAutocomplete";
import axiosInstance from '../components/util/AxiosUtil';
import CustomDatatable from '../components/ui/CustomDatatable';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import Toast from '../components/ui/Toast';
import { viewModeType } from '../components/const/CommCode'
import Confirm from '../components/ui/Confirm';

//검색 요청 기본 데이터
const defaultFindValues = {     
    pjtSeq : ''
}
//모달 데이터 기본 세팅
const defaultValues = {
    pjtSeq : '',
    accId : '',
    accPwd : '',
    connYn : ''
}

function Kea() {

    // [검색] 프로젝트
    const [pjtList, setPjtList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    // [modal] 프로젝트
    const [modalPjtList, setModalPjtList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    //[modal] 연동여부
    const [modalUseYn, setModalUseYn] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    //[modal] 데이터 수정시 중복체크 위한 기존 프로젝트, 공단id
    const [beforeSeq, setBeforeSeq] = useState('');
    const [beforeId, setBeforeId] = useState('');

    //검색필터
    const [filterData, setFilterData] = useState( {defaultFindValues} );
    //모달 노출 상태 정보
    const [open, setOpen] = useState(false);
    //데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);
    //서버 요청 상태 정보
    const [viewMode, setViewMode] = useState(viewModeType.New);

    //confrim ref 객체
    const confirmRef = useRef();
    //toast ref 객체
    const toastRef = useRef();

    useEffect(() => {
        makePjtCodeList();
        makeUseYnList();
    }, []);
    
    //조회 기본세팅
    const { 
        handleSubmit : find_handleSubmit, 
        control : find_control, 
        getValues: find_getValues, 
        formState: { errors: find_errors } 
    } = useForm( {defaultFindValues} );

    // [modal] 등록,수정 Form 기본세팅
    const { 
        handleSubmit, 
        getValues, 
        reset, 
        control, 
        formState: { errors } 
    } = useForm( {defaultValues} );

    function dataReload(data){   
        setPageFixed(false);
        setFilterData(data);  

    }function dataload(data){
        setPageFixed(true);
        setFilterData(data);        
    }
    const makePjtCodeList = () => {
        //프로젝트 검색 목록 생성
        axiosInstance.get('/pjtCodeList').then((Response)=>{
            if (Response.request.status === 200) {  

                const list =  Response.data.list;
                const newList = [{dtlCd: "", dtlCdNm: "전체"}, ...list];
                setPjtList(newList);
                setModalPjtList(list);
            }
            dataload(find_getValues());
        });
    }

    const makeUseYnList = () => {
        //연동여부 목록 생성
        axiosInstance.get('/codeList', {params: {grpCd: 'USE_YN'}}).then((Response) => {
            if(Response.request.status === 200) {
                
                let list = Response.data.list;

                list.map((value) => {
                    if(value.dtlCd == 'Y'){
                        value.dtlCdNm = '연동';
                    }else if(value.dtlCd == 'N') {
                        value.dtlCdNm = '미연동';
                    }
                })

                //(Modal) 사업구분 select박스
                setModalUseYn(list);
            }
        })
    }

    //테이블 컬럼정보
    const columns = [
        { id: "pjtNm", data: "pjtNm", title: '프로젝트' ,width: '20%',className: "text-center", responsivePriority: 1 },
        { id: "accId", data: "accId", title: '계정 ID', width: '20%', className: "text-center",responsivePriority: 2 },
        { id: "connYn", data: "connYn", title: '연동여부', width:'20%', className: "text-center", responsivePriority: 3 },
        { id: "cretDt", data: "cretDt", title: '등록일시', width:'15%', className: "text-center", responsivePriority: 4 },
        { id: "updtDt", data: "updtDt", title: '수정일시', width:'15%',className: "text-center", responsivePriority: 5 },
        { id: "pjtSeq", data: "accId", title: '관리', width:'10%',className: "text-center", responsivePriority: 0, orderable:false }
    ]
    const columnDefs = [
        //연동여부 코드 -> 코드명 변경
        {
            className: "text-center p-0", "targets":[2], render: function(data, type, row, meta){
                let connYn = row.connYn;
                let connYnNm = '-';
                if(connYn == 'Y'){
                    connYnNm = '연동';
                }else if(connYn == 'N'){
                    connYnNm = '미연동';
                }
                let result = "<div>" + connYnNm + "</div>";

                return result;
            }
        },
        //수정, 삭제버튼
        {
            className: "text-center p-0", "targets":[5], render: function(data, type, row, meta){
                let customButton = "<button class='data-btn' data-id='" + row.accId + "' data-seq='" + row.pjtSeq + "' data-type='update' style='margin:2px; padding:3px;'>수정</button>";

                customButton += "<button class='data-btn' data-id='" + row.accId + "' data-seq='" + row.pjtSeq + "' data-type='delete' style='margin:2px; padding:3px;'>삭제</button>";                                    
                
                return customButton;
            }
        }
    ]

    //등록버튼 클릭 이벤트
    const regClickEvent = () => {

        //모달 초기화
        reset(defaultValues);

        //등록으로 상태변경
        setViewMode(viewModeType.New);

        //모달 열기
        setOpen(true);
    }

    //테이블 (수정, 삭제버튼) 클릭 콜백함수
    const buttonClick = (e) => {
        if(e.target.dataset.type === "update") {
            
            setBeforeSeq(e.target.dataset.seq);
            setBeforeId(e.target.dataset.id);

            axiosInstance.get('/kea', {params: {pjtSeq: e.target.dataset.seq,
                                                accId: e.target.dataset.id}}).then((Response)=>{
            
                if(Response.request.status === 200){
                    setPageFixed(false);
                    setViewMode(viewModeType.Edit);
                    reset(Response.data.info);
                    setOpen(true);
                }else{
                    toastRef.current.handleOpen("error", "오류입니다. 데이터를 조회 할 수 없습니다.");
                }
            });   

        }else if (e.target.dataset.type === "delete"){            
            confirmRef.current.handleOpen("공단 정보 삭제", "프로젝트 정보를 삭제 합니다. 계속 진행 하시겠습니까?", e);
        } 

    }

    const del = (e) =>{
        axiosInstance.delete('/kea', {params: {pjtSeq: e.target.dataset.seq,
                                                accId: e.target.dataset.id}}).then((Response)=>{
            
            if(Response.request.status === 200){    
                toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
                dataReload(find_getValues());
            }else{                              
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }                  
        });
    }
    const onSubmit = (data) => {
        if (viewMode === viewModeType.New){
            axiosInstance.post('/kea', {...data}).then((Response)=>{            
                if(Response.request.status === 200 ){  
                    
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                        setOpen(false);
                        dataReload(find_getValues());

                    //프로젝트-공단ID 중복
                    }else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage );
                    }

                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            });        
        }else if (viewMode === viewModeType.Edit){

            axiosInstance.put('/kea', {pjtSeq : data.pjtSeq, 
                                        accId : data.accId, 
                                        accPwd : data.accPwd,
                                        connYn : data.connYn,
                                        beforeSeq : beforeSeq, 
                                        beforeId : beforeId}).then((Response)=>{            
                if(Response.request.status === 200){ 
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                        setOpen(false);
                        dataReload(find_getValues());
                        setBeforeSeq('');
                        setBeforeId('');
                    } else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage);                                        
                    }      
                    
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }
            });
        }
    }

    //input css style
    const textFileStyled = { mt:2, width: '100%', 'textAlign': 'center' };

    return (
        <div style={{ "padding": "16px 24px", 
        "color": "rgb(68, 89, 110)", 
        "height": "100%" 
        }}>
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>
                    <div style={{"marginBottom": "28px", "marginTop":"16px"}}>
                        <p fontWeight="600" className="css-1pr8naw">공단계정 관리</p>
                        <p className="css-3vvtry">PoSE / 신재생서비스 / 공단계정 관리</p>
                    </div> 
                </Grid>
                {/* 검색창 */}
                <Grid container 
                  alignItems="center" 
                  xs={12} sm={12} md={12} lg={12} xl={12} 
                  className="page-filter" 
                  sx={{p:0}}
                  justifyContent={'flex-end'}
                >
                    {/* 프로젝트 */}
                    <Grid xs={12} sm={6} md={6} lg={4} xl={4} sx={{ p: 2 }}>
                        <CustomAutocomplete 
                            name="pjtSeq"              
                            label="프로젝트"           
                            options={pjtList}
                            value="dtlCd"
                            text="dtlCdNm"             
                            control={find_control}
                            getValues={find_getValues}
                            errors={find_errors.pjtSeq}                            
                        />
                    </Grid>

                    {/* 조회, 등록버튼*/}
                    <Grid 
                        textAlign={'start'} 
                        alignItems="center" 
                        xs={12} sm={6} md={6} lg={4} xl={3}
                        sx={{p:0}}
                    >
                        <Button 
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ ml:2, height:'52px' }}  
                            startIcon={<SearchIcon />}
                            onClick={find_handleSubmit(dataload)}
                        >조회
                        </Button>
                        <Button
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ m:2, height:'52px' }} 
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={regClickEvent}
                        >등록
                        </Button>
                    </Grid>
                </Grid>

                {/* table */}
                <Grid container 
                        xs={12} sm={12} md={12} lg={12} xl={12} 
                        className="page-filter" 
                        sx={{ p: 2, mt: 3 }}
                >
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable id="table-list-0"               //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                        width="100%"                     //테이블 너비
                                        url="/keaList"                   //ajax url
                                        columns={columns}                //테이블 컬럼 정의
                                        columnDefs={columnDefs}          //Custom 컬럼 정의
                                        filter={filterData}              //ajax 검색 필터 정의
                                        paging={true}                    //페이징 여부
                                        pageLength="10"                  //페이징 수
                                        buttonClick ={buttonClick}       //버튼 클릭 콜백함수
                                        pageFixed={pageFixed}            //페이징 유지 여부
                                        order={[[0, 'asc']]}             //기본 정렬
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* 등록 / 수정 모달 */}
            <Modal open={open} 
                    onClose={e => setOpen(false)} 
                    aria-labelledby="parent-modal-title" 
                    aria-describedby="parent-modal-description" 
            >
                <Box className="smModal">
                    <h3 id="parent-modal-title" className="limiter">공단 계정 정보</h3>
                    <Divider sx={{ mt:2 }} />
                    
                    {/* 프로젝트 */}
                    <Grid sx={textFileStyled}>
                        <CustomAutocomplete 
                            fullWidth
                            name="pjtSeq"              
                            label="*프로젝트"
                            rules={{ required: "프로젝트를 선택 해주세요." }}            
                            options={modalPjtList}          
                            value="dtlCd"              
                            text="dtlCdNm"             
                            control={control}          
                            getValues={getValues}      
                            errors={errors.pjtSeq}
                        />
                    </Grid>
                    {/* 계정 ID */}
                    <CustomTextField 
                        fullWidth 
                        name="accId" 
                        label="*계정 ID" 
                        control={control}
                        rules={{ required: "계정 ID를 입력 해주세요." }} 
                        sx={textFileStyled} 
                        errors={errors.accId}
                    />
                    {/* 계정 PWD */}
                    <CustomTextField 
                        fullWidth 
                        name="accPwd" 
                        label="*계정 비밀번호" 
                        type="password"
                        control={control}
                        sx={textFileStyled} 
                        errors={errors.accPwd}
                        rules={{required: '계정 비밀번호를 입력 해주세요.'}}
                    /> 
                    {/* 연동여부 */}
                    <Grid sx={textFileStyled}>
                        <CustomAutocomplete 
                            fullWidth
                            name="connYn"              
                            label="*연동여부"
                            rules={{ required: "연동여부를 선택 해주세요." }}            
                            options={modalUseYn}          
                            value="dtlCd"              
                            text="dtlCdNm"             
                            control={control}          
                            getValues={getValues}      
                            errors={errors.connYn}
                        />
                    </Grid>
                    <Divider sx={{ mt:2, mb:3 }} />
                    
                    <Grid container direction="row" justifyContent="flex-end">
                        <Button variant="contained" size="large" color="primary" startIcon={<SaveAltIcon />} onClick={handleSubmit(onSubmit)}>저장</Button>
                        <Button variant="contained" size="large" color="primary" startIcon={<CloseIcon />} sx={{ ml: 2 }} onClick={e => setOpen(false)}>닫기</Button>
                    </Grid>
                </Box>
            </Modal>
            <Toast ref={toastRef} />
            <Confirm ref={confirmRef} ok={del} />
        </div>
    );
}

export default Kea;