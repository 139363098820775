import React, {useState, useEffect} from "react";
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import RtuInstDocument from './doc/RtuInstDocument';
import axiosInstance from '../../components/util/AxiosUtil';
import { viewModeType } from '../../components/const/CommCode';
import { useLocation } from 'react-router-dom';

function ReqContainer() {

    const [workTypeList, setWorkTypeList] = useState([{dtlCdNm:""}]);

    const [workType, setWorkType] = useState({dtlCdNm:""});    

    const location = useLocation();
  
    const [menuNm, setMenuNm] = useState("");

    useEffect(() => {

        if (location.state){
            if (location.state.viewMode === viewModeType.New){
                setMenuNm("업무요청서 등록");
            }else{
                setMenuNm("업무요청서 정보");
            }
        }else{
            setMenuNm("업무요청서 등록");
        }        

        //업무유형 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'WORK_TYPE_CD'}}).then((Response)=>{
            if (Response.request.status === 200) {  
                setWorkTypeList(Response.data.list);     
                setWorkType(Response.data.list[0]);
            }
        });

    }, [location.state]);

    return (
        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)", "height": "100%" }}>

            <Grid container spacing={2} sx={{ height:'100%'}}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>

                    <div style={{ "marginBottom": "16px" }}></div>
                        <div style={{ "marginBottom": "48px" }}>
                        <p fontWeight="600" className="css-1pr8naw">{menuNm}</p>
                        <p className="css-3vvtry">PoSE / 영업 지원 / {menuNm}</p>
                    </div>

                    <Grid justifyContent="center" alignItems="center" sx={{ display: 'flex' }}>

                        <Grid container justifyContent="center" alignItems="center" sx={{ display: 'flex', width:'100%', maxWidth:950 }}>

                            <Grid className="title" xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="flex-end" alignItems="cneter" sx={{ display: 'flex' }}>
                               
                                <Autocomplete value={workType}
                                              options={workTypeList}
                                              onChange={(event, newValue) => {                                            
                                                setWorkType(newValue);
                                              }}                                                                                                                                    
                                              getOptionLabel={(option) => option.dtlCdNm}
                                              sx={{ width: 300 }}
                                              renderInput={(params) => <TextField {...params} label="업무요청서 종류" variant="standard" />}
                                />

                            </Grid>
                            
                            {
                                workType.dtlCd === '001' ? <RtuInstDocument workSeq={location.state ? location.state.workSeq : null} 
                                                                            viewMode={location.state ? location.state.viewMode : viewModeType.New}
                                                                            listUrl={location.state ? location.state.listUrl : "/sales/work"}
                                                                            listPageParentMenuIndex={location.state ? location.state.listPageParentMenuIndex : "10"} /> 
                                : null
                            }
                            
                        </Grid>
                    </Grid>
                                   
                </Grid>
                
            </Grid>            

        </div>
    );
}

export default ReqContainer;