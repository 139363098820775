import React, { useEffect, useState } from "react";
import { AuthTokenSet} from '../components/util/AuthUtil';
import { useNavigate } from "react-router-dom";
import { useResetRecoilState } from 'recoil';
import { activeMenuPath } from '../components/atoms/MenuAtom';
import { useForm } from "react-hook-form";
import { setCookie } from '../components/util/CookieUtil';
import  { getHttpAuthHeader } from '../components/util/AuthUtil';
import logoImage from '../assets/image/logo_PoSE.png';
import axios from 'axios';
import '../assets/css/Login.css';

export default function Login() {

    const lastUrl = localStorage.getItem('lastUrl');
    const lastParentMenuIndex = localStorage.getItem('lastParentMenuIndex');

    const navigate = useNavigate();

    const resetActiveMenuPath = useResetRecoilState(activeMenuPath);
    
    const { register, handleSubmit, formState: { errors } } = useForm();
    
    const [formData, setFormData] = useState({
        userId: '',
        userPassword:''
    });

    const [resultMessage, setResultMessage] = useState("");
    
    const enterKey = () => {
        if (window.event.keyCode === 13){
            onSubmit();
        }
    }

    const goFileDownload = () =>{        
        window.open('/fileDownload', '_blank', "width=620,height=500");
    }

    
    const onSubmit = () => {
       axios.post(`${process.env.REACT_APP_API_URL}` + '/auth/login', 
                   {
                        usrId: formData.userId, 
                        pwd: formData.userPassword
                   },
                   {
                        headers:{
                                    'Accept':'application/json',
                                    'Content-Type':'application/x-www-form-urlencoded;charset=UTP-8',
                                    'Authorization': getHttpAuthHeader()
                                }
                   }).then((Response)=>{
            
            if(Response.request.status === 200){
                
                AuthTokenSet(Response.data.accessToken);
                setCookie("usrId", formData.userId);

                
                axios.get(`${process.env.REACT_APP_API_URL}` + '/auth/member',                    
                   {
                        headers:{
                                    'Accept':'application/json',
                                    'Content-Type':'application/x-www-form-urlencoded;charset=UTP-8',
                                    'Authorization': getHttpAuthHeader()
                                }
                   }).then((Response)=>{
                        if(Response.request.status === 200){    
                                            
                            let url = Response.data.member.stUrl;
                            let menuIndex = lastParentMenuIndex ? lastParentMenuIndex : -1;

                            //메뉴 정보 초기화                                
                            resetActiveMenuPath();
                            localStorage.setItem('lastParentMenuIndex', menuIndex);
                            localStorage.setItem('lastUrl', url);                

                            //메뉴 정보 조회
                            axios.get(`${process.env.REACT_APP_API_URL}` + '/auth/usrMenuList',                    
                            {
                                headers:{
                                            'Accept':'application/json',
                                            'Content-Type':'application/x-www-form-urlencoded;charset=UTP-8',
                                            'Authorization': getHttpAuthHeader()
                                        }
                            }).then((Response)=>{
                                if(Response.request.status === 200){                                        

                                    const jsonMenuList = JSON.stringify([...Response.data.list]);

                                    localStorage.setItem("accessMenuList", jsonMenuList);

                                    //마지막 화면 이동
                                    navigate(url);
                                }else{                              
                                    alert("사용자 메뉴정보를 조회 할 수 없습니다.")
                                }         
                            }); 
                            
                        }else{                              
                            alert("시작 URL을 확인 할 수 없습니다.")
                        }         
                   });    
            }                
        }).catch(error => {
            if (error.response){
                // 요청이 됬으나 서버가 2xx의 범위를 벗어나는 상태 코드를 응답
                if(error.response.status === 401){        
                    setResultMessage("아이디 또는 패스워드를 확인 해주세요.");
                }else{
                    alert("요청에 실패 했습니다. (오류코드:" + error.response.status + ")");
                }    
            }else if (error.request){
                // 요청이 됬으나 응답을 받지 못함
                alert('서버 오류입니다. 관리자에게 문의 하세요.')
            }else{
                alert('알수 없는 오류 입니다. 관리자에게 문의 하세요.');
            }   
        });           
           
    }

    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form className="login100-form validate-form p-l-55 p-r-55 p-t-178">                        
                        
                        <span className="login100-form-title"><img src={logoImage} width={180} height={50} alt=""/></span>                        

                        <div className={errors.userId ? 'wrap-input100 validate-input m-b-16 alert-validate' : 'wrap-input100 validate-input m-b-16'} data-validate={errors.userId ? errors.userId.message : ""}>

                            <input className="input100" 
                                   type="text"                                    
                                   placeholder="아이디" 
                                   value={formData.userId}                                                                       
                                   {
                                        ...register("userId", {
                                            required: '아이디를 입력 해주세요.',                                           
                                        })
                                   }
                                   onChange={e => setFormData({ ...formData, userId: e.target.value })}
                            />
                            <span className="focus-input100"></span>
                            
                        </div>

                        <div className={errors.userPassword ? 'wrap-input100 validate-input m-b-16 alert-validate' : 'wrap-input100 validate-input m-b-16'} data-validate={errors.userPassword ? errors.userPassword.message : ""}>
                            <input className="input100"                                 
                                   type="password"                                         
                                   placeholder="비밀번호" 
                                   value={formData.userPassword} 
                                   {
                                        ...register("userPassword", {
                                            required: '패스워드를 입력 해주세요.',
                                            pattern: {
                                                value: /^[a-zA-Z\\d`~!@#$%^&*()-_=+]{8,16}$/,
                                                message: '8~16자 영문, 숫자, 패스워드를 사용하세요.'
                                            }
                                        })
                                   }
                                   onChange={e => setFormData({ ...formData, userPassword: e.target.value })}
                                   onKeyUp={enterKey}
                            />
                            <span className="focus-input100"></span>
                        </div>

                        <div className="text-right p-t-13 p-b-23">
                            <a href="#!" className="txt2">
                                {resultMessage}
                            </a>
                        </div>

                        <div className="container-login100-form-btn">
                            <button className="login100-form-btn" onClick={handleSubmit(onSubmit)}>로그인</button>
                        </div>
                        
                        <div className="flex-col-c mt-4 mb-0">                            
                            <a href="#" onClick={goFileDownload} className="txt3">설비등록양식 다운로드</a>
                        </div>

                        <div className="flex-col-c p-t-120 p-b-40">
                            <span className="txt1 p-b-9">에너지사업부</span>
                            <a href="http://sqisoft.com" className="txt3">에스큐아이소프트(주)</a>
                        </div>
                    </form>
                </div>
            </div>
	    </div>
    );
}

