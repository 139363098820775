import React, { useState, useEffect, useRef, createRef }  from "react";
import axiosInstance from '../../../components/util/AxiosUtil';
import { Button, Box, Modal, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import defaultProfile from "../../../assets/image/noimage.gif"
import { FileUploader } from "react-drag-drop-files";
import Toast from '../../../components/ui/Toast';
import KnrecContent_2 from '../print/KnrecContent_2';
import ReactToPrint from "react-to-print";
import Alert from "../../../components/ui/Alert"

// Form 기본 값
const defaultValues = {    
    siteSeq: '',
    rtuSeq: '',
    siteNm: '',
    instlPldocNo: '',
    sido: '',
    sigungu: '',
    cids: ''
}

const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

function KnrecDocument_2(props) {

    const rtuSeq = new URL(window.location.href).searchParams.get("rtuSeq");    

    // Alert ref 객체
    const alertRef= useRef();

    //toast ref 객체
    const toastRef = useRef();

    // 프린터 ref 객체
    const printRef = createRef();

    //팝업 노출 상태 정보
    const [open, setOpen] = useState(false); 

    //사진대지 정보
    const [data, setData] = useState(defaultValues);

    //RTU 설치 사진 정보
    const [image001, setImage001] = useState({});

    //외함 전경 사진 정보
    const [image002, setImage002] = useState({});

    //RTU 명판 사진 정보
    const [image003, setImage003] = useState({});

    //REMS 2 사진 정보
    const [image004, setImage004] = useState({});

    //REMS 1 사진 정보
    const [image005, setImage005] = useState({});

    //이력 이미지 목록 정보
    const [imageHist, setImageHist] = useState([]);

    //버튼 비활성화 여부
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [focusPhotoTypeCd, setFocusPhotoTypeCd] = useState("");

    const handleImgError = (e) => {        
        e.target.src = defaultProfile;
    }   

    //파일 드래그 앤 드랍 컴포넌트 변경 이벤트
    const handleChange = (file, photoTypeCd) => {        
        save(file, photoTypeCd);
    };

    //데이터 조회
    const getData = () => {

        //사진대지 문서 정보 조회
        axiosInstance.get('/knrecRtuCompleteDoc', {params: {rtuSeq: rtuSeq}}).then((Response)=>{
            if (Response.request.status === 200) { 

                if (!Response.data.info.cids){
                    alertRef.current.handleOpen("확인", "RTU 에 연동된 설비가 없습니다. 설비 정보를 확인 해주세요.");
                }

                setData(Response.data.info);
            }
        });
    }

     // 이미지 저장
     const save = (file, photoTypeCd) => {

        //파일 서버 저장
        const formData = new FormData();
        formData.append("rtuSeq", rtuSeq);
        formData.append("photoTypeCd", photoTypeCd);
        formData.append("img", file);            
        
        axiosInstance.post('/photoDoc', formData, {headers: {'content-type': 'multipart/form-data'}}).then((Response)=>{            

            if (Response){
                if(Response.request.status === 200){     

                    toastRef.current.handleOpen("success", "저장 되었습니다.");                                        

                    //파일 저장 성공시 이미지 다시 조회
                    getImageList();

                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
            }
            
        });  

    };

    // 사진 이력 데이터 문서 사진 등록
    const photoHstToDoc = (histImage) => {

        if (histImage){
            axiosInstance.post('/photoHstToDoc', {rtuSeq: rtuSeq, photoTypeCd: histImage.photoTypeCd, photoSeq: histImage.photoSeq}).then((Response)=>{                    

                if (Response){
                    if(Response.request.status === 200){     
    
                        toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
    
                        //파일 저장 성공시 이미지 다시 조회
                        getImageList();
    
                        setOpen(false);
        
                    }else{                              
                        toastRef.current.handleOpen("error", "오류 입니다. 데이터를 삭제 할 수 없습니다.");                                        
                    }                  
                }else{
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 삭제 할 수 없습니다.");                                        
                }
                
            });  
        }

    };

    // 이미지 삭제
    const del = (photoTypeCd, e) => {

        axiosInstance.delete('/photoDoc', {params: {rtuSeq: rtuSeq, photoTypeCd: photoTypeCd}}).then((Response)=>{                    

            if (Response){
                if(Response.request.status === 200){     

                    toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        

                    //파일 삭제 성공시 이미지 다시 조회
                    getImageList();
    
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 삭제 할 수 없습니다.");                                        
                }                  
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 삭제 할 수 없습니다.");                                        
            }
            
        });  
    }

    // 이미지 조회
    const getImageList = () => {          
        
        axiosInstance.get('/photoDocList', {params: {rtuSeq: rtuSeq}}).then((Response)=>{
            
            if(Response.request.status === 200){

                //초기화
                setImage001({});                
                setImage002({});                
                setImage003({});                
                setImage004({}); 
                setImage005({}); 

                Response.data.list.map(function(file){

                    //DB 결과값 이미지 파일 생성
                    const newBlob = new Blob([convertToBlob(file.blob)], {type:file.fileTypeNm});                           
                    const createFile = new File([newBlob], file.fileNm,{type:file.fileTypeNm});                                        

                    //이미지 랜더링 데이터 생성
                    const blod = 'data:' + file.fileTypeNm + ';base64,' + file.blob;

                    //State 추가
                    const item = {
                                    photoTypeCd: file.photoTypeCd, 
                                    file: createFile, 
                                    blod: blod
                                 };

                    if (item.photoTypeCd === "001"){
                        setImage001(item); 
                    }else if (item.photoTypeCd === "002"){
                        setImage002(item); 
                    }else if (item.photoTypeCd === "003"){
                        setImage003(item); 
                    }else if (item.photoTypeCd === "004"){
                        setImage004(item); 
                    }else if (item.photoTypeCd === "005"){
                        setImage005(item); 
                    }   

                });   

                
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 조회 할 수 없습니다.");                   
            }
        });  
    }

    // 이미지 이력 목록 조회
    const getImageHistList = (photoTypeCd) => {          
        
        axiosInstance.get('/photoHstList', {params: {rtuSeq: rtuSeq, photoTypeCd: photoTypeCd}}).then((Response)=>{
            
            if(Response.request.status === 200){

                //초기화
                setImageHist([]);

                Response.data.list.map(function(file){

                    if (file.ord === 0 || file.ord === 1){

                        //DB 결과값 이미지 파일 생성
                        const newBlob = new Blob([convertToBlob(file.blob)], {type:file.fileTypeNm});                           
                        const createFile = new File([newBlob], file.fileNm,{type:file.fileTypeNm});                                        

                        //이미지 랜더링 데이터 생성
                        const blod = 'data:' + file.fileTypeNm + ';base64,' + file.blob;

                        //State 추가
                        const item = {
                                        photoSeq: file.photoSeq,
                                        photoTypeCd: file.photoTypeCd, 
                                        file: createFile, 
                                        blod: blod
                                    };

                        setImageHist(prev => [...prev, item]);       
                    }
                });   

                
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 조회 할 수 없습니다.");   
            }
        });  
    }

    // PDF 다운로드
    const PDFDownload = () => {
        axiosInstance.post('/knrecRtuCompleteDocPdf', {rtuSeqList: rtuSeq}, {responseType: "blob"}).then((Response)=>{            
            if (Response){
                const blob = new Blob([Response.data]);
                const fileObjectUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = fileObjectUrl;
                link.style.display = "none";

                const disposition = Response.headers["content-disposition"];
                link.download = decodeURI(
                    disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1].replace(/['"]/g, "")
                );
                
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(fileObjectUrl);
                
            }else{
                toastRef.current.handleOpen("error", "시스템 오류 입니다. 관리자에게 문의 하세요.");
            }
        }); 
    }

    // Base64 문자열 Blob 타입 변경
    const convertToBlob = (base64) =>{
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        return byteArray;
    }

    // 붙여넣기 이벤트
    document.onpaste = function(pasteEvent) {        
        if (focusPhotoTypeCd !== ""){
            const item = pasteEvent.clipboardData.items[0];
    
            if (item.type.indexOf("image") === 0)
            {
                const orgFile = item.getAsFile();
                const fileExtension = orgFile.name.split('.')[1];
                const fileName = Date.now() + '.' + fileExtension;        
                const newFile = new File([orgFile], fileName, {type: orgFile.type});

                save(newFile, focusPhotoTypeCd);
            }
        }    
    }

    useEffect(() => {

        getData();

        getImageList();

    }, []);

    return (

        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)", "height": "100%" }}>

            <Grid container spacing={2} sx={{ height:'100%'}}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>

                    <Grid justifyContent="center" alignItems="center" sx={{ display: 'flex' }}>

                        <Grid container justifyContent="center" alignItems="center" sx={{ display: 'flex', width:'100%', maxWidth:950 }}>
                            
                            <Grid container justifyContent="center" alignItems="center" sx={{ display: 'flex', width:'100%', maxWidth:950 }}>

                                <Grid className="title" xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center" sx={{ display: 'flex', mb: 4 }}>
                                    <p fontWeight="600" className="css-1pr8naw">모니터링 RTU 설치 사진대지</p>
                                </Grid>

                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={3} xl={3} sx={{ display: 'flex', pt:0, pb:0, minHeight:48}}>
                                    설치자명        
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={5} xl={5} sx={{ display: 'flex', pt:0, pb:0, minHeight:48}}>
                                    {data.siteNm}
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:48}}>
                                    설치계획번호
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={2} xl={2} sx={{ display: 'flex', pt:0, pb:0, minHeight:48}}>
                                    {data.instlPlndocNo}
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={3} xl={3} sx={{ display: 'flex', pt:0, pb:0, minHeight:48}}>
                                    CID
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex', pt:0, pb:0, minHeight:48}}>
                                    {data.cids}
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={3} xl={3} sx={{ display: 'flex', pt:0, pb:0, minHeight:48}}>
                                    주소
                                </Grid>
                                <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex', pt:0, pb:0, minHeight:48}}>
                                    {data.adr}
                                </Grid>
                                <Grid container xs={12} sm={12} md={12} lg={6} xl={6} sx={{ mt:1, pt:0, pb:0}}>                                    
                                    <Grid className={focusPhotoTypeCd === "001" ? "focus border-1" : "border-1"} justifyContent="center" alignItems="center" onClick={()=>setFocusPhotoTypeCd("001")} xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', p:0, height:360}}>
                                        {
                                            image001.blod ?
                                            <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "001")}} name="file" types={fileTypes}> 
                                                <img src={image001.blod} alt="이미지" height="100%" width="100%" style={{maxHeight:350}} onError={handleImgError}/>  
                                            </FileUploader> :
                                            <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "001")}} name="file" types={fileTypes} >
                                                <div className="mouseOverCustom"
                                                    style={{
                                                        "borderRadius":"10px",
                                                        "padding": "15px",
                                                        "border" : "3px dashed #1976d2",
                                                        "height" : "280px",
                                                        "width"  : "380px"
                                                    }}
                                                >영역을 클릭 또는 이미지 파일을 끌어 놓아 주세요.</div>
                                            </FileUploader>
                                        }                                        
                                    </Grid>                                    
                                    <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', pt:0, pb:0, height:45.91}}>
                                        RTU 설치 사진                                         
                                        <Button size="small" variant="contained" onClick={()=> { getImageHistList("001"); setOpen(true); }} sx={{m:1}}>변경</Button>
                                        <Button size="small" variant="contained" onClick={()=>del("001")} sx={{m:1}}>삭제</Button>
                                    </Grid>                                       
                                </Grid>
                                <Grid container xs={12} sm={12} md={12} lg={6} xl={6} sx={{ mt:1, pt:0, pb:0, minHeight:328}}>                                    
                                    <Grid className={focusPhotoTypeCd === "002" ? "focus border-1" : "border-1"} justifyContent="center" alignItems="center" onClick={()=>setFocusPhotoTypeCd("002")} xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', p:0, height:360}}>
                                        {
                                            image002.blod ?
                                            <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "002")}} name="file" types={fileTypes}> 
                                                <img src={image002.blod} alt="이미지" height="100%" width="100%" style={{maxHeight:350}} onError={handleImgError}/>  
                                            </FileUploader> :
                                            <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "002")}} name="file" types={fileTypes} >
                                                <div className="mouseOverCustom"
                                                    style={{
                                                        "borderRadius":"10px",
                                                        "padding": "15px",
                                                        "border" : "3px dashed #1976d2",
                                                        "height" : "280px",
                                                        "width"  : "380px"
                                                    }}
                                                >영역을 클릭 또는 이미지 파일을 끌어 놓아 주세요.</div>
                                            </FileUploader>
                                        } 
                                    </Grid>                                    
                                    <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', pt:0, pb:0, height:45.91}}>
                                        외함 전경 사진 
                                        <Button size="small" variant="contained" onClick={()=> { getImageHistList("002"); setOpen(true); }} sx={{m:1}}>변경 1</Button>
                                        <Button size="small" variant="contained" onClick={()=> { getImageHistList("007"); setOpen(true); }} sx={{m:1}}>변경 2</Button>
                                        <Button size="small" variant="contained" onClick={()=>del("002")} sx={{m:1}}>삭제</Button>
                                    </Grid>                                    
                                </Grid>
                                <Grid container xs={12} sm={12} md={12} lg={6} xl={6} sx={{ mt:1, mb:1, pt:0, pb:0, minHeight:328}}>                                    
                                    <Grid className={focusPhotoTypeCd === "003" ? "focus border-1" : "border-1"} justifyContent="center" alignItems="center" onClick={()=>setFocusPhotoTypeCd("003")} xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', p:0, height:466}}>
                                        {
                                            image003.blod ?
                                            <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "003")}} name="file" types={fileTypes}> 
                                                <img src={image003.blod} alt="이미지" height="100%" width="100%" style={{maxHeight:450}} onError={handleImgError}/>  
                                            </FileUploader> :
                                            <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "003")}} name="file" types={fileTypes} >
                                                <div className="mouseOverCustom"
                                                    style={{
                                                        "borderRadius":"10px",
                                                        "padding": "15px",
                                                        "border" : "3px dashed #1976d2",
                                                        "height" : "410px",
                                                        "width"  : "380px"
                                                    }}
                                                >영역을 클릭 또는 이미지 파일을 끌어 놓아 주세요.</div>
                                            </FileUploader>
                                        } 
                                    </Grid>                                    
                                    <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', pt:0, pb:0, height:45.91}}>
                                        RTU 명판 사진 
                                        <Button size="small" variant="contained" onClick={()=> { getImageHistList("003"); setOpen(true); }} sx={{m:1}}>변경</Button>
                                        <Button size="small" variant="contained" onClick={()=>del("003")} sx={{m:1}}>삭제</Button>
                                    </Grid>                                    
                                </Grid>

                                <Grid container xs={12} sm={12} md={12} lg={6} xl={6} sx={{ mt:1, mb:1, pt:0, pb:0, minHeight:328}}>                                    
                                    <Grid className={focusPhotoTypeCd === "004" ? "focus border-1" : "border-1"} justifyContent="center" alignItems="center" onClick={()=>setFocusPhotoTypeCd("004")} xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', p:0, height:210}}>
                                        {
                                            image004.blod ?
                                            <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "004")}} name="file" types={fileTypes}> 
                                                <img src={image004.blod} alt="이미지" height="100%" width="100%" style={{maxHeight:140}} onError={handleImgError}/>  
                                            </FileUploader> :
                                            <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "004")}} name="file" types={fileTypes} >
                                                <div className="mouseOverCustom"
                                                    style={{
                                                        "borderRadius":"10px",
                                                        "padding": "15px",
                                                        "border" : "3px dashed #1976d2",
                                                        "height" : "140px",
                                                        "width"  : "380px"
                                                    }}
                                                >영역을 클릭 또는 이미지 파일을 끌어 놓아 주세요.</div>
                                            </FileUploader>
                                        } 
                                    </Grid>                                    
                                    <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', pt:0, pb:0, height:45.91}}>
                                        REMS 1 상태 
                                        <Button size="small" variant="contained" onClick={()=> { getImageHistList("004"); setOpen(true); }} sx={{m:1}}>변경</Button>
                                        <Button size="small" variant="contained" onClick={()=>del("004")} sx={{m:1}}>삭제</Button>
                                    </Grid>  

                                    <Grid className={focusPhotoTypeCd === "005" ? "focus border-1" : "border-1"} justifyContent="center" alignItems="center" onClick={()=>setFocusPhotoTypeCd("005")} xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', p:0, height:210}}>
                                        {
                                            image005.blod ?
                                            <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "005")}} name="file" types={fileTypes}> 
                                                <img src={image005.blod} alt="이미지" height="100%" width="100%" style={{maxHeight:140}} onError={handleImgError}/>  
                                            </FileUploader> :
                                            <FileUploader label="이미지 파일을 여기로 끌어 놓아 주세요" handleChange={(file) => {handleChange(file, "005")}} name="file" types={fileTypes} >
                                                <div className="mouseOverCustom"
                                                    style={{
                                                        "borderRadius":"10px",
                                                        "padding": "15px",
                                                        "border" : "3px dashed #1976d2",
                                                        "height" : "140px",
                                                        "width"  : "380px"
                                                    }}
                                                >영역을 클릭 또는 이미지 파일을 끌어 놓아 주세요.</div>
                                            </FileUploader>
                                        } 
                                    </Grid>                                    
                                    <Grid className="border-1" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', pt:0, pb:0, height:45.91}}>
                                        REMS 2 상태 
                                        <Button size="small" variant="contained" onClick={()=> { getImageHistList("005"); setOpen(true); }} sx={{m:1}}>변경</Button>
                                        <Button size="small" variant="contained" onClick={()=>del("005")} sx={{m:1}}>삭제</Button>
                                    </Grid>                                   
                                </Grid>


                                <Grid xs={12} sm={12} md={3} lg={6} xl={6} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}></Grid>

                                <Grid xs={12} sm={12} md={3} lg={2} xl={2} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}>                                                                        
                                    <Button fullWidth variant="contained" size="large" color="primary" startIcon={<PrintIcon /> } onClick={PDFDownload} sx={{ m:1 }}>PDF</Button>
                                </Grid>

                                <Grid xs={12} sm={12} md={3} lg={2} xl={2} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}>                                                                        
                                    <ReactToPrint trigger={() => <Button fullWidth variant="contained" size="large" color="primary" startIcon={<PrintIcon />} sx={{ m:1 }}>인쇄</Button>} content={() => printRef.current} /> 
                                </Grid>

                                <Grid xs={12} sm={12} md={3} lg={2} xl={2} justifyContent="flex-end" alignItems="center" sx={{ display: 'flex'}}>                                      
                                    <Button fullWidth variant="contained" size="large" color="primary" sx={{ m: 1 }} startIcon={<CloseIcon />} onClick={()=>window.close()}>닫기</Button>   
                                </Grid>

                            </Grid>
                            
                        </Grid>
                    </Grid>
                                   
                </Grid>
                
            </Grid> 

            <Modal open={open} onClose={e => setOpen(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                <Box className="Modal">
                    <h3 id="parent-modal-title">사진 선택 <h6 style={{marginTop:5}}>(사진 등록화면에서 업로드 한 사진 입니다.)</h6></h3>
                    <Divider sx={{ mt:2 }} />

                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1, mt: 1 }}>                        
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:1, height:300}}>
                            <Box sx={{border:"1px solid silver", width:"100%", height:"90%", textAlign:"center"}}>
                                <img src={imageHist.length > 0 && imageHist[0].blod ? imageHist[0].blod : ""} alt="이미지" height="100%" width="100%" onError={handleImgError}/>                                                    
                            </Box>
                            <Button fullWidth variant="contained" size="large" sx={{mt:1}} onClick={(e)=>{photoHstToDoc(imageHist.length > 0 && imageHist[0] ? imageHist[0] : null)}}>선택</Button>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:1, height:300}}>
                            <Box sx={{border:"1px solid silver", width:"100%", height:"90%", textAlign:"center"}}>
                                <img src={imageHist.length > 1 && imageHist[1].blod ? imageHist[1].blod : ""} alt="이미지" height="100%" width="100%" onError={handleImgError}/>                                                    
                            </Box>
                            <Button fullWidth variant="contained" size="large" sx={{mt:1}} onClick={(e)=>{photoHstToDoc(imageHist.length > 1 && imageHist[1] ? imageHist[1] : null)}}>선택</Button>
                        </Grid>
                    </Grid>   

                    <Divider sx={{ mt:1, mb:3 }} />
                    
                    <Grid container direction="row" justifyContent="flex-end">                        
                        <Button variant="contained" size="large" color="primary" sx={{ ml: 2 }} onClick={e => setOpen(false)}>닫기</Button>
                    </Grid>
                    
                </Box>
            </Modal>  

            <Alert ref={alertRef} />

            <Toast ref={toastRef} />         

            {/* 프린터 인쇄 영역 */}
            <KnrecContent_2 ref={printRef} data={data} image001={image001} image002={image002} image003={image003} image004={image004} image005={image005} />

        </div>
    );
}

export default KnrecDocument_2;