import React, {useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Unstable_Grid2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomTextField from "../components/ui/CustomTextField";
import { Divider, Button } from '@mui/material';
import axiosInstance from '../components/util/AxiosUtil';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Toast from '../components/ui/Toast';
import { viewModeType } from '../components/const/CommCode'
import Confirm from '../components/ui/Confirm';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import CustomTreeView from '../components/ui/CustomTreeView'


let defaultValues = {
    menuId	: '',
    upperMenuId	: '',	
    menuName	: '',
    menuDpth	: '',
    url	: '',
    tag	: '',
    sortOrder : '',
    menuDesc : ''
}

function MenuMg() {
    // [modal] 등록,수정 Form 기본세팅
    const { 
        handleSubmit, 
        reset, 
        control, 
        formState: { errors } 
    } = useForm( {defaultValues} );

    //상위 메뉴 목록
    const [topMenuList, setTopMenuList] = useState([]);
    //하위 메뉴 목록
    const [botMeuuList, setBotMenuList] = useState([]);
    //서버 요청 상태 정보
    const [viewMode, setViewMode] = useState(viewModeType.New);
    //상위 메뉴Id
    const [upperMenuId, setUpperMenuId] = useState('');
    //현재 선택중인 메뉴 정보
    const [selectedInfo, setSelectedInfo] = useState([{menuId: '', menuName: ''}]);
    //전체 메뉴 리스트
    const [totalMenuList, setTotalMenuList] = useState([]);
    //메뉴 상세정보 부가설명
    const [formStateText, setFormStateText] = useState('');
    //toast ref 객체
    const toastRef = useRef();
    //메뉴아이디 disabled 처리
    const [menuIdDisabled, setMenuIdDisabled] = useState(false);
    //confrim ref 객체
    const confirmRef = useRef();
    
    useEffect(() => {
        makeMenuList();
    }, []);

    const makeMenuList = () => {
        //메뉴 목록 생성
        axiosInstance.get('/menuList').then((Response)=>{
            if(Response.request.status === 200) {
                const list =  Response.data.list;
                const bigMenuList = list.filter((object) => {
                    return object.menuDpth === 0 && object.menuId !== 'M0000100';
                })
                const smallMenuList = list.filter((object) => {
                    return object.menuDpth === 1;
                })

                setTopMenuList(bigMenuList);
                setBotMenuList(smallMenuList);
                setTotalMenuList(list);
            }
        });
    }

    //nodeid 선택시 이벤트
    const handleSelectedItems = ( nodeId) => {
        axiosInstance.get('/menu', {params: {menuId: nodeId}}).then((Response)=>{
            if(Response.request.status === 200){
                const info = Response.data.info;
                setViewMode(viewModeType.Edit);
                setUpperMenuId(info.upperMenuId);
                reset(info);
                setMenuIdDisabled(true);
                setSelectedInfo({menuId: info.menuId ,menuName:info.menuName});

                const text = " ( " + info.menuName+ " 조회 ) ";
                setFormStateText(text);
            }else{
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 조회 할 수 없습니다.");
            }
        });   

    }
    //배열에 속해있는 값 찾기
    const isFindUpMenuNm = (e) => {
        if(e.menuId === upperMenuId) { 
            return true;
        };
    }

    //추가버튼 클릭 이벤트
    const regClickEvent = () => {

        let upperId = upperMenuId;
        let menuLv = 0;
        const findUpMenuNm = totalMenuList.find(isFindUpMenuNm);

        //메뉴 상세정보 부가설명 및 menuDpth 설정
        if(findUpMenuNm){
            menuLv = 1;
            setFormStateText('( ' + findUpMenuNm.menuName + ' 하위메뉴 생성 )');
        }else if(selectedInfo.menuId === 'M0000100'){
            menuLv = 0;
            setFormStateText(' ( 상위 메뉴 생성 ) ')
            upperId = '';
        }else{
            menuLv = 1;
            setFormStateText('( ' + selectedInfo.menuName + ' 하위메뉴 생성 )');
            upperId = selectedInfo.menuId;
        }

        //모달 초기화
        reset(defaultValues = {
            menuId	: '',
            upperMenuId	: upperId,	
            menuName	: '',
            menuDpth	: menuLv,
            url	: '',
            tag	: '',
            sortOrder : '',
            menuDesc : ''
        });

        setMenuIdDisabled(false);

        //등록으로 상태변경
        setViewMode(viewModeType.New);

    }

  
    
    const onSubmit = (data) => {

        if (viewMode === viewModeType.New){
            axiosInstance.post('/menu', {...data}).then((Response)=>{            
                if(Response.request.status === 200 ){  
                    
                    if(Response.data.resultCode === "SUCCESS"){
                        makeMenuList();
                        toastRef.current.handleOpen("success", "저장 되었습니다.");  
                    
                    }else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage );
                    }
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            });        
        }else if (viewMode === viewModeType.Edit){
            axiosInstance.put('/menu', {...data}).then((Response)=>{            
                if(Response.request.status === 200){     
                    makeMenuList();
                    toastRef.current.handleOpen("success", "저장 되었습니다.");  
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }
            });
        }
    }
    //삭제버튼 클릭시 이벤트
    const delClickEvent = () => {
        const delMenuId = selectedInfo.menuId;
        const delMenuName = selectedInfo.menuName;
        
        confirmRef.current.handleOpen("메뉴 정보 삭제", "'" + delMenuName +"' 메뉴 정보를 삭제 합니다. 계속 진행 하시겠습니까?", delMenuId);
      
    }

    //삭제 팝업 수락시 
    const del = (delMenuId) =>{
        axiosInstance.delete('/menu', {params: {menuId: delMenuId}}).then((Response)=>{
            
            if(Response.request.status === 200){    
                makeMenuList();
                toastRef.current.handleOpen("success", "삭제 되었습니다.");      
            }else{                              
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }                  
        }); 
    }

    //[modal] input css style
    const textFileStyled = { mt:1, width: '100%', 'textAlign': 'center' };
    return (
        <div style={{ 
            "padding": "16px 24px", 
            "color": "rgb(68, 89, 110)", 
            "height": "100%" 
        }}>
             <Grid container spacing={2}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>
                    <div style={{"marginBottom": "28px", "marginTop":"16px"}}>
                        <p fontWeight="600" className="css-1pr8naw">메뉴 관리</p>
                        <p className="css-3vvtry">PoSE / 시스템 관리 /메뉴 관리</p>
                    </div>         
                </Grid>
                <Grid 
                    xs={12} sm={12} md={12} lg={6} xl={6}
                    sx={{p:1, minHeight:'780px'}}
                >
                    <Card elevation={4} sx={{height: '100%'}}>
                        {/* 메뉴 리스트 */}
                        <CardHeader
                            titleTypographyProps={{fontSize: 15}}
                            title="메뉴 리스트"
                            action={
                                <Stack direction="row" spacing={2}>
                                    <Button variant="contained" 
                                            size="small" 
                                            color="primary" 
                                            sx={{ ml:2 }}  
                                            startIcon={<AddCircleOutlineIcon />}
                                            onClick={regClickEvent}
                                    >추가
                                    </Button>
                                    <Button variant="contained" 
                                            size="small" 
                                            color="error" 
                                            sx={{ m:2 }} 
                                            startIcon={<RemoveCircleOutlineRoundedIcon />}
                                            onClick={delClickEvent}
                                    >삭제
                                    </Button>
                                </Stack>
                            }
                        >
                        </CardHeader>
                        <Divider/>
                        <Grid sx={{p:2}}>
                            {/*TreeView*/}
                            <CustomTreeView content="메인" itemId="M0000100" onItemClick={() => handleSelectedItems("M0000100")}>
                                {topMenuList.map((value) => {
                                    const list = botMeuuList.filter((botValue) => botValue.upperMenuId === value.menuId);
                                    return(
                                        <CustomTreeView content={value.menuName} key={value.menuId} itemId={value.menuId} onItemClick={() => handleSelectedItems(value.menuId)}>
                                            {list.map((value) => (
                                                <CustomTreeView content={value.menuName} key={value.menuId} itemId={value.menuId} onItemClick={() => handleSelectedItems(value.menuId)}/>
                                            ))}
                                        </CustomTreeView>
                                    )
                                })}
                            </CustomTreeView>
                          
                        </Grid>

                    </Card>
                </Grid>
                {/* 메뉴 상세 정보 */}
                <Grid 
                    xs={12} sm={12} md={12} lg={6} xl={6}
                    sx={{p:1, minHeight:'780px'}}
                >
                    <Card elevation={4} sx={{height: '100%'}}>
                        <CardHeader
                            titleTypographyProps={{fontSize: 15}}
                            title={"메뉴 상세정보 " + formStateText}
                        >   
                        </CardHeader>
                        <Divider/>
                        <Grid sx={{pr:2, pl:2}}>
                            {/* 메뉴 아이디 */}
                            <CustomTextField fullWidth
                                            name="menuId"
                                            label="*메뉴 아이디"
                                            rules={{ required: "메뉴 아이디가 필요합니다." }}  
                                            control={control}
                                            disabled ={menuIdDisabled}                                                         
                                            sx={textFileStyled}
                                            errors={errors.menuId} 
                            /> 
                        </Grid>
                        <Grid sx={{pr:2, pl:2}}>
                            {/*상위 메뉴 아이디 */}
                            <CustomTextField fullWidth
                                            name="upperMenuId"
                                            label="상위 메뉴 아이디"
                                            control={control}                                                           
                                            sx={textFileStyled} 
                                            errors={errors.upperMenuId}
                            /> 
                        </Grid>
                        <Grid sx={{pr:2, pl:2}}>
                            {/* 메뉴명 */}
                            <CustomTextField fullWidth
                                            name="menuName"
                                            label="*메뉴명"
                                            rules={{ required: "메뉴명이 필요합니다." }}  
                                            control={control}                                                           
                                            sx={textFileStyled} 
                                            errors={errors.menuName}
                            /> 
                        </Grid>
                        <Grid sx={{pr:2, pl:2}}>
                            {/* 메뉴 레벨 */}
                            <CustomTextField fullWidth
                                            name="menuDpth"
                                            label="*메뉴 레벨"
                                            rules={{ required: "메뉴 레벨이 필요합니다." }}
                                            control={control}     
                                            type="number"                                                      
                                            sx={textFileStyled} 
                                            errors={errors.menuDpth}
                            /> 
                        </Grid>
                        <Grid sx={{pr:2, pl:2}}>
                            {/* URL */}
                            <CustomTextField fullWidth
                                            name="url"
                                            label="URL"
                                            control={control}                                                           
                                            sx={textFileStyled} 
                                            errors={errors.url}
                            /> 
                        </Grid>
                        <Grid sx={{pr:2, pl:2}}>
                            {/* 아이콘명 */}
                            <CustomTextField fullWidth
                                            name="tag"
                                            label="아이콘명"
                                            control={control}                                                           
                                            sx={textFileStyled} 
                                            errors={errors.tag}
                            /> 
                        </Grid>
                        <Grid sx={{pr:2, pl:2}}>
                            {/* 정렬 순서 */}
                            <CustomTextField fullWidth
                                            name="sortOrder"
                                            label="정렬 순서"
                                            control={control}  
                                            type="number"                                                         
                                            sx={textFileStyled} 
                                            errors={errors.sortOrder}
                            /> 
                        </Grid>
                        <Grid sx={{pr:2, pl:2}}>
                            {/* 설명 */}
                            <CustomTextField fullWidth
                                            name="menuDesc"
                                            label="설명"
                                            control={control}                                                   
                                            sx={textFileStyled} 
                                            errors={errors.menuDesc}
                            /> 
                        </Grid>
                        {/* 조회, 등록버튼*/}
                        <Grid  
                            justifyContent={'flex-end'}
                            alignItems="center" 
                            container
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                        >
                            <Button variant="contained" 
                                    size="large" 
                                    color="primary" 
                                    sx={{ mt:2, mr:2, mb:2, ml:2 }}  
                                    startIcon={<SaveAltIcon />}
                                    onClick={handleSubmit(onSubmit)}
                            >저장
                            </Button>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Toast ref={toastRef} />
            <Confirm ref={confirmRef} ok={del} />
        </div>
    );
}

export default MenuMg;