import React from 'react'
import { Autocomplete, TextField } from '@mui/material';
import { Controller } from "react-hook-form";

const CustomAutocomplete = (props) => {

    return (
        <Controller name={props.name} 
                    control={props.control} 
                    rules={props.rules} 
                    render={({ field: { ref, onChange, ...field } }) => (

            <Autocomplete 
                    size={props.size}
                    options={props.options}        
                    disabled={props.disabled}

                    getOptionLabel={(option) => {
                        return option ? option[props.text] : ""
                    }}                                                                                

                    onChange={(_, option) => {                        
                        option ? onChange(option[props.value]) : onChange("");
                        if (props.onChange){
                            props.onChange(option);
                        }                        
                    }}                                        

                    isOptionEqualToValue={(option, value) => {                        
                        return (value === undefined || option[props.value] == value[props.value])
                    }}

                    value={props.options.find((option) => {                                
                        return (option[props.value] == props.getValues(props.name))
                    }) || ""}

                    sx={{ width: '100%' }}                                                           
                    renderInput={(params) => <TextField {...params} 
                                                        {...field}                                                         
                                                        inputRef={ref} 
                                                        label={props.label} 
                                                        variant={props.variant ? props.variant : "outlined" } 
                                                        error={props.errors}
                                                        helperText={props.errors ? props.errors.message : ""}
                                />} 
            />  
        )} /> 
    )
  };
  
  export default CustomAutocomplete