import React, {useEffect, useState, useRef} from "react";
import axiosInstance from '../components/util/AxiosUtil';
import CustomPieChart from '../components/ui/CustomPieChart';
import CustomColumnChart from '../components/ui/CustomColumnChart';
import CustomColumnMuiltChart from '../components/ui/CustomColumnMuiltChart';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material'
import Typography from '@mui/material/Typography';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import KakaoMap from "../components/util/KakaoMap";
import Carousel from 'react-material-ui-carousel';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import $ from 'jquery';
import '../assets/css/Dashboard.css';

function Main() {
    
    //Map ref 객체
    const mapRef = useRef();

    //장애 사이트 플레이 여부
    const [isErrSitePlay, setIsErrSitePlay] = useState(true);

    //영업 현황 플레이 여부
    const [isSalesPlay, setIsSalesPlay] = useState(true);

    //장애 현황 플레이 여부
    const [isErrStatePlay, setIsErrStatePlay] = useState(true);

    //RTU장애 목록
    const [rtuErrorList, setRtuErrorList] = useState([]);

    //업무 상태 건수
    const [workCnt, setWorkCnt] = useState({requestCnt:0, progressCnt:0, cancelCnt:0, completeCnt:0});

    //RTU 에너지원별 설치 건수
    const [rtuNrnGentCnt, setRtuNrnGentCnt] = useState([]);

    //RTU 모델별 설치 건수
    const [rtuModelCnt, setRtuModelCnt] = useState([]);

    //월 category
    const [monthCategory, setMonthCategory] = useState([]);

    //RTU 월별 모델 설치 건수
    const [rtuModelMonthCnt, setRtuModelMonthCnt] = useState([]);

    //RTU 업체별 설치 건수
    const [rtuAreaCnt, setRtuAreaCnt] = useState([]);

    //RTU 장애 목록
    const [rtuEvtCnt, setRtuEvtCnt] = useState([]);

    //RTU 장애 일자 목록
    const [rtuEvtDates, setRtuEvtDates] = useState([]);
    
    const onChange = (event, value) => {        

        mapSetMaker(rtuErrorList[event * 2]);

        try{
            setTimeout(() =>   
                mapSetMaker(rtuErrorList[(event * 2) + 1]),
            4000);          
        }catch(e){
            
        }        
    };

    const mapSetMaker = (rtuInfo) => {
        try{
            mapRef.current.handleSetMaker(rtuInfo);      
        }catch(e){
            
        }           
    }

    // RTU장애 사이트 UI 생성
    const rtuErrorRendering = () => {
        const result = [];

        for (let i = 0; i < rtuErrorList.length; i++){
            if (i % 2 === 0){                
                if (rtuErrorList.length > (i+1)){
                    result.push(
                        <Paper name="carousel-paper" sx={{height:'100%', boxShadow:"none"}}>                                
                            <Grid container sx={{height:'100%'}} xs={12} >
                                <Grid xs={6} sm={6} md={6} lg={6} xl={6} sx={{pr:1, pl:1}}>
                                    {
                                        errorCard(rtuErrorList[i])
                                    }
                                </Grid>                            
                                <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
                                    {
                                        errorCard(rtuErrorList[i+1])
                                    }
                                </Grid>
                            </Grid>                                
                        </Paper> 
                    );
                }else{
                    result.push(
                        <Paper name="carousel-paper" sx={{height:'100%', boxShadow:"none"}}>                                
                            <Grid container sx={{height:'100%'}} xs={12} >
                                <Grid xs={6} sm={6} md={6} lg={6} xl={6} sx={{pr:1, pl:1}}>
                                    {
                                        errorCard(rtuErrorList[i])
                                    }
                                </Grid>  
                                <Grid xs={6} sm={6} md={6} lg={6} xl={6} sx={{pr:1, pl:1}}>
                                    {
                                        errorCard(rtuErrorList[0])
                                    }
                                </Grid>                                                          
                            </Grid>                                
                        </Paper> 
                    );
                }                
            }
        }
    
        return result;
    }

    const errorCard = (rtuInfo) => (
        rtuInfo != undefined ?
        <div className="errCard" onClick={()=>mapRef.current.handleSetMaker(rtuInfo)}>
            <div className="header" title={rtuInfo.siteNm + " / " + rtuInfo.nrnGentNm + " [" + rtuInfo.connTypeNm + "]"}>
                {rtuInfo.siteNm} / {rtuInfo.nrnGentNm} [{rtuInfo.connTypeNm}]
            </div>   
            <div className="content-title">
                ● {rtuInfo.evtDtl}
            </div>                         
            <div className="content">
                {rtuInfo.keaLastRcpDt}
            </div>            
        </div>
        : ""
    );

    const stateCard = (title, content, unit, backgroundColor, color) => (
        <Box className="stateCard" sx={{height:"100%", backgroundColor:{backgroundColor}, color:{color}, p:2}}>
            <Grid container sx={{height:"100%"}}>
                <Grid xs={12} sx={{height:"50%"}}>
                    <div className="text-md" style={{marginBottom:7, fontSize:17}}>{title} </div>
                </Grid>
                <Grid xs={12} sx={{height:"50%", textAlign:"right", paddingRight:1}}>
                    <div className="text-lg">{content}<span className="text-sm" style={{marginLeft:5}}>{unit}</span></div>                
                </Grid>
            </Grid>
        </Box>
    );

    const options_6 = {
        chart: {
            type: 'bar',
            height: 30 + '%',    
            backgroundColor: 'transparent'	
        },
        title: {
            text: '[장애 발생현황]',
            align: 'center',            
            y: 2,
            style: {                            
                fontSize: '14px',                
                textTransform: 'none'
            }
        },          
        xAxis: {
            categories: rtuEvtDates,
            title: {
                text: null
            },
            gridLineWidth: 1,
            lineWidth: 0
        },
        yAxis: {
            min: 0,
            title: {
                text: '장애 (건수)',
                align: 'high'
            },
            labels: {
                overflow: 'justify'
            },
            gridLineWidth: 0
        },
        tooltip: {
            valueSuffix: ' 건'
        },
        colors: [
            '#909090', 
            '#bf2a3d'
        ],
        plotOptions: {
            bar: {
                borderRadius: '50%',
                dataLabels: {
                    enabled: true
                },
                groupPadding: 0.1
            },
            column: {
                colorByPoint: true
            }
        },
        legend: {            
            align: 'right',
            verticalAlign: 'top',
            x: 10,
            y: 5,
            floating: true,
            borderWidth: 1,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
            shadow: true
        },
        credits: {
            enabled: false
        },    
        series: rtuEvtCnt
    }

    useEffect(() => {        
        
        //RTU 장애 목록 조회
        axiosInstance.get('/rtuErrorList').then((Response)=>{
            if (Response.request.status === 200) {                  
                setRtuErrorList(Response.data.list);
            }
        });   

        //업무요청 상태 건수 조회
        axiosInstance.get('/workCnt').then((Response)=>{
            if (Response.request.status === 200) {                                  
                setWorkCnt(Response.data.info);
            }
        });   

        //RTU 에너지원별 설치 건수
        axiosInstance.get('/rtuNrnGentCnt').then((Response)=>{
            if (Response.request.status === 200) {                                  
                setRtuNrnGentCnt(Response.data.list);
            }
        });   
        
        //RTU 모델별 설치 건수
        axiosInstance.get('/rtuModelCnt').then((Response)=>{
            if (Response.request.status === 200) {                                  
                setRtuModelCnt(Response.data.list);
            }
        });   

        //RTU 월별 모델 설치 건수
        axiosInstance.get('/rtuModelMonthCnt').then((Response)=>{
            if (Response.request.status === 200) {         
                setMonthCategory(Response.data.category);
                setRtuModelMonthCnt(Response.data.list);
            }
        });  

        //RTU 지역별 설치 건수
        axiosInstance.get('/rtuAreaList').then((Response)=>{
            if (Response.request.status === 200) {                                  
                setRtuAreaCnt(Response.data.list);
            }
        });  

        //RTU 일자별 장애 건수
        axiosInstance.get('/rtuEvtList').then((Response)=>{
            if (Response.request.status === 200) {                                  
                setRtuEvtCnt(Response.data.list);
                setRtuEvtDates(Response.data.categories);
            }
        });  

        let timerId = setTimeout(() => 
            {
                $("div[name=carousel-paper]").parent("div").css("height", "100%");
                $("div[name=carousel-paper]").parent("div").parent("div").parent("div").parent("div").parent("div").css("height", "100%");        
            }
        , 500);

        return () => {
            clearTimeout(timerId);
        };

    }, []);  

    return (
        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)", "height": "100%"}}>

            <Grid container sx={{ height:{xs:'2300px', sm:'1500px', md:'1000px', lg:'100%', xl:'100%'}}}>
                <Grid xs={12} sm={12} md={12} lg={5} xl={5}>
                    <Grid container sx={{ height:{xs:'80%', sm:'80%', md:'80%', lg:'80%', xl:'80%'}, p:1}}>                                                
                        <Box className="box" sx={{width:'100%', height:'100%', p:1}}>
                            <Grid xs={12} sx={{height:'5%'}}>
                                <Typography sx={{fontWeight:600, fontSize:16, pl:1}}>● 설치 현황
                                    {
                                        isErrSitePlay ? <PauseIcon style={{color:"#9a2538f0", marginBottom:"2px", cursor:"hand"}} onClick={() => setIsErrSitePlay(!isErrSitePlay)} />
                                        : <PlayIcon style={{color:"#0a58ca", marginBottom:"3px", cursor:"hand"}} onClick={() => setIsErrSitePlay(!isErrSitePlay)} />
                                    }
                                </Typography>                                
                            </Grid>
                            <KakaoMap ref={mapRef} height='95%'/>                        
                        </Box>
                    </Grid>
                    <Grid container sx={{ height:{xs:'20%', sm:'20%', md:'20%', lg:'20%', xl:'20%'}, p:1}}>
                        <Box className="box" sx={{width:'100%', height:'100%', p:1}}>
                            <Grid xs={12} sx={{height:'15%'}}>
                                <Typography sx={{fontWeight:600, fontSize:16, pl:1}}>● 장애 사이트
                                    {
                                        isErrSitePlay ? <PauseIcon style={{color:"#9a2538f0", marginBottom:"2px", cursor:"hand"}} onClick={() => setIsErrSitePlay(!isErrSitePlay)} />
                                        : <PlayIcon style={{color:"#0a58ca", marginBottom:"3px", cursor:"hand"}} onClick={() => setIsErrSitePlay(!isErrSitePlay)} />
                                    }
                                </Typography>                                
                            </Grid>

                            <Carousel id="carousel" sx={{p:1, width:'100%', height:'85%'}} 
                                      interval="8000" 
                                      duration="1000" 
                                      animation="slide"
                                      autoPlay={isErrSitePlay}
                                      navButtonsAlwaysVisible={false}
                                      onChange={onChange}
                                      indicatorIconButtonProps={{ style: { visibility:"hidden" }}}>                            

                                { rtuErrorRendering() }

                            </Carousel>
                        </Box>
                    </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={7} xl={7}>

                    <Grid container sx={{ height:{xs:'20%', sm:'20%', md:'20%', lg:'20%', xl:'20%'}, p:1}}>
                        <Box className="box" sx={{width:'100%', height:'100%', pt:1, pb:1, pl:1, pr:1}}>
                            <Grid xs={12} sx={{height:'15%', pl:2}}>
                                <Typography sx={{fontWeight:600, fontSize:16}}>● 업무현황</Typography>  
                            </Grid>
                            <Grid container sx={{height:'85%', pt:1, pl:2, pr:2, pb:1}} xs={12}>
                                <Grid xs={3} sx={{height:'100%', pr:1}}>
                                    {stateCard('요청', workCnt.requestCnt.toLocaleString(), "건", "#18b6a9", "white")}
                                </Grid>                                
                                <Grid xs={3} sx={{height:'100%', pr:1}}>
                                    {stateCard('진행', workCnt.progressCnt.toLocaleString(), "건", "#d76037", "white")}
                                </Grid>
                                <Grid xs={3} sx={{height:'100%', pr:1}}>
                                    {stateCard('완료', workCnt.completeCnt.toLocaleString(), "건", "#0e3f9a", "white")}
                                </Grid>                                
                                <Grid xs={3} sx={{height:'100%'}}>
                                    {stateCard('취소', workCnt.cancelCnt.toLocaleString(), "건", "#626770", "white")}
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid container sx={{ height:{xs:'40%', sm:'40%', md:'40%', lg:'40%', xl:'40%'}, p:1}}>
                        <Box className="box" sx={{width:'100%', height:'100%', pt:2, pb:1, pl:1, pr:1 }}>
                            <Grid xs={12} sx={{height:'10%'}}>
                                <Typography sx={{fontWeight:600, fontSize:16, pl:2}}>
                                    ● 영업 현황
                                    {
                                        isSalesPlay ? <PauseIcon style={{color:"#9a2538f0", marginBottom:"3px", cursor:"hand"}} onClick={() => setIsSalesPlay(!isSalesPlay)} />
                                        : <PlayIcon style={{color:"#0a58ca", marginBottom:"3px", cursor:"hand"}} onClick={() => setIsSalesPlay(!isSalesPlay)} />
                                    }
                                </Typography>                                
                            </Grid>

                            <Carousel id="carousel" sx={{width:'100%', height:'90%'}} 
                                      interval="8000" 
                                      duration="1000" 
                                      autoPlay={isSalesPlay}
                                      navButtonsAlwaysVisible={false}
                                      indicatorIconButtonProps={{ style: { visibility:"hidden" }}}>

                                <Paper name="carousel-paper" sx={{height:'100%', backgroundColor:'transparent'}}> 
                                    <Grid container sx={{height:'100%'}}>
                                        <Grid xs={6} sx={{height:'100%', borderRight:"1px solid #c6c4c480"}}>
                                            <CustomPieChart title="[제품 판매현황]" 
                                                            data={rtuModelCnt}/>                                            
                                        </Grid>
                                        <Grid xs={6} sx={{height:'100%'}}>                                            
                                            <CustomPieChart title="[신재생에너지 현황]" 
                                                            data={rtuNrnGentCnt} 
                                                            colors={['#50B432', 
                                                                     '#ED561B', 
                                                                     '#DDDF00', 
                                                                     '#24CBE5', 
                                                                     '#64E572', 
                                                                     '#FF9655', 
                                                                     '#FFF263', 
                                                                     '#6AF9C4']}/>                                                                                        
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper name="carousel-paper" sx={{height:'100%', backgroundColor:'transparent'}}> 
                                    <Grid container sx={{height:'100%'}}>                                        
                                        <Grid xs={12} sx={{height:'100%'}}>                                            
                                            <CustomColumnMuiltChart 
                                                title="[월별 제품 판매현황]"
                                                yTitle="대수 (개)"
                                                seriesFormat="{point.y:.0f} 대"                                                
                                                categories={monthCategory}
                                                data={rtuModelMonthCnt}
                                            />                                     
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper name="carousel-paper" sx={{height:'100%', backgroundColor:'transparent'}}> 
                                    <Grid container sx={{height:'100%'}}>                                        
                                        <Grid xs={12} sx={{height:'100%'}}>
                                            <CustomColumnChart 
                                                title="[지역별 제품 판매현황]" 
                                                yTitle="대수 (개)"
                                                data={rtuAreaCnt} 
                                                seriesName="eTU"
                                                seriesFormat="{point.y:.0f} 대"                                                
                                            />                                            
                                        </Grid>
                                    </Grid>
                                </Paper>

                            </Carousel>                            
                        </Box>
                    </Grid>
                    
                    <Grid container sx={{ height:{xs:'40%', sm:'40%', md:'40%', lg:'40%', xl:'40%'}, p:1}}>
                        <Box className="box" sx={{width:'100%', height:'100%', pt:2, pb:1, pl:1, pr:1}}>
                        <Grid xs={12} sx={{height:'10%'}}>
                                <Typography sx={{fontWeight:600, fontSize:16, pl:2}}>
                                    ● 장애 현황
                                    {
                                        isErrStatePlay ? <PauseIcon style={{color:"#9a2538f0", marginBottom:"3px", cursor:"hand"}} onClick={() => setIsErrStatePlay(!isErrStatePlay)} />
                                        : <PlayIcon style={{color:"#0a58ca", marginBottom:"3px", cursor:"hand"}} onClick={() => setIsErrStatePlay(!isErrStatePlay)} />
                                    }
                                </Typography>
                            </Grid>
                            
                            <Grid container sx={{height:'100%'}}>                                        
                                <Grid xs={12} sx={{height:'100%'}}>
                                    <HighchartsReact highcharts={Highcharts} options={options_6} />                                        
                                </Grid>
                            </Grid>                            
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
}

export default Main;