import React from 'react';

export default function PlaceHolder(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.size}
        height={props.size}
        viewBox="0 0 460.484 460.484"                 
        fill={props.color ? props.color : "currentColor"}>
            
        <path d="M230.243,0C131.291,0,51.074,76.167,51.074,170.124c0,117.504,123.829,245.185,165.598,284.911 c7.615,7.242,19.535,7.271,27.17,0.051C285.64,415.562,409.41,288.529,409.41,170.124C409.411,76.167,329.195,0,230.243,0z M230.243,247.063c-40.884,0-74.026-33.143-74.026-74.026c0-40.884,33.143-74.026,74.026-74.026s74.026,33.143,74.026,74.026 C304.269,213.921,271.126,247.063,230.243,247.063z"></path> 
            
    </svg>
  );
};