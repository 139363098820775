import React from "react";
import notFoundImage from "../../assets/image/not-found-image.jpg"

export default function NotFound() {   

    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <img src={notFoundImage} width="500" height="300" alt="not found!"/>
                </div>   
            </div>
	    </div>
    );
}