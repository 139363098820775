import React, {useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomTextField from "../components/ui/CustomTextField";
import { Box, Modal, Divider, Button } from '@mui/material';
import axiosInstance from '../components/util/AxiosUtil';
import CustomDatatable from '../components/ui/CustomDatatable';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import Toast from '../components/ui/Toast';
import { viewModeType } from '../components/const/CommCode'
import Confirm from '../components/ui/Confirm';
import CustomAutocomplete from "../components/ui/CustomAutocomplete";

const defaultFindValues = {
    grpCd : '',
    dtlCd : '',
    dtlCdNm : ''
}

const defaultValues = {
    grpCd : '',
    dtlCd : '',
    langCd : 'KOR',
    dtlCdNm : '',
    dtlCdDesc : '',
    upGrpCd : '',
    upDtlCd : '',
    adt1 : '',
    adt2 : '',
    adt3 : '',
    ord : '',
    useYn : ''
}


function CodeDtl() {

    //조회 기본세팅
    const { 
        handleSubmit : find_handleSubmit, 
        control : find_control, 
        getValues: find_getValues, 
        formState: { errors: find_errors } 
    } = useForm( {defaultFindValues} );

    // [modal] 등록,수정 Form 기본세팅
    const { 
        handleSubmit, 
        getValues, 
        reset, 
        control, 
        formState: { errors } 
    } = useForm( {defaultValues} );

    //검색필터
    const [filterData, setFilterData] = useState( {defaultFindValues} );
    //모달 노출 상태 정보
    const [open, setOpen] = useState(false);
    //데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);
    //서버 요청 상태 정보
    const [viewMode, setViewMode] = useState(viewModeType.New);

    //[검색] 그룹코드 목록
    const [grpCdList, setGrpCdList] = useState(([{grpCd: "", grpNm: "전체"}]));
    //[모달] 그룹코드 목록
    const [mdGrpCdList, setMdGrpCdList] = useState(([{grpCd: "", grpNm: ""}]));
    //[모달] 상위 그룹코드 목록
    const [mdUpGrpCdList, setMdUpGrpCdList] = useState(([{upGrpCd: "", upGrpCdLabel: "전체"}]));
    //[모달] 상위 상세코드 목록
    const [mdUpDtlCdList, setMdUpDtlCdList] = useState(([{upDtlCd: "", upDtlCdLabel: "전체"}]));
    //[모달] 사용여부
    const [useYnList, setUseYnList] = useState([{dtlCd: "", dtlCdNm: ""}]);
    //[모달] 이전 상세코드
    const [mdBeforeDtlCd, setMdBeforeDtlCd] = useState('');

    //[모달] 그룹코드 disabled처리
    const [grpDisabled, setGrpDisabled] = useState(false);

    //confrim ref 객체
    const confirmRef = useRef();
    //toast ref 객체
    const toastRef = useRef();

    useEffect(() => {
        makeGrpCdList();
        makeDtlCdList();
        makeUseYnList();
    }, []);

    const makeUseYnList = () => {
        //사용여부코드 목록 조회
        axiosInstance.get('/codeList', {params: {grpCd: 'USE_YN'}}).then((Response) => {
           if (Response.request.status === 200) {  
              
               setUseYnList(Response.data.list);                       
           }
       });
   }

    const makeGrpCdList = () => {
        //그룹 코드 목록 생성
        axiosInstance.get('/grpCdList').then((Response)=>{
            if (Response.request.status === 200) {  

                const list =  Response.data.list;
                const newList = [{grpCd: "", grpNm: "전체"}, ...list];
        
                //검색창 그룹코드
                setGrpCdList(newList);
                //모달 그룹코드
                setMdGrpCdList(list);

                //모달 상위 그룹코드
               const makeUpGrpList = list.map((item) => {
                    return {upGrpCd : item.grpCd, 
                            upGrpCdLabel : item.grpCd}
                });
                setMdUpGrpCdList([{upGrpCd: "", upGrpCdLabel: "없음"}, ...makeUpGrpList]);

            }
            dataload(find_getValues());
        });
    }

    //상위 상세코드 목록 조회
    const makeDtlCdList = () => {
           
        axiosInstance.get('/codeList', {params: {grpCd: getValues().upGrpCd}}).then((Response)=>{                
            if (Response.request.status === 200) {      
                const list = Response.data.list;
                const makeUpDtlCdList = list.map((item) => {
                    return {upDtlCd: item.dtlCd, 
                            upDtlCdLabel: item.dtlCd}
                });
                setMdUpDtlCdList([{upDtlCd: "", upDtlCdLabel: "없음"}, ...makeUpDtlCdList]);
                                   
            }
        });
    }

    //등록버튼 클릭 이벤트
    const regClickEvent = () => {

        //모달 초기화
        reset(defaultValues);

        setGrpDisabled(false);
        
        //등록으로 상태변경
        setViewMode(viewModeType.New);

        //모달 열기
        setOpen(true);
    }

     //테이블 컬럼정보
     const columns = [
        { id: "grpCd", data: "grpCd", title: '그룹코드' ,width: '15%',className: "text-center", responsivePriority: 1 },
        { id: "dtlCd", data: "dtlCd", title: '상세코드', width: '15%', className: "text-center",responsivePriority: 2 },
        { id: "dtlCdNm", data: "dtlCdNm", title: '상세코드명', width:'15%', className: "text-center", responsivePriority: 3 },
        { id: "ord", data: "ord", title: '순서', width:'15%',className: "text-center", responsivePriority: 4 },
        { id: "useYnNm", data: "useYnNm", title: '사용여부', width:'15%', className: "text-center", responsivePriority: 5  },
        { id: "cretDt", data: "cretDt", title: '생성일시', width:'15%',className: "text-center", responsivePriority: 6 },
        { id: "dtlCd", data: "dtlCd", title: '관리', width:'10%',className: "text-center", responsivePriority: 0, orderable:false }
    ]

    const columnDefs = [
        //수정, 삭제버튼
        {
            className: "text-center p-0", "targets":[6], render: function(data, type, row, meta){
                let customButton = "<button class='data-btn' data-grp='" + row.grpCd + "' data-dtl='" + row.dtlCd + "' data-type='update' style='margin:2px; padding:3px;'>수정</button>";

                customButton += "<button class='data-btn' data-grp='" + row.grpCd + "' data-dtl='" + row.dtlCd + "' data-type='delete' style='margin:2px; padding:3px;'>삭제</button>";                                    
                
                return customButton;
            }
        }
    ]

     //테이블 (수정, 삭제버튼) 클릭 콜백함수
     const buttonClick = (e) => {

        if(e.target.dataset.type === "update") {
            
            axiosInstance.get('/codeDtl', {params: {grpCd: e.target.dataset.grp,
                                                    dtlCd: e.target.dataset.dtl}}).then((Response)=>{
            
                if(Response.request.status === 200){
   
                    setMdBeforeDtlCd(e.target.dataset.dtl);
                    setGrpDisabled(true);
                    setPageFixed(false);
                    setViewMode(viewModeType.Edit);
                    reset(Response.data.info);
                    setOpen(true);
                }else{
                    toastRef.current.handleOpen("error", "오류입니다. 데이터를 조회 할 수 없습니다.");
                }
            });   

        }else if (e.target.dataset.type === "delete"){            
            confirmRef.current.handleOpen("상세 코드 정보 삭제", "코드 정보를 삭제 합니다. 계속 진행 하시겠습니까?", e);
        } 

    }

    const del = (e) =>{
        axiosInstance.delete('/codeDtl', {params: {grpCd: e.target.dataset.grp,
                                                    dtlCd: e.target.dataset.dtl}}).then((Response)=>{
            
            if(Response.request.status === 200){    
                if(Response.data.resultCode === "SUCCESS"){
                    toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
                    dataReload(find_getValues());
                }else if(Response.data.resultCode === "ERROR"){
                    toastRef.current.handleOpen("error", Response.data.resultMessage);                                        
                }   
              
            }else{                              
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }                  
        });
    }

    const onSubmit = (data) => {
        if (viewMode === viewModeType.New){
            axiosInstance.post('/codeDtl', {...data}).then((Response)=>{            
                if(Response.request.status === 200 ){  
                    
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                        setOpen(false);
                        dataReload(find_getValues());

                    //코드 중복
                    }else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage );
                    }

                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            });        
        }else if (viewMode === viewModeType.Edit){

            axiosInstance.put('/codeDtl', {beforeDtlCd : mdBeforeDtlCd, ...data}).then((Response)=>{            
                if(Response.request.status === 200){ 
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                        setOpen(false);
                        dataReload(find_getValues());
                    } else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage);                                        
                    }      
                    
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }
            });
        }
    }


    function dataReload(data){   
        setPageFixed(false);
        setFilterData(data);  
    }

    function dataload(data){
        setPageFixed(true);
        setFilterData(data);        
    }

    //input css style
    const textFileStyled = { mt:2, width: '100%', 'textAlign': 'center' };

    const customModal = {
        'position': 'absolute',
        top: '50%',
        left: '50%',
        'transform': 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: '800px',
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: '240px',
        pt: '20px',
        pl: '20px',
        pr: '20px',
        pb: '30px',
        maxHeight: '600px',
        'overflowY': 'auto'
    };

    return (
        <div style={{ 
            "padding": "16px 24px", 
            "color": "rgb(68, 89, 110)", 
            "height": "100%" 
        }}>
            <Grid container spacing={2}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>
                    <div style={{"marginBottom": "28px", "marginTop":"16px"}}>
                        <p fontWeight="600" className="css-1pr8naw">코드 관리</p>
                        <p className="css-3vvtry">PoSE / 시스템 관리 /코드 관리</p>
                    </div>
                </Grid>
                 {/* 검색창 */}
                 <Grid container 
                  alignItems="center" 
                  xs={12} sm={12} md={12} lg={12} xl={12} 
                  className="page-filter" 
                  sx={{p:0}}
                  justifyContent={'flex-end'}
                >
                    {/* 그룹코드 */}
                    <Grid xs={12} sm={6} md={6} lg={2.5} xl={3} sx={{ p: 2 }}>
                        <CustomAutocomplete 
                            name="grpCd"              
                            label="그룹코드"           
                            options={grpCdList}
                            value="grpCd"
                            text="grpCd"             
                            control={find_control}
                            getValues={find_getValues}
                            errors={find_errors.grpCd}
                        />
                    </Grid>

                     {/* 그룹코드명 */}
                     <Grid xs={12} sm={6} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomAutocomplete 
                            name="grpCd"              
                            label="그룹코드명"           
                            options={grpCdList}
                            value="grpCd"
                            text="grpNm"             
                            control={find_control}
                            getValues={find_getValues}
                            errors={find_errors.grpCd}
                        />
                    </Grid>

                    {/* 상세코드 */}
                    <Grid xs={12} sm={6} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField
                            fullWidth 
                            name="dtlCd"              
                            label="상세 코드"          
                            control={find_control}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){                                    
                                    dataReload(find_getValues());
                                }
                             }}
                        />
                    </Grid>

                    {/* 상세코드 명*/}
                    <Grid xs={12} sm={6} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField
                            fullWidth 
                            name="dtlCdNm"              
                            label="상세 코드명"          
                            control={find_control}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter'){                                    
                                    dataReload(find_getValues());
                                }
                             }}
                        />
                    </Grid>

                    {/* 조회, 등록버튼*/}
                    <Grid 
                        textAlign={'start'} 
                        alignItems="center" 
                        xs={12} sm={6} md={6} lg={3.5} xl={3}
                        sx={{p:0}}
                    >
                        <Button 
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ ml:2, height:'52px' }}  
                            startIcon={<SearchIcon />}
                            onClick={find_handleSubmit(dataload)}
                        >조회
                        </Button>
                        <Button
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ m:2, height:'52px' }} 
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={regClickEvent}
                        >등록
                        </Button>
                    </Grid>
                </Grid>

                    {/* table */}
                    <Grid container 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            className="page-filter" 
                            sx={{ p: 2, mt: 3 }}
                    >
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CustomDatatable id="table-list-0"               //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                            width="100%"                     //테이블 너비
                                            url="/dtlCodeList"               //ajax url
                                            columns={columns}                //테이블 컬럼 정의
                                            columnDefs={columnDefs}          //Custom 컬럼 정의
                                            filter={filterData}              //ajax 검색 필터 정의
                                            paging={true}                    //페이징 여부
                                            pageLength="10"                  //페이징 수
                                            buttonClick ={buttonClick}       //버튼 클릭 콜백함수
                                            pageFixed={pageFixed}            //페이징 유지 여부
                                            order={[[0, 'asc']]}             //기본 정렬
                            />
                        </Grid>
                    </Grid>
            </Grid>
             {/* 등록 / 수정 모달 */}
            <Modal open={open} 
                    onClose={e => setOpen(false)} 
                    aria-labelledby="parent-modal-title" 
                    aria-describedby="parent-modal-description" 
            >
                <Box className="flex-w" sx={customModal}>
                    <h3 id="parent-modal-title" className="limiter">코드 정보</h3>
                    <Divider sx={{ mt:2, width: '100%'}} />

                    {/* 그룹코드 */}
                    <Grid container xs={12} sm={6} md={6} lg={6} xl={6} sx={{mt:2, pr:1, pl:1}}>
                        <CustomAutocomplete fullWidth
                                            name="grpCd"
                                            label="*그룹코드"
                                            size="large"
                                            rules={{ required: "그룹 코드를 선택 해주세요." }}
                                            options={mdGrpCdList}      
                                            disabled={grpDisabled}
                                            value="grpCd"              
                                            text="grpCd"             
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.grpCd}
                        />
                    </Grid>

                    {/* 그룹코드 명 */}
                    <Grid container xs={12} sm={6} md={6} lg={6} xl={6} sx={{mt:2, pr:1, pl:1}}>
                        <CustomAutocomplete fullWidth
                                            name="grpCd"
                                            label="*그룹코드 명"
                                            size="large"
                                            rules={{ required: "그룹 코드명을 선택 해주세요." }}
                                            options={mdGrpCdList} 
                                            disabled={grpDisabled}    
                                            value="grpCd"
                                            text="grpNm"
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.grpCd}
                        />
                    </Grid>

                    {/* 상세코드 */}
                    <Grid container xs={12} sm={6} md={6} lg={6} xl={6} sx={{ pr:1, pl:1}}>
                        <CustomTextField 
                            fullWidth 
                            name="dtlCd" 
                            label="*상세 코드" 
                            control={control}
                            rules={{ required: "상세 코드를 입력 해주세요." }} 
                            sx={textFileStyled} 
                            errors={errors.dtlCd}
                        />
                    </Grid>

                    {/* 상세코드 명*/}
                    <Grid container xs={12} sm={6} md={6} lg={6} xl={6} sx={{ pr:1, pl:1}}>
                        <CustomTextField 
                            fullWidth 
                            name="dtlCdNm" 
                            label="*상세 코드명" 
                            control={control}
                            rules={{ required: "상세 코드명을 입력 해주세요." }} 
                            sx={textFileStyled} 
                            errors={errors.dtlCdNm}
                        />
                    </Grid>

                    {/* 상세코드 설명*/}
                    <Grid container xs={12} sm={6} md={6} lg={6} xl={6} sx={{ pr:1, pl:1}}>
                        <CustomTextField 
                            fullWidth 
                            name="dtlCdDesc" 
                            label="*상세 코드 설명" 
                            control={control}
                            rules={{ required: "상세 코드 설명을 입력 해주세요." }} 
                            sx={textFileStyled} 
                            errors={errors.dtlCdDesc}
                        />
                    </Grid>

                     {/* 언어코드*/}
                     <Grid container xs={12} sm={6} md={6} lg={6} xl={6} sx={{ pr:1, pl:1}}>
                        <CustomTextField 
                            fullWidth 
                            name="langCd" 
                            label="*언어코드" 
                            control={control}
                            rules={{ required: "언어 코드를 입력 해주세요." }}
                            sx={textFileStyled} 
                            errors={errors.langCd}
                        />
                    </Grid>

                    <Grid container xs={12}  sx={{mt:3}}></Grid>
                    
                    {/* 상위 그룹코드 */}
                    <Grid container xs={12} sm={6} md={6} lg={6} xl={6} sx={{mt:2, pr:1, pl:1}}>
                        <CustomAutocomplete fullWidth
                                            name="upGrpCd"
                                            label="상위그룹코드"
                                            size="large"
                                            options={mdUpGrpCdList}          
                                            value="upGrpCd"
                                            text="upGrpCdLabel"
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.upGrpCd}
                                            onChange={makeDtlCdList}
                        />
                    </Grid>

                    {/* 상위 상세코드 */}
                    <Grid container xs={12} sm={6} md={6} lg={6} xl={6} sx={{mt:2, pr:1, pl:1}}>
                        <CustomAutocomplete fullWidth
                                            name="upDtlCd"
                                            label="상위상세코드"
                                            size="large"
                                            options={mdUpDtlCdList}          
                                            value="upDtlCd"
                                            text="upDtlCdLabel"
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.upDtlCd}
                        />
                    </Grid>

                    {/* 순서*/}
                    <Grid container xs={12} sm={6} md={6} lg={6} xl={6} sx={{pr:1, pl:1}}>
                        <CustomTextField 
                            fullWidth 
                            name="ord" 
                            label="순서" 
                            type="number"
                            control={control}
                            sx={textFileStyled} 
                            errors={errors.ord}
                        />
                    </Grid>

                    {/* 사용여부 */}
                    <Grid container xs={12} sm={6} md={6} lg={6} xl={6} sx={{mt:2, pr:1, pl:1}}>
                        <CustomAutocomplete fullWidth
                                            name="useYn"              
                                            label="*사용여부"                                             
                                            rules={{ required: "사용여부를 선택 해주세요." }}                                                 
                                            options={useYnList}          
                                            value="dtlCd"              
                                            text="dtlCdNm"             
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.useYn}
                        />
                    </Grid> 

                    {/* 부가1*/}
                    <Grid container xs={12} sm={4} md={4} lg={4} xl={4} sx={{pr:1, pl:1}}>
                        <CustomTextField 
                            fullWidth 
                            name="adt1" 
                            label="부가1"
                            control={control}
                            sx={textFileStyled} 
                            errors={errors.adt1}
                        />
                    </Grid>
                    
                    {/* 부가2*/}
                    <Grid container xs={12} sm={4} md={4} lg={4} xl={4} sx={{pr:1, pl:1}}>
                        <CustomTextField 
                            fullWidth 
                            name="adt2" 
                            label="부가2" 
                            control={control}
                            sx={textFileStyled} 
                            errors={errors.adt2}
                        />
                    </Grid>
                    
                    {/* 부가3*/}
                    <Grid container xs={12} sm={4} md={4} lg={4} xl={4} sx={{pr:1, pl:1}}>
                        <CustomTextField 
                            fullWidth 
                            name="adt3" 
                            label="부가3" 
                            control={control}
                            sx={textFileStyled} 
                            errors={errors.adt3}
                        />
                    </Grid>

                    <Divider sx={{ mt:2, width: '100%'}} />
                    <Grid sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', mt:1}}>
                        <Button variant="contained" size="large" color="primary" startIcon={<SaveAltIcon />} onClick={handleSubmit(onSubmit)}>저장</Button>
                        <Button variant="contained" size="large" color="primary" startIcon={<CloseIcon />} sx={{ ml: 2 }} onClick={e => setOpen(false)}>닫기</Button>
                    </Grid>
                </Box>
            </Modal>
            <Toast ref={toastRef} />
            <Confirm ref={confirmRef} ok={del} />
        </div>
    );
}

export default CodeDtl;