import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { viewModeType } from '../components/const/CommCode'
import { Box, Modal, Divider, Button } from '@mui/material';
import CustomTextField from "../components/ui/CustomTextField";
import CustomDatatable from '../components/ui/CustomDatatable';
import Toast from '../components/ui/Toast';
import Confirm from '../components/ui/Confirm';
import Grid from '@mui/material/Unstable_Grid2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../components/util/AxiosUtil';


//검색 요청 기본 데이터
const defaultFindValues = {
    compNm: '',
    compTypeCd:'',
    reprNm:'',
    repCntcDesk:''
}

//등록, 수정 기본 데이터
const defaultValues = {
    compSeq: '',
    compTypeCd: '',
    compNm: '',
    reprNm: '',
    repCntcDesk: '',
    fxno: '',
    addr: '',
    compHpage: ''    
}

function Business() {

    //팝업 노출 상태 정보
    const [open, setOpen] = useState(false); 
    
    //데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);

    //서버 요청 상태 정보
    const [viewMode, setViewMode] = useState(viewModeType.New);

    const [filterData, setFilterData] = useState( {defaultFindValues} );

    // 조회 Form
    const { handleSubmit : find_handleSubmit, control : find_control, getValues: find_getValues, formState: { errors: find_errors } } = useForm( {defaultFindValues} );

    // 등록,수정 Form
    const { handleSubmit, reset, control, formState: { errors } } = useForm( {defaultValues} );

    //input css style
    const textFileStyled = { mt:2, width: '100%', 'textAlign': 'center' };

    //toast ref 객체
    const toastRef = useRef();

    //confrim ref 객체
    const confirmRef = useRef();

    const onSubmit = (data) => {        

        if (viewMode === viewModeType.New){
            axiosInstance.post('/buiness', {...data}).then((Response)=>{            
                if(Response.request.status === 200){     
                    toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                    setOpen(false);
                    dataReload(find_getValues());
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            });        
        }else if (viewMode === viewModeType.Edit){
            axiosInstance.put('/buiness', {...data}).then((Response)=>{            
                if(Response.request.status === 200){     
                    toastRef.current.handleOpen("success", "저장 되었습니다.");                                        
                    setOpen(false);
                    dataReload(find_getValues());
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }
            });
        }
    }

    //등록 버튼 클릭 이벤트
    const regClickEvent = () =>{
        setViewMode(viewModeType.New);
        reset({defaultValues}); 
        setOpen(true)
    }

    //테이블 컬럼 정보
    const columns = [
        { id: "compNm", data: "compNm", title: '회사명' ,width: '15%', responsivePriority: 1 },
        { id: "compTypeCd", data: "compTypeCd", title: '유형', width: '5%', className: "text-center",responsivePriority: 2 },
        { id: "reprNm", data: "reprNm", title: '대표자', width:'10%', className: "text-center", responsivePriority: 3 },
        { id: "repCntcDesk", data: "repCntcDesk", title: '대표연락처', width:'15%', className: "text-center", responsivePriority: 4 },
        { id: "fxno", data: "fxno", title: '팩스번호', width:'15%',className: "text-center", responsivePriority: 5 },
        { id: "cretDt", data: "cretDt", title: '등록일시', width:'15%',className: "text-center", responsivePriority: 6 },
        { id: "updtDt", data: "updtDt", title: '수정일시', width:'15%',className: "text-center", responsivePriority: 7 },
        { id: "compNm", data: "compNm", title: '관리', width:'10%', className: "text-center", responsivePriority: 0 },
    ]

    //테이블 커스텀 컬럼 정보
    const columnDefs = [
        {
            className: "text-center p-0", "targets": [7], render: function (data, type, row, meta) {                        
                return "<button class='data-btn' data-seq='" + row.compSeq + "' data-type='info' style='margin-right:5px'>상세</button>"
                     + "<button class='data-btn' data-seq='" + row.compSeq + "' data-type='delete' style='margin-right:5px'>삭제</button>"
            }            
        }
    ]

    //테이블 버튼 클릭 콜백 함수
    const buttonClick = (e) =>{
        if (e.target.dataset.type === "info"){
            axiosInstance.get('/buiness', {params: {compSeq: e.target.dataset.seq}}).then((Response)=>{
            
                if(Response.request.status === 200){
                    setPageFixed(false);
                    setViewMode(viewModeType.Edit);
                    reset(Response.data.info);
                    setOpen(true);
                }else{
                    toastRef.current.handleOpen("error", "오류입니다. 데이터를 조회 할 수 없습니다.");
                }
            });        
        }else if (e.target.dataset.type === "delete"){            
            confirmRef.current.handleOpen("업체 정보 삭제", "업체 정보를 삭제 합니다. 계속 진행 하시겠습니까?", e);
        }        
    }

    const del = (e) =>{
        axiosInstance.delete('/buiness', {params: {compSeq: e.target.dataset.seq}}).then((Response)=>{
            
            if(Response.request.status === 200){    
                toastRef.current.handleOpen("success", "삭제 되었습니다.");                                        
                dataReload(find_getValues());
            }else{                              
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }                  
        }); 
    }

    function dataReload(data){
        setPageFixed(false);
        setFilterData(data);        
    }

    function dataload(data){           
        setPageFixed(true);
        setFilterData(data);        
    }

    return (
        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)" }}>

            <Grid container spacing={2} sx={{ height: '100%' }}>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 0 }}>
                    <div style={{ "marginBottom": "16px" }}></div>
                    <div style={{ "marginBottom": "28px" }}>
                        <p fontWeight="600" className="css-1pr8naw">업체 관리</p>
                        <p className="css-3vvtry">PoSE / 시스템 관리 / 업체 관리</p>
                    </div>
                </Grid>

                <Grid container alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 0 }}>

                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField fullWidth 
                                         name="compNm" 
                                         label="회사명" 
                                         control={find_control} 
                                         onKeyDown={(e) => {
                                            if (e.key === 'Enter'){                                    
                                                dataReload(find_getValues());
                                            }
                                         }}
                        />  
                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField fullWidth 
                                         name="compTypeCd" 
                                         label="유형" 
                                         control={find_control} 
                                         onKeyDown={(e) => {
                                            if (e.key === 'Enter'){                                    
                                                dataReload(find_getValues());
                                            }
                                         }}
                        />
                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField fullWidth 
                                         name="reprNm" 
                                         label="대표자" 
                                         control={find_control} 
                                         onKeyDown={(e) => {
                                            if (e.key === 'Enter'){                                    
                                                dataReload(find_getValues());
                                            }
                                         }}
                        />
                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                        <CustomTextField fullWidth 
                                         name="repCntcDesk" 
                                         label="대표연락처" 
                                         control={find_control} 
                                         onKeyDown={(e) => {
                                            if (e.key === 'Enter'){                                    
                                                dataReload(find_getValues());
                                            }
                                         }}
                        />
                    </Grid>

                    <Grid alignItems="center" xs={12} sm={12} md={6} lg={4} xl={4} sx={{p:0}}>
                        <Button variant="contained" size="large" color="primary" sx={{ ml: 2, height:'52px' }} startIcon={<SearchIcon />} onClick={find_handleSubmit(dataload)}>조회</Button>
                        <Button variant="contained" size="large" color="primary" sx={{ m: 2, height:'52px' }} startIcon={<AddCircleOutlineIcon />} onClick={regClickEvent} >등록</Button>
                    </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="page-filter" sx={{ p: 2, mt: 3 }}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable id="table-list-0"          //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                   width="100%"                     //테이블 너비
                                   url="/buinessList"               //ajax url                                   
                                   columns={columns}                //테이블 컬럼 정의                                   
                                   columnDefs={columnDefs}          //Custom 컬럼 정의
                                   filter={filterData}              //ajax 검색 필터 정의       
                                   paging={true}                    //페이징 여부
                                   pageLength="10"                  //페이징 수
                                   buttonClick={buttonClick}        //버튼 클릭 콜백함수
                                   pageFixed={pageFixed}
                        />
                    </Grid>
                </Grid>

            </Grid>

            <Modal open={open} onClose={e => setOpen(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                <Box className="smModal">
                    <h3 id="parent-modal-title">업체 관리</h3>
                    <Divider sx={{ mt:2 }} />

                    <CustomTextField 
                            fullWidth
                            name="compSeq"                
                            label="업체고유번호"                              
                            control={control}                                                           
                            errors={errors.compSeq}                  
                            hidden
                    /> 

                    <CustomTextField 
                            fullWidth
                            name="compNm"                
                            label="*회사명"                              
                            control={control}                               
                            rules={{ required: "회사명을 입력 해주세요." }} 
                            errors={errors.compNm}                  
                            sx={textFileStyled} 
                    /> 
                    
                    <CustomTextField 
                            fullWidth
                            name="compTypeCd"                
                            label="유형"                              
                            control={control}                                                           
                            errors={errors.compTypeCd}                  
                            sx={textFileStyled} 
                    /> 

                    <CustomTextField 
                            fullWidth
                            name="reprNm"                
                            label="대표자"                              
                            control={control}                                                           
                            errors={errors.reprNm}                  
                            sx={textFileStyled} 
                    /> 

                    <CustomTextField 
                            fullWidth
                            name="repCntcDesk"                
                            label="대표연락처"                              
                            control={control}                                                           
                            errors={errors.repCntcDesk}                  
                            sx={textFileStyled} 
                    /> 

                    <CustomTextField 
                            fullWidth
                            name="fxno"                
                            label="팩스번호"                              
                            control={control}                                                           
                            errors={errors.fxno}                  
                            sx={textFileStyled} 
                    /> 

                    <CustomTextField 
                            fullWidth
                            name="addr"                
                            label="주소"                              
                            control={control}                                                           
                            errors={errors.addr}                  
                            sx={textFileStyled} 
                    /> 

                    <CustomTextField 
                            fullWidth
                            name="compHpage"                
                            label="홈페이지"                              
                            control={control}                                                           
                            errors={errors.compHpage}                  
                            sx={textFileStyled} 
                    /> 

                    <Divider sx={{ mt:2, mb:3 }} />
                    
                    <Grid container direction="row" justifyContent="flex-end">
                        <Button variant="contained" size="large" color="primary" startIcon={<SaveAltIcon />} onClick={handleSubmit(onSubmit)}>저장</Button>
                        <Button variant="contained" size="large" color="primary" startIcon={<CloseIcon />} sx={{ ml: 2 }} onClick={e => setOpen(false)}>닫기</Button>
                    </Grid>
                    
                </Box>
            </Modal>
            
            <Toast ref={toastRef} />

            <Confirm ref={confirmRef} ok={del} />
        </div>
    );
}

export default Business;