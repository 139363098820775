import React, { useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Button } from '@mui/material';
import { useForm } from "react-hook-form";
import CustomTextField from "../components/ui/CustomTextField";
import CustomSelect from "../components/ui/CustomSelect";
import CustomAutocomplete from "../components/ui/CustomAutocomplete";
import CustomCheckbox from "../components/ui/CustomCheckbox";
import CustomDatePicker from "../components/ui/CustomDatePicker";
import { getYYYYMMDD } from '../components/util/DateUtil';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const defaultValues = {
    textFieldValue: "",
    selectValue: "",
    autocompleteValue: "",
    checkValue: false,
    datePickerValue: getYYYYMMDD(new Date())
}

function Sample3() {

    const { handleSubmit, reset, control, getValues, formState: { errors } } = useForm({ defaultValues });

    const [data, setData] = useState(null);
    
    // select list
    const [options, setOptions] = useState([
        {key: "", value: ''},
        {key: "1", value: 'Option 1'},
        {key: "2", value: 'Option 2'},
        {key: "3", value: 'Option 3'},
        {key: "4", value: 'Option 4'},
        {key: "5", value: 'Option 5'},
        {key: "6", value: 'Option 6'},
    ]);

    // 저장 버튼 클릭
    const onSubmit = (data) => {
        setData(data);
    }

    return (
        <div style={{ "padding": "16px 24px", "color": "rgb(68, 89, 110)" }}>
            <div style={{ "marginBottom": "16px" }}></div>
            <div style={{ "marginBottom": "48px" }}>
                <p fontWeight="600" className="css-1pr8naw">Form Sample</p>
                <a href="https://v4.mui.com/getting-started/installation" className="css-3vvtry">https://v4.mui.com</a>
            </div>
        
            Form Sample.
            <Box sx={{width:"100%", border: '1px dashed grey', padding:2, mb:2}}>                
                <Grid container>

                    {/* TextField */}
                    <Grid xs={4} justifyContent="left" alignItems="left" sx={{p:2}}>
                        <CustomTextField 
                            name="textFieldValue"                           //name.
                            label="TextField."                              //label text.
                            control={control}                               //react hook form control.
                            rules={{ required: "필수 입력 값 입니다." }}     //react hook form 유효성 검증 규칙.
                            errors={errors.textFieldValue}                  //react hook form error field.
                        />                                                            
                    </Grid>   

                    {/* Select */}
                    <Grid xs={4} justifyContent="left" alignItems="left" sx={{p:2}}>
                        <CustomSelect 
                            name="selectValue"                          //name.
                            label="Select."                             //label text.
                            options={options}                           //옵션 목록 객체              
                            value="key"                                 //옵션 value 값 필드명
                            text="value"                                //옵션 text 값 필드명
                            control={control}                           //react hook form control.
                            rules={{ required: "필수 입력 값 입니다." }}  //react hook form 유효성 검증 규칙.
                            errors={errors.selectValue}                 //react hook form error field.                      
                        />
                    </Grid> 

                    {/* Autocomplete */}
                    <Grid xs={4} justifyContent="left" alignItems="left" sx={{p:2}}>
                        <CustomAutocomplete
                            name="autocompleteValue"                    //name.
                            label="Autocomplete."                       //label text.
                            options={options}                           //옵션 목록 객체              
                            value="key"                                 //옵션 value 값 필드명
                            text="value"                                //옵션 text 값 필드명
                            control={control}                           //react hook form control.
                            getValues={getValues}                       //react hook form getValues.
                            rules={{ required: "필수 입력 값 입니다." }}  //react hook form 유효성 검증 규칙.
                            errors={errors.autocompleteValue}           //react hook form error field.                                   

                        />                                                                        
                    </Grid>   

                    {/* datepicker */}
                    <Grid xs={4} justifyContent="left" alignItems="left" sx={{p:2}}>
                        <CustomDatePicker 
                            name="datePickerValue"  //name.
                            label="일자"            //label text.
                            control={control}       //react hook form control.
                        />
                    </Grid> 

                    {/* Checkbox */}
                    <Grid xs={4} justifyContent="left" alignItems="left" sx={{p:2}}>
                        <CustomCheckbox 
                            name="checkValue"               //name.
                                label="eTU 셋팅"            //label text.
                                control={control}           //react hook form control.
                            />
                                                                           
                        </Grid>
                    </Grid> 

                    <Grid xs={12} container justifyContent="flex-end" alignItems="center">
                        <Button variant="contained" size="large" color="primary" sx={{ ml: 2 }} startIcon={<SaveAltIcon />} onClick={handleSubmit(onSubmit)}>저장</Button> 
                        <Button variant="contained" size="large" color="primary" sx={{ ml: 2 }} startIcon={<RestartAltIcon />} onClick={()=>{reset({...defaultValues, autocompleteValue:"2"})}}>초기화</Button>                         
                    </Grid>                                        
                    
                </Box>      

                Button Sample.
                <Box sx={{width:"100%", border: '1px dashed grey', padding:2}}>  
                    <Grid container>
                        <Grid xs={12} container justifyContent="flex-end" alignItems="center">                        
                            <Button variant="contained" size="large" color="primary" startIcon={<SearchIcon />} sx={{ ml: 2 }}>조회</Button> 
                            <Button variant="contained" size="large" color="primary" startIcon={<AddCircleOutlineIcon />} sx={{ ml: 2 }}>등록</Button> 
                            <Button variant="contained" size="large" color="primary" startIcon={<SaveAltIcon />} sx={{ ml: 2 }}>저장</Button> 
                            <Button variant="contained" size="large" color="primary" startIcon={<RestartAltIcon />} sx={{ ml: 2 }}>초기화</Button> 
                            <Button variant="contained" size="large" color="primary" startIcon={<CloseIcon />} sx={{ ml: 2 }}>닫기</Button>                         
                        </Grid>                                        
                    </Grid>                  
                </Box>

                {
                    data && (
                    <pre style={{ textAlign: "left", color: "black" }}>
                        {JSON.stringify(data, null, 2)}
                    </pre>
                )}
                  
        </div>
    );
}

export default Sample3;