import React, {Component} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import mapData from '../../api/MapData';

require("highcharts/modules/map")(Highcharts);

class Map extends Component {
    constructor(props) {

      super(props);

      this.state = { mapData: null };

      // init to get the map data from api
      this.mapData = new mapData();

      // preparing the config of map with empty data
      this.options = {
        chart: {
            map: null
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: true,
            buttonOptions: {
                verticalAlign: 'bottom'
            }
        },
        colorAxis: {
            min: 0,
            minColor: '#f3f3f3',
            maxColor: Highcharts.getOptions().colors[3]
        },

        series: [{
            data: [],
            name: '장애건수',
            allowPointSelect: true,
            cursor: 'pointer',          
            states: {
                hover: {
                    color: '#BADA55'
                },
                select: {
                    color: '#a4edba',
                    borderColor: 'black',
                    dashStyle: 'shortdot'
                }
            },
            dataLabels: {
                enabled: true,                
                format: '{point.name}'
            },
            point: {
                events: {
                    click: function(){
                        alert(this.name);
                    }
                }
            }
        }],

        legend: {
          layout: 'vertical',
          backgroundColor: 'rgba(255,255,255,0.85)',
          floating: true,
          verticalAlign: 'bottom',
          align: 'right',
          symbolHeight: 450
        },
        
      };
  
      // get the world map data
      this.mapData.getWorld().then(r => {
        this.setState({ mapData: r.data }, () => {

          //make sure data is empty before  fill
          this.options.series[0].data = [
            ['kr-4194', 0], ['kr-kg', 11], ['kr-cb', 12], ['kr-kn', 13],
            ['kr-2685', 0], ['kr-pu', 15], ['kr-2688', 16], ['kr-sj', 17],
            ['kr-tj', 18], ['kr-ul', 19], ['kr-in', 20], ['kr-kw', 21],
            ['kr-gn', 5], ['kr-cj', 23], ['kr-gb', 0], ['kr-so', 0],
            ['kr-tg', 0], ['kr-kj', 0]
          ]; 

          for (let i in this.state.mapData.objects.default.geometries){     
            const geoName = this.state.mapData.objects.default.geometries[i].properties.name;                   
            this.state.mapData.objects.default.geometries[i].properties.name = this.mapData.getName(geoName);
          }    

          // set the map data of the graph (using the world graph)
          this.options["chart"]["map"] = this.state.mapData; 
 
          // updating the map options
          this.setState({ mapOptions: this.options });
        });
      });
    }
  
    render() {
      return (
        <div>
          {this.state.mapData ? (
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"mapChart"}
              options={this.state.mapOptions}
            />
          ) : (
            ""
          )}
        </div>
      );
    }
  }
  
  export default Map;
  