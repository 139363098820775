import React, {useRef, useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomTextField from "../components/ui/CustomTextField";
import { Box, Modal, Divider, Button } from '@mui/material';
import axiosInstance from '../components/util/AxiosUtil';
import CustomDatatable from '../components/ui/CustomDatatable';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import Toast from '../components/ui/Toast';
import { viewModeType } from '../components/const/CommCode'
import Confirm from '../components/ui/Confirm';
import CustomAutocomplete from "../components/ui/CustomAutocomplete";

const defaultFindValues = {
    grpCd : '',
    grpNm : ''
}

const defaultValues = {
    grpCd : '',
    grpNm : '',
    cdLen : '',
    grpDesc : '',
    upGrpCd : '',
    useYn : ''
}

function CodeGrp () {

     //조회 기본세팅
     const { 
        handleSubmit : find_handleSubmit, 
        control : find_control, 
        getValues: find_getValues, 
        formState: { errors: find_errors } 
    } = useForm( {defaultFindValues} );

    // [modal] 등록,수정 Form 기본세팅
    const { 
        handleSubmit, 
        getValues, 
        reset, 
        control, 
        formState: { errors } 
    } = useForm( {defaultValues} );

    //검색필터
    const [filterData, setFilterData] = useState( {defaultFindValues} );
    //모달 노출 상태 정보
    const [open, setOpen] = useState(false);
    //데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);
    //서버 요청 상태 정보
    const [viewMode, setViewMode] = useState(viewModeType.New);

    //[검색] 그룹코드 목록
    const [grpCdList, setGrpCdList] = useState(([{grpCd: "", grpNm: "전체"}]));
    //[모달] 그룹코드 목록
    const [mdUpGrpCd, setMdUpGrpCd] = useState(([{grpCd: "", grpNm: "전체"}]));
    //[모달] 사용여부
    const [useYnList, setUseYnList] = useState([{dtlCd: "", dtlCdNm: ""}]);

    //그룹코드 disabled처리
    const [grpDisabled, setGrpDisabled] = useState(false);

    //confrim ref 객체
    const confirmRef = useRef();
    //toast ref 객체
    const toastRef = useRef();

    useEffect(() => {
        makeGrpCdList();
        makeUseYnList();
    }, []);

    const makeGrpCdList = () => {
        //그룹 코드 목록 생성
        axiosInstance.get('/grpCdList').then((Response)=>{
            if (Response.request.status === 200) {  

                const list =  Response.data.list;
                const newList = [{grpCd: "", grpNm: "전체"}, ...list];
        
                //검색창 그룹코드
                setGrpCdList(newList);
                //모달 그룹코드
                setMdUpGrpCd(list);
            }
            dataload(find_getValues());
        });
    }

    const makeUseYnList = () => {
         //사용여부코드 목록 조회
         axiosInstance.get('/codeList', {params: {grpCd: 'USE_YN'}}).then((Response) => {
            if (Response.request.status === 200) {  
               
                setUseYnList(Response.data.list);                       
            }
        });
    }

    //등록버튼 클릭 이벤트
    const regClickEvent = () => {

        //모달 초기화
        reset(defaultValues);

        setGrpDisabled(false);

        //등록으로 상태변경
        setViewMode(viewModeType.New);

        //모달 열기
        setOpen(true);
    }

    //테이블 컬럼정보
    const columns = [
        { id: "grpCd", data: "grpCd", title: '그룹코드' ,width: '15%',className: "text-center", responsivePriority: 1 },
        { id: "grpNm", data: "grpNm", title: '그룹명', width: '15%', className: "text-center",responsivePriority: 2 },
        { id: "grpDesc", data: "grpDesc", title: '그룹설명', width:'15%', className: "text-center", responsivePriority: 3 },
        { id: "upGrpCd", data: "upGrpCd", title: '상위그룹코드', width:'15%',className: "text-center", responsivePriority: 4 },
        { id: "cdLen", data: "cdLen", title: '코드길이', width:'15%', className: "text-center", responsivePriority: 5  },
        { id: "useYnNm", data: "useYnNm", title: '사용여부', width:'15%',className: "text-center", responsivePriority: 6 },
        { id: "grpCd", data: "grpCd", title: '관리', width:'10%',className: "text-center", responsivePriority: 0, orderable:false }
    ]

    const columnDefs = [
        //수정, 삭제버튼
        {
            className: "text-center p-0", "targets":[6], render: function(data, type, row, meta){
                let customButton = "<button class='data-btn' data-cd='" + row.grpCd + "' data-type='update' style='margin:2px; padding:3px;'>수정</button>";

                customButton += "<button class='data-btn' data-cd='" + row.grpCd + "' data-type='delete' style='margin:2px; padding:3px;'>삭제</button>";                                    
                
                return customButton;
            }
        }
    ]

    //테이블 (수정, 삭제버튼) 클릭 콜백함수
    const buttonClick = (e) => {

        if(e.target.dataset.type === "update") {
            
            axiosInstance.get('/codeGrp', {params: {grpCd: e.target.dataset.cd}}).then((Response)=>{
            
                if(Response.request.status === 200){
   
                    setGrpDisabled(true);
                    setPageFixed(false);
                    setViewMode(viewModeType.Edit);
                    reset(Response.data.info);
                    setOpen(true);
                }else{
                    toastRef.current.handleOpen("error", "오류입니다. 데이터를 조회 할 수 없습니다.");
                }
            });   

        }else if (e.target.dataset.type === "delete"){            
            confirmRef.current.handleOpen("그룹 코드 정보 삭제", "코드 정보를 삭제 합니다. 계속 진행 하시겠습니까?", e);
        } 

    }

    const del = (e) =>{
        axiosInstance.delete('/codeGrp', {params: {grpCd: e.target.dataset.cd}}).then((Response)=>{
            
            if(Response.request.status === 200){    
                if(Response.data.resultCode === "SUCCESS"){
                    toastRef.current.handleOpen("success", "삭제 되었습니다.");    
                    makeGrpCdList();                                    
                    dataReload(find_getValues());
                }else if(Response.data.resultCode === "ERROR"){
                    toastRef.current.handleOpen("error", Response.data.resultMessage);                                        
                }   
              
            }else{                              
                toastRef.current.handleOpen("error", "오류입니다. 데이터를 삭제 할 수 없습니다.");
            }                  
        });
    }

    const onSubmit = (data) => {
        if (viewMode === viewModeType.New){
            axiosInstance.post('/codeGrp', {...data}).then((Response)=>{            
                if(Response.request.status === 200 ){  
                    
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다."); 
                        makeGrpCdList();                                       
                        setOpen(false);
                        dataReload(find_getValues());

                    //코드 중복
                    }else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage );
                    }

                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }                  
            });        
        }else if (viewMode === viewModeType.Edit){

            axiosInstance.put('/codeGrp', {...data}).then((Response)=>{            
                if(Response.request.status === 200){ 
                    if(Response.data.resultCode === "SUCCESS"){
                        toastRef.current.handleOpen("success", "저장 되었습니다.");         
                        makeGrpCdList();                               
                        setOpen(false);
                        dataReload(find_getValues());
                    } else if(Response.data.resultCode === "ERROR"){
                        toastRef.current.handleOpen("error", Response.data.resultMessage);                                        
                    }      
                    
                }else{                              
                    toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");                                        
                }
            });
        }
    }

    function dataReload(data){   
        setPageFixed(false);
        setFilterData(data);  
    }

    function dataload(data){
        setPageFixed(true);
        setFilterData(data);        
    }

    //input css style
    const textFileStyled = { mt:2, width: '100%', 'textAlign': 'center' };

    return (
        <div style={{ 
            "padding": "16px 24px", 
            "color": "rgb(68, 89, 110)", 
            "height": "100%" 
        }}>
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>
                    <div style={{"marginBottom": "28px", "marginTop":"16px"}}>
                        <p fontWeight="600" className="css-1pr8naw">코드 그룹 관리</p>
                        <p className="css-3vvtry">PoSE / 시스템 관리 /코드 그룹 관리</p>
                    </div>         
                </Grid>
                {/* 검색창 */}
                <Grid container 
                  alignItems="center" 
                  xs={12} sm={12} md={12} lg={12} xl={12} 
                  className="page-filter" 
                  sx={{p:0}}
                  justifyContent={'flex-end'}
                >
                    {/* 그룹코드 */}
                    <Grid xs={12} sm={6} md={6} lg={3} xl={3} sx={{ p: 2 }}>
                        <CustomAutocomplete 
                            name="grpCd"              
                            label="그룹코드"           
                            options={grpCdList}
                            value="grpCd"
                            text="grpCd"             
                            control={find_control}
                            getValues={find_getValues}
                            errors={find_errors.grpCd}
                        />
                    </Grid>

                    {/* 그룹코드명 */}
                    <Grid xs={12} sm={6} md={6} lg={3} xl={3} sx={{ p: 2 }}>
                        <CustomAutocomplete 
                            name="grpCd"              
                            label="그룹코드명"           
                            options={grpCdList}
                            value="grpCd"
                            text="grpNm"             
                            control={find_control}
                            getValues={find_getValues}
                            errors={find_errors.grpCd}
                        />
                    </Grid>
                    {/* 조회, 등록버튼*/}
                    <Grid 
                        textAlign={'start'} 
                        alignItems="center" 
                        xs={12} sm={12} md={12} lg={3.5} xl={3}
                        sx={{p:0}}
                    >
                        <Button 
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ ml:2, height:'52px' }}  
                            startIcon={<SearchIcon />}
                            onClick={find_handleSubmit(dataload)}
                        >조회
                        </Button>
                        <Button
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            sx={{ m:2, height:'52px' }} 
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={regClickEvent}
                        >등록
                        </Button>
                    </Grid>
                </Grid>

                {/* table */}
                <Grid container 
                        xs={12} sm={12} md={12} lg={12} xl={12} 
                        className="page-filter" 
                        sx={{ p: 2, mt: 3 }}
                >
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomDatatable id="table-list-0"               //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                        width="100%"                     //테이블 너비
                                        url="/grpCodeList"               //ajax url
                                        columns={columns}                //테이블 컬럼 정의
                                        columnDefs={columnDefs}          //Custom 컬럼 정의
                                        filter={filterData}              //ajax 검색 필터 정의
                                        paging={true}                    //페이징 여부
                                        pageLength="10"                  //페이징 수
                                        buttonClick ={buttonClick}       //버튼 클릭 콜백함수
                                        pageFixed={pageFixed}            //페이징 유지 여부
                                        order={[[0, 'asc']]}             //기본 정렬
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* 등록 / 수정 모달 */}
            <Modal open={open} 
                    onClose={e => setOpen(false)} 
                    aria-labelledby="parent-modal-title" 
                    aria-describedby="parent-modal-description" 
            >
                <Box className="smModal">
                    <h3 id="parent-modal-title" className="limiter">그룹코드 정보</h3>
                    <Divider sx={{ mt:2 }} />
                    {/* 그룹코드 */}
                    <CustomTextField 
                        fullWidth 
                        name="grpCd" 
                        label="*그룹코드" 
                        control={control}
                        disabled ={grpDisabled}
                        rules={{ required: "그룹코드를 입력 해주세요." }} 
                        sx={textFileStyled} 
                        errors={errors.grpCd}
                    />
                    {/* 그룹명 */}
                    <CustomTextField 
                        fullWidth 
                        name="grpNm" 
                        label="*그룹명" 
                        control={control}
                        rules={{ required: "그룹명을 입력 해주세요." }} 
                        sx={textFileStyled} 
                        errors={errors.grpNm}
                    />
                     {/* 그룹설명 */}
                     <CustomTextField 
                        fullWidth 
                        name="grpDesc" 
                        label="*그룹 설명" 
                        control={control}
                        rules={{ required: "그룹 설명을 입력 해주세요." }} 
                        sx={textFileStyled} 
                        errors={errors.grpDesc}
                    />

                    {/* 상위그룹코드 */}
                    <Grid sx={textFileStyled}>
                        <CustomAutocomplete 
                            fullWidth
                            name="upGrpCd"              
                            label="상위그룹코드"
                            options={mdUpGrpCd}          
                            value="grpCd"              
                            text="grpCd"            
                            control={control}          
                            getValues={getValues}      
                            errors={errors.upGrpCd}
                        />
                    </Grid>

                     {/* 코드 길이 */}
                     <CustomTextField 
                        fullWidth 
                        name="cdLen" 
                        label="코드 길이" 
                        type="number"
                        control={control}
                        sx={textFileStyled} 
                        errors={errors.cdLen}
                    />

                    <Grid sx={textFileStyled}>
                        <CustomAutocomplete fullWidth
                                            name="useYn"              
                                            label="*사용여부"                                             
                                            rules={{ required: "사용여부를 선택 해주세요." }}                                                 
                                            options={useYnList}          
                                            value="dtlCd"              
                                            text="dtlCdNm"             
                                            control={control}          
                                            getValues={getValues}      
                                            errors={errors.useYn}
                        />
                    </Grid>

                    <Divider sx={{ mt:2, mb:3 }} />
                    <Grid container direction="row" justifyContent="flex-end">
                        <Button variant="contained" size="large" color="primary" startIcon={<SaveAltIcon />} onClick={handleSubmit(onSubmit)}>저장</Button>
                        <Button variant="contained" size="large" color="primary" startIcon={<CloseIcon />} sx={{ ml: 2 }} onClick={e => setOpen(false)}>닫기</Button>
                    </Grid>
                </Box>
            </Modal>

            <Toast ref={toastRef} />
            <Confirm ref={confirmRef} ok={del} />
        </div>
    );
}

export default CodeGrp;